/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Suspense, lazy, useEffect } from "react"

import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom"
import { AuthProvider } from "./Context/auth-context"
import { CompanyProvider } from "./Context/company-context"
import { WorkspacesProvider } from "./Context/workspace-context"

import { ThemeProvider, CSSReset, Flex } from "@chakra-ui/core"
import { BrowserRouter as Router } from "react-router-dom"

// Context Providers
import { ActivitiesProvider } from "./Context/activities-context"
import { PitchesProvider } from "./Context/pitches-context"
import { useAuth } from "./Context/auth-context"
import { useWorkspaces } from "./Context/workspace-context"

import { useCompany } from "./Context/company-context"

import HuminateLoader from "./Components/HuminateLoader"
import { FriendlyModal } from "./Components/FriendlyModal"
import { FillButton } from "./Components/Buttons"
// Views
import { ActivityView } from "./Views/Activity"
import { SettingsView } from "./Views/Settings"
import { SearchResultUsers, SearchResultPitches } from "./Views/Search"
import theme from "./theme"
import { HText } from "./Components/StyledTextComponents"

// Import views and lazy-loaded modules here
// It is suggested that any views not needed for first render should be converted to a lazy-load
const CompleteProfileScreens = lazy(
  () => import("./Views/SignupFlow/CompleteProfileScreens")
)
const AdminDashboard = lazy(() => import("./Views/AdminDashboard"))
const MyTeam = lazy(() => import("./Views/MyTeam"))

const AccountManager = lazy(() => import("./Views/AccountManager"))

const CompanyView = lazy(() => import("./Views/Gallery"))

const EditPitch = lazy(() => import("./Views/PitchDisplay/EditPitch"))

import CheckYourWorkspaceEmail from "./Views/FindYourWorkspace/CheckYourWorkspaceEmail"
import StyleGuide from "./Views/StyleGuide"
import SignInToYourWorkspace from "./Views/FindYourWorkspace/SignIntoYourWorkspace"
import FindYourWorkspace from "./Views/FindYourWorkspace/FindYourWorkspace"
import DashboardHome from "./Views/Dashboard/DashboardHome"

const PitchDisplay = lazy(() => import("./Views/PitchDisplay/PitchDisplay"))
const ProfileDisplay = lazy(() => import("./Views/Profile/ProfileDisplay"))
const Posts = lazy(() => import("./Views/Posts"))

const WelcomeScreens = lazy(() => import("./Views/SignupFlow/WelcomeScreens"))
const WorkspacesDisplay = lazy(
  () => import("./Views/workspaces/workspacesDisplay")
)

const App: React.FC = () => {
  const { loading, logout, noCompanyAccess } = useAuth()
  console.log({ loading, logout, noCompanyAccess })
  return loading ? (
    <HuminateLoader />
  ) : noCompanyAccess ? (
    <FriendlyModal
      isOpen={noCompanyAccess}
      noButton
      title={"Access Issue"}
      onClose={() => logout()}
    >
      <Flex direction="column" justify="space-between" alignItems={"center"}>
        <HText my="48px">Sorry you do not have access to this workspace.</HText>
        <Flex justify="flex-end" w="100%">
          <FillButton my="16px" onClick={() => logout()}>
            Go Back
          </FillButton>
        </Flex>
      </Flex>
    </FriendlyModal>
  ) : (
    <>
      <Suspense fallback={<HuminateLoader />}>
        <Router>
          <Routes>
            <Route path="/style-guide" element={<StyleGuide />} />
            <Route
              path="/sign-in-workspace"
              element={<SignInToYourWorkspace />}
            />
            <Route path="/find-workspace" element={<FindYourWorkspace />} />
            <Route
              path="/check-workspace-email"
              element={<CheckYourWorkspaceEmail />}
            />
            <Route
              // Need to update to handle multiple companies here instead of just demo
              path="/:company/*"
              element={
                <CompanyProvider>
                  <WorkspacesProvider>
                    <PitchesProvider>
                      <ActivitiesProvider>
                        <CompanyRoutes />
                      </ActivitiesProvider>
                    </PitchesProvider>
                  </WorkspacesProvider>
                </CompanyProvider>
              }
            />
            <Route
              path="*"
              element={<Navigate replace to="/sign-in-workspace" />}
            />
          </Routes>
        </Router>
      </Suspense>
    </>
  )
}

export const urls = (company?: string, workspace?: string) => {
  return {
    welcome: `${company}/welcome`,
    dashboard: `${company}/user_dashboard/${workspace}`,
    activity: `${company}/user_dashboard/activity`,
    createAPitch: `${company}/user_dashboard/${workspace}/create-a-pitch`,
    viewPitch: `${company}/pitch`,
    companyView: `${company}/company_view`,
    workspacesView: `${company}/workspaces_view`,
    userSkillSearchView: `${company}/users_view`,
    pitchSearchView: `${company}/pitches`,
    profile: `${company}/profile`,
    settings: `${company}/settings`,
    adminDashboard: `${company}/admin-dashboard`,
    myTeam: `${company}/my-team`,
    accountManager: `${company}/account-manager`,
    requests: `${company}/requests`,
    posts: `${company}/posts`,
  }
}

const CompanyRoutes: React.FC = () => {
  const { user, loading, userProfile, userProfileLoading } = useAuth()
  const { pathname } = useLocation()
  let w = ":workspace"
  const { company: companySlug } = useParams() as any
  const { setCompanyUrl, companyUrl } = useCompany()
  const c = pathname.split("/")[0] || companySlug
  const { workspaces } = useWorkspaces()
  const navigate = useNavigate()
  console.log({ workspaces })
  if (workspaces) {
    w = workspaces[0].name
  }
  // // If the user hasn't finished signing up, restrict the routes to signup views.
  // if (!userProfile || (userProfile && userProfile.finishedSignUp !== true)) {
  //   console.info(`User hasn't finished signing up, redirect to welcome flows`)
  //   navigate(urls(c).welcome)
  // }

  useEffect(() => {
    const allowedCompanies = ["demo", "huminate"]
    console.log({ companySlug })
    if (
      !userProfileLoading &&
      (!userProfile || userProfile.finishedSignUp !== true)
    ) {
      console.log({ userProfile })
      console.info(`User hasn't finished signing up, redirect to welcome flows`)
      setCompanyUrl(`${companySlug}`)
      navigate(`/${companySlug}/welcome`)
    } else if (allowedCompanies.indexOf(companySlug) === -1) {
      navigate("/demo/user_dashboard")
    } else {
      setCompanyUrl(`${companySlug}`)
    }
  }, [userProfile, companySlug, userProfileLoading, companyUrl, workspaces])
  console.log({ user, loading, userProfileLoading, companyUrl })

  console.log({ c })
  // Initial Loading State
  if (
    user === undefined ||
    loading ||
    userProfileLoading ||
    companyUrl === "" ||
    w === ":workspace" ||
    c === ""
  ) {
    return <HuminateLoader />
  }
  console.log("not loading")
  console.log({ user, loading, userProfileLoading, companyUrl, w, c })

  return (
    <Routes>
      <Route path={`/admin-dashboard`} element={<AdminDashboard />} />
      <Route path={"/welcome"} element={<WelcomeScreens />} />
      <Route path={urls("").activity} element={<ActivityView />} />
      <Route path={"/user_dashboard/:workspace"} element={<DashboardHome />} />

      <Route path={urls("").workspacesView} element={<WorkspacesDisplay />} />

      <Route path={urls("").myTeam} element={<MyTeam />} />
      <Route path={urls("").accountManager} element={<AccountManager />} />

      <Route path={urls("").companyView} element={<CompanyView />} />

      <Route
        path={urls("").userSkillSearchView}
        element={<SearchResultUsers />}
      />
      <Route
        path={urls("").pitchSearchView}
        element={<SearchResultPitches />}
      />
      <Route path={urls("").posts} element={<Posts />} />

      <Route
        path={`${urls(c, w).createAPitch}/newPitch`}
        element={<EditPitch />}
      />

      <Route
        path={`${urls("").viewPitch}/:pitchId/edit`}
        element={<EditPitch />}
      />
      <Route
        path={`${urls("").viewPitch}/:pitchId`}
        element={<PitchDisplay />}
      />
      <Route
        path={`${urls("").profile}/:userId`}
        element={<ProfileDisplay />}
      />
      <Route path={`${urls("").profile}`} element={<ProfileDisplay />} />
      <Route path={`${urls("").settings}`} element={<SettingsView />} />

      <Route
        path={`*`}
        element={<Navigate replace to={`/${urls(c, w).dashboard}`} />}
      />
    </Routes>
  )
}

const FullApp = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <CSSReset />
      <App />
    </ThemeProvider>
  </AuthProvider>
)

export default FullApp
