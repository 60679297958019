import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  InputGroup,
  InputRightElement,
  Link,
  Radio,
  Tag,
  RadioGroup,
  useDisclosure,
} from "@chakra-ui/core"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { format } from "date-fns"
//@ts-ignore
import gradient from "random-gradient"
import { useWorkspaces } from "../Context/workspace-context"
import {
  IUserProfile,
  IPitch,
  IInvite,
  InviteStatuses,
  PitchMemberTypes,
} from "../types"
import { PersonProfileModal } from "../Views/CreateAPitch/modals/personalProfileModal"
import { InviteLinkWPopover } from "./InviteLinkWithPopover"
import { HInput, HText } from "./StyledTextComponents"
import { usePitches } from "../Context/pitches-context"
import { useAuth } from "../Context/auth-context"
import { FillButton } from "./Buttons"

export const EmailInvitePeople: React.FC = () => {
  const { user } = useAuth()
  const { currentPitch, createInvite, createAdminInvite } = usePitches()
  const { users } = useWorkspaces()

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        type: PitchMemberTypes.collaborator,
      }}
      validate={(values) => {
        const errors: any = {}
        if (!values.name) {
          errors.name = "Name must not be blank"
        }
        if (!values.email) {
          errors.email = "Email must not be blank"
        }

        return errors
      }}
      onSubmit={(values, { resetForm }) => {
        //    here goes logic to invite by email

        const userAlreadyExists =
          users && users.find(({ email }) => email === values.email)

        if (userAlreadyExists) {
          const newInvite: IInvite = {
            status: InviteStatuses.pending,
            from: user?.sub,
            to: userAlreadyExists.uid,
            subject: {
              reference: currentPitch?.id,
              type: PitchMemberTypes.collaborator,
            },
          }
          currentPitch && createInvite(newInvite)
        } else {
          const newInvite: IInvite = {
            status: InviteStatuses.pending,
            from: user?.sub,
            toOutsideEmail: values.email,
            toOutsideName: values.name,
            subject: {
              reference: currentPitch?.id,
              type: values.type,
            },
          }
          currentPitch && createAdminInvite(newInvite)
        }
        resetForm()
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <HText mb={2}>
            This will send a request to your admin to add this person to your
            workspace.
          </HText>

          <Field name="name">
            {({ field, form }: any) => (
              <FormControl
                isRequired={true}
                isInvalid={form.errors.name && form.touched.name}
                marginBottom="32px"
              >
                <FormLabel fontWeight="bold" color="fontBlack" htmlFor="name">
                  Name
                </FormLabel>
                <HInput
                  mt="8px"
                  variant="filled"
                  data-testid="first-name"
                  {...field}
                  id="name"
                />
                <FormErrorMessage
                  style={{ height: "17px", marginBottom: "-25px" }}
                >
                  {form.errors.name}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email">
            {({ field, form }: any) => (
              <FormControl
                isRequired={true}
                isInvalid={form.errors.email && form.touched.email}
                marginBottom="32px"
              >
                <FormLabel fontWeight="bold" color="fontBlack" htmlFor="email">
                  Email
                </FormLabel>
                <HInput
                  mt="8px"
                  data-testid="last-name"
                  {...field}
                  id="email"
                />
                <FormErrorMessage
                  style={{ height: "17px", marginBottom: "-25px" }}
                >
                  {form.errors.email}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Flex justify="flex-start" mt={8} w="100%" flexShrink={0}>
            <FillButton type="submit" mb={4}>
              Invite to Pitch{" "}
            </FillButton>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
export const InvitePeople = () => {
  const [selectedPerson, setSelectedPerson] = useState<IUserProfile>()
  const { filteredResults, setSearchFilter, searchFilter } = useWorkspaces()
  const { currentPitch } = usePitches()
  const { userProfile } = useAuth()
  const { onClose, onOpen, isOpen } = useDisclosure()
  const tags = currentPitch?.skillTags

  return (
    <Box>
      <InputGroup height="48px">
        <HInput
          height="100%"
          minHeight="40px"
          mr={2}
          data-testid="name-skills"
          value={searchFilter || ""}
          onChange={(e: any) => setSearchFilter(e.target.value)}
          placeholder="Search name or skills"
        />
        <InputRightElement h="100%" pr={5}>
          <Icon name="search-2" color="gray.300" />
        </InputRightElement>
      </InputGroup>
      <Box
        w="100%"
        height="340px"
        data-testid="suggestion-results"
        overflowY="auto"
      >
        {filteredResults &&
          userProfile &&
          filteredResults
            .filter(({ uid }) => {
              if (
                userProfile &&
                userProfile?.manager &&
                (userProfile.manager.uid === uid || userProfile.uid === uid)
              ) {
                return false
              } else {
                return true
              }
            })
            .sort((a, b) => {
              let aCount = 0
              let bCount = 0
              tags &&
                tags.map(({ tag }: any) => {
                  if (
                    a &&
                    a.tags &&
                    a.tags.filter(({ id }) => id === tag.id).length > 0
                  ) {
                    aCount++
                  }
                  if (
                    b &&
                    b.tags &&
                    b.tags.filter(({ id }) => id === tag.id).length > 0
                  ) {
                    bCount++
                  }
                })
              return bCount - aCount
            })
            .map((user, i) => {
              if (!user || !user.lastName) {
                return <></>
              }
              return (
                <Flex
                  margin="16px 8px"
                  align="center"
                  key={i}
                  justify="space-between"
                >
                  <Flex>
                    <Avatar
                      h="40px"
                      w="40px"
                      src={user.photo}
                      name={user.firstName + " " + user.lastName}
                      background={gradient(user.lastName)}
                      mr="12px"
                    />
                    <Flex direction="column">
                      <Link
                        color="textLink"
                        onClick={() => {
                          setSelectedPerson(user)
                          onOpen()
                        }}
                      >
                        {user.firstName + " " + user.lastName}
                      </Link>
                      <HText>{user.position}</HText>
                      <Flex>
                        {user &&
                          user.tags &&
                          tags &&
                          user.tags
                            .filter(({ id: userTag }) => {
                              return tags.filter(
                                ({ tag }: any) => tag.id === userTag
                              ).length > 0
                                ? true
                                : false
                            })
                            .map(({ id, text }) => (
                              <Tag
                                key={id}
                                variant={"solid"}
                                backgroundColor="#78E1D4"
                                color="fontDarkgray"
                                size="sm"
                                mr="2px"
                              >
                                {text}
                              </Tag>
                            ))}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex justify="flex-start">
                    <InviteLinkWPopover person={user} />
                  </Flex>
                </Flex>
              )
            })}
      </Box>
      <PersonProfileModal
        userProfile={selectedPerson}
        onClose={onClose}
        isOpen={isOpen}
      />
    </Box>
  )
}
