import {
  Avatar,
  Box,
  Flex,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionHeader,
  IconButton,
  Input,
  AvatarGroup,
  Badge,
  Button,
  Divider,
} from "@chakra-ui/core"
import { format, addDays } from "date-fns"
import { Fade } from "react-awesome-reveal"
import { FaThumbtack } from "react-icons/fa"

import React, { Dispatch, SetStateAction, useState } from "react"
import { BackButton } from "../../Components/BackIcon"
import { HHeading, HText } from "../../Components/StyledTextComponents"
import { useAuth } from "../../Context/auth-context"
import { useCompany } from "../../Context/company-context"
import { usePitches } from "../../Context/pitches-context"
import { useWorkspaces } from "../../Context/workspace-context"
import { IPitch, ITask, ITaskList, PitchStatus } from "../../types"
import { CreateTaskListModal, EditTaskModal } from "./Modals/TaskModals"
import { DeleteTaskListModal } from "./Modals/DeleteTaskListModal"
import { isMobile } from "../../Components/isMobile"
import { PitchActionButton } from "./PitchDisplay"

//@ts-ignore
import gradient from "random-gradient"

export const PitchTasks: React.FC = () => {
  const { companyUrl: company } = useCompany()
  const { user, userProfile, updateUserProfile } = useAuth()
  const { currentPitch, taskLists, updateTaskList, userCanSee } = usePitches()
  const [currentTaskList, setCurrentTaskList] = useState<number>(0)
  const [showCompleted, setShowCompleted] = useState<any>({})
  const [editTaskList, setEditTaskList] = useState<string | undefined>()
  const [editTaskListName, setEditTaskListName] = useState<string | undefined>()

  const [showDeleteTaskList, setShowDeleteTaskList] =
    useState<ITaskList | null>(null)

  const { addTodoList, createTask } = userCanSee(currentPitch, user?.sub)
  const {
    onClose: onCloseAddListModal,
    onOpen: OnOpenAddListModal,
    isOpen: isOpenAddListModal,
  } = useDisclosure()
  const [addTask, setAddTask] = useState<string | undefined>()
  const isMobileDevice = isMobile()

  const isMember =
    currentPitch?.members?.find((x: string) => x === user?.sub) !== undefined
  const isChampion =
    currentPitch?.champions?.find((x: string) => x === user?.sub) !== undefined
  const isManager = currentPitch?.manager?.uid === user?.sub
  console.log(currentPitch)
  console.log(taskLists)
  const taskList = taskLists?.filter(({ pitch }) => pitch === currentPitch?.id)
  console.log({ taskList })
  return (
    <>
      {((currentPitch && currentPitch?.status === PitchStatus.draft) ||
        currentPitch?.status === PitchStatus.overdueSubmission) &&
        taskList &&
        taskList.length > 0 &&
        taskList[0].tasks &&
        taskList[0].tasks.length > 0 && (
          <Box
            borderRadius="8px"
            d="flex"
            alignItems="center"
            margin="8px"
            padding="8px"
            w="100%"
            justifyContent="center"
            backgroundColor="rgb(200, 234, 254)"
            border="1px solid #203854"
          >
            <HText whiteSpace="pre-line" m={0} color="#203854">
              Tasks cannot be completed until the pitch has approved.
            </HText>
          </Box>
        )}
      <Flex justify="flex-start" w="100%">
        {addTodoList && (
          <Button
            leftIcon="add"
            color="white"
            backgroundColor="darkBlue"
            fontSize="md"
            size="sm"
            mb={4}
            fontWeight="500"
            onClick={OnOpenAddListModal}
          >
            Add a new list
          </Button>
          // <PitchActionButton
          //   mr="8px"
          //   data-testid="submit-pitch"
          // >
          //   <Icon name="addList" aria-label="reply" mr={2} mt={1} />

          // </PitchActionButton>
        )}
      </Flex>
      <Box w="100%">
        {taskLists?.map((taskList, taskListIdx) =>
          editTaskList === taskList.id ? (
            <Flex
              boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05)"
              backgroundColor="white"
              mb={4}
              align="center"
              height="78px"
              mx="8px"
            >
              <Input
                defaultValue={taskList.name}
                value={editTaskListName}
                onChange={(event: any) =>
                  setEditTaskListName(event.target.value)
                }
                size="sm"
                width="240px"
                autoFocus
                fontWeight="bold"
                height="24px"
              />
              <IconButton
                //@ts-ignore
                icon="check"
                aria-label="Edit task list name"
                h="36px"
                variant="ghost"
                w="36px"
                onClick={(e) => {
                  if (taskList && taskList.id && editTaskListName) {
                    user?.sub &&
                      updateTaskList(
                        taskList?.id,
                        {
                          ...taskList,
                          name: editTaskListName,
                        },
                        user.sub
                      )
                  }

                  setEditTaskList("")
                }}
              />
              <IconButton
                //@ts-ignore
                icon="small-close"
                aria-label="Edit task list name"
                h="36px"
                variant="ghost"
                w="36px"
                onClick={(e) => {
                  setEditTaskListName(taskList.name)
                  setEditTaskList("")
                }}
              />
            </Flex>
          ) : (
            <>
              <Flex
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                mx="8px"
                borderBottom="1px solid #E2E8F0"
              >
                <Box
                  flex="1"
                  display="flex"
                  alignItems="center"
                  onClick={(e) => e.preventDefault()}
                >
                  <>
                    <HText as="b">{taskList.name}</HText>
                    <IconButton
                      //@ts-ignore
                      icon="edit"
                      aria-label="Edit task list name"
                      h="36px"
                      variant="ghost"
                      w="36px"
                      onClick={() => {
                        setEditTaskList(taskList?.id)
                      }}
                    />
                  </>
                </Box>
                {taskList.createdBy === user?.sub || isChampion || isManager ? (
                  <IconButton
                    ml={2}
                    onClick={() => {
                      setShowDeleteTaskList(taskList)
                    }}
                    variant="ghost"
                    aria-label="Delete TaskList"
                    icon="delete"
                  />
                ) : (
                  <></>
                )}
              </Flex>
              <Flex justify="space-between" align="center">
                {createTask ? (
                  <Flex
                    cursor="pointer"
                    m={2}
                    ml={4}
                    onClick={() => setAddTask(taskList.id)}
                    alignItems="center"
                  >
                    <Icon name="addList" aria-label="reply" mr={2} />
                    <HText
                      color="textLink"
                      fontSize="md"
                      fontWeight="semi-bold"
                      data-testid={`new-task-${taskList.name}`}
                    >
                      New Task
                    </HText>
                  </Flex>
                ) : (
                  <div></div>
                )}
                <Flex justify="center">
                  {typeof taskList?.id === "string" ? (
                    <Link
                      color="textLink"
                      opacity={showCompleted ? 1 : 0.7}
                      mb={4}
                      onClick={() =>
                        setShowCompleted({
                          [taskList.id as string]:
                            !showCompleted[taskList.id as string],
                        })
                      }
                    >
                      {showCompleted[taskList.id as string]
                        ? "Hide completed tasks"
                        : "Show completed tasks"}
                    </Link>
                  ) : (
                    <></>
                  )}
                </Flex>
                <Divider />
              </Flex>
              <Stack spacing={isMobileDevice ? 1 : 2}>
                {taskList.tasks
                  .filter(
                    (task) =>
                      showCompleted[taskList.id as string] ||
                      task.isCompleted !== true
                  )
                  .sort((a, b) =>
                    a.isHighPriority ? (b.isHighPriority ? 0 : -1) : 1
                  )
                  .map((task, i) => (
                    <TaskListItem
                      taskList={taskLists[currentTaskList]}
                      pitch={currentPitch}
                      key={i}
                      task={task}
                      company={company}
                    />
                  ))}
              </Stack>
            </>
          )
        )}
      </Box>
      <CreateTaskListModal
        pitch={currentPitch}
        isOpen={isOpenAddListModal}
        onClose={onCloseAddListModal}
        company={company}
      />

      <EditTaskModal
        pitch={currentPitch}
        readOnly={
          currentPitch?.status === PitchStatus.complete ||
          !(isMember || isManager)
        }
        isOpen={addTask !== undefined}
        taskList={taskLists?.find(({ id }) => id === addTask)}
        onClose={() => setAddTask(undefined)}
        company={company}
      />
      <DeleteTaskListModal
        taskList={showDeleteTaskList}
        isOpen={showDeleteTaskList !== null}
        onClose={() => setShowDeleteTaskList(null)}
      />
    </>
  )
}

interface TaskListItemProps {
  task: ITask
  company: string
  pitch: IPitch | undefined
  taskList: ITaskList | undefined
}
export const TaskListItem: React.FC<TaskListItemProps> = ({
  task,
  company,
  pitch,
  taskList,
}) => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const { users } = useWorkspaces()
  const { updateTaskList, userCanSee } = usePitches()
  const { user } = useAuth()
  const { editTask } = userCanSee(pitch, user?.sub)
  const isMobileDevice = isMobile()
  const isMember = pitch?.members.find((x: string) => x === user?.sub)
  const isManager = pitch?.manager?.uid === user?.sub
  const readOnly =
    pitch?.status === PitchStatus.complete ||
    !(isMember || isManager) ||
    task.isCompleted

  return (
    <Fade>
      <Box
        mb="12px"
        width={"100%"}
        opacity={readOnly ? 0.7 : 1}
        padding={4}
        background="white"
        box-shadow={"0px 8px 16px rgba(0, 0, 0, 0.5)"}
      >
        <Flex>
          <Flex
            as="button"
            outline="none"
            direction="column"
            justify={"flex-start"}
            pr="8px"
            onClick={() => {
              if (
                taskList?.id &&
                editTask &&
                pitch?.status === PitchStatus.inProgress
              ) {
                task.isCompleted = !task.isCompleted
                user?.sub && updateTaskList(taskList.id, taskList, user?.sub)
              }
            }}
          >
            {!task.isCompleted ? (
              <Box data-testid={`mark-completed-${task.title}`}>
                {!isMobileDevice}
                <Icon name="status" fontSize="xl" />
              </Box>
            ) : (
              <Box data-testid={`mark-uncompleted-${task.title}`}>
                {!isMobileDevice}
                <Icon name="complete" fontSize="xl" />
              </Box>
            )}
          </Flex>

          <Flex
            as="button"
            onClick={onOpen}
            justify="space-between"
            width="100%"
          >
            <Flex justify="flex-start">
              <HHeading
                fontSize="md"
                fontWeight="bold"
                marginTop="4px"
                w="100%"
                maxWidth="350px"
                //@ts-ignore
                textOverflow="ellipsis"
                //@ts-ignore
                whiteSpace="nowrap"
                //@ts-ignore
                overflow="hidden"
              >
                {task.title}
              </HHeading>
            </Flex>
            {/* <HText
              id="preview"
              fontSize="md"
              maxWidth="350px"
              maxHeight="72px"
              minHeight="32px"
              fontWeight="normal"
              //@ts-ignore
              textOverflow="ellipsis"
              textAlign="left"
              //@ts-ignore
              overflow="hidden"
            >
              {task.description}
            </HText> */}
            <Stack isInline spacing={6}>
              <Flex align="center">
                {task.dueDate && task.dueDate < Date.now() ? (
                  <>
                    <Icon name="dateBoard" mr="4px" color="red.500" />
                    <HText color="warning">
                      {format(task.dueDate, "dd/LL")}
                    </HText>
                  </>
                ) : task.dueDate &&
                  task.dueDate < addDays(new Date(), 3).getTime() ? (
                  <>
                    <Icon name="dateBoard" mr="4px" color="red.500" />
                    <HText color="yellow1">
                      {format(task.dueDate, "dd/LL")}
                    </HText>
                  </>
                ) : (
                  <>
                    <Icon name="dateBoard" mr="4px" color="fontSmall" />
                    <HText color="fontSmall">
                      {format(task.dueDate, "dd/LL")}
                    </HText>
                  </>
                )}
              </Flex>
              {task?.member && task?.member.length > 0 && (
                <Flex mr={2} flexWrap="wrap" maxW="58px">
                  <AvatarGroup size="sm" max={2} mr="8px">
                    {task.member?.map((member): any => {
                      const user = users?.find((user) => user.uid === member)
                      if (user) {
                        return (
                          <Avatar
                            key={member}
                            h="32px"
                            w="32px"
                            name={user.firstName + " " + user.lastName}
                            background={gradient(user.lastName)}
                            src={user.photo}
                          />
                        )
                      } else {
                        return <></>
                      }
                    })}
                  </AvatarGroup>
                </Flex>
              )}

              <Flex align="center">
                <Badge width="32px" variantColor="purple">
                  {task.difficulty}
                </Badge>
              </Flex>
            </Stack>
          </Flex>
          <Flex
            as="button"
            outline="none"
            direction="column"
            pl="8px"
            pt="8px"
            onClick={() => {
              if (
                taskList?.id &&
                editTask &&
                pitch?.status === PitchStatus.inProgress
              ) {
                task.isHighPriority = !task?.isHighPriority
                user?.sub && updateTaskList(taskList.id, taskList, user?.sub)
              }
            }}
          >
            {task?.isHighPriority ? (
              <HText>
                <FaThumbtack color="red" fontSize="xl" />
              </HText>
            ) : (
              <HText>
                <FaThumbtack fontSize="xl" />
              </HText>
            )}
          </Flex>
        </Flex>
        <EditTaskModal
          taskList={taskList}
          task={task}
          pitch={pitch}
          readOnly={readOnly}
          isOpen={isOpen}
          onClose={onClose}
          company={company}
        />
      </Box>
    </Fade>
  )
}
