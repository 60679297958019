import React, { Dispatch, SetStateAction } from "react";
import {
  IUserProfile,
  IInvite,
  PitchMemberTypes,
  InviteStatuses,
} from "../types";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Stack,
  Link,
  LinkProps,
} from "@chakra-ui/core";
import { usePitches } from "../Context/pitches-context";
import { useAuth } from "../Context/auth-context";

interface InviteLinkProps extends LinkProps {
  person: IUserProfile;
}

export const InviteLinkWPopover: React.FC<InviteLinkProps> = ({
  person,
  ...rest
}) => {
  const { invites, createInvite, currentPitch } = usePitches();
  const { user } = useAuth();

  return (
    <>
      {invites?.find((invitedPerson) => invitedPerson.to === person.uid) ? (
        <Link color="tagGreenText" {...rest}>
          Invited
        </Link>
      ) : (
        <Popover>
          <PopoverTrigger>
            <Link as="button" color="textLink" data-testid="invite" {...rest}>
              Invite
            </Link>
          </PopoverTrigger>
          <PopoverContent zIndex={4} w="120px" h="76px" padding="0px">
            <PopoverArrow />

            <PopoverBody w="100%" h="100%" padding="8px">
              <Stack align="left">
                <Link
                  data-testid="collaborator"
                  fontSize="sm"
                  color="fontDarkGray"
                  onClick={() => {
                    const newInvite: IInvite = {
                      status: InviteStatuses.pending,
                      from: user?.sub,
                      to: person.uid,
                      subject: {
                        reference: currentPitch?.id,
                        type: PitchMemberTypes.collaborator,
                      },
                    };

                    currentPitch && createInvite(newInvite);
                  }}
                  {...rest}
                >
                  As collaborator
                </Link>
                <Link
                  color="fontDarkGray"
                  fontSize="sm"
                  data-testid="champion"
                  onClick={() => {
                    const newInvite: IInvite = {
                      status: InviteStatuses.pending,
                      from: user?.sub,
                      to: person.uid,
                      subject: {
                        reference: currentPitch?.id,
                        type: PitchMemberTypes.champion,
                      },
                    };
                    currentPitch && createInvite(newInvite);
                  }}
                  {...rest}
                >
                  As champion
                </Link>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};
