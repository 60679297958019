/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, PseudoBox, Box, Image, FlexProps } from '@chakra-ui/core'

import GreenPea1 from '../../Components/Images/Green_Pea_1.png'

import Brand from './../../Components/Images/huminate-logo_R_blue.png'
import { HText } from '../../Components/StyledTextComponents'

export const FormLayoutWrapper: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      paddingLeft="10px"
      position="absolute"
      top="180px"
      right={['10px', '50px', '50px', '15%']}
      direction="column"
      justify="center"
      align="center"
      {...rest}
    >
      {children}
    </Flex>
  )
}

export const CompleteProfileLayout: React.FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <FormLayoutWrapper {...rest}>
      <Flex w="100%" justify="left">
        <Box minW={['100%', '100%', '100%', '420px']} maxW="420px">
          {children}
        </Box>
      </Flex>
    </FormLayoutWrapper>
  )
}
export const SignUpWrapper = ({ children }: any) => {
  const navigate = useNavigate()
  return (
    <Flex height="100vh">
      <Box
        height="100vh"
        bg="white"
        width={[
          '100%', // base
          '100%', // 480px upwards
          '50%', // 768px upwards
          '50%', // 992px upwards
        ]}
      >
        <Box height={'56px'} />

        <Box
          height="calc(100vh - 110px)"
          style={{ overflowY: 'auto' }}
          position="relative"
        >
          {children}
        </Box>
        <Footer />
      </Box>
      {/* responsive font size */}
      <Box
        height="100%"
        bg="turquoise"
        width={[
          '0%', // base
          '0%', // 480px upwards
          '50%', // 768px upwards
          '50%', // 992px upwards
        ]}
      >
        <Flex height="100vh" justify="flex-end" align="flex-end">
          <Image
            size={[
              '0px', // base
              '0px', // 480px upwards
              '280px', // 768px upwards
              '280px', // 992px upwards
            ]}
            objectFit="cover"
            src={GreenPea1}
            alt="Green Pea 1"
          />
        </Flex>
      </Box>
    </Flex>
  )
}

const Footer = () => (
  <Flex
    paddingX={['8px', '16px']}
    height="54px"
    align="center"
    borderTop="1px solid #F2F2F2"
  >
    <Image h="27px" src={Brand} />
  </Flex>
)

export const ProgressDotNext = ({ number, onPressNext }: any) => (
  <Flex width="100%" height="64px" align="center" justify="space-between">
    <Flex>
      {Array(number - 1)
        .fill(0)
        .map((i, idx) => (
          <div
            key={`complete-${idx}`}
            style={{
              height: '8px',
              width: '8px',
              margin: '8px',
              borderRadius: '100%',
              background: 'lightGray',
            }}
          />
        ))}
      <div
        style={{
          height: '8px',
          width: '8px',
          margin: '8px',
          borderRadius: '100%',
          background: '#7E52A0',
        }}
      />

      {Array(3 - number)
        .fill(0)
        .map((i, idx) => (
          <div
            key={`incomplete-${idx}`}
            style={{
              height: '8px',
              width: '8px',
              margin: '8px',
              borderRadius: '100%',
              background: 'lightGray',
            }}
          />
        ))}
    </Flex>
    <SignUpButton onClick={onPressNext} data-testid="next">
      Next
    </SignUpButton>
  </Flex>
)

export const SignUpButton = ({ children, onClick, style, ...rest }: any) => (
  <PseudoBox
    as="button"
    lineHeight="1.2"
    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
    border="1px"
    // @ts-ignore
    type="submit"
    padding="12px 33px"
    marginRight="16px"
    rounded="24px"
    fontSize="16px"
    fontWeight="semibold"
    onClick={onClick}
    bg="#203854"
    borderColor="#203854"
    color="backgroundWhite"
    {...rest}
    _disabled={{ opacity: 0.6 }}
    _focus={{
      boxShadow:
        '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
    }}
  >
    {children}
  </PseudoBox>
)
