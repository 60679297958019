import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { ActionMeta, OnChangeValue } from 'react-select'

export interface Tag {
  id: string
  text: string
}

interface TagInput {
  tags: any[]
  suggestions: any[]
  onTagUpdate: (tags: Tag[]) => void
  testId?: string
}

/* Styles for suggestions */
// div.ReactTags__suggestions {
//   position: absolute;
//   border-radius: 2px;
//   width: 100%;
//   z-index: 999;
// }
// div.ReactTags__suggestions ul {
//   list-style-type: none;
//   background: white;
//   width: 100%;
//   border: 1px solid #bababa;
//   box-sizing: border-box;
//   box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
//   border-radius: 4px;
//   padding: 0px;
//   margin: 0px;
//   margin-top: 6px;
// }
// div.ReactTags__suggestions li {
//   border-bottom: 1px solid #ddd;
//   padding: 16px;
//   color: #4f4f4f;
//   margin: 0;
// }
// div.ReactTags__suggestions li mark {
//   text-decoration: bold;
//   background: none;
//   font-size: 16;
//   font-weight: 600;
// }
// div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
//   background: #b7cfe0;
//   cursor: pointer;
// }

const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
  }),

  control: (_: any, { selectProps: { width } }: any) => ({
    width: width,
  }),

  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'

    return { ...provided, opacity, transition }
  },
}

export const TagInput = ({
  tags,
  suggestions,
  onTagUpdate,
  testId,
  ...rest
}: TagInput) => {
  const handleChange = (
    newValue: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    console.group('Value Changed')
    console.log(newValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
  }

  const options = suggestions.map(({ id, text }) => ({
    value: id,
    label: text,
  }))

  const defaultValue = tags.map(({ id, text }) => ({
    value: id,
    label: text,
  }))

  return (
    <CreatableSelect
      isMulti
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(9, 19, 33, 0.0638304)',
          borderRadius: '4px',
        }),
        multiValueLabel: (provided) => ({
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
          padding: '8px',
          backgroundColor: '#5b5a5a54',
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          backgroundColor: '#5b5a5a54',
        }),
      }}
      data-testid={testId}
      defaultValue={defaultValue}
      onChange={(newValue) => {
        const newTags = newValue.map(({ value, label }) => ({
          id: value,
          text: label,
        }))
        console.log(newTags)
        onTagUpdate(newTags)
      }}
      options={options}
    />
  )
}
