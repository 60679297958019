/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import * as ReactAutosuggest from 'react-autosuggest'
import './Autosuggest.css'
import { IUserProfile, IManager } from '../types'

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function getSuggestions(value: string, options: IUserProfile[]) {
  const escapedValue = escapeRegexCharacters(value.trim())

  if (escapedValue === '') {
    return []
  }

  const regex = new RegExp(`^${escapedValue}`, 'i')

  return options.filter((options: IUserProfile) =>
    regex.test(`${options.firstName} ${options.lastName}`)
  )
}

function getSuggestionValue(suggestion: IUserProfile) {
  return suggestion
}

function renderSuggestion(suggestion: IUserProfile) {
  return (
    <span>
      {suggestion ? `${suggestion.firstName} ${suggestion.lastName}` : ''}
    </span>
  )
}

class Autosuggest extends React.Component<
  {
    inputProps?: any
    initialValue?: string
    options: IUserProfile[]
    onSelect: (x: IManager) => void
    testId?: string
  },
  any
> {
  // @ts-ignore
  constructor(props) {
    // @ts-ignore
    super(props)

    this.state = {
      value: props.initialValue || '',
      suggestions: [],
    }
  }

  // @ts-ignore
  onChange = (event, { newValue, method }) => {
    // @ts-ignore
    // this is a bit of a hack where we toggle the displayed value between the users input, and the selected manager once the user selects one from the list
    //this way we can return the uid to the parent through the onSelect function to record to the DB
    //the value of the parent form is not set until a user is selected and we have a uid
    const newManager: IManager = {
      uid: newValue.uid,
      firstName: newValue.firstName,
      lastName: newValue.lastName,
    }
    this.props.onSelect(newManager)
    this.setState({
      value: newValue.uid
        ? `${newValue.firstName} ${newValue.lastName}`
        : newValue,
    })
  }

  // @ts-ignore
  select = (newValue) => {
    // @ts-ignore
    // this is a bit of a hack where we toggle the displayed value between the users input, and the selected manager once the user selects one from the list
    //this way we can return the uid to the parent through the onSelect function to record to the DB
    //the value of the parent form is not set until a user is selected and we have a uid
    const newManager: IManager = {
      uid: newValue.uid,
      firstName: newValue.firstName,
      lastName: newValue.lastName,
    }
    this.props.onSelect(newManager)
    this.setState({
      value: newValue.uid
        ? `${newValue.firstName} ${newValue.lastName}`
        : newValue,
    })
  }

  // @ts-ignore
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      // @ts-ignore
      suggestions: getSuggestions(value, this.props.options),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  render() {
    // @ts-ignore
    const { value, suggestions } = this.state
    const inputProps = {
      placeholder: 'Start typing to see options',
      value,
      onChange: this.onChange,
      'data-testid': this.props.testId,
      ...this.props.inputProps,
    }

    return (
      // @ts-ignore
      <ReactAutosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        highlightFirstSuggestion
      />
    )
  }
}

export { Autosuggest }
