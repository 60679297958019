import React from 'react'
import { Box, Flex, Avatar, Link } from '@chakra-ui/core'
import { HText } from '../../Components/StyledTextComponents'
import { IPitch, RequestStatuses } from '../../types'
import { usePitches } from '../../Context/pitches-context'
import { useWorkspaces } from '../../Context/workspace-context'

import { required } from '../../formUtils'
//@ts-ignore
import gradient from 'random-gradient'
export const PitchRequests: React.FC<any> = () => {
  const { requests, updateRequest } = usePitches()
  const { users } = useWorkspaces()
  console.log({ requests })
  if (requests === undefined || requests?.length === 0) {
    return <></>
  }

  return (
    <Box paddingLeft={['20px', '100px']}>
      <HText mb={6} color="black" fontSize="sm">
        {requests?.length ?? 0}{' '}
        {requests?.length === 1 ? ' user has ' : ' users have '}
        requested to join your pitch.
      </HText>
      {requests?.map((request) => {
        const requestor = users?.find(({ uid }: any) => uid === request.userId)
        if (requestor) {
          return (
            <Flex align="center" mb={4} key={request.userId}>
              <Avatar
                key={`${requestor.firstName}${requestor.lastName}`}
                h="64px"
                w="64px"
                mr="2"
                name={requestor.firstName + ' ' + requestor.lastName}
                background={gradient(requestor.lastName)}
                src={requestor.photo}
              />
              <Flex
                direction="column"
                h="100%"
                justify="space-between"
                mr="36px"
              >
                <HText fontSize="sm">{request.name}</HText>
                <HText fontSize="xs" color="fontSmall">
                  {request.name}
                </HText>
              </Flex>
              <Flex h="100%" justify="space-between">
                <Link
                  onClick={() =>
                    request.id &&
                    updateRequest(request.id, {
                      status: RequestStatuses.approved,
                    })
                  }
                  color="textLink"
                  fontWeight="semi-bold"
                  mr={6}
                >
                  Approve
                </Link>
                <Link
                  onClick={() =>
                    request.id &&
                    updateRequest(request.id, {
                      status: RequestStatuses.declined,
                    })
                  }
                  color="textLink"
                  fontWeight="semi-bold"
                >
                  Decline
                </Link>
              </Flex>
            </Flex>
          )
        } else {
          return <></>
        }
      })}
    </Box>
  )
}
