import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { firebaseApp } from "../firebase"
import {
  IActivity,
  IComment,
  IInvite,
  InviteStatuses,
  IPitch,
  IRequest,
  ITaskList,
  IUserProfile,
  PitchStatus,
  RequestStatuses,
  ActivityTypes,
} from "../types"
import { useAuth } from "./auth-context"
import { useCompany } from "./company-context"
import { useWorkspaces } from "./workspace-context"
import { createCtx } from "./createCtx"
import { getTime } from "date-fns"

interface IUserCanSee {
  submitPitch: boolean
  inviteCollaborators: boolean

  editPitch: boolean

  followPitch: boolean
  addTodoList: boolean
  createTask: boolean
  completePitch: boolean
  viewRequestsPitch: boolean
  approvePitch: boolean
  editTask: boolean
}
interface PitchesContextProps {
  pitches: IPitch[] | undefined
  archivedPitches: IPitch[] | undefined
  pitchFilterByTitle: IPitch[] | undefined
  createRequest: (data: IRequest) => void
  deleteRequest: (data: IRequest) => void
  updateRequest: (id: string, data: Partial<IRequest>) => void
  createInvite: (data: IInvite) => void
  createAdminInvite: (data: IInvite) => void
  updateInvite: (id: string, data: Partial<IInvite>) => void
  createPitch: (data: IPitch) => Promise<string>

  updatePitch: (id: string, data: Partial<IPitch>) => void
  updatePitchComments: (
    id: string,
    data: {
      commentCount: number
      comments: IComment
    }
  ) => void
  // createTask: (data: ITask) => void;
  createTaskList: (data: ITaskList, taskLists: string[]) => void
  updateTaskList: (id: string, data: Partial<ITaskList>, userId: string) => void
  currentPitch: IPitch | undefined
  setCurrentPitch: Dispatch<SetStateAction<IPitch | undefined>>
  taskLists: ITaskList[] | undefined
  userCanSee: (
    pitch: IPitch | undefined,
    userId: string | undefined
  ) => IUserCanSee
  addChampion: (userId: string, pitch: IPitch) => void
  updatePitchApprover: (user: any, pitch: IPitch) => Promise<unknown>
  removeCollaborator: (userId: string, pitch: IPitch) => void
  demoteChampion: (userId: string, pitch: IPitch) => void
  currentPitchManager: IUserProfile | undefined
  requests: IRequest[] | undefined
  invites: IInvite[] | undefined
  allInvites: IInvite[] | undefined
  allMyInvites: IInvite[] | undefined

  likePitch: (pitchId: string) => void
}

const [usePitches, PitchesContextProvider] = createCtx<PitchesContextProps>()

const PitchesProvider: React.FC = ({ children }) => {
  const { companyUrl } = useCompany()
  const { userProfile, user } = useAuth()
  const { users, searchFilter } = useWorkspaces()

  const [pitches, setPitches] = useState<IPitch[] | undefined>(undefined)
  const [archivedPitches, setArchivedPitches] = useState<IPitch[]>([])

  const [taskLists, setTaskLists] = useState<ITaskList[] | undefined>(undefined)
  const [pitchFilterByTitle, setPitchFilterByTitle] = useState<
    IPitch[] | undefined
  >([])
  const [requests, setRequests] = useState<IRequest[] | undefined>(undefined)
  const [invites, setInvites] = useState<IInvite[] | undefined>(undefined)
  const [allInvites, setAllInvites] = useState<IInvite[] | undefined>(undefined)

  const [allMyInvites, setAllMyInvites] = useState<IInvite[] | undefined>(
    undefined
  )

  const [currentPitch, setCurrentPitch] = useState<IPitch>()

  useEffect(() => {
    if (currentPitch) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("requests")
        .where("pitchReference", "==", currentPitch?.id)
        .where("status", "==", RequestStatuses.pending)
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            setRequests(
              snapshot.docs.map((doc) => {
                const d: IRequest = doc.data() as IRequest

                return { ...d, id: doc.id }
              })
            )
          },
          (err) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [currentPitch])

  useEffect(() => {
    if (user?.sub) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("invites")
        .where("to", "==", user?.sub)
        .where("status", "==", InviteStatuses.pending)
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            const invs = snapshot.docs.map((doc) => {
              const d: IInvite = doc.data() as IInvite

              return { ...d, id: doc.id }
            })
            setAllMyInvites(invs)
          },
          (err) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [user])
  useEffect(() => {
    if (currentPitch) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("invites")
        .where("subject.reference", "==", currentPitch?.id)
        .where("status", "==", InviteStatuses.pending)
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            setInvites(
              snapshot.docs.map((doc) => {
                const d: IInvite = doc.data() as IInvite

                return { ...d, id: doc.id }
              })
            )
          },
          (err) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [currentPitch])

  useEffect(() => {
    const unsubscribe = firebaseApp
      .firestore()
      .collection("invites")
      .where("company", "==", window.location.pathname.split("/")[1])
      .onSnapshot(
        (snapshot) => {
          setAllInvites(
            snapshot.docs.map((doc) => {
              const d: IInvite = doc.data() as IInvite

              return { ...d, id: doc.id }
            })
          )
        },
        (err) => {
          console.log(err)
        }
      )
    return unsubscribe
  }, [])
  useEffect(() => {
    if (currentPitch) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("taskLists")
        .where("pitch", "==", currentPitch?.id)
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            setTaskLists(
              snapshot.docs.map((doc) => {
                const d: ITaskList = doc.data() as ITaskList

                return { ...d, id: doc.id }
              })
            )
          },
          (err) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [currentPitch])

  // Need to set data from firestore here
  useEffect(() => {
    const unsubscribe = firebaseApp
      .firestore()
      .collection("pitches")
      .where("company", "==", window.location.pathname.split("/")[1])
      .onSnapshot(
        (snapshot) => {
          const pitchesF = snapshot.docs.map((doc) => {
            const d: IPitch = doc.data() as IPitch
            return { ...d, id: doc.id }
          })

          //@ts-ignore
          setPitches(
            pitchesF // hide soft deleted pitches
              .filter((pitch) => !pitch.archived)
          )
          setArchivedPitches(pitchesF.filter((pitch) => pitch.archived))
        },
        (err) => console.warn(err)
      )
    return unsubscribe
  }, [companyUrl])

  useEffect(() => {
    const splitSearch = searchFilter?.trim()?.split(",")

    const pitchFilterByT = splitSearch
      ? pitches &&
        pitches.filter(({ title }) => {
          let isNotInSearch = false
          splitSearch?.map((searchFilter) => {
            if (
              title.toLowerCase().indexOf(searchFilter.toLowerCase().trim()) !==
              -1
            ) {
              isNotInSearch = true
            }
          })
          return isNotInSearch
        })
      : pitches
    setPitchFilterByTitle(pitchFilterByT)
  }, [searchFilter])

  const createPitch = async (data: IPitch) => {
    setCurrentPitch(undefined)
    const date = new Date()
    let pitchId = ""
    await firebaseApp
      .firestore()
      .collection("pitches")
      .add({
        ...data,
        createdAt: date,
        company: window.location.pathname.split("/")[1],
      })
      .then((docRef) => {
        firebaseApp
          .firestore()
          .collection("users")
          .doc(data.createdBy)
          // merge avoids overwriting the entire document
          .update({
            pitches: [docRef.id],
          })
          .catch((reason) => console.error(reason))

        docRef
          .update({ id: docRef.id })
          .then(() => {
            console.log("success updating pitch id", docRef.id)
          })
          .catch((e) => console.log("failure updating pitch", e))
        // we set the current pitch to the newly created pitch
        docRef.get().then((newPitch) => {
          const d: IPitch = newPitch.data() as IPitch
          setCurrentPitch({ ...d, id: docRef.id })
          pitchId = docRef.id
        })
      })
    return pitchId
  }

  const createTaskList = (data: ITaskList, taskLists: string[]) => {
    const date = new Date()
    const newTaskList = {
      ...data,
      company: window.location.pathname.split("/")[1],
      createdAt: date,
    }
    firebaseApp
      .firestore()
      .collection("taskLists")
      .add(newTaskList)
      .then(({ id }) => {
        console.log("UPDATING PITCH")
        firebaseApp
          .firestore()
          .collection("pitches")
          .doc(data.pitch)
          // merge avoids overwriting the entire document
          .update({
            taskLists: [...taskLists, id],
          })
          .catch((reason) => console.error(reason))
      })
      .catch((reason) => console.error(reason))
  }

  const updateTaskList = (
    id: string,
    data: Partial<ITaskList>,
    userId: string
  ) => {
    const date = new Date()
    firebaseApp
      .firestore()
      .collection("taskLists")
      .doc(id)
      .update({
        ...data,
        updatedAt: date,
        createdBy: userId,
        company: window.location.pathname.split("/")[1],
      })
      .catch((reason) => console.log(reason))
  }

  const updatePitch = (id: string, data: Partial<IPitch>) => {
    firebaseApp
      .firestore()
      .collection("pitches")
      .doc(id)
      // merge avoids overwriting the entire document
      .update(data)
      .catch((reason) => console.log(reason))
  }

  const updatePitchComments = (
    id: string,
    data: {
      commentCount: number
      comments: IComment
    }
  ) => {
    firebaseApp
      .firestore()
      .collection("pitches")
      .doc(id)
      // merge avoids overwriting the entire document
      .update({ commentCount: data.commentCount })
      .catch((reason) => console.log(reason))

    firebaseApp
      .firestore()
      .collection(`pitches/${id}/comments`)
      .add({
        ...data.comments,
        company: window.location.pathname.split("/")[1],
      })
      .then((docRef) => docRef.update({ id: docRef.id }))
      .catch((reason) => console.log(reason))
  }
  const createRequest = (data: IRequest) => {
    const date = new Date()
    firebaseApp
      .firestore()
      .collection("requests")
      .add({
        ...data,
        createdAt: date,
        company: window.location.pathname.split("/")[1],
      })
      .catch((reason) => console.log(reason))
  }

  const updateRequest = (id: string, data: Partial<IRequest>) => {
    const date = new Date()
    firebaseApp
      .firestore()
      .collection("requests")
      .doc(id)
      // merge avoids overwriting the entire document
      .set(
        { ...data, updatedAt: date, updatedBy: userProfile?.uid },
        { merge: true }
      )
      .catch((reason) => console.log(reason))
  }

  const deleteRequest = (data: Partial<IRequest>) => {
    const requestRef = firebaseApp
      .firestore()
      .collection("requests")
      .where("pitchReference", "==", data.pitchReference)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          const docData = doc.data()
          if (docData.userId === data.userId) {
            doc.ref.delete()
          }
        })
      })

      .catch((reason) => console.log(reason))
  }

  const createInvite = (data: IInvite) => {
    const date = new Date()
    firebaseApp
      .firestore()
      .collection("invites")
      .add({
        ...data,
        createdAt: date,
        company: window.location.pathname.split("/")[1],
      })
      .catch((reason) => console.log(reason))
  }

  const createAdminInvite = (data: IInvite) => {
    const date = new Date()
    firebaseApp
      .firestore()
      .collection("adminInvites")
      .add({
        ...data,
        createdAt: date,
        company: window.location.pathname.split("/")[1],
      })
      .catch((reason) => console.log(reason))
  }

  const updateInvite = (id: string, data: Partial<IInvite>) => {
    const date = new Date()
    firebaseApp
      .firestore()
      .collection("invites")
      .doc(id)
      // merge avoids overwriting the entire document
      .set({ ...data, updatedAt: date }, { merge: true })
      .catch((reason) => console.log(reason))
  }

  // this function provides the context for displaying ui elements
  const userCanSee = (
    pitch: IPitch | undefined,
    userId: string | undefined
  ) => {
    if (!pitch || !userId) {
      return {
        submitPitch: false,
        inviteCollaborators: false,
        editPitch: false,
        requestToJoin: false,
        followPitch: false,
        addTodoList: false,
        createTask: false,
        completePitch: false,
        viewRequestsPitch: false,
        approvePitch: false,
        editTask: false,
      }
    }
    const managerOrChampion =
      pitch?.manager?.uid === userId ||
      !!pitch.champions?.find((champ) => champ === userId)

    const isMember = !!pitch.members.find((member) => member === userId)
    const submitPitch =
      managerOrChampion &&
      (pitch.status === PitchStatus.draft ||
        pitch.status === PitchStatus.overdueSubmission ||
        pitch.status === PitchStatus.declined)
    const inviteCollaborators = managerOrChampion
    const editPitch = managerOrChampion && pitch.status !== PitchStatus.complete

    console.log("findMember")
    console.log(pitch.members.find((member) => member === userId))
    const followPitch =
      pitch.members.find((member) => member === userId) === undefined
    const addTodoList =
      (managerOrChampion || isMember) && pitch.status !== PitchStatus.complete
    const createTask =
      (managerOrChampion || isMember) && pitch.status !== PitchStatus.complete
    const editTask =
      (managerOrChampion || isMember) && pitch.status !== PitchStatus.complete
    const completePitch =
      managerOrChampion && pitch.status === PitchStatus.inProgress
    const viewRequestsPitch = managerOrChampion
    const approvePitch =
      pitch.manager?.uid === userId &&
      pitch.status === PitchStatus.pendingApproval

    return {
      submitPitch: submitPitch,
      inviteCollaborators: inviteCollaborators,
      editPitch: editPitch,
      followPitch: followPitch,
      addTodoList: addTodoList,
      createTask: createTask,
      completePitch: completePitch,
      viewRequestsPitch: viewRequestsPitch,
      approvePitch: approvePitch,
      editTask: editTask,
    }
  }
  const addChampion = (userId: string, pitch: IPitch) => {
    const oldChampions = pitch.champions || []
    if (pitch.id && !oldChampions.find((champion) => champion === userId)) {
      const updateChampionsInPitch = firebaseApp
        .functions()
        .httpsCallable("updateChampionsInPitch")
      updateChampionsInPitch({
        pitchId: pitch.id,
        champions: [...oldChampions, userId],
      }).then((result) => {
        // Read result of the Cloud Function.
        console.log(result)
      })
    }
  }

  const updatePitchApprover = (pitch: IPitch, user: any) => {
    const updatePitchApproverCall = firebaseApp
      .functions()
      .httpsCallable("updatePitchApprover")

    return updatePitchApproverCall({
      pitchId: pitch.id,
      pitchApprover: user,
    })
  }

  const demoteChampion = (userId: string, pitch: IPitch) => {
    const isChampion = pitch.champions?.find((champion) => champion === userId)
    const isOnlyChampion = isChampion && pitch.champions?.length === 1
    // user cannot be removed if they are the only champion
    if (pitch.id && !isOnlyChampion) {
      const updateChampionsInPitch = firebaseApp
        .functions()
        .httpsCallable("updateChampionsInPitch")
      updateChampionsInPitch({
        pitchId: pitch.id,
        champions: [...(pitch.champions || []), userId],
      }).then((result) => {
        // Read result of the Cloud Function.
        console.log(result)
      })
    }
  }

  const removeCollaborator = async (userId: string, pitch: IPitch) => {
    const isChampion = pitch.champions?.find((champion) => champion === userId)
    const isMember = pitch.members?.find((member) => member === userId)

    const isOnlyChampion = isChampion && pitch.champions?.length === 1
    // user cannot be removed if they are the only champion
    if (pitch.id && isChampion && !isOnlyChampion) {
      const newPitch = {
        champions: pitch.champions?.filter((champ) => champ !== userId),
        members: pitch.members?.filter((member) => member !== userId),
      }
      updatePitch(pitch.id, { members: newPitch.members })
      const updateChampionsInPitch = firebaseApp
        .functions()
        .httpsCallable("updateChampionsInPitch")
      updateChampionsInPitch({
        pitchId: pitch.id,
        champions: newPitch.champions,
      }).then((result) => {
        // Read result of the Cloud Function.
        console.log(result)
      })
    } else if (pitch.id && isMember) {
      // const newPitch = {
      //   members: pitch.members?.filter((member) => member !== userId),
      // }

      // console.log({ newPitch, userId })
      // updatePitch(pitch.id, newPitch)
      const removeSelfFromPitch = firebaseApp
        .functions()
        .httpsCallable("removeSelfFromPitch")
      removeSelfFromPitch({ pitchId: pitch.id }).then((result) => {
        // Read result of the Cloud Function.
        console.log(result)
      })
    }
    // TODO need to remove member from tasks
    let taskLists: ITaskList[] = []
    await firebaseApp
      .firestore()
      .collection("taskLists")
      .where("pitch", "==", pitch.id)
      .get()
      .then((snapshot: any) => {
        taskLists = snapshot.docs.map((doc: any) => {
          const d: ITaskList = doc.data() as ITaskList
          return { ...d, id: doc.id }
        })
      })
      .catch((err: any) => {
        console.log(err)
      })

    taskLists?.forEach(async (taskList) => {
      taskList.tasks.forEach((task) => {
        // find and remove the member from incomplete tasks
        if (!task.isCompleted && task.member) {
          const memberIndex = task.member?.findIndex(
            (member) => member === userId
          )
          if (memberIndex !== -1) {
            task.member.splice(memberIndex, 1)
          }
        }
      })
      // save the updated tasklist
      await firebaseApp
        .firestore()
        .collection("taskLists")
        .doc(taskList.id)
        .set(
          {
            ...taskList,
          },
          { merge: true }
        )
    })
  }

  const likePitch = (pitchId: string) => {
    const likeRef = firebaseApp
      .firestore()
      .collection("pitchLikes")
      .doc(`${userProfile?.uid}:${pitchId}`)

    const pitchRef = firebaseApp.firestore().collection("pitches").doc(pitchId)

    likeRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        // do stuff with the data
        console.log("pitch already liked")
        likeRef.delete()
        // update the like counter
        pitchRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            const previous = docSnapshot?.data()?.likesCount ?? 0
            pitchRef.update({ likesCount: previous > 0 ? previous - 1 : 0 })
          }
        })
      } else {
        // create the new like
        likeRef.set({ liked: true })
        // update the like counter
        pitchRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            pitchRef.update({
              likesCount: (docSnapshot?.data()?.likesCount ?? 0) + 1,
            })
          }
        })
      }
    })
  }
  let currentPitchManager
  if (
    users &&
    users.length > 0 &&
    currentPitch &&
    currentPitch.manager &&
    currentPitch.manager.uid
  ) {
    //@ts-ignore
    currentPitchManager = users.find(
      ({ uid }) => uid === currentPitch?.manager?.uid
    )
  }

  console.log({ pitches })

  return (
    <PitchesContextProvider
      value={{
        allMyInvites,
        archivedPitches,
        invites,
        allInvites,
        pitchFilterByTitle,
        createInvite,
        updateInvite,
        requests,
        createRequest,
        updateRequest,
        likePitch,
        deleteRequest,
        demoteChampion,
        removeCollaborator,
        addChampion,
        userCanSee,
        pitches,
        createPitch,
        updatePitch,
        updatePitchComments,
        createAdminInvite,
        currentPitchManager,
        updateTaskList,
        createTaskList,
        currentPitch,
        setCurrentPitch,
        taskLists,
        updatePitchApprover,
        // createTask,
      }}
    >
      {children}
    </PitchesContextProvider>
  )
}

export { usePitches, PitchesProvider }

// const { activities } = useActivities();
