import React, { useState, SetStateAction, Dispatch, useEffect } from "react"

import { createCtx } from "./createCtx"

import { IUserProfile, IWorkspace, ISkillTag, IPitch } from "../types"
import { firebaseApp } from "../firebase"
import { useAuth } from "./auth-context"
import { stringSearch } from "../stringSearch"

interface WorkspacesContextProps {
  users: IUserProfile[] | undefined
  filteredResults: IUserProfile[] | undefined
  filteredResultsWithInactive: IUserProfile[] | undefined
  managerUserProfile: IUserProfile | undefined
  setSearchFilter: Dispatch<SetStateAction<string | undefined>>
  searchFilter: string | undefined
  workspaces: IWorkspace[] | undefined
  setWorkspaces: Dispatch<SetStateAction<IWorkspace[] | undefined>>
  currentWorkspace: IWorkspace | undefined
  setCurrentWorkspace: Dispatch<SetStateAction<IWorkspace | undefined>>
  setCurrentWorkspaceBySlug: (slug: string) => void
  createWorkspace: (data: IWorkspace) => void
  updateWorkspace: (data: Partial<IWorkspace>) => void
  fullUserName: (userId: string) => string
  updateTags: (data: ISkillTag[]) => void
  tags: { id: string; text: string }[]
}

const [useWorkspaces, WorkspacesContextProvider] =
  createCtx<WorkspacesContextProps>()

const WorkspacesProvider: React.FC = ({ children }) => {
  console.log("WORKSPACE")
  const { loading, user: authUser, userProfile, userProfileLoading } = useAuth()

  const [users, setUsers] = useState<IUserProfile[] | undefined>(undefined)

  const [workspaces, setWorkspaces] = useState<IWorkspace[] | undefined>(
    undefined
  )
  const [tags, setTags] = useState<ISkillTag[]>([])

  const [currentWorkspace, setCurrentWorkspace] = useState<
    IWorkspace | undefined
  >(undefined)

  const setCurrentWorkspaceBySlug = (slug: string) => {
    const newWorkspace: IWorkspace | undefined = workspaces?.find(
      (workspace) => workspace.slug === slug
    )
    if (newWorkspace) {
      sessionStorage.setItem("workspaceSlug", newWorkspace.slug)
      setCurrentWorkspace(newWorkspace)
    }
  }
  console.log("loading workspaces")

  const [filteredResults, setFilteredResults] = useState<
    IUserProfile[] | undefined
  >(users)
  const [searchFilter, setSearchFilter] = useState<string | undefined>()

  // Need to set data from firestore here
  useEffect(() => {
    if (!loading) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("users")
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            setUsers(
              snapshot.docs.map((x) => {
                const data: IUserProfile = x.data() as IUserProfile
                data.uid = x.id
                return data
              })
            )
          },
          (err) => {
            console.error(err)
          }
        )
      return unsubscribe
    }
  }, [loading])
  useEffect(() => {
    if (!loading) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("skillTags")
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            setTags(
              snapshot.docs.map((x) => {
                const data: ISkillTag = x.data() as ISkillTag

                return data
              })
            )
          },
          (err) => {
            console.error(err)
          }
        )
      return unsubscribe
    }
  }, [loading, userProfileLoading])

  useEffect(() => {
    console.log({ userProfile })
    if (!loading && userProfile && !userProfile?.isExternal) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("workspaces")
        .where("company", "==", window.location.pathname.split("/")[1])
        .onSnapshot(
          (snapshot) => {
            const workspaces = snapshot.docs.map((workspace) => {
              // Necessary to typecast the arbitrary document to an IWorkspace
              const data: IWorkspace = workspace.data() as IWorkspace
              data.id = workspace.id
              return data
            })
            setWorkspaces(workspaces)
            const savedWorkspaceSlug = sessionStorage.getItem("workspaceSlug")
            if (savedWorkspaceSlug) {
              const workspace = workspaces.find(
                ({ slug }: any) => savedWorkspaceSlug === slug
              )
              setCurrentWorkspace(workspace)
            } else {
              workspaces.sort(
                //@ts-ignore
                (a, b) => a?.createdAt.seconds - b?.createdAt.seconds
              )
              const initialLoadWorkspace = workspaces[0]

              setCurrentWorkspace(initialLoadWorkspace)
            }
          },
          (err) => {
            console.log(err)
          }
        )
      return unsubscribe
    } else if (!loading && userProfile && userProfile.isExternal) {
      console.log("loading external workspaces")
      const unsubscribe = firebaseApp
        .firestore()
        .collection("workspaces")
        .where("company", "==", window.location.pathname.split("/")[1])
        .where("id", "in", userProfile.workspaces)
        .onSnapshot(
          (snapshot) => {
            const workspaces = snapshot.docs.map((workspace) => {
              // Necessary to typecast the arbitrary document to an IWorkspace
              const data: IWorkspace = workspace.data() as IWorkspace
              data.id = workspace.id
              return data
            })
            setWorkspaces(workspaces)
            const savedWorkspaceSlug = sessionStorage.getItem("workspaceSlug")
            if (savedWorkspaceSlug) {
              const workspace = workspaces.find(
                ({ slug }: any) => savedWorkspaceSlug === slug
              )
              setCurrentWorkspace(workspace)
            } else {
              workspaces.sort(
                //@ts-ignore
                (a, b) => a?.createdAt.seconds - b?.createdAt.seconds
              )
              const initialLoadWorkspace = workspaces[0]

              setCurrentWorkspace(initialLoadWorkspace)
            }
          },
          (err) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [loading, userProfileLoading])

  useEffect(() => {
    if (searchFilter) {
      if (!users || users.length === 0) {
        return
      } else {
        const searchOptions = searchFilter
          .split(",")
          .filter((o) => o.length >= 2)
        const usersWithAll =
          users
            .filter(
              ({ workspaces }) =>
                workspaces === undefined ||
                (currentWorkspace &&
                  workspaces.indexOf(currentWorkspace?.id || ""))
            )

            .filter((user) => {
              const matchedOptions = searchOptions.filter(
                (e: string) =>
                  stringSearch(e, `${user.firstName} ${user.lastName}`) ||
                  user.tags?.find((tag) => stringSearch(e, tag.text))
              )

              return matchedOptions.length === searchOptions.length
            }) || []

        const results = searchOptions.map((e) =>
          users.filter((user) => {
            const hasMatch =
              stringSearch(e, `${user.firstName} ${user.lastName}`) ||
              user.tags?.find((tag) => stringSearch(e, tag.text))
            if (usersWithAll && usersWithAll.find((user) => user?.firstName)) {
              return false
            }
            return hasMatch
          })
        ) || [[], []]

        const finalUsers = [...usersWithAll, ...results.flat()]
        const finalUsersNoInvites = finalUsers?.filter(
          (user) => user?.firstName
        )

        setFilteredResults(finalUsersNoInvites)
      }
    } else {
      // setFilteredResults(users?.filter((user) => user.uid !== authUser?.sub))
      setFilteredResults(users?.filter((user) => user?.firstName))
    }
  }, [searchFilter, users])
  const fullUserName = (userId: string) => {
    const manager = users?.find((user) => user.uid === userId)
    return manager ? `${manager.firstName} ${manager.lastName}` : ""
  }

  const managerUserProfile = users?.find(
    (user) => user.uid === userProfile?.manager?.uid
  )

  const createWorkspace = (data: IWorkspace) => {
    const date = new Date()
    const newWorkspace = { ...data, createdAt: date }

    console.log(JSON.stringify(newWorkspace))

    firebaseApp
      .firestore()
      .collection("workspaces")
      .add(newWorkspace)
      .catch((reason) => console.error(reason))
  }

  const updateWorkspace = (data: Partial<IWorkspace>) => {
    firebaseApp
      .firestore()
      .collection("workspaces")
      .doc(data.id)
      .update(data)
      .catch((reason) => console.error(reason))
  }

  const updateTags = async (data: ISkillTag[]) => {
    const tags = await firebaseApp
      .firestore()
      .collection("skillTags")
      .where("company", "==", window.location.pathname.split("/")[1])
      .get()

    const tagData: ISkillTag[] = []
    tags.docs.forEach((doc) => {
      //@ts-ignore
      tagData.push(doc.data())
    })
    data.forEach((doc) => {
      if (
        tagData.filter(
          //@ts-ignore
          ({ text }) => text.toLowerCase() === doc.text.toLowerCase()
        ).length === 0
      ) {
        return firebaseApp
          .firestore()
          .collection("skillTags")
          .add({
            ...doc,
            company: window.location.pathname.split("/")[1],
            createdAt: new Date(),
          })
      }
    })
  }

  return (
    <WorkspacesContextProvider
      value={{
        tags,
        updateTags,
        createWorkspace,
        fullUserName,
        updateWorkspace,
        searchFilter,
        users,
        managerUserProfile,
        workspaces,
        setWorkspaces,
        currentWorkspace,
        setCurrentWorkspace,
        setCurrentWorkspaceBySlug,
        setSearchFilter,
        filteredResults: filteredResults?.filter(
          ({ isInactive }) => !isInactive
        ),
        filteredResultsWithInactive: filteredResults,
      }}
    >
      {children}
    </WorkspacesContextProvider>
  )
}

export { useWorkspaces, WorkspacesProvider }
