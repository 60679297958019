import { Flex, Stack, Text, Switch, FormLabel, Divider } from '@chakra-ui/core'
import React, { useState } from 'react'
import { DashboardContainer } from './Dashboard/DashboardWrapper'
import { useAuth } from '../Context/auth-context'

export const SettingsView: React.FC = () => {
  const { userProfile, updateUserProfile } = useAuth()

  const [pitchStatus, setPitchStatus] = useState(
    userProfile?.pitchStatusNotification === undefined
      ? true
      : userProfile?.pitchStatusNotification
  )
  const [skillMatchPitch, setSkillMatchPitch] = useState(
    userProfile?.skillMatchPitchNotification === undefined
      ? true
      : userProfile?.skillMatchPitchNotification
  )
  const [taggedComment, setTaggedComment] = useState(
    userProfile?.taggedCommentNotification === undefined
      ? true
      : userProfile?.taggedCommentNotification
  )
  const [taskStatus, setTaskStatus] = useState(
    userProfile?.taskStatusNotification === undefined
      ? true
      : userProfile?.taskStatusNotification
  )

  return (
    <DashboardContainer>
      <Flex justify="center">
        <Stack w="100%" maxW="900px" flexGrow={1}>
          <Text fontSize="2xl" fontWeight="600" color="fontBlack">
            Notification Settings
          </Text>
          <Text as="b">Email Alerts</Text>
          <Flex mt={5} flexDirection="column" alignItems="start">
            <Divider width="100%" />
            <Flex
              justify="space-between"
              w="480px"
              mb={2}
              mt={2}
              align="center"
            >
              <FormLabel htmlFor="email-alerts">
                Skills match on Pitches
              </FormLabel>
              <Flex align="center" justify="center">
                <Text pb="4px" pr="8px">
                  Off
                </Text>
                <Switch
                  size="lg"
                  isChecked={skillMatchPitch}
                  onChange={() => {
                    const newS = !skillMatchPitch
                    setSkillMatchPitch(newS)
                    updateUserProfile({ skillMatchPitchNotification: newS })
                  }}
                  color="teal"
                  id="email-alerts"
                  defaultIsChecked
                />
                <Text pb="4px" pl="8px">
                  Realtime
                </Text>
              </Flex>
            </Flex>
            <Divider width="100%" />
            <Flex
              justify="space-between"
              w="480px"
              mb={2}
              mt={2}
              align="center"
            >
              <FormLabel htmlFor="email-alerts">Tagged Comments</FormLabel>
              <Flex align="center" justify="center">
                <Text pb="4px" pr="8px">
                  Off
                </Text>
                <Switch
                  size="lg"
                  isChecked={taggedComment}
                  onChange={() => {
                    const newS = !taggedComment
                    setTaggedComment(newS)
                    updateUserProfile({ taggedCommentNotification: newS })
                  }}
                  color="teal"
                  id="email-alerts"
                  defaultIsChecked
                />
                <Text pb="4px" pl="8px">
                  Realtime
                </Text>
              </Flex>
            </Flex>
            <Divider width="100%" />
            <Flex
              justify="space-between"
              w="480px"
              mb={2}
              mt={2}
              align="center"
            >
              <FormLabel htmlFor="email-alerts">Task Updates</FormLabel>
              <Flex align="center" justify="center">
                <Text pb="4px" pr="8px">
                  Off
                </Text>
                <Switch
                  size="lg"
                  isChecked={taskStatus}
                  onChange={() => {
                    const newS = !taskStatus
                    setTaskStatus(newS)
                    updateUserProfile({ taskStatusNotification: newS })
                  }}
                  color="teal"
                  id="email-alerts"
                  defaultIsChecked
                />
                <Text pb="4px" pl="4px">
                  Realtime
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex justify="center" mt="8"></Flex>
        </Stack>
      </Flex>
    </DashboardContainer>
  )
}
