import React, { useState, SetStateAction, Dispatch, useEffect } from 'react'

import { createCtx } from './createCtx'

// import { IUserProfile, IWorkspace } from "../types";
import { firebaseApp } from '../firebase'
import { useAuth } from './auth-context'
import {
  IActivity,
  IComment,
  IInvite,
  InviteStatuses,
  IPitch,
  IRequest,
  ITaskList,
  PitchStatus,
  RequestStatuses,
  ActivityTypes,
} from '../types'

interface PitchOrderContextProps {
  pitchOrder: IPitchOrder
  updatePitchOrder: (data: Partial<IPitchOrder>) => void
}

interface IPitchOrder {
  all: string[]
  draft: string[]
  inProgress: string[]
  complete: string[]
  pendingInvites: string[]
  approvalRequests: string[]
}

const [
  usePitchOrder,
  PitchOrderContextProvider,
] = createCtx<PitchOrderContextProps>()

const PitchOrderProvider: React.FC = ({ children }) => {
  const { userProfile, user } = useAuth()

  const defaultPitchOrder = {
    all: [],
    draft: [],
    inProgress: [],
    complete: [],
    pendingInvites: [],
    approvalRequests: [],
  }

  const [pitchOrder, setPitchOrder] = useState<IPitchOrder>(defaultPitchOrder)

  const updatePitchOrder = (data: Partial<IPitchOrder>) => {
    const date = new Date()
    console.log('update pitch order')
    setPitchOrder({ ...defaultPitchOrder, ...pitchOrder, ...data })
    firebaseApp
      .firestore()
      .collection('pitchOrder')
      .doc(user?.sub)
      // merge avoids overwriting the entire document
      .set({ ...defaultPitchOrder, ...data, updatedAt: date }, { merge: true })
      .catch((reason) => console.log(reason))
  }

  useEffect(() => {
    if (user?.sub) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection('pitchOrder')
        .doc(user.sub)
        .onSnapshot(
          (snapshot) => {
            const pitchOrderData = snapshot.data() as IPitchOrder

            setPitchOrder(pitchOrderData)
          },
          (err) => {
            console.warn(err)
          }
        )
      return unsubscribe
    }
  }, [])

  return (
    <PitchOrderContextProvider
      value={{
        pitchOrder,
        updatePitchOrder,
      }}
    >
      {children}
    </PitchOrderContextProvider>
  )
}

export { usePitchOrder, PitchOrderProvider }
