import React from 'react'
import { ThemeProvider, CSSReset, Flex, Image, Spinner } from '@chakra-ui/core'
import theme from '../theme'
import GreenPea1 from './Images/Green_Pea_1-min.png'

const HuminateLoader: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CSSReset />
    <Flex
      height="100vh"
      width="100vw"
      direction="column"
      backgroundColor="turquoise"
      justify="center"
      align="center"
    >
      <Image
        size={['0px', '0px', '280px', '280px']}
        objectFit="cover"
        src={GreenPea1}
        alt="Green Pea 1"
      />
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="textLink"
        size="xl"
      />
    </Flex>
  </ThemeProvider>
)

export default HuminateLoader
