/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useDebugValue,
} from "react"
import {
  Box,
  Button,
  ButtonGroup,
  Link as ChakraLink,
  Flex,
  Icon,
  Stack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  SimpleGrid,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/core"
import { Field, Formik } from "formik"
import { TagInput } from "./TagInput"
import { useNavigate, useParams } from "react-router-dom"
import { PitchCard } from "./PitchCard"
import { StyledTab } from "./StyledTextComponents"
import { isMobile } from "./isMobile"
import { HHeading, HText, HInput, HTextArea } from "./StyledTextComponents"
import { PitchStatus, InviteStatuses, IPitch } from "../types"
import { usePitches } from "../Context/pitches-context"
import { useCompany } from "../Context/company-context"
import theme from "../theme"
import { FillButton, OutlineButton } from "./Buttons"

import { FriendlyModal } from "./FriendlyModal"
import { useWindowDimensions } from "../useWindowDimensions"
import { required, requireAtleastOne } from "../formUtils"

import { CreateAPitchOneView } from "../Views/CreateAPitch/CreateAPitch1"
import { CreateAPitchTwoView } from "../Views/CreateAPitch/CreateAPitch2"
import {
  CreateAPitchThreeOneView,
  CreateAPitchThreeTwoView,
} from "../Views/CreateAPitch/CreateAPitch3"
import { CreateAPitchFourView } from "../Views/CreateAPitch/CreateAPitch4"
import { useAuth } from "../Context/auth-context"
import { useWorkspaces } from "../Context/workspace-context"
import { SkillTagSuggestions } from "../fixtures"

export const CreatePitch: React.FC<any> = ({ isOpen, onClose }) => {
  const [isSavingPitch, setIsSavingPitch] = useState(false)
  const { companyUrl: company } = useCompany()
  const { createPitch } = usePitches()
  const { userProfile, updateUserProfile } = useAuth()
  const { currentWorkspace, tags } = useWorkspaces()
  const [openCreatePitchModal, setOpenCreatePitchModal] = useState(
    isOpen ? (userProfile?.hidePitchWorkflowPrompt ? -1 : 1) : 0
  )
  useEffect(() => {
    setOpenCreatePitchModal(
      isOpen ? (userProfile?.hidePitchWorkflowPrompt ? -1 : 1) : 0
    )
  }, [isOpen])

  return (
    <FriendlyModal
      isOpen={openCreatePitchModal}
      noButton
      title={"Create a Pitch"}
      onClose={() => {
        window.sessionStorage.removeItem("pitchDraft")
        setOpenCreatePitchModal(0)
        onClose()
      }}
    >
      {openCreatePitchModal === -1 ? (
        <Formik
          initialValues={{
            title: "",
            description: "",
            skillTags: [],
          }}
          onSubmit={async (values, _actions) => {
            setIsSavingPitch(true)
            const newPitch: Partial<IPitch> = {
              title: values.title || "",
              description: values.description || "",
              skillTags: values.skillTags.map((tag) => {
                return { tag: tag, usersNotified: true }
              }),
              pitchApprover: userProfile?.manager || "",
              company,
              createdBy: userProfile?.uid,
              createdByFirstName: userProfile?.firstName || "",
              createdByLastName: userProfile?.lastName || "",
              createdByProfilePicture: userProfile?.photo ?? "",
              members: [userProfile?.uid || ""],
              workspaceUID: currentWorkspace?.id,
            }
            //@ts-ignore
            await createPitch(newPitch)
            setOpenCreatePitchModal(0)
            window.sessionStorage.removeItem("pitchDraft")
            setIsSavingPitch(false)
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} style={{ width: "100%" }}>
              <Field name="title" validate={required}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.title && form.touched.title}
                    marginBottom="32px"
                  >
                    <FormLabel color="fontBlack" htmlFor="title">
                      Title
                    </FormLabel>
                    <HInput mt={2} data-testid="title" {...field} id="title" />
                    <FormErrorMessage
                      style={{ height: "17px", marginBottom: "-25px" }}
                    >
                      {form.errors.title}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description" validate={required}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                    marginBottom="32px"
                  >
                    <FormLabel color="fontBlack" htmlFor="description">
                      Description
                    </FormLabel>
                    <FormHelperText color="fontDarkGray">
                      E.g. competitor analysis, market size, customer examples
                      and successes
                    </FormHelperText>
                    <HTextArea
                      minH="200px"
                      mt={2}
                      data-testid="description"
                      {...field}
                      style={{
                        fontFamily: "inherit",
                        fontSize: "inherit",
                      }}
                      id="description"
                      value={props.values.description}
                      fontSize="16px"
                      resize="vertical"
                    />
                    <FormErrorMessage
                      style={{ height: "17px", marginBottom: "-25px" }}
                    >
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="skillTags" validate={requireAtleastOne}>
                {({ field, form }: any) => (
                  <FormControl
                    marginBottom="32px"
                    isInvalid={form.errors.skillTags && form.touched.skillTags}
                  >
                    <FormLabel color="fontBlack" htmlFor="skillTags">
                      Skill tags
                    </FormLabel>
                    <FormHelperText color="fontDarkGray" mb={2}>
                      Tags help others discover and participate your pitch. Add
                      some relevant skill hashtags that are related to the
                      pitch. E.g. People Management, CRM, Python, or Social
                      Media.
                    </FormHelperText>
                    <TagInput
                      testId="skillTags"
                      tags={field.value}
                      suggestions={[...SkillTagSuggestions, ...tags]}
                      onTagUpdate={(tags) => {
                        props.setFieldValue("skillTags", tags)
                      }}
                    />
                    <FormErrorMessage
                      style={{ height: "17px", marginBottom: "-25px" }}
                    >
                      {form.errors.skillTags}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Flex justify="center" align="center">
                <OutlineButton
                  onClick={() => {
                    setOpenCreatePitchModal(0)
                    onClose()
                  }}
                  data-testid="back"
                  style={{ marginRight: "8px" }}
                >
                  Cancel
                </OutlineButton>
                <FillButton my="16px" type="submit">
                  Create Pitch
                </FillButton>
              </Flex>
            </form>
          )}
        </Formik>
      ) : openCreatePitchModal === 1 ? (
        <CreateAPitchOneView
          onBack={() => {
            window.sessionStorage.removeItem("pitchDraft")
            setOpenCreatePitchModal(1)
          }}
          onNext={() => {
            setOpenCreatePitchModal(openCreatePitchModal + 1)
          }}
          onSkipWalkthrough={() => {
            updateUserProfile({ hidePitchWorkflowPrompt: true })
            setOpenCreatePitchModal(-1)
          }}
        />
      ) : openCreatePitchModal === 2 ? (
        <CreateAPitchTwoView
          onBack={(e: any) =>
            //@ts-ignore
            {
              e.preventDefault()
              setOpenCreatePitchModal(1)
            }
          }
          onNext={() => {
            setOpenCreatePitchModal(3)
          }}
        />
      ) : openCreatePitchModal === 3 ? (
        <CreateAPitchThreeOneView
          onBack={() => {
            setOpenCreatePitchModal(openCreatePitchModal - 1)
          }}
          onNext={() => {
            setOpenCreatePitchModal(openCreatePitchModal + 1)
          }}
        />
      ) : openCreatePitchModal === 4 ? (
        <CreateAPitchThreeTwoView
          onBack={() => {
            setOpenCreatePitchModal(openCreatePitchModal - 1)
          }}
          onNext={() => {
            setOpenCreatePitchModal(openCreatePitchModal + 1)
          }}
        />
      ) : openCreatePitchModal === 5 ? (
        <CreateAPitchFourView />
      ) : (
        <></>
      )}
    </FriendlyModal>
  )
}
