/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import {useNavigate} from 'react-router'
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormErrorMessage,
  Input,
} from '@chakra-ui/core'
import { Formik, Field } from 'formik'

import { SignUpWrapper, SignUpButton } from '../SignupFlow/Layouts'
import { OutlineButton, FillButton } from '../../Components/Buttons'

import { firebaseApp } from '../../firebase'

const required = (value: any) => {
  if (!value) {
    return 'This is required'
  }
}

const functions = firebaseApp.functions()
let backendUrl = 'https://us-central1-huminate-e8f9b.cloudfunctions.net'

if (process.env.NODE_ENV === 'development') {
  functions.useFunctionsEmulator('http://localhost:5001')
  backendUrl = 'http://localhost:5001/huminate-e8f9b/us-central1'
}

const FindYourWorkspace = () => {
  const navigate = useNavigate()
  return (
  <SignUpWrapper workspace="huminate">
    <Flex justify="center">
      <Box padding={['8px', '16px']}>
        <Text fontWeight="700" fontSize="3xl" color="fontBlack" my="40px">
          Find your workspace
        </Text>
        <Text maxWidth="480px" mb="32px">
          We’ll send you an email to confirm your address and find existing
          workspaces you’ve joined.
        </Text>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={(values, _actions) => {
            fetch(`${backendUrl}/sendFindWorkspaceMail`, {
              method: 'post',
              headers: {
                'Content-type': 'application/json; charset=UTF-8',
              },
              body: JSON.stringify({ email: values.email }),
            })
              .then(() =>
                navigate(`/check-workspace-email?email=${values.email}`)
              )
              .catch(console.error)
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Field name="email" validate={required}>
                {({ field, form }: any) => (
                  <>
                    <FormControl
                      marginBottom="48px"
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <Flex>
                        <Input
                          variant="filled"
                          placeholder="Work email"
                          {...field}
                        />
                        <Box />
                      </Flex>

                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex justify="flex-end" my="16px">
                      <OutlineButton
                        onClick={() => {
                          navigate(`/sign-in-workspace`)
                        }}
                        mr="8px"
                      >
                        Back
                      </OutlineButton>
                      <FillButton style={{ margin: '0px' }}>Confirm</FillButton>
                    </Flex>
                  </>
                )}
              </Field>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  </SignUpWrapper>
)}

export default FindYourWorkspace
