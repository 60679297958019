import React, { useEffect, useState } from "react"

import {
  Flex,
  Avatar,
  Link,
  Image,
  Text,
  Icon,
  Button,
  Box,
} from "@chakra-ui/core"
import TimeAgo from "javascript-time-ago"
import { HText, HHeading } from "./StyledTextComponents"
import en from "javascript-time-ago/locale/en"
import { IActivity, ActivityTypes } from "../types"
import { urls } from "../App"
import { useCompany } from "../Context/company-context"
import { usePitches } from "../Context/pitches-context"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import { firebaseApp } from "../firebase"
import { useAuth } from "../Context/auth-context"
import GreenPea1 from "./Images/Green_Pea_1-min.png"

//@ts-ignore
import gradient from "random-gradient"
//@ts-ignore
TimeAgo.addLocale(en)

// Create relative date/time formatter.
const timeAgo = new TimeAgo("en-US")
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ActivityButton = ({ text, action }: any) => (
  <Button
    variant="outline"
    borderWidth="1px"
    borderColor="darkBlue"
    borderRadius="1rem"
    marginTop="8px"
    marginBottom="8px"
    marginRight="8px"
    color="darkBlue"
    size="sm"
    onClick={action}
  >
    {text}
  </Button>
)

const WelcomeActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return activity.userId === userProfile?.uid ? (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px" mr="8px">
        <Image
          size={["64px"]}
          objectFit="cover"
          borderRadius="100%"
          src={GreenPea1}
          p="5px"
          alt="Green Pea 1"
        />
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between">
          <HText as="b">Welcome to Huminate</HText>
        </Flex>
        <HText>
          Get involved with pitches in your company or get to know your
          co-workers.
        </HText>
        <Flex>
          <ActivityButton
            text={"View Pitches"}
            //@ts-ignore
            action={() => navigate(`/${urls(companyUrl).pitchSearchView}`)}
          />
          <ActivityButton
            text={"View People"}
            //@ts-ignore
            action={() => navigate(`/${urls(companyUrl).userSkillSearchView}`)}
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  ) : (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px" mr="8px">
        <Image
          size={["64px"]}
          objectFit="cover"
          borderRadius="100%"
          src={GreenPea1}
          p="5px"
          alt="Green Pea 1"
        />
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between">
          <HText as="b">
            {" "}
            {`${capitalizeFirstLetter(
              activity.firstName || ""
            )} ${capitalizeFirstLetter(
              activity.lastName || ""
            )}  has Joined Huminate`}{" "}
          </HText>
        </Flex>
        <HText>
          Get involved with pitches in your company or get to know your
          co-workers.
        </HText>
        <Flex>
          <ActivityButton
            text={"View Pitches"}
            //@ts-ignore
            action={() => navigate(`/${urls(companyUrl).pitchSearchView}`)}
          />
          <ActivityButton
            text={"View People"}
            //@ts-ignore
            action={() => navigate(`/${urls(companyUrl).userSkillSearchView}`)}
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const CreatedActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              mr="8px"
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex w="100%" pl="4px" direction="column">
        <Flex justify="space-between" w="100%">
          {activity.userId === userProfile?.uid ? (
            <Flex w="100%" flexWrap="wrap">
              <HText as="b">You</HText>
              <HText mx="4px">created</HText>
              <HText as="b">
                {capitalizeFirstLetter(activity.pitchTitle || "")}
              </HText>
            </Flex>
          ) : (
            <Flex w="100%" flexWrap="wrap">
              <HText as="b">
                {`${capitalizeFirstLetter(
                  activity.firstName || ""
                )} ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
              <HText mx="4px">created</HText>
              <HText as="b">
                {capitalizeFirstLetter(activity.pitchTitle || "")}
              </HText>
              <HText mx="4px">and selected you as the</HText>
              <HText as="b">Approver</HText>
            </Flex>
          )}
        </Flex>
        {activity.userId === userProfile?.uid && (
          <HText fontSize="sm" h="24px" p="2px">
            Invite People, Create Tasks and Get Approval
          </HText>
        )}
        <Flex>
          <ActivityButton
            text={"View Pitch"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
        </Flex>
      </Flex>

      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const RequestedToJoinActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">requested to join</HText>
            <HText as="b">
              {capitalizeFirstLetter(activity.pitchTitle || "")}
            </HText>
          </Flex>
        </Flex>
        <Flex>
          <ActivityButton
            text={"View People"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}#people`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const AcceptedRequestToJoinActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">accepted</HText>
            <HText as="b">
              {`${capitalizeFirstLetter(activity.invitedFirstName || "")} 
                ${capitalizeFirstLetter(activity.invitedLastName || "")}'s`}
            </HText>
            <HText mx="4px">request to join</HText>
            <HText as="b">
              {capitalizeFirstLetter(activity.pitchTitle || "")}
            </HText>
          </Flex>
        </Flex>
        <Flex>
          <ActivityButton
            text={"View People"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}#people`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const InvitedToPitchActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.invitedUserId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between">
          <HText as="b">
            {activity.userId === userProfile?.uid
              ? `You invited ${capitalizeFirstLetter(
                  activity?.invitedFirstName || ""
                )} ${capitalizeFirstLetter(
                  activity?.invitedLastName || ""
                )} to pitch ${capitalizeFirstLetter(
                  activity?.pitchTitle || ""
                )}`
              : `${capitalizeFirstLetter(
                  activity?.firstName || ""
                )} ${capitalizeFirstLetter(
                  activity?.lastName || ""
                )} invited ${capitalizeFirstLetter(
                  activity?.invitedFirstName || ""
                )} ${capitalizeFirstLetter(
                  activity?.invitedLastName || ""
                )} to pitch ${capitalizeFirstLetter(
                  activity?.pitchTitle || ""
                )} `}
          </HText>
        </Flex>
        <Flex>
          <ActivityButton
            text={"View Pitch"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const AcceptedInviteToPitchActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between">
          <HText as="b">
            {activity.userId === userProfile?.uid
              ? `You accepted invite to pitch ${capitalizeFirstLetter(
                  activity.pitchTitle || ""
                )}`
              : `${capitalizeFirstLetter(
                  activity.firstName || ""
                )} ${capitalizeFirstLetter(
                  activity.lastName || ""
                )} accepted invite to pitch ${capitalizeFirstLetter(
                  activity.pitchTitle || ""
                )} `}
          </HText>
        </Flex>
        <Flex>
          <ActivityButton
            text={"View Pitch"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const RejectedInviteToPitchActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between">
          <HText as="b">
            {activity.userId === userProfile?.uid
              ? `You declined invite to pitch ${capitalizeFirstLetter(
                  activity.pitchTitle || ""
                )}`
              : `${capitalizeFirstLetter(
                  activity.firstName || ""
                )} ${capitalizeFirstLetter(
                  activity.lastName || ""
                )} declined invite to pitch ${capitalizeFirstLetter(
                  activity.pitchTitle || ""
                )} `}
          </HText>
        </Flex>
        <Flex>
          <ActivityButton
            text={"View Pitch"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const InvitedToPitchTaskActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between">
          <HText as="b">
            {activity.userId === userProfile?.uid
              ? `You invited ${capitalizeFirstLetter(
                  activity.invitedFirstName || ""
                )} ${capitalizeFirstLetter(
                  activity.invitedLastName || ""
                )} to pitch ${capitalizeFirstLetter(
                  activity.pitchTitle || ""
                )} to work on task ${activity.taskTitle}`
              : `${capitalizeFirstLetter(
                  activity.firstName || ""
                )} ${capitalizeFirstLetter(
                  activity.lastName || ""
                )} invited ${capitalizeFirstLetter(
                  activity.invitedFirstName || ""
                )} ${capitalizeFirstLetter(
                  activity.invitedLastName || ""
                )} to pitch ${capitalizeFirstLetter(
                  activity?.pitchTitle || ""
                )} to work on task ${activity.taskTitle}`}
          </HText>
        </Flex>
        <Flex>
          <ActivityButton
            text={"View Tasks"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}#tasks`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const PitchSubmitForApprovalActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">submitted</HText>
            <HText as="b">
              {`${capitalizeFirstLetter(activity.pitchTitle || "")}`}
            </HText>
            <HText mx="4px">for</HText>
            {activity.pitchApproverId === userProfile?.uid ? (
              <HText>{"your review"}</HText>
            ) : (
              <HText as="b">{"Approval"}</HText>
            )}
          </Flex>
        </Flex>
        {activity.pitchApproverId === userProfile?.uid && (
          <HText fontSize="sm" minHeight="24px">
            {`Please let the team know your decision to approve or decline.`}
          </HText>
        )}
        <Flex>
          {activity.pitchApproverId === userProfile?.uid ? (
            <ActivityButton
              text={"Review"}
              //@ts-ignore
              action={() =>
                navigate(
                  `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
                )
              }
            />
          ) : (
            <ActivityButton
              text={"View"}
              //@ts-ignore
              action={() =>
                navigate(
                  `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
                )
              }
            />
          )}
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const CommentActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">commented on pitch</HText>
            <HText as="b">
              {capitalizeFirstLetter(activity.pitchTitle || "")}
            </HText>
          </Flex>
        </Flex>
        {activity.comment && (
          <HText fontSize="sm" h="24px">
            {`"${activity.comment}"`}
          </HText>
        )}
        <Flex>
          <ActivityButton
            text={"View Comments"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${
                  activity.pitchReference
                }#comments`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const PitchCompletedActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
              mr="8px"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">completed and added an</HText>
            <HText as="b">achievement</HText>
            <HText mx="4px">to</HText>
            <HText as="b">
              {capitalizeFirstLetter(activity.pitchTitle || "")}
            </HText>
          </Flex>
        </Flex>
        {activity.achievementText && (
          <HText fontSize="sm" h="24px">
            {`"${activity.achievementText}"`}
          </HText>
        )}
        <Flex>
          <ActivityButton
            text={"View Achievement"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
          <ActivityButton
            text={"Congratulate Team"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${
                  activity.pitchReference
                }#comments`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const PitchApprovedActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" mr="8px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">approved</HText>
            <HText as="b">
              {capitalizeFirstLetter(activity.pitchTitle || "")}
            </HText>
          </Flex>
        </Flex>

        <Flex>
          <ActivityButton
            text={"View"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

const PitchDeclinedActivity = ({
  time,
  activity,
}: {
  time: number
  activity: IActivity
}) => {
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()

  return (
    <Flex w="100%" p="8px">
      <Flex align="center" w="70px">
        {activity.userId === userProfile?.uid ? (
          <Box h="calc(100% + 16px)" w="68px" mr="8px" ml="-8px" mt="0px">
            <Image
              h="100%"
              w="100%"
              borderBottomLeftRadius={"8px"}
              borderTopLeftRadius={"8px"}
              background={gradient(activity.pitchReference)}
              src={activity?.pitchCoverPicture}
              objectFit="cover"
            />
          </Box>
        ) : (
          <Box h="64px" w="64px" p="8px">
            <Avatar
              background={gradient(activity.lastName || "")}
              src={activity.userPictureUrl}
            />
          </Box>
        )}
      </Flex>
      <Flex direction="column" w="100%" pl="4px">
        <Flex justify="space-between" w="100%">
          <Flex w="100%" flexWrap="wrap">
            {activity.userId === userProfile?.uid ? (
              <HText as="b">You</HText>
            ) : (
              <HText as="b">
                {`${capitalizeFirstLetter(activity.firstName || "")} 
                ${capitalizeFirstLetter(activity.lastName || "")}`}
              </HText>
            )}
            <HText mx="4px">declined</HText>
            <HText as="b">
              {capitalizeFirstLetter(activity.pitchTitle || "")}
            </HText>
          </Flex>
        </Flex>
        <HText fontSize="sm" h="24px">
          {`You may resubmit or delete the pitch`}
        </HText>
        <Flex>
          <ActivityButton
            text={"View"}
            //@ts-ignore
            action={() =>
              navigate(
                `/${urls(companyUrl)}/pitches/${activity.pitchReference}`
              )
            }
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end" w="32px">
        <HText fontSize="sm">
          {
            //@ts-ignore
            timeAgo.format(time).split(" ")[0] +
              //@ts-ignore
              timeAgo.format(time).split(" ")[1][0]
          }
        </HText>
      </Flex>
    </Flex>
  )
}

interface ActivityMenuItemProps {
  activity: IActivity
}
export const ActivityMenuItem: React.FC<ActivityMenuItemProps> = ({
  activity,
}) => {
  const { companyUrl } = useCompany()
  const { pitches, likePitch } = usePitches()
  const { userProfile } = useAuth()
  switch (activity.type) {
    case ActivityTypes.welcome:
      return <WelcomeActivity time={activity.time} activity={activity} />
    case ActivityTypes.createdPitch:
      return <CreatedActivity time={activity.time} activity={activity} />
    case ActivityTypes.invitedToPitch:
      return <InvitedToPitchActivity time={activity.time} activity={activity} />
    case ActivityTypes.invitedToTask:
      return (
        <InvitedToPitchTaskActivity time={activity.time} activity={activity} />
      )
    case ActivityTypes.requestedToJoin:
      return (
        <RequestedToJoinActivity time={activity.time} activity={activity} />
      )
    case ActivityTypes.commentedOnPitch:
      return <CommentActivity time={activity.time} activity={activity} />
    case ActivityTypes.acceptedRequestToJoin:
      return (
        <AcceptedRequestToJoinActivity
          time={activity.time}
          activity={activity}
        />
      )
    case ActivityTypes.pitchCompleted:
      return <PitchCompletedActivity time={activity.time} activity={activity} />
    case ActivityTypes.acceptedInviteToPitch:
      return (
        <AcceptedInviteToPitchActivity
          time={activity.time}
          activity={activity}
        />
      )
    case ActivityTypes.rejectedInviteToPitch:
      return (
        <RejectedInviteToPitchActivity
          time={activity.time}
          activity={activity}
        />
      )
    case ActivityTypes.pitchSubmitForApproval:
      return (
        <PitchSubmitForApprovalActivity
          time={activity.time}
          activity={activity}
        />
      )
    case ActivityTypes.pitchApproved:
      return <PitchApprovedActivity time={activity.time} activity={activity} />
    case ActivityTypes.pitchDeclined:
      return <PitchDeclinedActivity time={activity.time} activity={activity} />
    default:
      return <></> //<div>{JSON.stringify(activity, null, 2)}</div>
  }
}
