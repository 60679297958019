/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
  Text,
  Box,
} from "@chakra-ui/core"
import { Field, Formik } from "formik"
import React, { useEffect, useState } from "react"

import { useAuth } from "../../Context/auth-context"
import { usePitches } from "../../Context/pitches-context"
import { useCompany } from "../../Context/company-context"
import { OutlineButton, FillButton } from "../../Components/Buttons"
import { IPitch, PitchStatus, TagTypes } from "../../types"
import { SignUpButton, SignUpWrapper } from "../SignupFlow/Layouts"
import { required } from "../../formUtils"
import { useWorkspaces } from "../../Context/workspace-context"

interface MyFormValues {
  description: string
}

export const CreateAPitchOneView = ({
  onNext,
  onBack,
  onSkipWalkthrough,
}: any) => {
  const { createPitch } = usePitches()
  const { user, userProfile } = useAuth()
  const { companyUrl: company } = useCompany()
  const { currentWorkspace, tags } = useWorkspaces()
  const [draftPitch, setDraftPitch] = useState<IPitch | null | undefined>()

  useEffect(() => {
    const d = window.sessionStorage.getItem("pitchDraft")
    if (d) {
      setDraftPitch(JSON.parse(d))
    } else {
      setDraftPitch(null)
    }
  }, [])

  if (draftPitch === undefined) {
    return <Box width="100%" minHeight="400px" />
  }

  const initialValues: MyFormValues = {
    description: draftPitch?.description || "",
  }

  return (
    <Box minHeight="400px">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (user?.sub && userProfile) {
            const newPitch: IPitch = {
              status: PitchStatus.draft,
              title: "",
              description: values.description,
              company,
              createdBy: user.sub,
              createdByFirstName: userProfile?.firstName || "",
              createdByLastName: userProfile?.lastName || "",
              createdByProfilePicture: userProfile?.photo ?? "",
              members: [user.sub],
              skillTags: [],
              attachments: [],
              champions: [user.sub],
              workspaceUID: currentWorkspace?.id,
            }
            // when a pitch is created, the create pitch function sets the newly created pitch as the {currentPitch} from usePitches()
            // createPitch(newPitch)
            window.sessionStorage.setItem(
              "pitchDraft",
              JSON.stringify(newPitch)
            )

            onNext()
          }
        }}
      >
        {(props: any) => (
          <form
            onSubmit={props.handleSubmit}
            style={{ width: "100%", height: "calc(100% - 86px)" }}
          >
            <Flex direction="column" h="100%" justify="space-between">
              <>
                <Field name="description" validate={required}>
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                    >
                      <FormLabel color="fontBlack" htmlFor="description">
                        Describe your idea
                      </FormLabel>
                      <FormHelperText color="fontDarkGray">
                        E.g. competitor analysis, market size, customer examples
                        and successes
                      </FormHelperText>
                      <Textarea
                        minH="200px"
                        color="fontDarkGray"
                        mt={2}
                        width="100%"
                        variant="filled"
                        data-testid="description"
                        {...field}
                        style={{
                          fontFamily: "inherit",
                          fontSize: "inherit",
                        }}
                        id="description"
                        backgroundColor="rgba(9, 19, 33, 0.0638304)"
                        placeholder=""
                        fontSize="16px"
                        resize="vertical"
                      />
                      <FormErrorMessage
                        style={{ height: "17px", marginBottom: "-25px" }}
                      >
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
              <Flex justify="center" mt="64px">
                <OutlineButton
                  onClick={() => onBack()}
                  data-testid="back"
                  style={{ marginRight: "8px" }}
                >
                  Cancel
                </OutlineButton>
                <OutlineButton
                  onClick={() => onSkipWalkthrough()}
                  data-testid="back"
                  style={{ marginRight: "8px" }}
                >
                  Skip Walkthough
                </OutlineButton>
                <FillButton
                  type="submit"
                  data-testid="next"
                  style={{ marginLeft: "8px" }}
                >
                  Next
                </FillButton>
              </Flex>
              <Flex justify="center" width="100%" my="16px">
                <Flex
                  alignItems="center"
                  maxWidth="120px"
                  width="100%"
                  justify="space-between"
                >
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={"100%"}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkGray"
                    borderRadius={"100%"}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkGray"
                    borderRadius={"100%"}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkGray"
                    borderRadius={"100%"}
                  />
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Box>
  )
}
