import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/core'
import React, { useState } from 'react'
import { FillButton } from '../../../Components/Buttons'
import { IPitch } from '../../../types'
import { HTextArea } from './../../../Components/StyledTextComponents'
import { usePitches } from '../../../Context/pitches-context'
import { useAuth } from '../../../Context/auth-context'

interface EditAchievementModalProps {
  onClose: () => void
  isOpen: boolean
  pitch: IPitch
  prefill?: string
}
export const EditAchievementModal: React.FC<EditAchievementModalProps> = ({
  isOpen,
  onClose,
  pitch,
  prefill,
}) => {
  const [achievement, setAchievement] = useState<string>(prefill ? prefill : '')
  const { updatePitch } = usePitches()
  const { user } = useAuth()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10}>
          <FormLabel>Achievement</FormLabel>
          <HTextArea
            data-testid="achievement-text"
            minH="140px"
            value={achievement}
            onChange={(e: any) => setAchievement(e.target.value)}
          />
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          <Button variant="ghost" color="darkBlue" onClick={onClose}>
            Cancel
          </Button>
          <FillButton
            data-testid="save-profile-achievement"
            onClick={() => {
              const existingAchievements = pitch.pitchMemberAchievements || []
              pitch.id &&
                user?.sub &&
                updatePitch(pitch.id, {
                  pitchMemberAchievements: [
                    { userId: user?.sub, achievement: achievement },
                  ],
                })
              onClose()
            }}
          >
            Save
          </FillButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
