/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/core'
import { getTime } from 'date-fns'
import { Field, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { OutlineButton, FillButton } from '../../Components/Buttons'
import { IAttachment, IPitch, TagTypes } from '../../types'
import { AddLinkModal } from './modals/addLinkModal'
import { SkillTagSuggestions } from '../../fixtures'
import {
  required,
  requireAtleastOne,
  requireAtleastThree,
} from '../../formUtils'
import { Tag, TagInput } from '../../Components/TagInput'
import { useWorkspaces } from '../../Context/workspace-context'

interface MyFormValues2 {
  skillTags: Tag[]
}
export const CreateAPitchTwoView = ({ onNext, onBack }: any) => {
  // the pitches provider holds the id of the lastest created pitch as "editing pitch"
  const { currentWorkspace, tags } = useWorkspaces()
  const [draftPitch, setDraftPitch] = useState<IPitch | null | undefined>()
  // @TODO use the attachements from the current pitch so that navigating back will display the attachements
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [links, setLinks] = useState<IAttachment[]>([])
  useEffect(() => {
    const d = window.sessionStorage.getItem('pitchDraft')
    if (d) {
      const draftP = JSON.parse(d)
      setDraftPitch(draftP)
    } else {
      setDraftPitch(null)
    }
  }, [])

  if (draftPitch === undefined) {
    return <Box width="100%" minHeight="400px" />
  }

  const initialValues: MyFormValues2 = {
    skillTags: draftPitch?.skillTags.map(({ tag }: any) => tag) || [],
  }
  return (
    <Box minHeight="400px" w="100%">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const newPitch = {
            skillTags: values.skillTags.map((tag) => {
              return {
                tag: { ...tag, type: TagTypes.skill },
                usersNotified: false,
              }
            }),
          }
          window.sessionStorage.setItem(
            'pitchDraft',
            JSON.stringify({ ...draftPitch, ...newPitch })
          )
          onNext()
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            style={{ width: '100%', height: 'calc(100% - 86px)' }}
          >
            <Flex
              direction="column"
              minHeight="400px"
              justify="space-between"
              height="100%"
              width="100%"
            >
              <>
                <Field name="skillTags" validate={requireAtleastThree}>
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="32px"
                      isInvalid={
                        form.errors.skillTags && form.touched.skillTags
                      }
                    >
                      <FormLabel color="fontBlack" htmlFor="skillTags">
                        Notify people through tags about your idea
                      </FormLabel>
                      <FormHelperText color="fontDarkGray" mb={2}>
                        Find the people you need to support your idea. These
                        tags help others discover and participate your pitch.
                        For example; People Management, CRM, Python, or Social
                        Media.
                      </FormHelperText>
                      <TagInput
                        testId="skillTags"
                        tags={field.value}
                        suggestions={[...SkillTagSuggestions, ...tags]}
                        onTagUpdate={(tags) => {
                          props.setFieldValue('skillTags', tags)
                        }}
                      />
                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.skillTags}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
              <Flex direction="column">
                <Flex justify="center">
                  <OutlineButton
                    onClick={onBack}
                    data-testid="back"
                    style={{ marginRight: '8px' }}
                  >
                    Back
                  </OutlineButton>
                  <FillButton
                    type="submit"
                    data-testid="next"
                    style={{ marginLeft: '8px' }}
                  >
                    Next
                  </FillButton>
                </Flex>
                <Flex justify="center" width="100%" my="16px">
                  <Flex
                    alignItems="center"
                    maxWidth="120px"
                    width="100%"
                    justify="space-between"
                  >
                    <Box
                      width="16px"
                      height="16px"
                      backgroundColor="darkBlue"
                      borderRadius={'100%'}
                    />
                    <Box
                      width="16px"
                      height="16px"
                      backgroundColor="darkBlue"
                      borderRadius={'100%'}
                    />
                    <Box
                      width="16px"
                      height="16px"
                      backgroundColor="darkGray"
                      borderRadius={'100%'}
                    />
                    <Box
                      width="16px"
                      height="16px"
                      backgroundColor="darkGray"
                      borderRadius={'100%'}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
      <AddLinkModal
        onClose={onClose}
        isOpen={isOpen}
        links={links}
        setLinks={setLinks}
      />
    </Box>
  )
}
