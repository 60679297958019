import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  Icon,
  Divider,
  Stack,
  useDisclosure,
} from "@chakra-ui/core"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import React, { useState, useEffect } from "react"
import { OutlineButton } from "../../Components/Buttons"
import { HText, HTextArea } from "../../Components/StyledTextComponents"
import { isMobile } from "../../Components/isMobile"

import { useAuth } from "../../Context/auth-context"
import { usePitches } from "../../Context/pitches-context"
import { IComment, IPitch } from "../../types"
import { getTime } from "date-fns"
import {
  MentionsInput,
  Mention,
  SuggestionDataItem,
  MentionItem,
} from "react-mentions"
import { useWorkspaces } from "../../Context/workspace-context"
import { firebaseApp } from "../../firebase"
//@ts-ignore
import gradient from "random-gradient"
//@ts-ignore
TimeAgo.addLocale(en)

// Create relative date/time formatter.
const timeAgo = new TimeAgo("en-US")
interface AddCommentCardProps extends BoxProps {
  comment?: IComment
  pitch?: IPitch
  allComments: any
  setComments: any
  onClose?: () => void
}

export const AddCommentCard: React.FC<AddCommentCardProps> = ({
  comment,
  pitch,
  allComments,
  setComments,
  onClose,

  ...rest
}) => {
  const [thisComment, setThisComment] = useState<string>("")
  const { userProfile } = useAuth()
  const { updatePitchComments } = usePitches()
  const { users } = useWorkspaces()
  const [commentMentions, setCommentMentions] = useState<MentionItem[]>([])

  const AddComment = () => {
    if (userProfile && pitch && pitch.id) {
      if (comment) {
        console.log({ comment })
        const newComment = {
          taggedUsersNotified: false,
          userId: userProfile.uid,
          time: getTime(new Date()),
          comment: thisComment,
          taggedUsers: commentMentions,
          replyTo: comment.id,
        }
        const newData = {
          comments: newComment,
          commentCount: (pitch?.commentCount ?? 0) + 1,
        }
        console.log({ newData })
        updatePitchComments(pitch.id, newData)
        setComments([
          {
            ...newData.comments,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            photo: userProfile.photo || "",
          },
          ...allComments,
        ])
        setThisComment("")
        onClose && onClose()
      } else {
        // add this comment to the pitch comments
        const newComment: IComment = {
          taggedUsersNotified: false,
          userId: userProfile.uid,
          time: getTime(new Date()),
          comment: thisComment,
          taggedUsers: commentMentions,
        }
        const newData = {
          comments: newComment,
          commentCount: (pitch?.commentCount ?? 0) + 1,
        }
        updatePitchComments(pitch.id, newData)
        setComments([
          {
            ...newData.comments,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            photo: userProfile.photo || "",
          },
          ...allComments,
        ])
        setThisComment("")
        setCommentMentions([])
        onClose && onClose()
      }
    }
  }

  return (
    <Box maxW="420px" {...rest}>
      {/* add comment box */}
      <Flex align="center" mb={4}>
        <Avatar
          h="40px"
          w="40px"
          src={userProfile?.photo}
          name={userProfile?.firstName + " " + userProfile?.lastName}
          background={gradient(userProfile?.lastName)}
          mr="24px"
        />
        <HText ml={2}>Add a new comment</HText>
      </Flex>
      <MentionsInput
        aria-multiline
        style={{
          suggestions: {
            list: {
              backgroundColor: "white",
              border: "1px solid rgba(0,0,0,0.15)",
              fontSize: 10,
            },

            item: {
              padding: "5px 15px",
              borderBottom: "1px solid rgba(0,0,0,0.15)",

              "&focused": {
                backgroundColor: "#cee4e5",
              },
            },
          },
          input: {
            padding: "10px",
            color: "fontDarkGray",
          },
          minHeight: "107px",
          color: "fontDarkGray",
          background: "rgba(9, 19, 33, 0.0638304)",
        }}
        value={thisComment}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          setThisComment(newPlainTextValue)
          setCommentMentions([...commentMentions, ...mentions])
        }}
      >
        <Mention
          style={{}}
          trigger="@"
          data={
            users?.map((user) => {
              return {
                id: user.uid,
                display: `${user.firstName} ${user.lastName}` ?? "",
              }
            }) ?? []
          }
          renderSuggestion={(
            entry,
            search,
            highlightedDisplay,
            index,
            focused
          ) => {
            return <HText>{entry.display}</HText>
          }}
        />
      </MentionsInput>

      <OutlineButton
        isDisabled={!thisComment}
        mt={2}
        onClick={() => {
          AddComment()
        }}
      >
        Post comment
      </OutlineButton>
    </Box>
  )
}

interface CommentCardProps extends BoxProps {
  comment: any
  allComments: any
  setComments: any
  replies: any[]
  isReply?: boolean
  preview?: boolean
  pitch: IPitch
}
const CommentCard: React.FC<CommentCardProps> = ({
  comment,
  isReply,
  allComments,
  setComments,
  preview,
  replies,
  pitch,
  ...rest
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <Box key={comment.userId + "||" + comment.time.toString()} {...rest}>
      <Flex mb={4}>
        <Avatar
          h="40px"
          w="40px"
          src={comment?.photo}
          name={comment?.firstName + " " + comment?.lastName}
          background={gradient(comment?.lastName || "test")}
          mr="24px"
        />
        <Box ml={2}>
          <HText fontSize="sm">
            {comment?.firstName + " " + comment?.lastName}
          </HText>
          <HText fontSize="xs"> {timeAgo.format(comment.time)}</HText>
        </Box>
      </Flex>

      <HText>{comment.comment}</HText>
      {/* reply function hidden on reply comments */}
      <Flex
        hidden={isOpen || isReply || preview}
        onClick={onOpen}
        cursor="pointer"
        mt={2}
      >
        <Icon name="replyComment" aria-label="reply" mr={2} />
        <HText color="textLink" fontSize="sm">
          Reply
        </HText>
      </Flex>
      <Box ml={12}>
        {/* map existing replies */}
        {replies.map((replyComment) => {
          return (
            <CommentCard
              pitch={pitch}
              key={replyComment.userId + "||" + replyComment.time.toString()}
              mt={12}
              allComments={allComments}
              setComments={setComments}
              replies={[]}
              comment={replyComment}
              isReply={true}
            />
          )
        })}
        {/* add reply box, only displayed when reply button clicked */}
        <AddCommentCard
          key={comment.userId + "||" + comment.time.toString()}
          comment={comment}
          mt={6}
          allComments={allComments}
          setComments={setComments}
          hidden={!isOpen}
          onClose={onClose}
          pitch={pitch}
        />
      </Box>
    </Box>
  )
}

interface PitchCommentProps {
  currentPitch?: IPitch
  preview?: boolean
}

export const PitchComments: React.FC<PitchCommentProps> = ({
  currentPitch,
  preview,
}) => {
  const [comments, setComments] = useState<IComment[]>([])
  const { users } = useWorkspaces()
  const isMobileDevice = isMobile()

  useEffect(() => {
    if (currentPitch && users) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection(`pitches/${currentPitch.id}/comments`)
        .orderBy("time", "desc")
        .onSnapshot(
          (snapshot: any) => {
            console.log(snapshot)
            setComments(
              snapshot.docs
                .map((doc: any) => {
                  const d = doc.data()
                  const user = users.find(({ uid }) => uid === d.userId)
                  return user ? { ...d, ...user } : (d as IComment)
                })
                .sort((a: any, b: any) => a.time - b.time)
            )
          },
          (err: any) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [currentPitch])
  if (currentPitch) {
    console.log(comments)
    const parentComments = comments?.filter(
      ({ replyTo }) => replyTo === undefined
    )
    const replyComments = comments?.filter(
      ({ replyTo }) => replyTo !== undefined
    )

    if (preview) {
      return (
        <Flex justify="center" width="100%" pb="32px">
          <Box width="100%">
            <Stack mt={preview ? 0 : 12} spacing={12}>
              {parentComments.map((comment, i) => {
                if (i < 3) {
                  return (
                    <CommentCard
                      key={comment.userId + "||" + comment.time.toString()}
                      comment={comment}
                      setComments={setComments}
                      allComments={comments}
                      pitch={currentPitch}
                      preview
                      replies={[]}
                    />
                  )
                }
              })}
            </Stack>
          </Box>
        </Flex>
      )
    }

    return (
      <Flex justify="center" width="100%" pb="32px">
        <Box width="100%">
          <Stack mt={preview ? 0 : 12} spacing={12}>
            {parentComments.map((comment) => {
              const replies =
                replyComments.filter(({ replyTo }) => replyTo === comment.id) ||
                []
              console.log({ replies })
              return (
                <CommentCard
                  key={comment.userId + "||" + comment.time.toString()}
                  comment={comment}
                  setComments={setComments}
                  allComments={comments}
                  replies={replies}
                  pitch={currentPitch}
                />
              )
            })}
          </Stack>

          <Divider />
          <AddCommentCard
            pitch={currentPitch}
            setComments={setComments}
            allComments={comments}
          />
        </Box>
      </Flex>
    )
  } else {
    return <></>
  }
}
