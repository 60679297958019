/* eslint-disable react/no-unescaped-entities */
import { Flex, TabList, TabPanel, TabPanels, Tabs, Box } from '@chakra-ui/core'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmailInvitePeople, InvitePeople } from '../../Components/InvitePeople'
import { HText, StyledTab } from '../../Components/StyledTextComponents'
import { useCompany } from '../../Context/company-context'
import { urls } from '../../App'
import { useWorkspaces } from '../../Context/workspace-context'
import { FillButton, OutlineButton } from '../../Components/Buttons'
import { usePitches } from '../../Context/pitches-context'
import { isMobile } from '../../Components/isMobile'

export const CreateAPitchFourView = () => {
  const { companyUrl: company } = useCompany()
  const { currentWorkspace } = useWorkspaces()
  const workspaceSlug = currentWorkspace?.slug || 'general'
  const navigate = useNavigate()
  const { currentPitch } = usePitches()
  const [showPeople, setShowPeople] = useState(false)
  const isMobileDevice = isMobile()

  console.log(workspaceSlug)
  if (!workspaceSlug) {
    return <></>
  }

  return (
    <Box h={'auto'} p={isMobileDevice ? '0px' : '32px'}>
      <Flex
        direction="column"
        justify="space-between"
        minHeight="280px"
        w="100%"
      >
        <Box>
          {!showPeople && (
            <>
              <HText fontSize="md" as="b" mb={6}>
                People who matched your pitch tags have now been notified.
              </HText>
              <HText fontSize="md" mt={6}>
                Do you want to build your team now or wait for people to join?
              </HText>
            </>
          )}
          {showPeople && (
            <Tabs color="fontDarkGray">
              <TabList mb="16px">
                <StyledTab>Search People</StyledTab>
                <StyledTab>Invite by Email</StyledTab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <InvitePeople />
                </TabPanel>

                <TabPanel mb="30px">
                  <EmailInvitePeople />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Box>
        <Flex justify="center" mt="16px">
          {!showPeople && (
            <OutlineButton
              mr={2}
              data-testid="build-team"
              onClick={() => setShowPeople(true)}
            >
              Build Team
            </OutlineButton>
          )}
          <FillButton
            data-testid="see-pitch"
            onClick={() =>
              currentPitch &&
              navigate(
                `/${urls(company, workspaceSlug).viewPitch}/${currentPitch?.id}`
              )
            }
          >
            See Pitch
          </FillButton>
        </Flex>
      </Flex>
    </Box>
  )
}
