import React, { useEffect, useState } from "react"
import {
  Flex,
  Box,
  Image,
  Text,
  Icon,
  Avatar,
  AvatarGroup,
} from "@chakra-ui/core"
import { format } from "date-fns"
import { Link, useNavigate } from "react-router-dom"
import { FriendlyModal } from "./FriendlyModal"
import { PitchComments } from "../Views/PitchDisplay/Comments"

import { IPitch, PitchStatus } from "../types"
import { useCompany } from "../Context/company-context"
import { HText } from "./StyledTextComponents"
import { usePitches } from "../Context/pitches-context"
import { firebaseApp } from "../firebase"
import { useAuth } from "../Context/auth-context"
import { PitchProgressBar } from "../Views/PitchDisplay/PitchSidebar"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import { useWorkspaces } from "../Context/workspace-context"

//@ts-ignore
import gradient from "random-gradient"
interface PitchCardProps {
  pitch: Partial<IPitch>
  big?: boolean
  isRequest?: boolean
}

const StatusIndicator = ({ status }: { status: string }) => (
  <Box
    style={{
      position: "absolute",
      margin: "16px",
      padding: "0px 4px 0px",
      backgroundColor: "#1D3149",
      color: "white",
      fontWeight: "bold",
      borderRadius: "4px",
    }}
  >
    {status}
  </Box>
)

const Overdue = () => (
  <Box
    style={{
      position: "absolute",
      margin: "16px",
      padding: "0px 4px 0px",
      backgroundColor: "#af2020",
      right: "0%",
      color: "white",
      fontWeight: "bold",
      borderRadius: "4px",
    }}
  >
    {"Overdue"}
  </Box>
)

export const PitchCard: React.FC<PitchCardProps> = ({
  pitch,
  big,
  isRequest,
  ...restProps
}) => {
  const {
    id,
    status,
    coverPicture,
    title,
    dueDate,
    createdByProfilePicture,
    createdByFirstName,
    createdByLastName,
    likesCount,
    members,
    submissionDate,
    ...rest
  } = pitch
  const { companyUrl } = useCompany()
  const { likePitch } = usePitches()
  const { userProfile } = useAuth()
  const { users } = useWorkspaces()
  const [liked, setLiked] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isCommentOpen, setIsCommentOpen] = useState(false)

  const collaborators = pitch.members?.map((userId) =>
    users?.find((user: any) => user.uid === userId)
  )
  useEffect(() => {
    const unsubscribe = firebaseApp
      .firestore()
      .collection("pitchLikes")
      .doc(`${userProfile?.uid}:${pitch.id}`)
      .onSnapshot(
        (docSnapshot) => {
          if (docSnapshot.exists) {
            setLiked(true)
          } else {
            setLiked(false)
          }
        },
        (err) => console.log(err)
      )
    return unsubscribe
  }, [])

  return (
    <>
      <FriendlyModal
        title="Comments"
        isOpen={isCommentOpen}
        noButton
        onClose={() => {
          setIsCommentOpen(false)
        }}
      >
        <Flex justify={"center"} ml="32px">
          <Box maxW="480px" w="100%">
            <PitchComments
              //@ts-ignore
              currentPitch={pitch}
            />
          </Box>
        </Flex>
      </FriendlyModal>
      <Box
        borderRadius={"8px"}
        height={big ? "592px" : isRequest ? "320px" : "288px"}
        width={big ? "656px" : "320px"}
        backgroundColor="backgroundWhite"
        data-testid={`pitch-${pitch.title}`}
        boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05)"
        {...restProps}
      >
        <Flex direction="column" h="100%">
          <Link to={`/${companyUrl}/pitch/${id}`}>
            <Flex w="100%" style={{ position: "relative" }}>
              {status ? (
                <StatusIndicator
                  status={
                    isRequest
                      ? `${
                          status === PitchStatus.overdueSubmission
                            ? PitchStatus.draft
                            : status === PitchStatus.overdue
                            ? PitchStatus.inProgress
                            : status
                        } Request`
                      : status === PitchStatus.overdueSubmission
                      ? PitchStatus.draft
                      : status === PitchStatus.overdue
                      ? PitchStatus.inProgress
                      : status
                  }
                />
              ) : null}

              {status === PitchStatus.overdue ||
              status === PitchStatus.overdueSubmission ? (
                <Overdue />
              ) : null}

              <Image
                h={big ? "393px" : "123px"}
                borderTopRightRadius={"8px"}
                borderTopLeftRadius={"8px"}
                w="100%"
                background={gradient(pitch.id)}
                src={coverPicture?.url}
                objectFit="cover"
              />
            </Flex>
            {pitch.status !== PitchStatus.draft &&
            pitch.completion !== undefined ? (
              <PitchProgressBar
                percentComplete={pitch.completion}
                mb={4}
                rounded={0}
              />
            ) : (
              <div style={{ height: "28px" }} />
            )}
            <Flex w="100%" minHeight="56px" px={"16px"} pb={"16px"}>
              <Text fontSize="lg">{title}</Text>
            </Flex>
          </Link>
          <Flex
            direction="column"
            h="64px"
            justify="space-between"
            px={"16px"}
            pb={"16px"}
          >
            <div />
            <Flex justify="space-between">
              <AvatarGroup size="sm" max={2} mr="8px">
                {collaborators?.map(({ firstName, lastName, photo }: any) => {
                  return (
                    <Avatar
                      key={`${firstName}${lastName}`}
                      h="32px"
                      w="32px"
                      name={firstName + " " + lastName}
                      background={gradient(lastName)}
                      src={photo}
                    />
                  )
                })}
              </AvatarGroup>
              <Flex>
                {/* Tasks */}
                <Flex
                  align="center"
                  mr="16px"
                  cursor="pointer"
                  onClick={() => {
                    navigate(`/${companyUrl}/pitch/${id}#tasks`)
                  }}
                >
                  <Icon name="checkBoard" mr="4px" />
                  <Text color="fontDarkgrey">{pitch?.taskCount || 0}</Text>
                </Flex>

                {/* Due Date */}
                <Flex
                  align="center"
                  mr="16px"
                  cursor="pointer"
                  onClick={() => {
                    navigate(`/${companyUrl}/pitch/${id}`)
                  }}
                >
                  <Icon name="dateBoard" mr="4px" />
                  <Text color="fontDarkgrey">
                    {(dueDate && format(dueDate, "dd/LL")) ||
                      (submissionDate && format(submissionDate, "dd/LL"))}
                  </Text>
                </Flex>

                {/* Comments */}
                <Flex
                  align="center"
                  mr="16px"
                  cursor="pointer"
                  onClick={() => {
                    setIsCommentOpen(true)
                  }}
                >
                  <Icon name="commentBubble" mr="4px" />
                  <Text color="fontDarkgrey">{pitch?.commentCount || 0}</Text>
                </Flex>

                {/* Likes */}
                <Flex
                  align="center"
                  mr="16px"
                  onClick={() => pitch.id && likePitch(pitch.id)}
                  cursor="pointer"
                >
                  {/* Show the correct emoji depending on if its liked or not */}
                  {liked ? (
                    <HText
                      color={"tagRedText"}
                      mr={2}
                      fontSize="xl"
                      data-testid={`${pitch.title}-liked`}
                    >
                      <FaHeart style={{ height: "18px" }} />
                    </HText>
                  ) : (
                    <HText
                      color={"#bababa"}
                      mr={2}
                      fontSize="xl"
                      data-testid={`${pitch.title}-like`}
                    >
                      <FaRegHeart style={{ height: "18px" }} />
                    </HText>
                  )}

                  <HText>{likesCount || 0}</HText>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
