import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/core'
import { HHeading, HTextArea } from './../../../Components/StyledTextComponents'

import { FillButton } from '../../../Components/Buttons'
import { IPitch, PitchStatus } from '../../../types'

interface CompletePitchModalProps {
  onClose: () => void
  isOpen: boolean
  updatePitch: (id: string, data: Partial<IPitch>) => void
  pitch: IPitch
  userId: string
}
export const CompletePitchModal: React.FC<CompletePitchModalProps> = ({
  isOpen,
  onClose,
  updatePitch,
  userId,
  pitch,
}) => {
  const [achievement, setAchievement] = useState<string>('')
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="8px">
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10}>
          <HHeading mb="32px">Complete pitch</HHeading>
          <FormLabel>Achievements</FormLabel>
          <FormHelperText color="fontBlack" mb="8px">
            You may want to add comments regarding pitch highlights,
            accomplishments and acknowledgements.
          </FormHelperText>
          <HTextArea
            minH="140px"
            value={achievement}
            data-testid="pitch-achievement-input"
            onChange={(e: any) => {
              setAchievement(e.target.value)
            }}
          />
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          <FillButton
            data-testid="pitch-achievement-button"
            onClick={(e) => {
              pitch.id &&
                updatePitch(pitch.id, {
                  status: PitchStatus.complete,
                  completedBy: userId,
                  completedAt: new Date(),
                  skillTags: pitch?.skillTags.map(({ tag, ...rest }) => ({
                    tag: {
                      ...tag,
                      toggled: true,
                    },
                    ...rest,
                  })),
                  pitchAchievement: achievement,
                })
              onClose()
            }}
          >
            Done
          </FillButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
