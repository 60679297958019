import React from 'react'
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './DatePicker.css'

import enAu from 'date-fns/locale/en-AU'
registerLocale('en-au', enAu)
setDefaultLocale('en-au')

export const DatePicker = ({ startDate, handleChange, ...rest }: any) => (
  <ReactDatePicker
    selected={startDate}
    onChange={handleChange}
    minDate={new Date()}
    dateFormat="dd/MM/yyyy"
    locale="en-au"
    {...rest}
  />
)
