/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { SignUpWrapper } from '../SignupFlow/Layouts'
import { Flex, Box, Text, Link } from '@chakra-ui/core'
import { Link as ReactRouterLink } from 'react-router-dom'

export const CheckYourWorkspaceEmail: React.FC = () => {
  const email = window.location.search.split('?email=')[1]

  return (
    <SignUpWrapper workspace="huminate">
      <Flex justify="center">
        <Box padding={['8px', '16px']}>
          <Text fontWeight="700" fontSize="3xl" color="fontBlack" my="40px">
            Check your email!
          </Text>
          <Text maxWidth="480px" mb="16px">
            {`We've emailed a special link to ${email}.`}
          </Text>
          <Text maxWidth="480px" mb="32px">
            Click the link to confirm your address and get started.
          </Text>
          <Flex align="center" pt="32px">
            <Text fontSize="md" color="fontDarkGray">
              Wrong email?
            </Text>
            <Link
              // @ts-ignore
              as={ReactRouterLink}
              to="/find-workspace"
              fontSize="md"
              ml="4px"
              color="textLink"
            >
              Re-enter your email address
            </Link>
          </Flex>
        </Box>
      </Flex>
    </SignUpWrapper>
  )
}

export default CheckYourWorkspaceEmail
