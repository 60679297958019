import {
  Box,
  Flex,
  IconButton,
  Image,
  Button,
  Link as ChakraLink,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Badge,
  useDisclosure,
  Icon,
  Text,
  Link,
  AvatarGroup,
  Avatar,
  MenuButton,
  MenuItem,
  Menu,
  InputRightElement,
  Tag,
  TagLabel,
  TagCloseButton,
  InputGroup,
  MenuList,
} from "@chakra-ui/core"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { FaHeart, FaRegHeart, FaShare } from "react-icons/fa"
import { firebaseApp } from "../../firebase"
import { OutlineButton, FillButton } from "../../Components/Buttons"
import { urls } from "../../App"
import theme from "../../theme"
import {
  HHeading,
  HInput,
  HText,
  HTextArea,
  StyledTab,
} from "../../Components/StyledTextComponents"

import { FriendlyModal } from "../../Components/FriendlyModal"
import { isMobile } from "../../Components/isMobile"
import { format } from "date-fns"
import * as R from "ramda"

import { useAuth } from "../../Context/auth-context"
import { useCompany } from "../../Context/company-context"
import { usePitches } from "../../Context/pitches-context"
import { useWorkspaces } from "../../Context/workspace-context"
import {
  IPitch,
  PitchMemberTypes,
  PitchStatus,
  RequestStatuses,
  IComment,
  IUserProfile,
  InviteStatuses,
} from "../../types"
import { DashboardContainer } from "../Dashboard/DashboardWrapper"
import { PitchComments } from "./Comments"
import { DeletePitchModal } from "./Modals/DeletePitchModal"
import { CompletePitchModal } from "./Modals/CompletePitchModal"

import { SubmitPitchModal } from "./Modals/SubmitPitchModal"
import { PitchDetail } from "./Pitch"
import PitchEdit from "./EditPitch"

import { PitchPeople } from "./PitchPeople"

import { PitchTasks } from "./Tasks"
//@ts-ignore
import gradient from "random-gradient"
//@ts-ignore
import flatten from "flat"

//@ts-ignore
import { parse } from "json2csv"

//@ts-ignore
function download(data, filename, type) {
  const file = new Blob([data], { type: type })
  //@ts-ignore
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    //@ts-ignore
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    // Others
    const a = document.createElement("a"),
      url = URL.createObjectURL(file)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    window.open(url)
    // a.click()
    // setTimeout(function () {
    //   document.body.removeChild(a)
    //   window.URL.revokeObjectURL(url)
    // }, 0)
  }
}

const StatusLine = ({
  currentPitch,
  completion,
}: {
  currentPitch: IPitch
  completion: number
}) => {
  const green = "#78E1D4"
  const gray = "#A6A6A6"
  const { users } = useWorkspaces()

  function capitalize(str: string) {
    const lower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + lower.slice(1)
  }

  const createdByUser = users?.find(({ uid }) => uid === currentPitch.createdBy)
  if (!currentPitch) {
    return <></>
  }

  const approvedByUser =
    users?.find(({ uid }) => uid === currentPitch?.approvedBy) ||
    currentPitch?.manager

  const updatedAt = currentPitch?.updatedAt?.seconds
    ? new Date(currentPitch?.updatedAt?.seconds * 1000)
    : new Date()

  const approvedOn = currentPitch?.approvedOn?.seconds
    ? new Date(currentPitch?.approvedOn?.seconds * 1000)
    : updatedAt

  console.log(currentPitch?.createdAt)

  const createdDate = currentPitch?.createdAt?.seconds
    ? new Date(currentPitch?.createdAt?.seconds * 1000)
    : new Date()

  const DATE_MIN_WIDTH = "184px"

  console.log("pitch display")

  return currentPitch.status === PitchStatus.draft ? (
    <Flex w="100%" justify="center" maxWidth="640px">
      <Flex direction="column">
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={"white"}
                border={`4px solid ${green}`}
                zIndex={3}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>{`${format(
                createdDate,
                "do MMMM yyyy"
              )}`}</HText>
              <HText>{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={gray}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={gray} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} as="b">
                {`${format(
                  new Date(currentPitch?.submissionDate || Date.now()),
                  "do MMMM yyyy"
                )}
            `}
              </HText>
              <HText as="b">{`Due for Submission`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : currentPitch.status === PitchStatus.overdueSubmission ? (
    <Flex w="100%" justify="center">
      <Flex direction="column" maxWidth="560px">
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={"white"}
                border={`4px solid ${green}`}
                zIndex={3}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} as="b">
                {`${format(createdDate, "do MMMM yyyy")}`}
              </HText>
              <HText as="b">{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={"tagRedText"}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"tagRedText"} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center" ml="2px">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} color="tagRedText">
                {`${format(
                  new Date(currentPitch?.submissionDate || Date.now()),
                  "do MMMM yyyy"
                )}`}
              </HText>
              <HText color="tagRedText">{`Due for Submission`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : currentPitch.status === PitchStatus.inProgress ? (
    <Flex w="100%" justify="center">
      <Flex direction="column" maxWidth="560px">
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} as="b">
                {`${format(createdDate, "do MMMM yyyy")}`}
              </HText>
              <HText as="b">{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(approvedOn, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Approved by ${capitalize(
                approvedByUser?.firstName || ""
              )} ${capitalize(approvedByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={"white"}
                border={`4px solid ${green}`}
                zIndex={3}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" my={2} justify="center" w="100%">
            <Flex flexWrap="wrap">
              <HText mb={2} as="b" minW={DATE_MIN_WIDTH}>{`${Math.floor(
                (completion ?? 0) * 100
              )}% of Tasks Complete`}</HText>

              <HText mb={2} as="b">
                Pitch is in Progress
              </HText>
            </Flex>
            <Box
              position="relative"
              h={3}
              rounded="full"
              w="100%"
              overflow="hidden"
            >
              <Box
                w="100%"
                backgroundColor="#DDDDDD"
                h="100%"
                pos="absolute"
              ></Box>
              <Box
                w={`${(completion / 1) * 100}%`}
                backgroundColor="#78E1D4"
                h="100%"
                position="absolute"
              ></Box>
            </Box>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={gray}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={gray} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(
                  new Date(currentPitch?.submissionDate || Date.now()),
                  "do MMMM yyyy"
                )}`}
              </HText>
              <HText>{`Due for Completion`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : currentPitch.status === PitchStatus.almostDue ? (
    <Flex w="100%" justify="center">
      <Flex direction="column" maxWidth="560px">
        <Flex minHeight="48px">
          <Flex mr="16px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="21px"
                h="21px"
                mr="-8px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-5px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="-5px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(createdDate, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="21px"
                h="21px"
                mr="-8px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-5px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="-5px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(approvedOn, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Approved by ${capitalize(
                approvedByUser?.firstName || ""
              )} ${capitalize(approvedByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" my={2} justify="center" w="100%">
            <Flex flexWrap="wrap">
              <HText mb={2} as="b" minW={DATE_MIN_WIDTH}>{`${Math.floor(
                (completion ?? 0) * 100
              )}% of Tasks Complete`}</HText>

              <HText mb={2} as="b">
                Pitch is in Progress
              </HText>
            </Flex>
            <Box
              position="relative"
              h={3}
              rounded="full"
              w="100%"
              overflow="hidden"
            >
              <Box
                w="100%"
                backgroundColor="#DDDDDD"
                h="100%"
                pos="absolute"
              ></Box>
              <Box
                w={`${(completion / 1) * 100}%`}
                backgroundColor="#78E1D4"
                h="100%"
                position="absolute"
              ></Box>
            </Box>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="21px"
                h="21px"
                mr="-8px"
                borderRadius="100%"
                backgroundColor={gray}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-5px" w="4px" h="100%" backgroundColor={gray} />
              <Box ml="-5px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center" color="yellow1">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(
                  new Date(currentPitch?.submissionDate || Date.now()),
                  "do MMMM yyyy"
                )}`}
              </HText>
              <HText>{`Nearly Due for Completion`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : currentPitch.status === PitchStatus.overdue ? (
    <Flex w="100%" justify="center" maxWidth="560px">
      <Flex direction="column">
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex justify="center" align="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(createdDate, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(approvedOn, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Approved by ${capitalize(
                approvedByUser?.firstName || ""
              )} ${capitalize(approvedByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={"white"}
                border={`4px solid ${green}`}
                zIndex={3}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" my={2} justify="center" w="100%">
            <Flex flexWrap="wrap">
              <HText mb={2} as="b" minW={DATE_MIN_WIDTH}>{`${Math.floor(
                (completion ?? 0) * 100
              )}% of Tasks Complete`}</HText>

              <HText mb={2} as="b">
                Pitch is in Progress
              </HText>
            </Flex>
            <Box
              position="relative"
              h={3}
              rounded="full"
              w="100%"
              overflow="hidden"
            >
              <Box
                w="100%"
                backgroundColor="#DDDDDD"
                h="100%"
                pos="absolute"
              ></Box>
              <Box
                w={`${(completion / 1) * 100}%`}
                backgroundColor="#78E1D4"
                h="100%"
                position="absolute"
              ></Box>
            </Box>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={"warning"}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"warning"} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center" color="warning">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} color="warning">
                {`${format(
                  new Date(currentPitch?.submissionDate || Date.now()),
                  "do MMMM yyyy"
                )}`}
              </HText>
              <HText color="warning">{`Overdue for Completion`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : currentPitch.status === PitchStatus.complete ? (
    <Flex w="100%" justify="center">
      <Flex direction="column" maxWidth="560px">
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(createdDate, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(approvedOn, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Approved by ${capitalize(
                approvedByUser?.firstName || ""
              )} ${capitalize(approvedByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" my={2} justify="center" w="100%">
            <HText mb={2}>{`${Math.floor(1 * 100)}% of Tasks Complete`}</HText>
            <Box
              position="relative"
              h={3}
              rounded="full"
              w="100%"
              overflow="hidden"
            >
              <Box
                w="100%"
                backgroundColor="#DDDDDD"
                h="100%"
                pos="absolute"
              ></Box>
              <Box
                w={`${100}%`}
                backgroundColor="#78E1D4"
                h="100%"
                position="absolute"
              ></Box>
            </Box>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={"white"}
                border={`4px solid ${green}`}
                zIndex={3}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} as="b">
                {`${format(new Date(updatedAt), "do MMMM yyyy")}`}
              </HText>
              <HText as="b">{`Completed`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <Flex w="100%" justify="center">
      <Flex direction="column" maxWidth="560px">
        <Flex minHeight="48px">
          <Flex mr="14px" ml="3px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="16px"
                h="16px"
                mr="-6px"
                borderRadius="100%"
                backgroundColor={green}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="-4px" w="4px" h="100%" backgroundColor={"transparent"} />
              <Box ml="-4px" w="4px" h="100%" backgroundColor={green} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH}>
                {`${format(createdDate, "do MMMM yyyy")}`}
              </HText>
              <HText>{`Drafted by ${capitalize(
                createdByUser?.firstName || ""
              )} ${capitalize(createdByUser?.lastName || "")}`}</HText>
            </Flex>
          </Flex>
        </Flex>
        <Flex minHeight="48px">
          <Flex mr="16px" ml="-2px">
            <Flex direction="column" alignItems="center" justify="center">
              <Box
                w="27px"
                h="27px"
                mr="-17px"
                borderRadius="100%"
                backgroundColor={"white"}
                border={`4px solid ${green}`}
                zIndex={3}
              />
            </Flex>
            <Flex direction="column" alignItems="center" justify="center">
              <Box ml="1px" w="4px" h="100%" backgroundColor={green} />
              <Box ml="1px" w="4px" h="100%" backgroundColor={"transparent"} />
            </Flex>
          </Flex>
          <Flex direction="column" justify="center">
            <Flex flexWrap="wrap">
              <HText minW={DATE_MIN_WIDTH} as="b">
                {`${format(new Date(updatedAt), "do MMMM yyyy")}
            `}
              </HText>
              <HText>{`Waiting for approval`}</HText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const PitchActionButton = ({ children, ...rest }: any) => (
  <Button
    color="white"
    backgroundColor="darkBlue"
    fontSize="md"
    size="sm"
    fontWeight="500"
    {...rest}
  >
    {children}
  </Button>
)

export const createSharePitchRequest = ({
  userProfiles,
  pitch,
  message,
  company,
}: {
  userProfiles: IUserProfile[]
  pitch: IPitch
  message: string
  company: string
}) => {
  firebaseApp
    .firestore()
    .collection("sharePitchRequests")
    .doc(company)
    .collection("pending")
    .add({
      userProfiles,
      pitch,
      message,
    })
}

const PitchDisplay: React.FC = () => {
  const {
    createRequest,
    updateRequest,
    deleteRequest,
    invites,
    updateInvite,
    currentPitchManager,
    pitches,
    setCurrentPitch,
    currentPitch,
    taskLists,
    userCanSee,
    likePitch,
    updatePitch,
    requests,
    removeCollaborator,
  } = usePitches()
  const [alreadyInPitchModal, setAlreadyInPitchModal] = useState(false)
  const navigate = useNavigate()
  const { companyUrl: company } = useCompany()
  const [isCommentOpen, setIsCommentOpen] = useState(false)
  const { user, userProfile, updateUserProfile } = useAuth()
  const { pitchId } = useParams<{ pitchId?: string }>()
  const { currentWorkspace } = useWorkspaces()
  const isMobileDevice = isMobile()
  const [currentPitchLoading, setCurrentPitchLoading] = useState<boolean>(true)
  const [comments, setComments] = useState<IComment[]>([])
  const [acceptingInvite, setAcceptingInvite] = useState(false)
  const { filteredResults, setSearchFilter, searchFilter } = useWorkspaces()

  let taskCount = 0

  taskLists?.map(({ tasks }) => {
    tasks.map(() => {
      taskCount++
    })
  })

  if (
    window.location.hash &&
    window.location.hash.indexOf("email") > -1 &&
    !alreadyInPitchModal
  ) {
    console.log("YEP EMAIL")
    setAlreadyInPitchModal(true)
  }
  const [liked, setLiked] = useState<boolean>(false)

  useEffect(() => {
    if (currentPitch) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection("pitchLikes")
        .doc(`${userProfile?.uid}:${currentPitch.id}`)
        .onSnapshot(
          //@ts-ignore
          (docSnapshot) => {
            if (docSnapshot.exists) {
              setLiked(true)
            } else {
              setLiked(false)
            }
          },
          //@ts-ignore
          (err) => console.log(err)
        )
      return unsubscribe
    }
  }, [currentPitch])
  //   match the pitch id from the URL to the pitches list
  useEffect(() => {
    const p = pitches?.find((pitch) => pitch.id === pitchId)
    setCurrentPitch(p)
    if (pitches) {
      setCurrentPitchLoading(false)
    }
  }, [pitches])

  useEffect(() => {
    if (currentPitch && users) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection(`pitches/${currentPitch.id}/comments`)
        .onSnapshot(
          (snapshot: any) => {
            setComments(
              snapshot.docs.map((doc: any) => {
                const d = doc.data()
                //@ts-ignore
                const user = users.find(({ uid }) => uid === d.userId)
                return user ? { ...d, ...user } : (d as IComment)
              })
            )
          },
          (err: any) => {
            console.log(err)
          }
        )
      return unsubscribe
    }
  }, [currentPitch])

  const {
    submitPitch,
    completePitch,
    followPitch,
    inviteCollaborators,
    approvePitch,
  } = userCanSee(currentPitch, user?.sub)
  const { users, workspaces } = useWorkspaces()
  const [tabIndex, setTabIndex] = useState(
    window.location.hash === "#tasks"
      ? 3
      : window.location.hash === "#pitch"
      ? 1
      : window.location.hash === "#people"
      ? 2
      : 0
  )

  const {
    onOpen: onCompleteOpen,
    onClose: onCompleteClose,
    isOpen: isCompleteOpen,
  } = useDisclosure()
  const {
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
    isOpen: isSubmitOpen,
  } = useDisclosure()
  const handleTabsChange = (index: any) => {
    setTabIndex(index)
  }
  const isChampion = currentPitch?.champions?.find(
    (uid: any) => uid === user?.sub
  )
  const isMember = currentPitch?.members?.find((uid: any) => uid === user?.sub)
  const isManager = currentPitch?.manager?.uid === user?.sub

  const { isOpen, onOpen, onClose } = useDisclosure()
  const manager = users?.find(
    ({ uid }: any) => uid === currentPitch?.manager?.uid
  )
  const hasInvite = invites?.filter(({ to }) => to === user?.sub)
  console.log({ followPitch })
  const {
    isOpen: isPitchEditOpen,
    onOpen: onPitchEditOpen,
    onClose: onPitchEditClose,
  } = useDisclosure()

  const {
    isOpen: isPitchShareOpen,
    onOpen: onPitchShareOpen,
    onClose: onPitchShareClose,
  } = useDisclosure()

  const [showMembers, setShowMembers] = useState(false)
  const [message, setMessage] = useState("")
  const [listShare, setListShare] = useState<any>([])
  const tags = currentPitch?.skillTags

  const MoreActionButton = ({ children, ...rest }: any) => (
    <Button
      color="darkBlue"
      backgroundColor="white"
      fontSize="md"
      variant="outline"
      border="1px"
      borderColor="brandSecondary"
      size="sm"
      fontWeight="500"
      {...rest}
    >
      {children}
    </Button>
  )

  // calculate the completion percentage based on completion of tasks and their difficulty
  const allTasks =
    taskLists && R.flatten(taskLists.map((taskList) => taskList.tasks))
  let totalTaskValue = 0
  let currentTaskCompletionValue = 0
  allTasks?.forEach((task) => {
    totalTaskValue += task.difficulty
    if (task.isCompleted) {
      currentTaskCompletionValue += task.difficulty
    }
  })
  console.log({ currentPitch })
  const completion = totalTaskValue
    ? currentTaskCompletionValue / totalTaskValue
    : 0

  if (currentPitch === undefined && !currentPitchLoading) {
    const defaultWorkspace =
      workspaces && workspaces.length > 0 ? workspaces[0].slug : ""
    return (
      <DashboardContainer>
        <Flex
          w="100%"
          h="100%"
          direction="column"
          justify="center"
          align="center"
        >
          <HText mb="8px" as="b">
            Pitch no longer exists
          </HText>
          <FillButton
            onClick={() =>
              navigate(`/${urls(company, defaultWorkspace).dashboard}`)
            }
          >
            Go to Workspace
          </FillButton>
        </Flex>
      </DashboardContainer>
    )
  } else if (currentPitch === undefined) {
    return (
      <DashboardContainer>
        <></>
      </DashboardContainer>
    )
  }

  const dueDateS =
    currentPitch.status === PitchStatus.draft ||
    currentPitch.status === PitchStatus.overdueSubmission
      ? currentPitch?.submissionDate || Date.now()
      : currentPitch?.dueDate || Date.now()

  return (
    <DashboardContainer>
      {currentPitch?.id && (
        <DeletePitchModal
          pitchId={currentPitch.id}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <FriendlyModal
        title="Accepting Invite"
        isOpen={alreadyInPitchModal}
        onClose={() => {
          window.location.hash = ""
          setAlreadyInPitchModal(false)
        }}
      >
        <Text fontSize="xl">You are already in this Pitch!</Text>
      </FriendlyModal>
      <FriendlyModal
        title="Share Pitch"
        isOpen={isPitchShareOpen}
        onClose={() => {
          setSearchFilter("")
          onPitchShareClose()
        }}
        noButton
      >
        <Flex width="100%" my="16px">
          <Text fontSize="md">Share with others in your workspace.</Text>
        </Flex>
        <HTextArea
          placeholder="Type a personalised message here."
          onChange={(e: any) => setMessage(e.target.value)}
        />

        {/* <Flex width="100%" my="16px">
          <Text fontSize="md">
            Select Members to share with
          </Text>
        </Flex>
        <HInput /> */}

        {showMembers ? (
          <>
            <Stack
              spacing={4}
              isInline
              mt={2}
              shouldWrapChildren
              flexWrap={"wrap"}
            >
              {listShare.map((a: any, i: number) => (
                <Tag
                  size={"lg"}
                  key={a.email}
                  rounded="full"
                  variant="solid"
                  variantColor="cyan"
                  minWidth={"180px"}
                  mt={2}
                >
                  <TagLabel>{a?.email}</TagLabel>
                  <TagCloseButton
                    onClick={() =>
                      setListShare([
                        ...listShare.slice(0, i),
                        ...listShare.slice(i, listShare.length - 1),
                      ])
                    }
                  />
                </Tag>
              ))}
            </Stack>
            <Flex width="100%" justify="flex-end" my="32px">
              <OutlineButton
                mr="16px"
                onClick={() => {
                  setSearchFilter("")
                  setShowMembers(false)
                }}
              >
                Back
              </OutlineButton>
              <FillButton
                onClick={() => {
                  createSharePitchRequest({
                    userProfiles: listShare,
                    pitch: currentPitch,
                    message: message,
                    company,
                  })
                  onPitchShareClose()
                  setSearchFilter("")
                  setMessage("")
                }}
              >
                Send to selected members
              </FillButton>
            </Flex>
            <HText my={4}>Send to people in your workspace</HText>
            <InputGroup mb="22px" h="48px" width="100%">
              <HInput
                placeholder="Search names or skills"
                value={searchFilter}
                onChange={(e: any) => setSearchFilter(e.target.value)}
                data-testid="search-pitch-members"
              ></HInput>
              <InputRightElement>
                <Icon name="search-2" mt="4px" color="#BABABA" />
              </InputRightElement>
            </InputGroup>
            <Box
              w="100%"
              maxHeight="340px"
              data-testid="suggestion-results"
              overflowY="auto"
            >
              {filteredResults &&
                userProfile &&
                filteredResults
                  .filter(({ uid }) => {
                    if (
                      (userProfile &&
                        userProfile?.manager &&
                        (userProfile.manager.uid === uid ||
                          userProfile.uid === uid)) ||
                      listShare.filter(({ uid: tUid }: any) => tUid === uid)
                        .length > 0
                    ) {
                      return false
                    } else {
                      return true
                    }
                  })
                  .sort((a, b) => {
                    let aCount = 0
                    let bCount = 0
                    tags &&
                      tags.map(({ tag }: any) => {
                        if (
                          a &&
                          a.tags &&
                          a.tags.filter(({ id }) => id === tag.id).length > 0
                        ) {
                          aCount++
                        }
                        if (
                          b &&
                          b.tags &&
                          b.tags.filter(({ id }) => id === tag.id).length > 0
                        ) {
                          bCount++
                        }
                      })
                    return bCount - aCount
                  })
                  .map((user, i) => {
                    if (!user || !user.lastName) {
                      return <></>
                    }
                    return (
                      <Flex
                        margin="16px 8px"
                        align="center"
                        key={i}
                        justify="space-between"
                      >
                        <Flex>
                          <Avatar
                            h="40px"
                            w="40px"
                            src={user.photo}
                            name={user.firstName + " " + user.lastName}
                            background={gradient(user.lastName)}
                            mr="12px"
                          />
                          <Flex direction="column">
                            <Link
                              color="textLink"
                              onClick={() => {
                                // setSelectedPerson(user)
                                onOpen()
                              }}
                            >
                              {user.firstName + " " + user.lastName}
                            </Link>
                            <HText>{user.position}</HText>
                            <Flex>
                              {user &&
                                user.tags &&
                                tags &&
                                user.tags
                                  .filter(({ id: userTag }) => {
                                    return tags.filter(
                                      ({ tag }: any) => tag.id === userTag
                                    ).length > 0
                                      ? true
                                      : false
                                  })
                                  .map(({ id, text }) => (
                                    <Tag
                                      key={id}
                                      variant={"solid"}
                                      backgroundColor="#78E1D4"
                                      color="fontDarkgray"
                                      size="sm"
                                      mr="2px"
                                    >
                                      {text}
                                    </Tag>
                                  ))}
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex justify="flex-start">
                          <PitchActionButton
                            mr="8px"
                            onClick={() => setListShare([...listShare, user])}
                          >
                            Add
                          </PitchActionButton>
                        </Flex>
                      </Flex>
                    )
                  })}
            </Box>
          </>
        ) : (
          <Flex width="100%" justify="flex-end" my="32px">
            <OutlineButton mr="16px" onClick={() => setShowMembers(true)}>
              Select members to share with
            </OutlineButton>
            <FillButton
              onClick={() => {
                createSharePitchRequest({
                  userProfiles: users || [],
                  pitch: currentPitch,
                  message: message,
                  company,
                })
                onPitchShareClose()
                setSearchFilter("")
                setMessage("")
              }}
            >
              Send to all
            </FillButton>
          </Flex>
        )}
      </FriendlyModal>
      <FriendlyModal
        isOpen={isPitchEditOpen}
        onClose={onPitchEditClose}
        noButton
      >
        <PitchEdit onSuccess={onPitchEditClose} />
      </FriendlyModal>
      <FriendlyModal
        title="Comments"
        isOpen={isCommentOpen}
        noButton
        onClose={() => {
          setIsCommentOpen(false)
        }}
      >
        <Box pl={isMobileDevice ? "0px" : "96px"}>
          <PitchComments currentPitch={currentPitch} />
        </Box>
      </FriendlyModal>
      <CompletePitchModal
        userId={user?.sub || ""}
        isOpen={isCompleteOpen}
        onClose={onCompleteClose}
        updatePitch={updatePitch}
        pitch={currentPitch}
      />
      <SubmitPitchModal
        isOpen={isSubmitOpen}
        onClose={onSubmitClose}
        pitch={currentPitch}
      />
      <Box
        backgroundColor="backgroundLight"
        display="flex"
        justifyContent="center"
      >
        <Flex
          direction="column"
          maxW="720px"
          width="100%"
          px={isMobileDevice ? "0px" : "16px"}
          alignItems="center"
        >
          <Box
            maxH="356px"
            borderRadius={isMobileDevice ? "0px" : "8px"}
            margin={isMobileDevice ? "-9px -8px 0 -8px" : "16px"}
            width={isMobileDevice ? "100vw" : "100%"}
            backgroundColor="backgroundWhite"
            boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05)"
          >
            <Flex direction="column" h="100%">
              <Flex w="100%" style={{ position: "relative" }}>
                <Image
                  h={"200px"}
                  borderTopRightRadius={isMobileDevice ? "0px" : "8px"}
                  borderTopLeftRadius={isMobileDevice ? "0px" : "8px"}
                  w="100%"
                  background={gradient(currentPitch.id)}
                  src={currentPitch.coverPicture?.url}
                  objectFit="cover"
                />
              </Flex>
              <Box p="16px" maxHeight="172px">
                <Flex w="100%">
                  <Text fontSize="xl" as="b" color="fontDarkGray">
                    {currentPitch?.title}
                  </Text>
                </Flex>

                <Flex justify="space-between">
                  <ChakraLink
                    my="4px"
                    onClick={() => {
                      window.location.hash = "#status"
                      setTabIndex(4)
                    }}
                  >
                    <Flex w="100%" align="center">
                      <HText fontSize="md" as="b" color="fontDarkGray">
                        {currentPitch.status === PitchStatus.overdueSubmission
                          ? PitchStatus.draft
                          : currentPitch.status === PitchStatus.overdue
                          ? PitchStatus.inProgress
                          : currentPitch.status}
                      </HText>
                      <HText fontSize="sm" mx="4px" color="fontDarkGray">
                        {"•"}
                      </HText>
                      <HText fontSize="sm" color="fontDarkGray">
                        {`Due ${format(new Date(dueDateS), "do MMMM yy")}`}
                      </HText>
                    </Flex>
                  </ChakraLink>
                </Flex>

                {!acceptingInvite && (
                  <Flex mt="4px">
                    {!(currentPitch.status === PitchStatus.complete) &&
                      !(isManager || isChampion || isMember) &&
                      !(hasInvite && hasInvite.length > 0) &&
                      (!requests?.find((req) => req.userId === user?.sub) ? (
                        <Popover>
                          <PopoverTrigger>
                            <Box>
                              <PitchActionButton mr="8px">
                                Request to join
                              </PitchActionButton>
                            </Box>
                          </PopoverTrigger>
                          <PopoverContent zIndex={4} w="120px">
                            <PopoverBody w="100%" h="100%" padding="8px">
                              <Stack align="left">
                                <ChakraLink
                                  data-testid="collaborator"
                                  fontSize="sm"
                                  color="fontDarkGray"
                                  onClick={() =>
                                    currentPitch &&
                                    currentPitch.id &&
                                    userProfile &&
                                    userProfile.firstName &&
                                    userProfile.lastName &&
                                    userProfile.uid &&
                                    createRequest({
                                      name:
                                        userProfile.firstName +
                                        " " +
                                        userProfile.lastName,
                                      userId: userProfile.uid,
                                      pitchReference: currentPitch.id,
                                      status: RequestStatuses.pending,
                                      type: PitchMemberTypes.collaborator,
                                    })
                                  }
                                >
                                  As collaborator
                                </ChakraLink>
                                <ChakraLink
                                  color="fontDarkGray"
                                  fontSize="sm"
                                  onClick={() =>
                                    currentPitch &&
                                    currentPitch.id &&
                                    userProfile &&
                                    userProfile.firstName &&
                                    userProfile.lastName &&
                                    userProfile.uid &&
                                    createRequest({
                                      name:
                                        userProfile.firstName +
                                        " " +
                                        userProfile.lastName,
                                      userId: userProfile.uid,
                                      pitchReference: currentPitch.id,
                                      status: RequestStatuses.pending,
                                      type: PitchMemberTypes.champion,
                                    })
                                  }
                                >
                                  As champion
                                </ChakraLink>
                              </Stack>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      ) : (
                        <PitchActionButton
                          onClick={() => {
                            const request = requests?.find(
                              (req) => req.userId === user?.sub
                            )
                            userProfile && request && deleteRequest(request)
                          }}
                          mr={4}
                        >
                          Withdraw Request
                        </PitchActionButton>
                      ))}
                    {!(currentPitch.status === PitchStatus.complete) &&
                    !(isMember || isManager || isChampion) ? (
                      followPitch &&
                      userProfile?.followedPitches?.find(
                        (followedPitch) => followedPitch === currentPitch.id
                      ) ? (
                        <PitchActionButton
                          mr="8px"
                          data-testid="unfollow"
                          onClick={() =>
                            currentPitch.id &&
                            updateUserProfile({
                              followedPitches:
                                userProfile.followedPitches?.filter(
                                  (pitch) => pitch !== currentPitch.id
                                ),
                            })
                          }
                        >
                          Following
                        </PitchActionButton>
                      ) : (
                        <PitchActionButton
                          mr="8px"
                          data-testid="follow"
                          onClick={() =>
                            // Follow the pitch
                            currentPitch.id &&
                            updateUserProfile({
                              followedPitches: userProfile?.followedPitches
                                ? [
                                    ...userProfile.followedPitches,
                                    currentPitch.id,
                                  ]
                                : [currentPitch.id],
                            })
                          }
                        >
                          Follow
                        </PitchActionButton>
                      )
                    ) : (
                      <></>
                    )}
                    {hasInvite &&
                    hasInvite.length > 0 &&
                    !isChampion &&
                    !isMember &&
                    !isManager ? (
                      <PitchActionButton
                        data-testid="accept-invite"
                        onClick={() => {
                          hasInvite[0].id &&
                            updateInvite(hasInvite[0].id, {
                              status: InviteStatuses.accepted,
                            })
                          setAcceptingInvite(true)
                        }}
                      >
                        Join as {hasInvite[0].subject?.type}
                      </PitchActionButton>
                    ) : (
                      <></>
                    )}
                    {approvePitch && isManager && (
                      <>
                        <PitchActionButton
                          data-testid="approve-pitch"
                          onClick={() =>
                            currentPitch.id &&
                            updatePitch(currentPitch.id, {
                              status: PitchStatus.inProgress,
                              // Saves as firestore timestamp
                              //@ts-ignore
                              approvedOn: new Date(),
                              approvedBy: user?.sub,
                            })
                          }
                        >
                          Approve
                        </PitchActionButton>
                        <PitchActionButton
                          mx={3}
                          onClick={() =>
                            currentPitch.id &&
                            updatePitch(currentPitch.id, {
                              status: PitchStatus.declined,
                            })
                          }
                        >
                          Decline
                        </PitchActionButton>
                      </>
                    )}
                    {completePitch &&
                      isChampion &&
                      allTasks &&
                      allTasks.filter(({ isCompleted }) => !isCompleted)
                        .length === 0 && (
                        <PitchActionButton
                          onClick={onCompleteOpen}
                          mr={2}
                          data-testid="complete-pitch"
                        >
                          Complete
                        </PitchActionButton>
                      )}
                    {submitPitch && isChampion ? (
                      <PitchActionButton
                        mr="8px"
                        data-testid="submit-pitch"
                        onClick={onSubmitOpen}
                      >
                        Submit For Approval
                      </PitchActionButton>
                    ) : (
                      <></>
                    )}
                    {isChampion || isManager ? (
                      <Menu>
                        <MenuButton as={MoreActionButton}>•••</MenuButton>
                        <MenuList>
                          <MenuItem onClick={onPitchEditOpen}>
                            Edit Pitch
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              // const fields = ['title', 'id', 'createdBy', 'members']
                              // const opts = { fields }
                              try {
                                const csv = parse(flatten(currentPitch))
                                console.log(csv)
                                download(
                                  csv,
                                  "text.csv",
                                  "data:text/csv;charset=utf-8"
                                )
                              } catch (err) {
                                console.error(err)
                              }
                            }}
                          >
                            {" "}
                            Download Pitch as CSV
                          </MenuItem>
                          <MenuItem onClick={onOpen}> Archive Pitch</MenuItem>
                        </MenuList>
                      </Menu>
                    ) : (
                      <></>
                    )}
                  </Flex>
                )}
                <Flex mt="8px">
                  {/* Likes */}
                  <Flex
                    align="center"
                    data-testId="likePitch"
                    mr="16px"
                    onClick={() =>
                      currentPitch.id && likePitch(currentPitch.id)
                    }
                    cursor="pointer"
                  >
                    {/* Show the correct emoji depending on if its liked or not */}
                    {liked ? (
                      <HText
                        color={"tagRedText"}
                        mr={2}
                        fontSize="xl"
                        data-testId="pitchLiked"
                      >
                        <FaHeart style={{ height: "18px" }} />
                      </HText>
                    ) : (
                      <HText
                        color={"#ababab"}
                        mr={2}
                        fontSize="xl"
                        data-testId="pitchNotLiked"
                      >
                        <FaRegHeart style={{ height: "18px" }} />
                      </HText>
                    )}

                    <HText>
                      {" "}
                      {`${currentPitch?.likesCount || 0}  ${
                        (currentPitch?.likesCount || 0) === 1 ? "Like" : "Likes"
                      }`}
                    </HText>
                  </Flex>
                  {/* Comments */}
                  <Flex
                    align="center"
                    mr="16px"
                    cursor="pointer"
                    onClick={() => setIsCommentOpen(true)}
                  >
                    <Icon color={"#ababab"} name="commentBubble" mr="4px" />
                    <HText>
                      {`${comments?.length || 0} ${
                        isMobileDevice
                          ? ""
                          : comments?.length > 1
                          ? "Comments"
                          : "Comment"
                      }`}
                    </HText>
                  </Flex>
                  <Flex
                    align="center"
                    mr="16px"
                    cursor="pointer"
                    onClick={() => onPitchShareOpen()}
                  >
                    <FaShare color={"#ababab"} style={{ marginRight: "8px" }} />
                    <HText>{`Share`}</HText>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Flex
            direction={"column"}
            h="100%"
            minW="100%"
            w="100%"
            flexShrink={0}
          >
            {/* main body */}
            <Tabs w="100%" index={tabIndex} onChange={handleTabsChange}>
              <TabList
                marginBottom={10}
                mt={2}
                width="100%"
                justifyContent="space-around"
              >
                <StyledTab data-testid="home-tab" mr="2">
                  Home
                </StyledTab>
                <StyledTab data-testid="pitch-tab" mr="2">
                  Pitch
                </StyledTab>
                <StyledTab data-testid="team-tab" mr="2">
                  Team
                </StyledTab>
                <StyledTab position="relative" data-testid="tasks-tab" mr="2">
                  Tasks{" "}
                  <Box
                    position="absolute"
                    top="-2px"
                    right="-2px"
                    color="brandPrimaryDark"
                    fontSize="xs"
                  >
                    {taskCount}
                  </Box>
                </StyledTab>
                <StyledTab data-testid="status-tab" mr="2">
                  Status
                </StyledTab>
              </TabList>
              <TabPanels flexGrow={1}>
                <TabPanel flexGrow={1} px={isMobileDevice ? "8px" : "16px"}>
                  {currentPitch?.pitchAchievement && (
                    <Flex
                      boxShadow="0px 8px 16px #84E5DA"
                      direction="column"
                      borderColor="#84E5DA"
                      backgroundColor="backgroundWhite"
                      borderWidth="2px"
                      flex="1"
                      rounded="lg"
                      p="16px"
                      mb={8}
                    >
                      <HHeading mb={4} fontSize="lg">
                        {`🎉 Congratulations team🎉`}
                      </HHeading>
                      <HText color="teal" whiteSpace="pre-line" mb={4}>
                        {currentPitch?.pitchAchievement}
                      </HText>{" "}
                    </Flex>
                  )}
                  {/* overview */}
                  <HHeading mb={3} fontSize="lg">
                    Overview
                  </HHeading>

                  <HText>{currentPitch.description.slice(0, 300)}</HText>
                  <ChakraLink
                    color="textLink"
                    onClick={() => {
                      window.location.hash = "#pitch"
                      setTabIndex(1)
                    }}
                  >
                    Read More
                  </ChakraLink>
                  <HHeading mb={3} mt={8} fontSize="lg">
                    Seeking Experience
                  </HHeading>
                  <Flex flexWrap="wrap">
                    {currentPitch?.skillTags.map((skill, i) =>
                      skill.tag?.toggled ? (
                        <></>
                      ) : (
                        <Tag
                          mb={2}
                          mr={2}
                          p={2}
                          px={4}
                          fontSize="sm"
                          key={i}
                          as={isChampion || isManager ? "button" : "div"}
                          textAlign="start"
                          backgroundColor="#78E1D4"
                          color="fontDarkgray"
                          variant={"solid"}
                        >
                          {skill.tag.text}
                        </Tag>
                      )
                    )}
                  </Flex>
                  <ChakraLink
                    color="textLink"
                    onClick={() => {
                      window.location.hash = "#tasks"
                      setTabIndex(3)
                    }}
                  >
                    View Tasks
                  </ChakraLink>
                  <HHeading mb={3} mt={8} fontSize="lg">
                    People
                  </HHeading>
                  <div
                    style={{
                      width: isMobileDevice ? "calc(100vw - 32px)" : "720px",
                      display: "grid",
                      marginRight: "8px",
                      gridAutoFlow: "column",
                      overflowY: "auto",
                      overscrollBehaviorX: "contain",
                      scrollSnapType: "x mandatory",
                      placeContent: "flex-start",
                      gap: "8px",
                      marginBottom: "8px",
                    }}
                  >
                    {manager && (
                      <Flex
                        shadow="md"
                        direction="column"
                        key={manager.uid}
                        backgroundColor="backgroundWhite"
                        borderWidth="1px"
                        flex="1"
                        rounded="lg"
                        py="16px"
                        minHeight="236px"
                        width="148px"
                      >
                        <Flex justify="center">
                          <AvatarGroup size="md" py="16px" max={2} mr="8px">
                            <Avatar
                              key={`${manager.firstName}${manager.lastName}`}
                              h={"84px"}
                              w={"84px"}
                              name={manager.firstName + " " + manager.lastName}
                              background={gradient(manager.lastName)}
                              src={manager.photo}
                            />
                          </AvatarGroup>
                        </Flex>
                        <Flex direction="column" mx="16px">
                          <Text color="fontBlack">
                            {manager.firstName + " " + manager.lastName}
                          </Text>
                          <Text color="fontDarkGray" fontSize="14px">
                            {manager.position}
                          </Text>
                          <Badge
                            variant="solid"
                            backgroundColor={theme.colors.approverBadge}
                            w="78px"
                          >
                            Approver
                          </Badge>
                        </Flex>
                      </Flex>
                    )}

                    {currentPitch?.members.map((userId) => {
                      const user = users?.find(({ uid }: any) => uid === userId)

                      const isMemberChampion = currentPitch?.champions?.find(
                        (uid: any) => uid === userId
                      )
                      return user ? (
                        <Flex
                          shadow="md"
                          direction="column"
                          key={userId}
                          backgroundColor="backgroundWhite"
                          borderWidth="1px"
                          flex="1"
                          rounded="lg"
                          py="16px"
                          minHeight="236px"
                          width="148px"
                        >
                          {isChampion && (
                            <Flex
                              mt="-16px"
                              mb="-16px"
                              width="100%"
                              justify={"flex-end"}
                            >
                              <IconButton
                                variant="ghost"
                                variantColor="gray"
                                aria-label="close"
                                icon="close"
                                size="sm"
                                onClick={() => {
                                  if (currentPitch) {
                                    removeCollaborator(userId, currentPitch)
                                  }
                                }}
                              />
                            </Flex>
                          )}
                          <Flex justify="center">
                            <AvatarGroup size="md" py="16px" max={2} mr="8px">
                              <Avatar
                                key={`${user.firstName}${user.lastName}`}
                                h={"84px"}
                                w={"84px"}
                                name={user.firstName + " " + user.lastName}
                                background={gradient(user.lastName)}
                                src={user.photo}
                              />
                            </AvatarGroup>
                          </Flex>
                          <Flex direction="column" mx="16px">
                            <Text color="fontBlack">
                              {user.firstName + " " + user.lastName}
                            </Text>
                            <Text color="fontDarkGray" fontSize="14px">
                              {user.position}
                            </Text>
                            {isMemberChampion ? (
                              <Badge
                                variant="solid"
                                backgroundColor={theme.colors.championBadge}
                                w="78px"
                              >
                                champion
                              </Badge>
                            ) : (
                              <Badge
                                variant="solid"
                                backgroundColor={theme.colors.collaboratorBadge}
                                w="108px"
                              >
                                collaborator
                              </Badge>
                            )}
                          </Flex>
                        </Flex>
                      ) : (
                        <></>
                      )
                    })}
                  </div>
                  <ChakraLink
                    color="textLink"
                    mt={"12px"}
                    onClick={() => {
                      window.location.hash = "#people"
                      setTabIndex(2)
                    }}
                  >
                    View People
                  </ChakraLink>
                  <HHeading mb={3} mt={8} fontSize="lg">
                    Recent Comments
                  </HHeading>

                  <PitchComments preview currentPitch={currentPitch} />
                  <ChakraLink
                    color="textLink"
                    onClick={() => setIsCommentOpen(true)}
                  >
                    Open Comments
                  </ChakraLink>
                </TabPanel>
                <TabPanel flexGrow={1} px={isMobileDevice ? "8px" : "16px"}>
                  {/* pitch */}
                  <PitchDetail currentPitch={currentPitch} />
                </TabPanel>

                <TabPanel flexGrow={1} px={isMobileDevice ? "8px" : "16px"}>
                  {/* people */}
                  <PitchPeople currentPitch={currentPitch} />
                </TabPanel>
                <TabPanel>
                  {/* tasks */}
                  <Flex direction="column" align="center" w="100%">
                    {currentPitch && <PitchTasks />}
                  </Flex>
                </TabPanel>
                <TabPanel px={isMobileDevice ? "8px" : "16px"}>
                  {/* overview */}
                  <StatusLine
                    currentPitch={currentPitch}
                    completion={completion}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
    </DashboardContainer>
  )
}

interface EditPitchWPopoverProps {
  pitch?: IPitch
  company: string
}

const EditPitchWPopover: React.FC<EditPitchWPopoverProps> = ({
  pitch,
  company,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isPitchEditOpen,
    onOpen: onPitchEditOpen,
    onClose: onPitchEditClose,
  } = useDisclosure()

  return (
    <>
      <FriendlyModal
        isOpen={isPitchEditOpen}
        onClose={onPitchEditClose}
        noButton
      >
        <PitchEdit onSuccess={onPitchEditClose} />
      </FriendlyModal>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <IconButton
            backgroundColor="fontDarkGray"
            fontSize="4xl"
            // @ts-ignore
            icon="menuDots"
            aria-label="pitch-menu"
            cursor="pointer"
          />
        </PopoverTrigger>
        <PopoverContent zIndex={4} w="190px">
          <PopoverArrow />

          <PopoverBody w="100%" h="100%">
            <Flex direction="column" h="100%" justify="center">
              <ChakraLink onClick={onPitchEditOpen}>
                <HText fontSize="sm" mb={2}>
                  Edit pitch
                </HText>
              </ChakraLink>
              <ChakraLink
                onClick={() => {
                  // const fields = ['title', 'id', 'createdBy', 'members']
                  // const opts = { fields }
                  try {
                    const csv = parse(flatten(pitch))
                    console.log(csv)
                    download(csv, "text.csv", "data:text/csv;charset=utf-8")
                  } catch (err) {
                    console.error(err)
                  }
                }}
              >
                <HText fontSize="sm" mb={2}>
                  Download Pitch as CSV
                </HText>
              </ChakraLink>
              <ChakraLink onClick={onOpen}>
                <HText fontSize="sm" color="deleteRed" mb={2}>
                  Archive pitch
                </HText>
              </ChakraLink>
            </Flex>
          </PopoverBody>
        </PopoverContent>
        {pitch?.id && (
          <DeletePitchModal
            pitchId={pitch.id}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </Popover>
    </>
  )
}

export default PitchDisplay
