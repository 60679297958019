import React from 'react'
import {
  Text,
  BoxProps,
  HeadingProps,
  Heading,
  Textarea,
  InputProps,
  Tab,
  Input,
} from '@chakra-ui/core'
import styled from '@emotion/styled'

export const HText: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <Text color="fontDarkGray" fontSize="md" height="auto" {...rest}>
      {children}
    </Text>
  )
}

export const HHeading: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontSize="32px" color="fontBlack" fontWeight="bold" {...rest}>
      {children}
    </Heading>
  )
}

export const HTextArea: React.FC<InputProps<HTMLTextAreaElement>> = ({
  children,
  ...rest
}) => {
  return (
    <Textarea
      color="fontDarkGray"
      backgroundColor="rgba(9, 19, 33, 0.0638304)"
      {...rest}
    >
      {children}
    </Textarea>
  )
}

// fixed colors here
export const StyledTab = styled(Tab)`
  /* tab / default */
  font-weight: 500;
  font-size: 14px;
  color: #4f4f4f;
  padding-bottom: 16px;
  padding-left: 4px;
  padding-right: 4px;
  border: unset;

  /* tab / active */
  &[aria-selected='true'] {
    font-weight: bold;
    border: unset;
    border-bottom: 2px solid #333333;
    color: #333333;
  }
`

export const HInput: React.FC<InputProps> = ({ children, ...rest }) => {
  return (
    <Input
      width="100%"
      variant="filled"
      color="fontDarkGray"
      backgroundColor="rgba(9, 19, 33, 0.0638304)"
      placeholder=""
      {...rest}
    >
      {children}
    </Input>
  )
}
