import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Divider,
} from "@chakra-ui/core"
import React, { useState, useEffect } from "react"
import { ActivityMenuItem } from "../Components/ActivityCard"
import { isMobile } from "../Components/isMobile"

import { useActivities } from "../Context/activities-context"
import { useAuth } from "../Context/auth-context"
import * as R from "ramda"

import { IActivity } from "../types"
import { firebaseApp } from "../firebase"

import { DashboardContainer } from "./Dashboard/DashboardWrapper"
import InfiniteScroll from "react-infinite-scroll-component"

export const ActivityView: React.FC = () => {
  const {
    activities,
    allActivities,
    followingActivities,
    achievementActivities,
    getMoreActivities,
    getMoreAllActivities,
  } = useActivities()

  const { userProfile, updateUserProfile } = useAuth()
  // const [unreadActivities, setUnreadActivities] = useState(0)
  const isMobileDevice = isMobile()

  enum SortFilterActivities {
    MyActivity = "My Activity",
    Achievements = "Achievements",
    AllActivity = "All Activity",
    Following = "Following",
  }

  const [activityFilter, setActivityFilter] = useState<SortFilterActivities>(
    SortFilterActivities.MyActivity
  )

  const [displayedActivities, setDisplayedActivities] = useState<IActivity[]>(
    []
  )
  console.log(activities)

  useEffect(() => {
    if (
      activities &&
      achievementActivities &&
      followingActivities &&
      allActivities
    ) {
      if (activityFilter === SortFilterActivities.MyActivity) {
        setDisplayedActivities(activities)
      }
      if (activityFilter === SortFilterActivities.Achievements) {
        setDisplayedActivities(achievementActivities)
      }
      if (activityFilter === SortFilterActivities.Following) {
        setDisplayedActivities(followingActivities)
      }
      if (activityFilter === SortFilterActivities.AllActivity) {
        setDisplayedActivities(allActivities)
      }
    }
  }, [activityFilter, activities])

  useEffect(() => {
    console.log({ activities })
    if (activities && userProfile) {
      const activityIds: string[] = []

      activities.map(({ id }) => {
        if (id) {
          activityIds.push(id)
        }
      })

      const existingReadIds = userProfile?.readActivities || []

      const readActivitiesList = R.uniq([...existingReadIds, ...activityIds])

      // if (userProfile !== undefined && activities) {
      //   if (userProfile.readActivities !== undefined) {
      //     setUnreadActivities(
      //       activities.filter(
      //         //@ts-ignore
      //         ({ id }) => userProfile.readActivities.indexOf(id) === -1
      //       ).length
      //     )
      //   } else {
      //     setUnreadActivities(activities.length)
      //   }
      // }

      updateUserProfile({
        readActivities: readActivitiesList,
      })
    }
  }, [activities, userProfile])

  return (
    <DashboardContainer>
      <Flex>
        <Text fontSize="16px" marginRight="4px" marginTop="8px">
          Sort
        </Text>
        <Menu>
          <Box minWidth="124px" mb={4}>
            <MenuButton
              as={Button}
              color="black"
              _hover={{ bg: "gray.400" }}
              //@ts-ignore
              rightIcon="chevron-down"
            >
              {activityFilter}
            </MenuButton>
          </Box>
          <MenuList placement="bottom-start">
            <MenuItem
              onClick={() => {
                setActivityFilter(SortFilterActivities.MyActivity)
              }}
            >
              My Activity
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActivityFilter(SortFilterActivities.Achievements)
              }}
            >
              Achievements
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActivityFilter(SortFilterActivities.Following)
              }}
            >
              Following
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActivityFilter(SortFilterActivities.AllActivity)
              }}
            >
              All Activity
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex justify="center" w="100%">
        <Box
          w="100%"
          maxW="900px"
          overflow="auto"
          id="activityScroll"
          height="calc(100vh - 220px)"
        >
          <InfiniteScroll
            dataLength={displayedActivities?.length ?? 0} //This is important field to render the next data
            next={() => getMoreActivities()}
            hasMore={false}
            scrollableTarget="activityScroll"
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {displayedActivities?.map((activity, i) =>
              isMobileDevice ? (
                <>
                  <ActivityMenuItem key={activity.id} activity={activity} />
                  <Divider />
                </>
              ) : (
                <Box
                  flexGrow={1}
                  key={i}
                  w="100%"
                  shadow="md"
                  mb="16px"
                  borderWidth="1px"
                  flex="1"
                  pr="6px"
                  rounded="8px"
                  h="100%"
                  backgroundColor={"backgroundWhite"}
                >
                  <ActivityMenuItem key={activity.id} activity={activity} />
                </Box>
              )
            )}
          </InfiniteScroll>
        </Box>
      </Flex>
    </DashboardContainer>
  )
}
