import React, { useState, SetStateAction, Dispatch } from "react"

import { createCtx } from "./createCtx"

interface CompanyContextProps {
  companyUrl: string
  setCompanyUrl: Dispatch<SetStateAction<string>>
}

const [useCompany, CompanyContextProvider] = createCtx<CompanyContextProps>()

const CompanyProvider: React.FC = ({ children }) => {
  const [companyUrl, setCompanyUrl] = useState<string>("")

  return (
    <CompanyContextProvider
      value={{
        companyUrl,
        setCompanyUrl,
      }}
    >
      {children}
    </CompanyContextProvider>
  )
}

export { useCompany, CompanyProvider }
