import React, { useState } from 'react'
import {
  Box,
  SimpleGrid,
  Flex,
  Image,
  Link,
  Button,
  Stack,
  Badge,
  Avatar,
  Tag,
  Icon,
  PseudoBox,
  Text,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionHeader,
  useDisclosure,
  AvatarGroup,
} from '@chakra-ui/core'
import * as R from 'ramda'
import { format, isAfter, isWithinInterval, addDays } from 'date-fns'

import {
  HText,
  HHeading,
  HTextArea,
} from '../../Components/StyledTextComponents'
import { FillButton, OutlineButton } from '../../Components/Buttons'
import { useAuth } from '../../Context/auth-context'
import theme from '../../theme'
import { useWorkspaces } from '../../Context/workspace-context'
import { usePitches } from '../../Context/pitches-context'
import { isMobile } from '../../Components/isMobile'
import { InvitePeopleModal } from './Modals/InviteModal'
import { RemoveCollaboratorModal } from './Modals/RemoveCollaborator'
import { FriendlyModal } from '../../Components/FriendlyModal'

import { IPitch, ISkillTag, InviteStatuses, RequestStatuses } from '../../types'
//@ts-ignore
import gradient from 'random-gradient'

function capitalizeFirstLetter(string?: string) {
  if (string === undefined) {
    return
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const PeopleTabCard = ({ currentPitch, userId }: any) => {
  const isMobileDevice = isMobile()
  const [removeSelfModal, setRemoveSelfModal] = useState(false)
  const [isRemoveCollabOpen, setIsRemoveCollabOpen] = useState(false)
  const { users } = useWorkspaces()
  const { taskLists } = usePitches()
  const { userProfile } = useAuth()
  const { demoteChampion } = usePitches()

  const isChampion = currentPitch.champions?.find(
    (champion: string) => champion === userId
  )
  const user = users?.find(({ uid }: any) => uid === userId)

  const isMe = userId === userProfile?.uid

  if (!user || !users) {
    return <></>
  }

  const matchedTags: ISkillTag[] = []
  const unMatchedTags: ISkillTag[] = []

  //@ts-ignore
  user?.tags?.map((userTag) => {
    if (
      currentPitch?.skillTags.filter(
        ({ tag }: any): any => tag.id === userTag.id
      ).length > 0
    ) {
      matchedTags.push(userTag)
    } else {
      unMatchedTags.push(userTag)
    }
  })

  //@ts-ignore
  const allTasks: any[] = taskLists
    ? taskLists?.reduce(
        //@ts-ignore
        (acc, { tasks }) => [...acc, ...tasks],
        []
      )
    : []
  const userTasks: any[] = allTasks
    ? allTasks?.filter(
        //@ts-ignore
        ({ member }) => member.indexOf(user.uid) !== -1
      )
    : []

  const canRemoveFromPitch = isMe

  return (
    <>
      <FriendlyModal
        isOpen={removeSelfModal}
        noButton
        noTop
        onClose={() => {
          setRemoveSelfModal(false)
        }}
      >
        <Flex p="16px" direction="column" align="center">
          <HText>Remove yourself from the pitch</HText>
          <Flex w="180px" justify="space-between" mt="32px">
            <OutlineButton
              onClick={() => {
                setRemoveSelfModal(false)
              }}
            >
              Cancel
            </OutlineButton>
            <FillButton
              backgroundColor="deleteRed"
              onClick={() => {
                setRemoveSelfModal(false)
              }}
            >
              Remove
            </FillButton>
          </Flex>
        </Flex>
      </FriendlyModal>
      <RemoveCollaboratorModal
        currentPitch={currentPitch}
        isOpen={isRemoveCollabOpen}
        onClose={() => setIsRemoveCollabOpen(false)}
        collaborator={userId}
      />
      <AccordionItem defaultIsOpen={false} border="none" mb="6px">
        <AccordionHeader
          shadow="md"
          backgroundColor="backgroundWhite"
          borderWidth="1px"
          d="flex"
          justifyContent="space-between"
          flex="1"
          w="100%"
          flexDirection="column"
          rounded="lg"
          p={isMobileDevice ? '8px' : '16px'}
          minHeight="96px"
          textAlign="left"
        >
          <Flex w="100%">
            <Flex>
              <AvatarGroup size="md" max={2} mr="8px">
                <Avatar
                  key={`${user.firstName}${user.lastName}`}
                  h={isMobileDevice ? '48px' : '64px'}
                  w={isMobileDevice ? '48px' : '64px'}
                  name={user.firstName + ' ' + user.lastName}
                  background={gradient(user.lastName)}
                  src={user.photo}
                />
              </AvatarGroup>
              <Flex direction="column" mx="16px" w="216px">
                <Flex>
                  <Text color="fontBlack" mr="4px">
                    {capitalizeFirstLetter(user?.firstName)}
                  </Text>
                  <Text color="fontBlack">
                    {capitalizeFirstLetter(user?.lastName)}
                  </Text>
                </Flex>
                <Text color="fontDarkGray" fontSize="14px">
                  {user.position}
                </Text>
                {isChampion ? (
                  <Badge
                    variant="solid"
                    backgroundColor={theme.colors.championBadge}
                    w="78px"
                  >
                    champion
                  </Badge>
                ) : (
                  <Badge
                    variant="solid"
                    backgroundColor={theme.colors.collaboratorBadge}
                    w="108px"
                  >
                    collaborator
                  </Badge>
                )}
              </Flex>
            </Flex>

            <Flex direction="column" mx="8px" minWidth="96px" w="100%">
              {matchedTags.length > 0 && (
                <Text color="fontBlack" as="b" fontSize="sm">
                  Matched
                </Text>
              )}
              <Flex flexWrap="wrap">
                {matchedTags.map((skill, i) => (
                  <Tag
                    mb={2}
                    mr={2}
                    p={2}
                    px={4}
                    fontSize="sm"
                    key={i}
                    textAlign="start"
                    color="fontDarkgray"
                    variant={'outline'}
                  >
                    {skill.text}
                  </Tag>
                ))}
              </Flex>
            </Flex>
            <Flex direction="column" mx="8px">
              <Flex>
                <Text color="fontBlack" as="b" fontSize="sm">
                  Tasks
                </Text>
                <AccordionIcon />
              </Flex>
              <Flex justify="center" textAlign="center">
                <Text
                  background="#FF7C80"
                  px="8px"
                  py="8px"
                  w="42px"
                  borderRadius="100%"
                  color="fontBlack"
                >
                  {userTasks.length}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex w="100%" mt="8px">
            {isChampion && canRemoveFromPitch ? (
              <Link
                fontSize="sm"
                color="textLink"
                mr="8px"
                onClick={() => userId && demoteChampion(userId, currentPitch)}
              >
                Change to Collaborator
              </Link>
            ) : (
              <></>
            )}
            {canRemoveFromPitch ? (
              <>
                <Link
                  fontSize="sm"
                  color="textLink"
                  onClick={() => setRemoveSelfModal(true)}
                >
                  Withdraw From Pitch
                </Link>
              </>
            ) : isChampion ? (
              <Link
                fontSize="sm"
                color="textLink"
                onClick={() => setIsRemoveCollabOpen(true)}
              >
                Remove From Pitch
              </Link>
            ) : (
              <></>
            )}
          </Flex>
        </AccordionHeader>
        <AccordionPanel
          pb={4}
          background="#f0f0f0"
          mt="2px"
          borderBottomRightRadius="8px"
          borderBottomLeftRadius="8px"
        >
          {userTasks.map((task, i) => {
            const matchedTags: ISkillTag[] = []
            const unMatchedTags: ISkillTag[] = []
            //@ts-ignore
            user?.tags?.map((userTag) => {
              if (
                currentPitch?.skillTags.filter(
                  ({ tag }: any): any =>
                    tag.id.toLowerCase() === userTag.id.toLowerCase()
                ).length > 0
              ) {
                matchedTags.push(userTag)
              }
            })
            return (
              <Flex
                key={task.id}
                background="white"
                shadow="md"
                mt="12px"
                p="8px"
                borderRadius="4px"
              >
                <Flex align="center" w="24px" justify="center">
                  {!task.isCompleted ? (
                    <Box data-testid={`mark-completed-${task.title}`}>
                      <Icon name="status" />
                    </Box>
                  ) : (
                    <Box data-testid={`mark-uncompleted-${task.title}`}>
                      <Icon name="complete" />
                    </Box>
                  )}
                </Flex>
                <Flex ml="8px" mr="8px" flexWrap="wrap" align="center">
                  <HText as="b" my="4px" mr="16px">
                    {`${task.title} `}
                  </HText>
                  <HText fontSize="xs" mr="16px">
                    {`Due ${format(task.dueDate, 'EEE d MMMM, yyyy')}`}
                  </HText>

                  <Flex align="center" flexWrap="wrap" mt="2px">
                    {matchedTags.map((skill, i) => (
                      <Tag
                        mb={2}
                        mr={2}
                        p={2}
                        px={4}
                        fontSize="sm"
                        key={i}
                        textAlign="start"
                        color="fontDarkgray"
                        variant={'outline'}
                      >
                        {skill.text}
                      </Tag>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            )
          })}
        </AccordionPanel>
      </AccordionItem>
    </>
  )
}

const InvitedMember = ({ userId, currentPitch, joinType }: any) => {
  const { users } = useWorkspaces()
  const isMobileDevice = isMobile()
  const { taskLists } = usePitches()

  const user = users?.find((user) => user.uid === userId)
  const matchedTags: ISkillTag[] = []
  const unMatchedTags: ISkillTag[] = []
  //@ts-ignore
  user?.tags?.map((userTag) => {
    if (
      currentPitch?.skillTags.filter(
        ({ tag }: any): any => tag.id.toLowerCase() === userTag.id.toLowerCase()
      ).length > 0
    ) {
      matchedTags.push(userTag)
    } else {
      unMatchedTags.push(userTag)
    }
  })

  const isChampion = joinType === 'champion'
  //@ts-ignore
  const allTasks: any[] = taskLists
    ? taskLists?.reduce(
        //@ts-ignore
        (acc, { tasks }) => [...acc, ...tasks],
        []
      )
    : []
  const userTasks: any[] = allTasks
    ? allTasks?.filter(
        //@ts-ignore
        ({ member }) => member.indexOf(user?.uid) !== -1
      )
    : []

  if (!user) {
    return <></>
  }

  return (
    <AccordionItem defaultIsOpen={false} border="none" mb="6px">
      <AccordionHeader
        shadow="md"
        backgroundColor="backgroundWhite"
        borderWidth="1px"
        d="flex"
        justifyContent="space-between"
        flex="1"
        mb="8px"
        rounded="lg"
        p={isMobileDevice ? '8px' : '16px'}
        minHeight="96px"
        textAlign="left"
      >
        <Flex>
          <AvatarGroup size="md" max={2} mr="8px">
            <Avatar
              key={`${user.firstName}${user.lastName}`}
              h={isMobileDevice ? '48px' : '64px'}
              w={isMobileDevice ? '48px' : '64px'}
              name={user.firstName + ' ' + user.lastName}
              background={gradient(user.lastName)}
              src={user.photo}
            />
          </AvatarGroup>
          <Flex direction="column" mx="16px" w="216px">
            <Text color="fontBlack">
              {user.firstName + ' ' + user.lastName}
            </Text>
            <Text color="fontDarkGray" fontSize="14px">
              {user.position}
            </Text>
            {isChampion ? (
              <Badge
                variant="solid"
                backgroundColor={theme.colors.championBadge}
                w="78px"
              >
                champion
              </Badge>
            ) : (
              <Badge
                variant="solid"
                backgroundColor={theme.colors.collaboratorBadge}
                w="108px"
              >
                collaborator
              </Badge>
            )}
          </Flex>
        </Flex>

        <Flex direction="column" mx="8px" minWidth="96px" w="100%">
          {matchedTags.length > 0 && (
            <Text color="fontBlack" as="b" fontSize="sm">
              Seeking
            </Text>
          )}
          <Flex flexWrap="wrap">
            {matchedTags.map((skill, i) => (
              <Tag
                mb={2}
                mr={2}
                p={2}
                px={4}
                fontSize="sm"
                key={i}
                textAlign="start"
                color="fontDarkgray"
                variant={'outline'}
              >
                {skill.text}
              </Tag>
            ))}
          </Flex>
        </Flex>
        <Flex direction="column" mx="8px">
          <Flex>
            <Text color="fontBlack" as="b" fontSize="sm">
              Tasks
            </Text>
            <AccordionIcon />
          </Flex>
          <Flex justify="center" textAlign="center">
            <Text
              background="#FF7C80"
              px="8px"
              py="8px"
              w="42px"
              borderRadius="100%"
              color="fontBlack"
            >
              {userTasks.length}
            </Text>
          </Flex>
        </Flex>
      </AccordionHeader>
      <AccordionPanel
        pb={4}
        background="#f0f0f0"
        mt="-5px"
        borderBottomRightRadius="8px"
        borderBottomLeftRadius="8px"
      >
        {userTasks.map((task, i) => {
          const matchedTags: ISkillTag[] = []
          const unMatchedTags: ISkillTag[] = []
          //@ts-ignore
          user?.tags?.map((userTag) => {
            if (
              currentPitch?.skillTags.filter(
                ({ tag }: any): any =>
                  tag.id.toLowerCase() === userTag.id.toLowerCase()
              ).length > 0
            ) {
              matchedTags.push(userTag)
            }
          })
          return (
            <Flex
              key={task.id}
              background="white"
              shadow="md"
              p="8px"
              mt="12px"
              borderRadius="4px"
            >
              <Flex align="center" w="24px" justify="center">
                {!task.isCompleted ? (
                  <Box data-testid={`mark-completed-${task.title}`}>
                    <Icon name="status" />
                  </Box>
                ) : (
                  <Box data-testid={`mark-uncompleted-${task.title}`}>
                    <Icon name="complete" />
                  </Box>
                )}
              </Flex>
              <Flex ml="8px" mr="8px" flexWrap="wrap" align="center">
                <HText as="b" my="4px" mr="16px">
                  {`${task.title} `}
                </HText>
                <HText fontSize="xs" mr="16px">
                  {`Due ${format(task.dueDate, 'EEE d MMMM, yyyy')}`}
                </HText>

                <Flex align="center" flexWrap="wrap" mt="2px">
                  {matchedTags.map((skill, i) => (
                    <Tag
                      mb={2}
                      mr={2}
                      p={2}
                      px={4}
                      fontSize="sm"
                      key={i}
                      textAlign="start"
                      color="fontDarkgray"
                      variant={'outline'}
                    >
                      {skill.text}
                    </Tag>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          )
        })}
      </AccordionPanel>
    </AccordionItem>
  )
}

interface PitchPeopleProps {
  currentPitch?: IPitch
}
export const PitchPeople: React.FC<PitchPeopleProps> = ({ currentPitch }) => {
  const { user } = useAuth()

  if (!currentPitch) {
    return <></>
  }

  const {
    pitches: pitchesToBeFiltered,
    allMyInvites,
    requests,
    invites,
    updateInvite,
    updatePitch,
    updateRequest,
  } = usePitches()
  const { users } = useWorkspaces()
  const isMobileDevice = isMobile()
  const {
    onOpen: onInviteOpen,
    onClose: onInviteClose,
    isOpen: isInviteOpen,
  } = useDisclosure()

  const invitePitches =
    allMyInvites &&
    allMyInvites?.filter(
      ({ subject }) => currentPitch.id === subject?.reference
    )

  const allRequests = requests?.filter(
    ({ pitchReference }) => pitchReference === currentPitch?.id
  )

  const isChampion = currentPitch.champions?.find(
    (champion) => champion === user?.sub
  )
  const isManager = currentPitch?.manager?.uid === user?.sub

  return (
    <Flex maxWidth="95vw" justify="center" d="column" px="8px">
      {/* <HText>{`Current Approver is ${capitalizeFirstLetter(
        currentPitch?.manager?.firstName || ''
      )} ${capitalizeFirstLetter(
        currentPitch?.manager?.lastName || ''
      )}`}</HText>
      {isManager && <FillButton my="8px">Change Approver</FillButton>} */}
      <InvitePeopleModal noTop isOpen={isInviteOpen} onClose={onInviteClose} />

      <HHeading mb={3} fontSize="md">
        Experience Required
      </HHeading>
      <Flex flexWrap="wrap" mb={6}>
        {currentPitch?.skillTags.map((skill, i) =>
          skill.tag?.toggled ? (
            <Tag
              mb={2}
              mr={2}
              p={2}
              px={4}
              color="fontDarkgray"
              variant={'outline'}
              fontSize="sm"
              key={i}
              as={isChampion || isManager ? 'button' : 'div'}
              textAlign="start"
              cursor="pointer"
              onClick={() => {
                if (currentPitch && (isChampion || isManager)) {
                  const foundIndex = currentPitch.skillTags.findIndex(
                    (x) => x.tag.id == skill.tag.id
                  )
                  skill.tag.toggled = false
                  currentPitch.skillTags[foundIndex] = skill
                  currentPitch.id && updatePitch(currentPitch.id, currentPitch)
                }
              }}
            >
              {skill.tag.text}
            </Tag>
          ) : (
            <Tag
              mb={2}
              mr={2}
              p={2}
              px={4}
              fontSize="sm"
              key={i}
              as={isChampion || isManager ? 'button' : 'div'}
              textAlign="start"
              backgroundColor="#78E1D4"
              color="fontDarkgray"
              variant={'solid'}
              onClick={() => {
                if (currentPitch && (isChampion || isManager)) {
                  const foundIndex = currentPitch.skillTags.findIndex(
                    (x) => x.tag.id == skill.tag.id
                  )
                  skill.tag.toggled = true
                  currentPitch.skillTags[foundIndex] = skill
                  currentPitch.id && updatePitch(currentPitch.id, currentPitch)
                }
              }}
            >
              {skill.tag.text}
            </Tag>
          )
        )}
      </Flex>
      <Flex align="center">
        <HHeading mb={3} fontSize="md">
          Team
        </HHeading>
        <Flex
          as="button"
          cursor="pointer"
          align="center"
          onClick={onInviteOpen}
          mb={3}
          ml={2}
          w="100%"
        >
          <Icon name="addList" aria-label="addpeople" mr={1} />
          <HText
            color="textLink"
            fontSize="sm"
            fontWeight="semi-bold"
            data-testid="add-list"
          >
            Add Members
          </HText>
        </Flex>
      </Flex>
      <Accordion allowToggle maxWidth="95vw">
        {currentPitch?.members.map((userId) => {
          return (
            <PeopleTabCard
              key={userId}
              currentPitch={currentPitch}
              userId={userId}
            />
          )
        })}
      </Accordion>

      <Stack>
        {invites && (
          <HHeading mt={10} fontSize="md">
            Invited
          </HHeading>
        )}
        {invites &&
          R.uniqBy(({ to }) => to, invites).map((invite) =>
            invite.to ? (
              <InvitedMember
                joinType={invite?.subject?.type}
                userId={invite.to}
                currentPitch={currentPitch}
                task={invite?.subject?.task}
              />
            ) : (
              <Box
                shadow="md"
                backgroundColor="backgroundWhite"
                borderWidth="1px"
                flex="1"
                rounded="lg"
                p="16px"
              >
                <Flex
                  cursor="pointer"
                  padding="4px"
                  justify="space-between"
                  mr="2px"
                >
                  <Avatar
                    h={isMobileDevice ? '48px' : '64px'}
                    w={isMobileDevice ? '48px' : '64px'}
                    mr={2}
                    background={gradient(invite.toOutsideName)}
                  />

                  <Flex direction="column" w="100%">
                    <HText fontSize="sm" w="100%">
                      {invite.toOutsideName}
                    </HText>
                    <HText fontSize="xs">{invite.toOutsideEmail}</HText>
                    {invite?.subject?.type === 'champion' ? (
                      <Badge
                        variant="solid"
                        backgroundColor={theme.colors.championBadge}
                        w="78px"
                      >
                        champion
                      </Badge>
                    ) : (
                      <Badge
                        variant="solid"
                        backgroundColor={theme.colors.collaboratorBadge}
                        w="108px"
                      >
                        collaborator
                      </Badge>
                    )}
                  </Flex>
                </Flex>
              </Box>
            )
          )}
      </Stack>
      {isChampion && allRequests && allRequests?.length > 0 && (
        <HHeading mt={10} fontSize="md" mb="2">
          Requests to Join
        </HHeading>
      )}
      <Stack>
        {isChampion &&
          allRequests?.map((request) => {
            const requestor = users?.find(
              ({ uid }: any) => uid === request.userId
            )
            if (requestor) {
              return (
                <Flex
                  align="center"
                  mb={4}
                  key={request.userId}
                  shadow="md"
                  backgroundColor="backgroundWhite"
                  borderWidth="1px"
                  d="flex"
                  flex="1"
                  rounded="lg"
                  p={isMobileDevice ? '8px' : '16px'}
                  minHeight="96px"
                  textAlign="left"
                >
                  <Avatar
                    key={`${requestor.firstName}${requestor.lastName}`}
                    h={isMobileDevice ? '48px' : '64px'}
                    w={isMobileDevice ? '48px' : '64px'}
                    name={requestor.firstName + ' ' + requestor.lastName}
                    background={gradient(requestor.lastName)}
                    src={requestor.photo}
                  />
                  <Flex direction="column" mx="24px" w="216px">
                    <Text color="fontBlack">
                      {requestor.firstName + ' ' + requestor.lastName}
                    </Text>
                    <Text color="fontDarkGray" fontSize="14px">
                      {requestor.position}
                    </Text>
                    {request.type === 'champion' ? (
                      <Badge
                        variant="solid"
                        backgroundColor={theme.colors.championBadge}
                        w="78px"
                      >
                        champion
                      </Badge>
                    ) : (
                      <Badge
                        variant="solid"
                        backgroundColor={theme.colors.collaboratorBadge}
                        w="108px"
                      >
                        collaborator
                      </Badge>
                    )}
                  </Flex>
                  <Flex d="column">
                    <Link
                      onClick={() =>
                        request.id &&
                        updateRequest(request.id, {
                          status: RequestStatuses.approved,
                        })
                      }
                      color="textLink"
                      fontWeight="semi-bold"
                      mr={6}
                    >
                      Approve
                    </Link>
                    <Link
                      onClick={() =>
                        request.id &&
                        updateRequest(request.id, {
                          status: RequestStatuses.declined,
                        })
                      }
                      color="textLink"
                      fontWeight="semi-bold"
                    >
                      Decline
                    </Link>
                  </Flex>
                </Flex>
              )
            } else {
              return <></>
            }
          })}
      </Stack>
    </Flex>
  )
}
