import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Button,
  Box,
} from '@chakra-ui/core'
import { HHeading, HText } from './../../../Components/StyledTextComponents'
import { FillButton } from '../../../Components/Buttons'
import { usePitches } from '../../../Context/pitches-context'
import { IPitch, PitchStatus } from '../../../types'
import { DatePicker } from '../../../Components/DatePicker'
import { getTime } from 'date-fns'
import { useAuth } from '../../../Context/auth-context'

interface SubmitPitchModalProps {
  onClose: () => void
  isOpen: boolean
  pitch: IPitch
}
export const SubmitPitchModal: React.FC<SubmitPitchModalProps> = ({
  isOpen,
  onClose,
  pitch,
}) => {
  const { updatePitch } = usePitches()
  const { user } = useAuth()
  const [dueDate, setDueDate] = useState(new Date())
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={8}>
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10}>
          <HHeading mb="29px" fontSize="xl">
            Submit pitch?
          </HHeading>
          <HText fontSize="sm">
            {`Are you sure you want to submit the pitch '${pitch.title}'. This action
            cannot be undone.`}
          </HText>
          <FormLabel color="fontBlack" htmlFor="dueDate" mt={4}>
            Due Date for Pitch
          </FormLabel>
          <Box maxW="180px">
            <DatePicker
              testid="datepicker"
              startDate={dueDate}
              handleChange={(val: any) => setDueDate(val)}
            />
          </Box>
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          <Button rounded="full" variant="ghost" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <FillButton
            data-testid="submit-confirm"
            onClick={() => {
              pitch.id &&
                updatePitch(pitch.id, {
                  status: PitchStatus.pendingApproval,
                  submittedOn: new Date(),
                  submittedBy: user?.sub,
                  dueDate: getTime(dueDate),
                })
              onClose()
            }}
          >
            Submit
          </FillButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
