import React, { useState } from 'react'
import { Tag } from 'react-tag-input'
import { TagInput } from '../Components/TagInput'

const ComponentBox = ({ children }: any) => (
  <div style={{ marginTop: '32px', marginBottom: '32px' }}>
    <h3>{children}</h3>
  </div>
)

export const StyleGuide: React.FC = () => {
  const [tags, setTags] = useState<Tag[]>([])
  return (
    <div>
      <h1>Style Guide</h1>
      <div style={{ width: '360px' }}>
        <ComponentBox>Autosuggest</ComponentBox>
        {/* <Autosuggest options={languages} onSelect={console.log} /> */}
        <ComponentBox>TagInput</ComponentBox>
        <TagInput
          tags={tags}
          suggestions={[
            { id: 'apple', text: 'apple' },
            { id: 'someReallyLongThing', text: 'someReallyLongThing' },
            { id: 'someOtherThing', text: 'someOtherThing' },
          ]}
          onTagUpdate={(tags) => {
            // setTags(tags)
          }}
        />
      </div>
    </div>
  )
}

const languages = [
  {
    name: 'C',
    year: 1972,
  },
  {
    name: 'C#',
    year: 2000,
  },
  {
    name: 'C++',
    year: 1983,
  },
  {
    name: 'Clojure',
    year: 2007,
  },
  {
    name: 'Elm',
    year: 2012,
  },
  {
    name: 'Go',
    year: 2009,
  },
  {
    name: 'Haskell',
    year: 1990,
  },
  {
    name: 'Java',
    year: 1995,
  },
  {
    name: 'Javascript',
    year: 1995,
  },
  {
    name: 'Perl',
    year: 1987,
  },
  {
    name: 'PHP',
    year: 1995,
  },
  {
    name: 'Python',
    year: 1991,
  },
  {
    name: 'Ruby',
    year: 1995,
  },
  {
    name: 'Scala',
    year: 2003,
  },
]

export default StyleGuide
