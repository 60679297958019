import React, { useCallback } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  RadioGroup,
  Radio,
} from "@chakra-ui/core"
import { HHeading, HText } from "../../../Components/StyledTextComponents"
import { FillButton } from "../../../Components/Buttons"
import { useNavigate } from "react-router-dom"
import { urls } from "../../../App"
import { useCompany } from "../../../Context/company-context"
import { useWorkspaces } from "../../../Context/workspace-context"
import { useAuth } from "../../../Context/auth-context"

interface PitchIntroModalProps {
  onClose: () => void
  pitchIntro: number
  onClickNext: () => void
  onClickCreate: () => void
  isOpen: boolean
}
export const PitchIntroModal: React.FC<PitchIntroModalProps> = ({
  isOpen,
  pitchIntro,
  onClickCreate,
  onClickNext,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10} minHeight="460px">
          {pitchIntro === 1 && (
            <>
              <HText mb={8} color="teal">
                Step 1
              </HText>
              <HText mb={8} fontSize="xl" as="b">
                Create draft, find champions by tags
              </HText>
              <HText mt={8} mb={8}>
                Write an interesting description about your initative to attact
                fellow colleagues to help along the way.
              </HText>
              <HText mb={8}>
                Add skill tags which will notify colleagues who match those
                skills about your initiative.
              </HText>
              <HText mb={8}>
                If you have already selected the champions (accountable for the
                initative), invite them directly to join.
              </HText>
            </>
          )}
          {pitchIntro === 2 && (
            <>
              <HText mb={8}>Step 2</HText>
              <HText mb={8} fontSize="xl" as="b">
                Add tasks, find collaborators by tags
              </HText>
              <HText mt={8} mb={8}>
                {`Once the champions have been added, start brainstorming what's required by adding lists and tasks to the initiative. `}
              </HText>
              <HText mb={8}>
                Use skill tags to connect collaborators to tasks or invite them
                directly by reviewing skills in bios.
              </HText>
            </>
          )}
          {pitchIntro === 3 && (
            <>
              <HText mb={8}>Step 3</HText>
              <HText mb={8} fontSize="xl" as="b">
                Submit for approval, track to completion
              </HText>
              <HText mt={8} mb={8}>
                Once the draft initiative has been planned out, resourced and is
                ready to go live, submit to the approver to progress the pitch.
                Use the comments section for communication.
              </HText>
              <HText mb={8}>
                Once approved, track the through to completion.
              </HText>
              <HText mb={8}>
                You can update the tasks and team along the way as things change
                and evolve.
              </HText>
            </>
          )}
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          {pitchIntro === 3 ? (
            <FillButton
              onClick={() => {
                onClose()
                onClickCreate()
              }}
              data-testid="create"
            >
              Create
            </FillButton>
          ) : (
            <FillButton onClick={onClickNext} data-testid="next">
              Close
            </FillButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
