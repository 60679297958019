import React, { useState, useEffect } from "react"
import { Flex, Box, SimpleGrid, Stack } from "@chakra-ui/core"
import { DashboardContainer } from "./DashboardWrapper"
import { HHeading, HText } from "../../Components/StyledTextComponents"
import { usePitches } from "../../Context/pitches-context"
import { useAuth } from "../../Context/auth-context"

import { PitchCard } from "../../Components/PitchCard"
import { FillButton } from "../../Components/Buttons"
import { PitchStatus, InviteStatuses, IPitch } from "../../types"
import { PitchRequests } from "./PitchRequests"
import { PitchActionButton } from "../PitchDisplay/PitchDisplay"
import { Fade } from "react-awesome-reveal"

import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

export const Requests = ({ children, ...rest }: any) => {
  const [approvalRequests, setApprovalRequests] = useState<IPitch[]>([])
  const { userProfile } = useAuth()

  const {
    pitches: pitchesToBeFiltered,
    allMyInvites,
    requests,
    updateInvite,
  } = usePitches()
  console.log(allMyInvites)
  console.log(userProfile)

  const invitePitches =
    allMyInvites &&
    allMyInvites?.filter(({ subject }) =>
      pitchesToBeFiltered?.find((pitch) => pitch.id === subject?.reference)
    )
  console.log({ invitePitches })
  useEffect(() => {
    if (userProfile && pitchesToBeFiltered) {
      setApprovalRequests(
        pitchesToBeFiltered.filter(
          (pitch) =>
            pitch.status === PitchStatus.pendingApproval &&
            pitch.manager?.uid === userProfile?.uid
        )
      )
    }
  }, [pitchesToBeFiltered && pitchesToBeFiltered.length, userProfile])

  return (
    <Box {...rest}>
      {approvalRequests.length > 0 && (
        <>
          <HText as="b">Requests for Approval</HText>
          <SimpleGrid columns={[1, 1, 3]} spacingX={10} spacingY={10}>
            <Fade triggerOnce duration={500}>
              {approvalRequests.map((pitch) => (
                <PitchCard
                  data-testid={`pitch-${pitch.title}`}
                  pitch={pitch}
                  key={pitch.id}
                />
              ))}
            </Fade>
          </SimpleGrid>
        </>
      )}
      <PitchRequests />
      {invitePitches && invitePitches.length > 0 && (
        <>
          <SimpleGrid columns={[1, 1, 3]} spacingX={10} spacingY={10}>
            <Fade triggerOnce duration={500}>
              {invitePitches?.map((invite) => {
                const invitePitch = pitchesToBeFiltered?.find(
                  (pitch) => pitch.id === invite.subject?.reference
                )

                console.log(invitePitch)

                return invitePitch !== undefined ? (
                  <Box key={invitePitch.id}>
                    <PitchCard
                      isRequest
                      pitch={invitePitch}
                      data-testid={`pitch-${invitePitch.title}`}
                    />{" "}
                    <Flex mt="-48px" px="12px" justify={"space-between"}>
                      <PitchActionButton
                        onClick={() =>
                          invite.id &&
                          updateInvite(invite.id, {
                            status: InviteStatuses.accepted,
                          })
                        }
                      >
                        Join pitch as {invite.subject?.type}
                      </PitchActionButton>
                      <PitchActionButton
                        onClick={() =>
                          invite.id &&
                          updateInvite(invite.id, {
                            status: InviteStatuses.rejected,
                          })
                        }
                      >
                        Decline
                      </PitchActionButton>
                    </Flex>
                  </Box>
                ) : null
              })}
            </Fade>
          </SimpleGrid>
        </>
      )}
      {invitePitches &&
        invitePitches.length === 0 &&
        pitchesToBeFiltered &&
        approvalRequests.length === 0 && (
          <>
            <HText as="b">No Requests</HText>
          </>
        )}
    </Box>
  )
}
