import React from 'react'
import { Flex, Box } from '@chakra-ui/core'
import { ProfileDisplayMobile } from '../../../Views/Profile/ProfileDisplay'
import { FriendlyModal } from '../../../Components/FriendlyModal'
import { IUserProfile } from '../../../types'

export const PersonProfileModal: React.FC<{
  userProfile?: IUserProfile
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose, userProfile }) => {
  if (!userProfile) {
    return null
  }
  return (
    <FriendlyModal isOpen={isOpen} onClose={onClose} size="720px" noTop>
      <Flex justifyContent="center">
        <Box maxWidth="720px" w="100%">
          <ProfileDisplayMobile
            displayedUserProfile={userProfile}
            inviteDisabled
          />
        </Box>
      </Flex>
    </FriendlyModal>
  )
}
