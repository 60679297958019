export const SkillTagSuggestions = [
  { id: "Lead Generation", text: "Lead Generation" },
  {
    id: "Contract & Deal Negotiation",
    text: "Contract & Deal Negotiation",
  },
  { id: "Gas sales", text: "Gas sales" },
  { id: "Gas distrubution", text: "Gas distrubution" },
  { id: "Shipping", text: "Shipping" },
  {
    id: "Maintaining Profitable, Amicable Relationships",
    text: "Maintaining Profitable, Amicable Relationships",
  },
];

export const InterestTagSuggestions = [
  { id: "Marketing", text: "Marketing" },
  {
    id: "Painting",
    text: "Painting",
  },
  { id: "Writing", text: "Writing" },
];

export const StrengthTagSuggestions = [
  { id: "Competitive", text: "Competitive" },
  {
    id: "Patient",
    text: "Patient",
  },
  { id: "Quality", text: "Quality" },
];
