import React from "react"
import { FillButton } from "./Buttons"
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Flex,
  Text,
} from "@chakra-ui/core"
import GreenPea1 from "./Images/Green_Pea_1.png"
import { isMobile } from "./isMobile"

export const FriendlyModal = ({
  title,
  isOpen,
  onClose,
  children,
  noButton,
  noOverlay,
  noClose,
  noTop,
}: any) => {
  const isMobileDevice = isMobile()

  const ModalContentProps = isMobileDevice
    ? {
        height: "100vh",
        //@ts-ignore
        marginTop: 0,
        padding: 0,
        paddingTop: "16px",
        marginBottom: 0,
        zIndex: 99999999,
      }
    : {
        borderRadius: "8px",
        zIndex: 99999999,
      }

  const ModalHeaderProps = isMobileDevice
    ? { height: "0px" }
    : {
        height: "0px",
        borderTopRightRadius: "8px",
        borderTopLeftRadius: "8px",
      }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isMobileDevice ? "full" : "2xl"}
      >
        {noOverlay ? <></> : <ModalOverlay />}

        <ModalContent
          //@ts-ignore
          style={ModalContentProps}
        >
          {noTop ? (
            <ModalHeader
              {...ModalHeaderProps}
              height="32px"
              pb="16px"
              backgroundColor="white"
            >
              <></>
            </ModalHeader>
          ) : (
            <ModalHeader {...ModalHeaderProps}>
              <Flex
                justify="center"
                w="100%"
                mt={isMobileDevice ? "-28px" : "-58px"}
              >
                <Image
                  borderRadius="100%"
                  border="3px solid white"
                  size={"86px"}
                  src={GreenPea1}
                  alt="Green Pea 1"
                />
              </Flex>
            </ModalHeader>
          )}
          {noClose ? (
            <></>
          ) : onClose && noTop ? (
            <ModalCloseButton color="fontDarkGray" />
          ) : (
            <ModalCloseButton color="fontDarkGray" />
          )}
          <ModalBody mt={noTop ? "0px" : "48px"} overflowY="auto">
            {children}
          </ModalBody>

          {!noButton && (
            <ModalFooter>
              <Flex justify="center">
                <FillButton variantColor="blue" onClick={onClose}>
                  Ok
                </FillButton>
              </Flex>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
