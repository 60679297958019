import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Button,
  ModalFooter,
} from '@chakra-ui/core'
import {
  HHeading,
  HText,
  StyledTab,
} from '../../../Components/StyledTextComponents'
import {
  InvitePeople,
  EmailInvitePeople,
} from '../../../Components/InvitePeople'
import { OutlineButton } from '../../../Components/Buttons'
import { ITaskList } from '../../../types'

import { firebaseApp } from '../../../firebase'

interface DeleteTaskListModalProps {
  taskList: ITaskList | null
  onClose: () => void
  isOpen: boolean
}
export const DeleteTaskListModal: React.FC<DeleteTaskListModalProps> = ({
  isOpen,
  onClose,
  taskList,
}) => {
  const [isDeleting, setIsDeleting] = useState(false)
  if (taskList === null) {
    return <></>
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={10} px={10}>
          <HHeading mb={2}>Delete Task List</HHeading>
          <HText
            mb={8}
          >{`Are you sure you want to delete ${taskList.name}?`}</HText>
        </ModalBody>
        <ModalFooter>
          <Button
            variantColor="red"
            isLoading={isDeleting}
            loadingText="Deleting"
            onClick={() => {
              setIsDeleting(true)
              firebaseApp
                .firestore()
                .collection('taskLists')
                .doc(taskList.id)
                .delete()
                .then(function () {
                  setIsDeleting(false)
                  onClose()
                  console.log('Document successfully deleted!')
                })
                .catch(function (error) {
                  console.error('Error removing document: ', error)
                })
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
