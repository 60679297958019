import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/core'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { Autosuggest } from '../../../Components/Autosuggest'
import { FillButton } from '../../../Components/Buttons'
import { Dropzone } from '../../../Components/Dropzone'
import { HInput } from '../../../Components/StyledTextComponents'
import { TagInput } from '../../../Components/TagInput'
import { FriendlyModal } from '../../../Components/FriendlyModal'

import { useAuth } from '../../../Context/auth-context'
import { useWorkspaces } from '../../../Context/workspace-context'
import { requireAtleastOne, required } from '../../../formUtils'
import { IAttachment, IUserProfile, TagTypes } from '../../../types'
import {
  SkillTagSuggestions,
  InterestTagSuggestions,
  StrengthTagSuggestions,
} from '../../../fixtures'

interface ProfileModalProps {
  onClose: () => void
  isOpen: boolean
}
export const EditProfileModal: React.FC<ProfileModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [files, setFiles] = useState<IAttachment[]>([])
  const { userProfile, updateUserProfile } = useAuth()
  const { users } = useWorkspaces()
  const [managerChanged, setManagerChanged] = useState<boolean>(false)

  return (
    <FriendlyModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          firstName: userProfile?.firstName || '',
          lastName: userProfile?.lastName || '',
          position: userProfile?.position || '',
          manager: userProfile?.manager,
        }}
        onSubmit={(values, _actions) => {
          let newProfile: Partial<IUserProfile> = {
            firstName: values.firstName,
            lastName: values.lastName,
            position: values.position,
            manager: !managerChanged ? userProfile?.manager : values.manager,
          }
          if (files[0]) {
            newProfile = { ...newProfile, photo: files[0].url }
          }
          updateUserProfile(newProfile)
          onClose()
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
            <ModalBody paddingTop={10} paddingX={10}>
              <Field name="firstName" validate={required}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.firstName && form.touched.firstName}
                    marginBottom="32px"
                  >
                    <FormLabel
                      fontWeight="bold"
                      color="fontBlack"
                      htmlFor="firstName"
                    >
                      First Name
                    </FormLabel>
                    <HInput
                      mt="8px"
                      data-testid="first-name"
                      {...field}
                      id="firstName"
                    />
                    <FormErrorMessage
                      style={{ height: '17px', marginBottom: '-25px' }}
                    >
                      {form.errors.firstName}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="lastName" validate={required}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.lastName && form.touched.lastName}
                    marginBottom="32px"
                  >
                    <FormLabel
                      fontWeight="bold"
                      color="fontBlack"
                      htmlFor="lastName"
                    >
                      Last Name
                    </FormLabel>
                    <HInput
                      mt="8px"
                      data-testid="last-name"
                      {...field}
                      id="lastName"
                    />
                    <FormErrorMessage
                      style={{ height: '17px', marginBottom: '-25px' }}
                    >
                      {form.errors.lastName}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="position" validate={required}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.position && form.touched.position}
                    marginBottom="32px"
                  >
                    <FormLabel
                      fontWeight="bold"
                      color="fontBlack"
                      htmlFor="position"
                    >
                      Job title
                    </FormLabel>
                    <HInput
                      mt="8px"
                      data-testid="position"
                      {...field}
                      id="position"
                    />
                    <FormErrorMessage
                      style={{ height: '17px', marginBottom: '-25px' }}
                    >
                      {form.errors.position}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="manager" validate={required}>
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.manager && form.touched.manager}
                    marginBottom="32px"
                  >
                    <FormLabel
                      fontWeight="bold"
                      color="fontBlack"
                      htmlFor="manager"
                    >
                      Manager
                    </FormLabel>
                    <FormHelperText color="fontDarkGray">
                      In most of the cases, your manager is the pitch approver,
                      but you can update it if required.
                    </FormHelperText>
                    <Autosuggest
                      options={users || []}
                      initialValue={
                        props.values.manager?.firstName +
                        ' ' +
                        props.values.manager?.lastName
                      }
                      onSelect={(val) => {
                        setManagerChanged(true)
                        props.setFieldValue('manager', val)
                      }}
                    />
                    <FormErrorMessage
                      style={{ height: '17px', marginBottom: '-25px' }}
                    >
                      {form.errors.manager}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <FormLabel
                fontWeight="bold"
                color="fontBlack"
                htmlFor="profilePhoto"
              >
                Profile photo (optional)
              </FormLabel>
              <Dropzone
                files={files}
                setFiles={setFiles}
                path={`images/user-profile/${userProfile?.uid}.jpg`}
              />
            </ModalBody>
            <ModalFooter paddingBottom={10} paddingX={10}>
              <Button
                type="button"
                rounded="full"
                variant="ghost"
                mr={2}
                onClick={onClose}
              >
                Cancel
              </Button>
              <FillButton type="submit"> Save</FillButton>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </FriendlyModal>
  )
}

export const EditBioModal: React.FC<ProfileModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { userProfile, updateUserProfile } = useAuth()
  const { tags, updateTags } = useWorkspaces()
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Formik
          initialValues={{
            bio: userProfile?.bio || '',
            professionalExperience:
              userProfile?.tags?.filter((tag) => tag.type === TagTypes.skill) ||
              [],
            interests:
              userProfile?.tags?.filter(
                (tag) => tag.type === TagTypes.interest
              ) || [],
            strengths:
              userProfile?.tags?.filter(
                (tag) => tag.type === TagTypes.strength
              ) || [],
          }}
          onSubmit={(values, _actions) => {
            const profileTags = [
              ...values.professionalExperience.map((tag) => {
                return {
                  ...tag,
                  type: TagTypes.skill,
                }
              }),
              ...values.interests.map((tag) => {
                return {
                  ...tag,
                  type: TagTypes.interest,
                }
              }),
              ...values.strengths.map((tag) => {
                return {
                  ...tag,
                  type: TagTypes.strength,
                }
              }),
            ]

            const newProfile: Partial<IUserProfile> = {
              bio: values.bio,
              tags: profileTags,
            }

            updateUserProfile(newProfile)
            const updatedTags: any = profileTags.map((d) => ({
              ...d,
            }))
            updateTags(updatedTags)

            onClose()
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
              <ModalBody paddingTop={10} paddingX={10}>
                <Field name="bio">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.bio && form.touched.bio}
                      marginBottom="32px"
                    >
                      <FormLabel
                        fontWeight="bold"
                        color="fontBlack"
                        htmlFor="bio"
                      >
                        Bio Statement (optional)
                      </FormLabel>
                      <FormHelperText color="fontDarkGray">
                        In 2 - 3 sentences, tell us about yourself or what you
                        are passionate about.
                      </FormHelperText>
                      <Textarea
                        color="fontDarkGray"
                        minH="144px"
                        mt="8px"
                        width="100%"
                        variant="filled"
                        data-testid="bio"
                        {...field}
                        style={{
                          fontFamily: 'inherit',
                          fontSize: 'inherit',
                        }}
                        id="bio"
                        backgroundColor="rgba(9, 19, 33, 0.0638304)"
                        placeholder=""
                        fontSize="16px"
                        resize="vertical"
                      />
                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.bio}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field
                  name="professionalExperience"
                  validate={requireAtleastOne}
                >
                  {({ field, form }: any) => (
                    <FormControl
                      marginBottom="32px"
                      isInvalid={
                        form.errors.professionalExperience &&
                        form.touched.professionalExperience
                      }
                    >
                      <FormLabel
                        fontWeight="bold"
                        color="fontBlack"
                        htmlFor="professionalExperience"
                      >
                        Professional Experience
                      </FormLabel>
                      <FormHelperText color="fontDarkGray" mb={2}>
                        Throughout your career what areas have you gained
                        experience in? Some examples might be Sales, Analytics,
                        IT
                      </FormHelperText>
                      <TagInput
                        testId="professionalExperience"
                        tags={field.value}
                        suggestions={[...SkillTagSuggestions, ...tags]}
                        onTagUpdate={(tags) => {
                          props.setFieldValue('professionalExperience', tags)
                        }}
                      />
                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.professionalExperience}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="interests">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      marginBottom="32px"
                    >
                      <FormLabel
                        fontWeight="bold"
                        color="fontBlack"
                        htmlFor="interests"
                      >
                        Interests
                      </FormLabel>
                      <FormHelperText color="fontDarkGray" mb={2}>
                        What are some things you enjoy outside of work? Some
                        examples might be Photography, Reading, Camping
                      </FormHelperText>
                      <TagInput
                        testId="interests"
                        tags={field.value}
                        suggestions={[...InterestTagSuggestions, ...tags]}
                        onTagUpdate={(tags) => {
                          props.setFieldValue('interests', tags)
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="strengths" validate={required}>
                  {({ field, form }: any) => (
                    <FormControl marginBottom="32px">
                      <FormLabel
                        fontWeight="bold"
                        color="fontBlack"
                        htmlFor="strengths"
                      >
                        Strengths
                      </FormLabel>
                      <FormHelperText color="fontDarkGray" mb={2}>
                        Thinking about your career professional experience and
                        interests outside of work, list your strengths. Some
                        examples might be Creativity, Research, Planning
                      </FormHelperText>
                      <TagInput
                        testId="strengths"
                        tags={field.value}
                        suggestions={[...StrengthTagSuggestions, ...tags]}
                        onTagUpdate={(tags) => {
                          props.setFieldValue('strengths', tags)
                        }}
                      />
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter paddingBottom={10} paddingX={10}>
                <Button
                  type="button"
                  rounded="full"
                  variant="ghost"
                  mr={2}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <FillButton type="submit">Save</FillButton>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
