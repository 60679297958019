import React, { Dispatch, SetStateAction } from "react";
import { IAttachment } from "../../../types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/core";
import { HHeading, HInput } from "../../../Components/StyledTextComponents";
import { Formik, Form } from "formik";

interface AddLinkModalProps {
  isOpen: boolean;
  onClose: () => void;
  links: IAttachment[];
  setLinks: Dispatch<SetStateAction<IAttachment[]>>;
}
export const AddLinkModal: React.FC<AddLinkModalProps> = ({
  isOpen,
  onClose,
  links,
  setLinks,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HHeading>Add link</HHeading>
        </ModalHeader>
        <ModalCloseButton type="button" />
        <Formik
          initialValues={{ url: "", title: "" }}
          onSubmit={(values) => {
            const newLinks = [...links];
            newLinks.push({
              url: values.url,
              name: values.title,
              isLink: true,
            });
            setLinks(newLinks);
            onClose();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ModalBody>
                <HHeading fontSize="md" mb={2}>
                  URL
                </HHeading>

                <HInput
                  data-testid="link-url"
                  value={values.url}
                  onChange={(e: any) => {
                    setFieldValue("url", e.target.value);
                  }}
                />
                <HHeading fontSize="md" mt={8} mb={2}>
                  Title (optional)
                </HHeading>
                <HInput
                  data-testid="link-title"
                  value={values.title}
                  onChange={(e: any) => {
                    setFieldValue("title", e.target.value);
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  rounded="full"
                  backgroundColor="white"
                  mr={2}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="link-modal-submit"
                  type="submit"
                  rounded="full"
                  backgroundColor="darkBlue"
                  color="white"
                >
                  Add
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
