import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import config from './auth_config.json'
import { Auth0Provider } from './auth0-spa'

import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

Sentry.init({
  dsn:
    'https://80469ada11e84e8190695e90d52e7053@o468388.ingest.sentry.io/5496131',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
// A function that routes the user to the right place
// after login

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = (appState: { targetUrl: any }) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}
const subdomain = window.location.pathname.split('/')[1]

// const redirectUri =
//   process.env.NODE_ENV === "production"
//     ? `https://app.huminate.com/${subdomain}/`
//     : `http://localhost:3002/${subdomain}/`;

const redirectUri = `${window.location.origin}/${subdomain}`

const Wrapper = () => (
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={redirectUri}
    audience={config.audience}
    onRedirectCallback={onRedirectCallback}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
)

ReactDOM.render(<Wrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register()
