/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useContext } from "react"
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js"
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore"
import { firebaseApp } from "./firebase"
import { Auth0User } from "./types"

const firebaseAuth = firebaseApp.auth()
const functions = firebaseApp.functions()
let backendUrl = "https://us-central1-huminate-e8f9b.cloudfunctions.net"
const db = getFirestore(firebaseApp)

if (process.env.NODE_ENV === "development") {
  functions.useEmulator("localhost", 5001)
  backendUrl = "http://localhost:5001/huminate-e8f9b/us-central1"
}

const checkCompanyAccess = ({ company }: { company: string }) => {
  const checkCompanyAccessCall = functions.httpsCallable("checkCompanyAccess")

  return checkCompanyAccessCall({ company })
}

interface Auth0ContextProps {
  isAuthenticated: boolean
  user: Auth0User | undefined
  loading: boolean
  popupOpen: boolean
  loginWithPopup: () => Promise<void>
  handleRedirectCallback: () => Promise<void>
  logout: () => void
  noCompanyAccess: boolean
}

const defaultProps = {
  isAuthenticated: false,
  user: undefined,
  loading: true,
  popupOpen: false,
  noCompanyAccess: false,
  loginWithPopup: async () => {
    console.warn("auth provider not ready")
  },
  handleRedirectCallback: async () => {
    console.warn("auth provider not ready")
  },
  logout: () => console.log("not yet initialised"),
}

export const Auth0Context = React.createContext<Auth0ContextProps>(defaultProps)
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<Auth0User>()
  const [auth0Client, setAuth0] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [noCompanyAccess, setNoCompanyAccess] = useState(false)

  useEffect(() => {
    const companySlug = window.location.pathname.split("/")[1]
    const company = companySlug ? companySlug : "demo"

    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...initOptions,
      })

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }
      let token
      try {
        token = await auth0FromHook.getTokenSilently({
          timeoutInSeconds: 20,
        })
      } catch (e) {
        auth0FromHook.loginWithRedirect()

        setLoading(false)
        return
      }
      const isAuthenticated = await auth0FromHook
        .isAuthenticated()
        .catch((e) => console.log(e))
      // @ts-ignore
      setIsAuthenticated(isAuthenticated)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const user = await auth0FromHook.getUser().catch((e) => console.log(e))

      if (isAuthenticated) {
        const user = (await auth0FromHook
          .getUser()
          .catch((e) => console.log(e))) as Auth0User

        try {
          const response = await fetch(`${backendUrl}/auth`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          const { firebaseToken } = await response.json()

          await firebaseAuth.signInWithCustomToken(firebaseToken)

          if (user) {
            console.log("CHECK COMPANY ACCESS")
            try {
              const q = query(
                collection(db, "users"),
                where("company", "==", company)
              )

              const querySnapshot = await getDocs(q)
              if (querySnapshot.docs) {
                setUser(user)
                setLoading(false)
              }
            } catch (e) {
              console.log({ e })
              checkCompanyAccess({ company })
                .then(({ data }) => {
                  setUser(user)
                  if (data === true) {
                    setLoading(false)
                  } else {
                    console.log("FALSE")
                    setNoCompanyAccess(true)
                    setLoading(false)
                  }
                })
                .catch((e) => {
                  console.error(e)
                  setLoading(false)
                })
            }
          } else {
            setLoading(false)
          }
        } catch (e) {
          setLoading(false)
        }
      } else {
        console.log("SET LOADING FALSE")
        setLoading(false)
      }
    }
    if (
      window.location.pathname.indexOf("sign-in-workspace") === -1 &&
      window.location.pathname.indexOf("find-workspace") === -1
    ) {
      try {
        initAuth0()
      } catch (e) {
        console.log({ e })
      }
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [isAuthenticated])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      // @ts-ignore
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    // @ts-ignore
    const user = await auth0Client.getUser()
    setUser(user)
    // @ts-ignore
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    // @ts-ignore
    await auth0Client.handleRedirectCallback()
    // @ts-ignore
    const user = await auth0Client.getUser()
    setLoading(false)
    // @ts-ignore
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        noCompanyAccess,

        //I have disabled all of these because they don't seen to be used anywhere
        // // @ts-ignore
        // getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        // // @ts-ignore
        // loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        // // @ts-ignore
        // getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        // // @ts-ignore
        // getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        // @ts-ignore
        logout: (p) => {
          // firebaseAuth.signOut()
          const auth0Client = new Auth0Client(initOptions)

          auth0Client.logout({
            federated: true,
            returnTo: `${window.location.origin}/sign-in-workspace`,
          })
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
