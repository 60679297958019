export function required(value: string) {
  let error
  if (!value) {
    error = 'This is required'
  }
  return error
}
export function requireAtleastOne(value: any[]) {
  let error
  if (value.length === 0) {
    error = 'At least one required'
  }
  return error
}

export function requireAtleastThree(value: any[]) {
  let error
  if (value.length < 3) {
    error = 'At least three required'
  }
  return error
}
