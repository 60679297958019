import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/core'
import { HHeading, HText } from './../../../Components/StyledTextComponents'
import { usePitches } from '../../../Context/pitches-context'
import { IPitch } from '../../../types'

interface RemoveCollaboratorModalProps {
  collaborator: string
  currentPitch: IPitch
  onClose: () => void
  isOpen: boolean
  firstName?: string
}
export const RemoveCollaboratorModal: React.FC<RemoveCollaboratorModalProps> = ({
  isOpen,
  onClose,
  collaborator,
  currentPitch,
  firstName,
}) => {
  const { removeCollaborator } = usePitches()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="8px">
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10}>
          <HHeading mb={7} fontSize="xl">
            Remove pitch member?
          </HHeading>
          <HText fontSize="sm">
            {`Are you sure you want to remove ${firstName} from ${currentPitch?.title}. This action
            cannot be undone.`}
          </HText>
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          <Button rounded="full" variant="ghost" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="white"
            backgroundColor="deleteRed"
            rounded="full"
            onClick={() => {
              removeCollaborator(collaborator, currentPitch)
              onClose()
            }}
          >
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
