import {
  Avatar,
  Badge,
  Box,
  BoxProps,
  Flex,
  Icon,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Spinner,
  Stack,
  Tag,
  useDisclosure,
  ButtonGroup,
  Text,
  Button,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/core'
import { format, isAfter, isWithinInterval, addDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
//@ts-ignore
import gradient from 'random-gradient'
import * as R from 'ramda'
import { FillButton, OutlineButton } from '../../Components/Buttons'
import { HHeading, HText } from '../../Components/StyledTextComponents'
import { useAuth } from '../../Context/auth-context'
import { usePitches } from '../../Context/pitches-context'
import { useWorkspaces } from '../../Context/workspace-context'
import {
  IPitch,
  PitchMemberTypes,
  PitchStatus,
  RequestStatuses,
  InviteStatuses,
} from '../../types'
import { CompletePitchModal } from './Modals/CompletePitchModal'
import { InvitePeopleModal } from './Modals/InviteModal'
import { RemoveCollaboratorModal } from './Modals/RemoveCollaborator'
import { SubmitPitchModal } from './Modals/SubmitPitchModal'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { firebaseApp } from '../../firebase'

interface PitchSidebarProps {
  currentPitch?: IPitch
}
export const PitchSidebar: React.FC<PitchSidebarProps> = ({ currentPitch }) => {
  const { user, userProfile, updateUserProfile } = useAuth()

  const {
    userCanSee,
    updatePitch,
    createRequest,
    requests,
    updateRequest,
    deleteRequest,
    invites,
    updateInvite,
    taskLists,
    currentPitchManager,
    likePitch,
  } = usePitches()
  const { users } = useWorkspaces()
  const {
    submitPitch,
    completePitch,
    followPitch,
    inviteCollaborators,
    approvePitch,
  } = userCanSee(currentPitch, user?.sub)

  const isChampion = !!currentPitch?.champions?.find(
    (champion) => champion === user?.sub
  )

  const isManager = currentPitchManager?.uid === user?.sub

  const [liked, setLiked] = useState<boolean>(false)

  useEffect(() => {
    const unsubscribe = firebaseApp
      .firestore()
      .collection('pitchLikes')
      .doc(`${userProfile?.uid}:${currentPitch?.id}`)
      .onSnapshot(
        (docSnapshot) => {
          if (docSnapshot.exists) {
            setLiked(true)
          } else {
            setLiked(false)
          }
        },
        (err) => console.log(err)
      )
    return unsubscribe
  }, [])

  const usersFollowing =
    users !== undefined &&
    currentPitch !== undefined &&
    currentPitch.id !== undefined
      ? users.filter(
          ({ followedPitches }) =>
            //@ts-ignore
            followedPitches && followedPitches?.indexOf(currentPitch.id) !== -1
        )
      : []

  const hasInvite = invites?.filter(({ to }) => to === user?.sub)

  const {
    onOpen: onInviteOpen,
    onClose: onInviteClose,
    isOpen: isInviteOpen,
  } = useDisclosure()

  const {
    onOpen: onCompleteOpen,
    onClose: onCompleteClose,
    isOpen: isCompleteOpen,
  } = useDisclosure()
  const {
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
    isOpen: isSubmitOpen,
  } = useDisclosure()
  if (!currentPitch) {
    return <Spinner></Spinner>
  }

  const allTasks =
    taskLists && R.flatten(taskLists.map((taskList) => taskList.tasks))
  const submittedByUser = users?.find(
    ({ uid }) => uid === currentPitch?.submittedBy
  )
  const approvedByUser = users?.find(
    ({ uid }) => uid === currentPitch?.approvedBy
  )

  // calculate the completion percentage based on completion of tasks and their difficulty
  let totalTaskValue = 0
  let currentTaskCompletionValue = 0
  let totalTasksCount = 0
  allTasks?.forEach((task) => {
    totalTasksCount += 1
    totalTaskValue += task.difficulty
    if (task.isCompleted) {
      currentTaskCompletionValue += task.difficulty
    }
  })
  console.log({ currentPitch })
  const completion = totalTaskValue
    ? currentTaskCompletionValue / totalTaskValue
    : 0

  return (
    <Box>
      <Box
        minW="305px"
        flexShrink={0}
        backgroundColor="backgroundWhite"
        paddingY={4}
        shadow="md"
        borderRadius="8px"
        paddingX={6}
      >
        {/* sidebar */}
        <Flex direction={['column', 'column', 'row', 'column']}>
          <Flex mb="8px" justify="flex-end">
            {!(currentPitch.status === PitchStatus.complete) &&
              !currentPitch.members.find((member) => member === user?.sub) &&
              (!requests?.find((req) => req.userId === user?.sub) ? (
                <Popover>
                  <PopoverTrigger>
                    <Box>
                      <OutlineButton mr={4}>Request to join</OutlineButton>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent zIndex={4} w="120px">
                    <PopoverBody w="100%" h="100%" padding="8px">
                      <Stack align="left">
                        <Link
                          data-testid="collaborator"
                          fontSize="sm"
                          color="fontDarkGray"
                          onClick={() =>
                            currentPitch &&
                            currentPitch.id &&
                            userProfile &&
                            userProfile.firstName &&
                            userProfile.lastName &&
                            userProfile.uid &&
                            createRequest({
                              name:
                                userProfile.firstName +
                                ' ' +
                                userProfile.lastName,
                              userId: userProfile.uid,
                              pitchReference: currentPitch.id,
                              status: RequestStatuses.pending,
                              type: PitchMemberTypes.collaborator,
                            })
                          }
                        >
                          As collaborator
                        </Link>
                        <Link
                          color="fontDarkGray"
                          fontSize="sm"
                          onClick={() =>
                            currentPitch &&
                            currentPitch.id &&
                            userProfile &&
                            userProfile.firstName &&
                            userProfile.lastName &&
                            userProfile.uid &&
                            createRequest({
                              name:
                                userProfile.firstName +
                                ' ' +
                                userProfile.lastName,
                              userId: userProfile.uid,
                              pitchReference: currentPitch.id,
                              status: RequestStatuses.pending,
                              type: PitchMemberTypes.champion,
                            })
                          }
                        >
                          As champion
                        </Link>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : (
                <FillButton
                  onClick={() => {
                    const request = requests?.find(
                      (req) => req.userId === user?.sub
                    )
                    userProfile && request && deleteRequest(request)
                  }}
                  mr={4}
                >
                  Withdraw Request
                </FillButton>
              ))}
            {currentPitch.members.find(
              (member) => member === userProfile?.uid
            ) ? (
              <Button rounded="full" onClick={() => ''}>
                Joined
              </Button>
            ) : followPitch &&
              userProfile?.followedPitches?.find(
                (followedPitch) => followedPitch === currentPitch.id
              ) ? (
              <Button
                rounded="full"
                onClick={() =>
                  currentPitch.id &&
                  updateUserProfile({
                    followedPitches: userProfile.followedPitches?.filter(
                      (pitch) => pitch !== currentPitch.id
                    ),
                  })
                }
              >
                Following
              </Button>
            ) : (
              <OutlineButton
                onClick={() =>
                  // Follow the pitch
                  currentPitch.id &&
                  updateUserProfile({
                    followedPitches: userProfile?.followedPitches
                      ? [...userProfile.followedPitches, currentPitch.id]
                      : [currentPitch.id],
                  })
                }
              >
                Follow
              </OutlineButton>
            )}
          </Flex>
          <Box minW="305px" maxW={[null, null, null, '305px']}>
            {hasInvite && hasInvite.length > 0 && (
              <Box mb={'16px'}>
                <HHeading fontSize="md" mb={3}>
                  Accept Invite to Pitch
                </HHeading>
                <Flex>
                  <FillButton
                    data-testid="accept-invite"
                    onClick={() =>
                      hasInvite[0].id &&
                      updateInvite(hasInvite[0].id, {
                        status: InviteStatuses.accepted,
                      })
                    }
                  >
                    Join pitch as {hasInvite[0].subject?.type}
                  </FillButton>
                  <FillButton
                    onClick={() =>
                      hasInvite[0].id &&
                      updateInvite(hasInvite[0].id, {
                        status: InviteStatuses.rejected,
                      })
                    }
                  >
                    Decline
                  </FillButton>
                </Flex>
              </Box>
            )}
            {currentPitch?.status === PitchStatus.draft &&
            currentPitch?.submissionDate ? (
              <>
                <HHeading
                  mb={3}
                  fontSize="md"
                  //@ts-ignore
                >
                  Submission Due Date
                </HHeading>
                <HText mb={6} fontSize="sm" color={'fontDarkGray'}>
                  {format(
                    new Date(currentPitch?.submissionDate),
                    'EEE d MMMM, yyyy'
                  )}
                </HText>
              </>
            ) : currentPitch?.status === PitchStatus.overdueSubmission &&
              currentPitch?.submissionDate ? (
              <>
                <HHeading
                  mb={3}
                  fontSize="md"
                  //@ts-ignore
                  color={'warning'}
                >
                  Submission Due Date
                </HHeading>
                <HText mb={6} fontSize="sm" color={'warning'}>
                  {currentPitch?.submissionDate &&
                    format(currentPitch?.submissionDate, 'EEE d MMMM, yyyy')}
                </HText>
              </>
            ) : currentPitch?.status === PitchStatus.overdue ? (
              <>
                <HHeading
                  mb={3}
                  fontSize="md"
                  //@ts-ignore
                  color={'warning'}
                >
                  Due Date
                </HHeading>
                <HText mb={6} fontSize="sm" color={'warning'}>
                  {currentPitch?.dueDate &&
                    format(currentPitch?.dueDate, 'EEE d MMMM, yyyy')}
                </HText>
              </>
            ) : (
              <>
                <HHeading
                  mb={3}
                  fontSize="md"
                  //@ts-ignore
                >
                  Due Date
                </HHeading>
                <HText mb={6} fontSize="sm" color={'fontDarkGray'}>
                  {currentPitch?.dueDate &&
                    format(currentPitch?.dueDate, 'EEE d MMMM, yyyy')}
                </HText>
              </>
            )}
            <HHeading fontSize="md" mb={3}>
              Status
            </HHeading>
            <Flex direction="column" justify="space-between" w="100%">
              <HText mb={6} fontSize="md">
                {currentPitch?.status === PitchStatus.overdueSubmission
                  ? PitchStatus.draft
                  : currentPitch?.status === PitchStatus.overdue
                  ? PitchStatus.inProgress
                  : currentPitch?.status}
              </HText>
              <Box mb="16px">
                <Flex justify="flex-end">
                  {submitPitch && (
                    <FillButton
                      data-testid="submit-pitch"
                      onClick={onSubmitOpen}
                    >
                      Submit for Approval
                    </FillButton>
                  )}
                  {approvePitch && (
                    <Flex>
                      <FillButton
                        data-testid="approve-pitch"
                        mr={2}
                        onClick={() =>
                          currentPitch.id &&
                          updatePitch(currentPitch.id, {
                            status: PitchStatus.inProgress,
                            // Saves as firestore timestamp
                            //@ts-ignore
                            approvedOn: new Date(),
                            approvedBy: user?.sub,
                          })
                        }
                      >
                        Approve
                      </FillButton>
                      <FillButton
                        onClick={() =>
                          currentPitch.id &&
                          updatePitch(currentPitch.id, {
                            status: PitchStatus.declined,
                          })
                        }
                      >
                        Decline
                      </FillButton>
                    </Flex>
                  )}
                </Flex>
              </Box>
            </Flex>

            <HHeading mb={3} fontSize="md">
              Progress
            </HHeading>
            <Flex justify="space-between" mb={2}>
              <HText>{`${Math.floor((completion ?? 0) * 100)}%`}</HText>
            </Flex>

            <PitchProgressBar percentComplete={completion ?? 0} mb={6} />
            <Flex w="100%" justify="flex-end">
              {completePitch &&
                (isManager || isChampion) &&
                allTasks &&
                allTasks.filter(({ isCompleted }) => !isCompleted).length ===
                  0 && (
                  <FillButton
                    onClick={onCompleteOpen}
                    data-testid="complete-pitch"
                  >
                    Complete
                  </FillButton>
                )}
            </Flex>
            <HHeading mb={3} fontSize="md">
              Skill tags
            </HHeading>
            <Flex flexWrap="wrap">
              {currentPitch?.skillTags.map((skill, i) =>
                skill.tag?.toggled ? (
                  <Tag
                    mb={2}
                    mr={2}
                    p={2}
                    px={4}
                    color="fontDarkgray"
                    variant={'outline'}
                    fontSize="sm"
                    key={i}
                    as={isChampion ? 'button' : 'div'}
                    textAlign="start"
                    onClick={() => {
                      if (currentPitch && isChampion) {
                        const foundIndex = currentPitch.skillTags.findIndex(
                          (x) => x.tag.id == skill.tag.id
                        )
                        skill.tag.toggled = false
                        currentPitch.skillTags[foundIndex] = skill
                        currentPitch.id &&
                          updatePitch(currentPitch.id, currentPitch)
                      }
                    }}
                  >
                    {skill.tag.text}
                  </Tag>
                ) : (
                  <Tag
                    mb={2}
                    mr={2}
                    p={2}
                    px={4}
                    fontSize="sm"
                    key={i}
                    as={isChampion ? 'button' : 'div'}
                    textAlign="start"
                    backgroundColor="#78E1D4"
                    color="fontDarkgray"
                    variant={'solid'}
                    onClick={() => {
                      if (currentPitch && isChampion) {
                        const foundIndex = currentPitch.skillTags.findIndex(
                          (x) => x.tag.id == skill.tag.id
                        )
                        skill.tag.toggled = true
                        currentPitch.skillTags[foundIndex] = skill
                        currentPitch.id &&
                          updatePitch(currentPitch.id, currentPitch)
                      }
                    }}
                  >
                    {skill.tag.text}
                  </Tag>
                )
              )}
            </Flex>
          </Box>
          <Box
            ml={[0, 0, 6, 0]}
            mt={[10, 10, 0, 10]}
            minW="305px"
            maxW={[null, null, null, '305px']}
            flexShrink={0}
            backgroundColor="backgroundWhite"
            paddingBottom={4}
            // paddingX={6}
          >
            <HHeading mt={10} fontSize="md">
              Collaborators
            </HHeading>
            {/* invite model trigger */}
            {inviteCollaborators && (
              <Flex cursor="pointer" mt={6} onClick={onInviteOpen}>
                <Icon name="addList" aria-label="reply" mr={2} />
                <HText color="textLink" fontSize="sm" fontWeight="semi-bold">
                  Invite colleagues to join
                </HText>
              </Flex>
            )}
            <Stack mt={2}>
              {currentPitch?.members.map((member) => (
                <CollaboratorCard
                  member={member}
                  key={member}
                  currentPitch={currentPitch}
                />
              ))}
              {currentPitchManager && (
                <PseudoBox role="group" _hover={{ backgroundColor: '#F2F2F2' }}>
                  <Flex cursor="pointer" padding="4px" justify="space-between">
                    <Avatar
                      h={10}
                      w={10}
                      src={currentPitchManager?.photo}
                      mr={2}
                      background={
                        currentPitchManager?.lastName
                          ? gradient(currentPitchManager?.lastName)
                          : gradient('test')
                      }
                    />

                    <Flex direction="column" w="100%">
                      <HText fontSize="sm" w="100%">
                        {currentPitchManager?.firstName +
                          ' ' +
                          currentPitchManager?.lastName}
                      </HText>
                    </Flex>
                    <Box>
                      <Badge variant="solid" variantColor="blue" ml={2}>
                        Approver
                      </Badge>
                    </Box>
                  </Flex>
                </PseudoBox>
              )}
              {invites && (
                <HHeading mt={10} fontSize="md">
                  Invited
                </HHeading>
              )}
              {invites &&
                R.uniqBy(({ to }) => to, invites).map((invite) =>
                  invite.to ? (
                    <CollaboratorCard
                      member={invite.to || ''}
                      key={invite.to}
                      currentPitch={currentPitch}
                    />
                  ) : (
                    <PseudoBox
                      role="group"
                      _hover={{ backgroundColor: '#F2F2F2' }}
                    >
                      <Flex
                        cursor="pointer"
                        padding="4px"
                        justify="space-between"
                      >
                        <Avatar
                          h={10}
                          w={10}
                          mr={2}
                          background={gradient(invite.toOutsideName)}
                        />

                        <Flex direction="column" w="100%">
                          <HText fontSize="sm" w="100%">
                            {invite.toOutsideName}
                          </HText>
                          <HText fontSize="xs">{invite.toOutsideEmail}</HText>
                        </Flex>
                      </Flex>
                    </PseudoBox>
                  )
                )}
            </Stack>
          </Box>
        </Flex>
        <Flex justify="space-between">
          <HText mt={2}>{`${usersFollowing.length} ${
            usersFollowing.length === 1 ? 'Follower' : 'Followers'
          }`}</HText>
          <Flex
            align="center"
            onClick={() => {
              setLiked(!liked)
              currentPitch.id && likePitch(currentPitch.id)
            }}
            mt={2}
            cursor="pointer"
          >
            {/* Show the correct emoji depending on if its liked or not */}

            <HText mr="8px">{`${currentPitch.likesCount || 0} ${
              currentPitch.likesCount === 1 ? 'Like' : 'Likes'
            }`}</HText>
            {liked ? (
              <HText color={'tagRedText'} mr={2} fontSize="xl">
                <FaHeart style={{ height: '18px' }} />
              </HText>
            ) : (
              <HText color={'#bababa'} mr={2} fontSize="xl">
                <FaRegHeart style={{ height: '18px' }} />
              </HText>
            )}
          </Flex>
          <Flex>
            <Text> </Text>
          </Flex>
        </Flex>
        {currentPitch?.submittedOn && submittedByUser ? (
          <Text>
            {`Submitted on ${format(
              new Date(currentPitch?.submittedOn),
              'dd/MM/YY'
            )} by ${submittedByUser?.firstName} ${submittedByUser?.lastName}`}
          </Text>
        ) : (
          <></>
        )}
        {currentPitch?.approvedOn && approvedByUser ? (
          <Text>
            {`Approved on ${format(
              new Date(currentPitch?.approvedOn?.seconds * 1000),
              'dd/MM/YY'
            )} by ${approvedByUser?.firstName} ${approvedByUser?.lastName}`}
          </Text>
        ) : (
          <></>
        )}
      </Box>{' '}
      <InvitePeopleModal isOpen={isInviteOpen} onClose={onInviteClose} />
      <CompletePitchModal
        userId={user?.sub || ''}
        isOpen={isCompleteOpen}
        onClose={onCompleteClose}
        updatePitch={updatePitch}
        pitch={currentPitch}
      />
      <SubmitPitchModal
        isOpen={isSubmitOpen}
        onClose={onSubmitClose}
        pitch={currentPitch}
      />
    </Box>
  )
}

interface PitchProgressBarProps extends BoxProps {
  percentComplete: number //0.5 etc
}
export const PitchProgressBar: React.FC<PitchProgressBarProps> = ({
  percentComplete,
  ...rest
}) => {
  return (
    <Box position="relative" h={3} rounded="full" overflow="hidden" {...rest}>
      <Box w="100%" backgroundColor="#DDDDDD" h="100%" pos="absolute"></Box>
      <Box
        w={`${(percentComplete / 1) * 100}%`}
        backgroundColor="#78E1D4"
        h="100%"
        position="absolute"
      ></Box>
    </Box>
  )
}

interface CollaboratorCardProps extends BoxProps {
  member: string
  currentPitch: IPitch
}
export const CollaboratorCard: React.FC<CollaboratorCardProps> = ({
  member,
  currentPitch,
}) => {
  // @TODO reference the user here
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { users } = useWorkspaces()
  const { user } = useAuth()
  const { addChampion, demoteChampion, removeCollaborator } = usePitches()
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const collaborator = users?.find((user) => user.uid === member)
  const {
    isOpen: isPopoverOpen,
    onClose: OnPopoverClose,
    onOpen: onPopoverOpen,
  } = useDisclosure()

  // if you are a champion you can edit collaborators
  const OpenPopoverIfAllowed = () => {
    currentPitch.champions?.find((champ) => champ === user?.sub) &&
      onPopoverOpen()
  }

  const isChampion = !!currentPitch.champions?.find(
    (champion) => champion === member
  )
  const isOnlyChampion = isChampion && currentPitch.champions?.length === 1

  return (
    <Popover
      isOpen={isPopoverOpen}
      onClose={OnPopoverClose}
      onOpen={OpenPopoverIfAllowed}
    >
      <PopoverTrigger>
        <PseudoBox role="group" _hover={{ backgroundColor: '#F2F2F2' }}>
          <Flex cursor="pointer" padding="4px" justify="space-between">
            <Avatar
              h={10}
              w={10}
              src={collaborator?.photo}
              mr={2}
              background={
                collaborator?.lastName
                  ? gradient(collaborator?.lastName)
                  : gradient('test')
              }
            />

            <Flex direction="column" w="100%">
              <HText fontSize="sm" w="100%">
                {collaborator?.firstName + ' ' + collaborator?.lastName}
              </HText>
              <HText fontSize="xs">{collaborator?.position}</HText>
            </Flex>
            <Flex direction="column" alignItems="flex-end">
              {isChampion ? (
                <Badge variant="solid" variantColor="green" ml={2}>
                  champion
                </Badge>
              ) : null}
              {collaborator?.uid === user?.sub &&
              !(isChampion && isOnlyChampion) ? (
                isWithdrawing ? (
                  <Text fontSize="sm" color="textLink">
                    Withdrawing...
                  </Text>
                ) : (
                  <Link
                    fontSize="sm"
                    color="textLink"
                    onClick={() => {
                      if (collaborator?.uid) {
                        removeCollaborator(collaborator?.uid, currentPitch)
                        setIsWithdrawing(true)
                      }
                    }}
                  >
                    Withdraw
                  </Link>
                )
              ) : null}
            </Flex>

            {collaborator?.uid && (
              <RemoveCollaboratorModal
                onClose={onClose}
                isOpen={isOpen}
                collaborator={collaborator?.uid}
                firstName={collaborator?.firstName}
                currentPitch={currentPitch}
              />
            )}
          </Flex>
        </PseudoBox>
      </PopoverTrigger>
      <PopoverContent zIndex={4} w="195px">
        <PopoverBody>
          <Flex direction="column" h="100%" justify="center">
            {isChampion ? (
              <Link
                isDisabled={isOnlyChampion}
                fontSize="sm"
                color="fontDarkGray"
                mb={2}
                onClick={() =>
                  collaborator?.uid &&
                  demoteChampion(collaborator?.uid, currentPitch)
                }
              >
                Change to collaborator
              </Link>
            ) : (
              <Link
                isDisabled={isChampion}
                fontSize="sm"
                color="fontDarkGray"
                mb={2}
                onClick={() =>
                  collaborator?.uid &&
                  addChampion(collaborator?.uid, currentPitch)
                }
              >
                Change to champion
              </Link>
            )}

            <Link
              color="deleteRed"
              fontSize="sm"
              onClick={onOpen}
              isDisabled={isOnlyChampion}
            >
              Remove from the pitch
            </Link>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
