import {
  Avatar,
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Tag,
  Text,
  AvatarGroup,
  useDisclosure,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/core"
import React, { useState, useEffect } from "react"
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom"
import { PitchCard } from "../../Components/PitchCard"
import {
  HHeading,
  HText,
  StyledTab,
} from "../../Components/StyledTextComponents"
import { useAuth } from "../../Context/auth-context"
import { FillButton } from "../../Components/Buttons"
import { usePitches } from "../../Context/pitches-context"
import { useWorkspaces } from "../../Context/workspace-context"
import { useCompany } from "../../Context/company-context"

import { useUserProfile } from "../../firestore-hooks"
import {
  IPitch,
  PitchStatus,
  TagTypes,
  IInvite,
  IUserProfile,
  InviteStatuses,
  PitchMemberTypes,
} from "../../types"
import { DashboardContainer } from "../Dashboard/DashboardWrapper"
import { EditAchievementModal } from "./modals/EditAchievementModal"
import { EditBioModal, EditProfileModal } from "./modals/EditDetailsModal"
import { FriendlyModal } from "../../Components/FriendlyModal"
import { isMobile } from "../../Components/isMobile"
import { urls } from "../../App"

//@ts-ignore
import gradient from "random-gradient"

export const PitchInviteCard = ({ pitch, selectedUser, tags }: any) => {
  const { users } = useWorkspaces()
  const { user } = useAuth()
  const { allInvites, createInvite, currentPitch } = usePitches()
  const [isSendingInvite, setIsSendingInvite] = useState(false)
  const { companyUrl } = useCompany()
  const navigate = useNavigate()

  const collaborators = pitch.members?.map((userId: string) =>
    users?.find((user: any) => user.uid === userId)
  )

  const invite = allInvites
    ?.filter(({ from }) => from === user?.sub)
    ?.filter(({ to }) => selectedUser.uid === to)
    ?.filter(({ subject }) => subject?.reference === pitch?.id)
  console.log({ tags })
  return (
    <Flex
      shadow="md"
      borderWidth="1px"
      flex="1"
      mb="8px"
      borderRadius="8px"
      backgroundColor="backgroundWhite"
    >
      <Image
        borderTopLeftRadius={"8px"}
        borderBottomLeftRadius={"8px"}
        minW="156px"
        maxW="156px"
        background={gradient(pitch.id)}
        objectFit="cover"
        src={pitch.coverPicture?.url}
      />
      <Flex direction="column" width="100%" justify="space-between">
        <Link
          fontSize="md"
          color="fontDarkGray"
          p="12px"
          onClick={() =>
            //@ts-ignore
            navigate(`/${urls(companyUrl).viewPitch}/${pitch.id}`)
          }
        >
          {pitch.title}
        </Link>
        <Flex wrap="wrap">
          {
            //@ts-ignore
            tags &&
              tags.map((tag: any) => (
                <Tag
                  mr={2}
                  mb={2}
                  fontSize="sm"
                  key={"1"}
                  backgroundColor="turquoise"
                  color="fontBlack"
                  cursor="pointer"
                  onClick={() => {
                    //@ts-ignore
                    // setSearchFilter(tag.text)
                  }}
                >
                  {
                    //@ts-ignore
                    tag.text
                  }
                </Tag>
              ))
          }
        </Flex>
        <AvatarGroup px="16px" size="sm" max={2} mr="8px">
          {collaborators.map(({ firstName, lastName, photo }: any) => (
            <Avatar
              key={`${firstName}${lastName}`}
              h="40px"
              w="40px"
              name={firstName + " " + lastName}
              background={gradient(lastName)}
              src={photo}
            />
          ))}
        </AvatarGroup>

        {invite && invite.length > 0 ? (
          <Text p="16px">{`Invited as ${invite[0]?.subject?.type}`}</Text>
        ) : isSendingInvite ? (
          <Text p="16px">Sending invite...</Text>
        ) : (
          <Flex
            p="16px"
            justify="space-between"
            flexWrap="wrap"
            maxWidth="320px"
          >
            <Link
              color="textLink"
              fontSize="sm"
              onClick={() => {
                setIsSendingInvite(true)
                const newInvite: IInvite = {
                  status: InviteStatuses.pending,
                  from: user?.sub,
                  to: selectedUser.uid,
                  subject: {
                    reference: pitch?.id,
                    type: PitchMemberTypes.collaborator,
                  },
                }

                pitch && createInvite(newInvite)
              }}
            >
              Invite As Collaborator
            </Link>
            <Link
              color="textLink"
              fontSize="sm"
              onClick={() => {
                setIsSendingInvite(true)
                const newInvite: IInvite = {
                  status: InviteStatuses.pending,
                  from: user?.sub,
                  to: selectedUser.uid,
                  subject: {
                    reference: pitch.id,
                    type: PitchMemberTypes.champion,
                  },
                }

                pitch && createInvite(newInvite)
              }}
            >
              Invite As Champion
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export const ProfileDisplayMobile = ({
  displayedUserProfile,
  inviteDisabled,
}: {
  displayedUserProfile: IUserProfile
  inviteDisabled?: boolean
}) => {
  const { userProfile } = useAuth()

  const [pitches, setPitches] = useState<IPitch[] | undefined>([])
  const isMobileDevice = isMobile()

  const {
    pitches: pitchesToBeFiltered,
    allMyInvites,
    updateInvite,
  } = usePitches()
  const { currentWorkspace, setCurrentWorkspaceBySlug, setSearchFilter } =
    useWorkspaces()

  const {
    onClose: bioOnClose,
    onOpen: bioOnOpen,
    isOpen: bioIsOpen,
  } = useDisclosure()
  const {
    onClose: profileOnClose,
    onOpen: profileOnOpen,
    isOpen: profileIsOpen,
  } = useDisclosure()

  const {
    onClose: inviteOnClose,
    onOpen: inviteOnOpen,
    isOpen: inviteIsOpen,
  } = useDisclosure()

  const navigate = useNavigate()

  //@ts-ignore
  const { workspace: workspaceSlug } = useParams()

  const pitchAchievements = pitchesToBeFiltered?.filter(
    (pitch) =>
      pitch.status === PitchStatus.complete &&
      displayedUserProfile &&
      pitch.members.indexOf(displayedUserProfile?.uid) !== -1
  )

  useEffect(() => {
    if (currentWorkspace && userProfile) {
      setPitches(
        pitchesToBeFiltered
          ?.filter((pitch) => pitch.workspaceUID === currentWorkspace.id)
          ?.filter(
            (pitch) =>
              pitch.members.indexOf(userProfile.uid) !== -1 ||
              pitch?.manager?.uid === userProfile.uid
          )
      )
    }
  }, [currentWorkspace, pitchesToBeFiltered])

  if (!currentWorkspace) {
    return <></>
  }

  return (
    <>
      <EditBioModal isOpen={bioIsOpen} onClose={bioOnClose} />
      <EditProfileModal isOpen={profileIsOpen} onClose={profileOnClose} />
      <FriendlyModal
        title="Invite to Pitch"
        isOpen={inviteIsOpen}
        onClose={inviteOnClose}
      >
        <Stack padding="0px">
          {pitches &&
            pitches.map((pitch) => (
              <PitchInviteCard
                pitch={pitch}
                key={pitch.id}
                selectedUser={displayedUserProfile}
              />
            ))}
        </Stack>
      </FriendlyModal>
      <Flex>
        <Avatar
          h="64px"
          w="64px"
          mb="8px"
          src={displayedUserProfile?.photo}
          background={gradient(displayedUserProfile?.lastName)}
          name={
            displayedUserProfile?.firstName +
            " " +
            displayedUserProfile?.lastName
          }
        />
        <Flex d="column" ml="8px">
          <HText color="fontBlack">
            {displayedUserProfile?.firstName +
              " " +
              displayedUserProfile?.lastName}
          </HText>
          <HText fontSize="xs" color="fontSmall">
            {displayedUserProfile?.position}
          </HText>
          {inviteDisabled ? (
            <></>
          ) : (
            <FillButton size="sm" mt="4px" onClick={inviteOnOpen}>
              Invite to Pitch
            </FillButton>
          )}
        </Flex>
      </Flex>
      <Tabs w="100%" flexGrow={1}>
        <TabList marginBottom={4} mt={2} width="100%" justify="space-between">
          <StyledTab data-testid="pitch-tab" mr="2">
            Bio
          </StyledTab>
          <StyledTab data-testid="pitch-tab" mr="2">
            Pitches
          </StyledTab>
          <StyledTab data-testid="pitch-tab" mr="2">
            Achievements
          </StyledTab>
        </TabList>
        <TabPanels flexGrow={1}>
          <TabPanel flexGrow={1}>
            {/* Bio */}
            <Flex justify="space-between" borderRadius="8px">
              {displayedUserProfile?.uid === userProfile?.uid && (
                <Icon
                  as="button"
                  name="editIcon"
                  h="40px"
                  w="40px"
                  onClick={bioOnOpen}
                />
              )}
            </Flex>

            <HText whiteSpace="pre-line">{displayedUserProfile?.bio}</HText>
            <Flex alignItems="baseline">
              <span aria-label="bag" role="img">
                💼
              </span>
              <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                Professional skills
              </HHeading>
            </Flex>

            <Flex wrap="wrap">
              {displayedUserProfile?.tags
                ?.filter((tag) => tag.type === TagTypes.skill)
                .map((skill, i) => (
                  <Tag
                    mr={2}
                    mb={2}
                    fontSize="sm"
                    key={i}
                    backgroundColor="tagGreenBackground"
                    color="tagGreenText"
                    cursor="pointer"
                    onClick={() => {
                      setSearchFilter(skill.text)
                      navigate(`/${currentWorkspace?.company}/users_view`)
                    }}
                  >
                    {skill.text}
                  </Tag>
                ))}
            </Flex>
            <Flex alignItems="baseline">
              <span aria-label="heart" role="img">
                ❤️
              </span>
              <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                Passion
              </HHeading>
            </Flex>
            <Flex wrap="wrap">
              {displayedUserProfile?.tags
                ?.filter((tag) => tag.type === TagTypes.interest)
                .map((skill, i) => (
                  <Tag
                    mr={2}
                    mb={2}
                    fontSize="sm"
                    key={i}
                    backgroundColor="tagRedBackground"
                    cursor="pointer"
                    color="tagRedText"
                    onClick={() => {
                      setSearchFilter(skill.text)
                      navigate(`/${currentWorkspace?.company}/users_view`)
                    }}
                  >
                    {skill.text}
                  </Tag>
                ))}
            </Flex>
            <Flex alignItems="baseline">
              <span aria-label="muscles" role="img">
                💪
              </span>
              <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                Strengths
              </HHeading>
            </Flex>
            <Flex wrap="wrap">
              {displayedUserProfile?.tags
                ?.filter((tag) => tag.type === TagTypes.strength)
                .map((skill, i) => (
                  <Tag
                    mb={2}
                    mr={2}
                    fontSize="sm"
                    key={i}
                    backgroundColor="tagBlueBackground"
                    cursor="pointer"
                    color="tagBlueText"
                    onClick={() => {
                      setSearchFilter(skill.text)
                      navigate(`/${currentWorkspace?.company}/users_view`)
                    }}
                  >
                    {skill.text}
                  </Tag>
                ))}
            </Flex>
          </TabPanel>
          <TabPanel flexGrow={1}>
            {/* Pitches in Progress */}

            {pitches && pitches.length > 0 && (
              <Stack spacing={6} align="center">
                {pitches.map((pitch) => (
                  <PitchCard
                    data-testid={`pitch-${pitch.title}`}
                    pitch={pitch}
                    key={pitch.id}
                  />
                ))}
              </Stack>
            )}
          </TabPanel>
          <TabPanel flexGrow={1}>
            {/* achievements */}
            {pitchAchievements && pitchAchievements.length > 0 ? (
              <Stack spacing={4}>
                {pitchAchievements.map((pitch) => (
                  <AchievementCard
                    key={pitch.id}
                    pitch={pitch}
                    displayedUserProfile={displayedUserProfile}
                    isOwner={displayedUserProfile === userProfile}
                  />
                ))}
              </Stack>
            ) : (
              <></>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

const ProfileDisplay: React.FC = () => {
  const { userProfile } = useAuth()
  const { userId } = useParams<{ userId?: string }>()
  const [pitches, setPitches] = useState<IPitch[] | undefined>([])
  const isMobileDevice = isMobile()

  const {
    pitches: pitchesToBeFiltered,
    allMyInvites,
    updateInvite,
  } = usePitches()
  const { currentWorkspace, setCurrentWorkspaceBySlug, setSearchFilter } =
    useWorkspaces()

  const URLUserProfile = useUserProfile(userId)
  const {
    onClose: bioOnClose,
    onOpen: bioOnOpen,
    isOpen: bioIsOpen,
  } = useDisclosure()
  const {
    onClose: profileOnClose,
    onOpen: profileOnOpen,
    isOpen: profileIsOpen,
  } = useDisclosure()

  const {
    onClose: inviteOnClose,
    onOpen: inviteOnOpen,
    isOpen: inviteIsOpen,
  } = useDisclosure()

  const displayedUserProfile = URLUserProfile.userProfile || userProfile
  const navigate = useNavigate()

  //@ts-ignore
  const { workspace: workspaceSlug } = useParams()

  const pitchAchievements = pitchesToBeFiltered?.filter(
    (pitch) =>
      pitch.status === PitchStatus.complete &&
      displayedUserProfile &&
      pitch.members.indexOf(displayedUserProfile?.uid) !== -1
  )

  useEffect(() => {
    if (currentWorkspace && userProfile) {
      console.log({ userProfile })
      setPitches(
        pitchesToBeFiltered
          ?.filter((pitch) => pitch.workspaceUID === currentWorkspace.id)
          ?.filter(
            (pitch) =>
              pitch.members.indexOf(userProfile.uid) !== -1 ||
              pitch?.manager?.uid === userProfile.uid
          )
      )
    }
  }, [currentWorkspace, pitchesToBeFiltered])

  return (
    <>
      <FriendlyModal
        title="Invite to Pitch"
        isOpen={inviteIsOpen}
        onClose={inviteOnClose}
      >
        <Stack>
          {pitches &&
            pitches.map((pitch) => (
              <PitchInviteCard
                pitch={pitch}
                key={pitch.id}
                selectedUser={displayedUserProfile}
              />
            ))}
        </Stack>
      </FriendlyModal>
      <DashboardContainer background="#FAFAFA">
        {isMobileDevice && displayedUserProfile ? (
          <ProfileDisplayMobile displayedUserProfile={displayedUserProfile} />
        ) : (
          <>
            <Flex
              w="100%"
              h="100%"
              direction={["column", "column", "column", "row"]}
              flexGrow={1}
            >
              {/* profile pic bar */}
              <Flex
                direction="column"
                backgroundColor="white"
                borderRadius="8px"
                boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05)"
              >
                <Flex justify="flex-end" p={6} minH="90px">
                  {displayedUserProfile?.uid === userProfile?.uid && (
                    <Icon
                      as="button"
                      name="editIcon"
                      h="40px"
                      w="40px"
                      onClick={profileOnOpen}
                    />
                  )}
                </Flex>
                <Flex paddingX="80px" paddingBottom="80px">
                  <Flex direction="column" align="center">
                    <Avatar
                      h="160px"
                      w="160px"
                      mb="32px"
                      src={displayedUserProfile?.photo}
                      background={gradient(displayedUserProfile?.lastName)}
                      name={
                        displayedUserProfile?.firstName +
                        " " +
                        displayedUserProfile?.lastName
                      }
                    />
                    <HText color="fontBlack">
                      {displayedUserProfile?.firstName}
                    </HText>
                    <HText color="fontBlack">
                      {displayedUserProfile?.lastName}
                    </HText>
                    <HText fontSize="xs" color="fontSmall">
                      {displayedUserProfile?.position}
                    </HText>
                    <FillButton mt="8px" onClick={inviteOnOpen}>
                      Invite to Pitch
                    </FillButton>
                  </Flex>
                </Flex>
              </Flex>
              {/* profile */}
              <Flex maxW="1040px" h="100%" ml={"40px"} flexGrow={1}>
                <Flex direction="column" w="100%" h="100%">
                  <Flex
                    direction="column"
                    padding="24px"
                    backgroundColor="white"
                    w="100%"
                    borderRadius="8px"
                    boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05)"
                  >
                    <Flex justify="space-between" borderRadius="8px">
                      <HHeading fontSize="md" mb={2}>
                        Bio
                      </HHeading>

                      {displayedUserProfile?.uid === userProfile?.uid && (
                        <Icon
                          as="button"
                          name="editIcon"
                          h="40px"
                          w="40px"
                          onClick={bioOnOpen}
                        />
                      )}
                    </Flex>

                    <HText whiteSpace="pre-line">
                      {displayedUserProfile?.bio}
                    </HText>
                    <Flex alignItems="baseline">
                      <span aria-label="bag" role="img">
                        💼
                      </span>
                      <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                        Professional skills
                      </HHeading>
                    </Flex>

                    <Flex wrap="wrap">
                      {displayedUserProfile?.tags
                        ?.filter((tag) => tag.type === TagTypes.skill)
                        .map((skill, i) => (
                          <Tag
                            mr={2}
                            mb={2}
                            fontSize="sm"
                            key={i}
                            backgroundColor="tagGreenBackground"
                            color="tagGreenText"
                            cursor="pointer"
                            onClick={() => {
                              setSearchFilter(skill.text)
                              navigate(
                                `/${currentWorkspace?.company}/users_view`
                              )
                            }}
                          >
                            {skill.text}
                          </Tag>
                        ))}
                    </Flex>
                    <Flex alignItems="baseline">
                      <span aria-label="heart" role="img">
                        ❤️
                      </span>
                      <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                        Passion
                      </HHeading>
                    </Flex>
                    <Flex wrap="wrap">
                      {displayedUserProfile?.tags
                        ?.filter((tag) => tag.type === TagTypes.interest)
                        .map((skill, i) => (
                          <Tag
                            mr={2}
                            mb={2}
                            fontSize="sm"
                            key={i}
                            backgroundColor="tagRedBackground"
                            cursor="pointer"
                            color="tagRedText"
                            onClick={() => {
                              setSearchFilter(skill.text)
                              navigate(
                                `/${currentWorkspace?.company}/users_view`
                              )
                            }}
                          >
                            {skill.text}
                          </Tag>
                        ))}
                    </Flex>
                    <Flex alignItems="baseline">
                      <span aria-label="muscles" role="img">
                        💪
                      </span>
                      <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                        Strengths
                      </HHeading>
                    </Flex>
                    <Flex wrap="wrap">
                      {displayedUserProfile?.tags
                        ?.filter((tag) => tag.type === TagTypes.strength)
                        .map((skill, i) => (
                          <Tag
                            mb={2}
                            mr={2}
                            fontSize="sm"
                            key={i}
                            backgroundColor="tagBlueBackground"
                            cursor="pointer"
                            color="tagBlueText"
                            onClick={() => {
                              setSearchFilter(skill.text)
                              navigate(
                                `/${currentWorkspace?.company}/users_view`
                              )
                            }}
                          >
                            {skill.text}
                          </Tag>
                        ))}
                    </Flex>
                  </Flex>
                  {/* pitches */}

                  {pitches && pitches.length > 0 && (
                    <PitchSlider
                      displayedUser={displayedUserProfile?.uid || ""}
                      pitches={pitches}
                    />
                  )}

                  {/* achievement */}
                  {pitchAchievements && pitchAchievements.length > 0 ? (
                    <Box mt={10}>
                      <HHeading fontSize="md" mb={4}>
                        Achievements
                      </HHeading>
                      <Stack>
                        {pitchAchievements.map((pitch) => (
                          <AchievementCard
                            key={pitch.id}
                            pitch={pitch}
                            displayedUserProfile={displayedUserProfile}
                            isOwner={
                              displayedUserProfile?.uid === userProfile?.uid
                            }
                          />
                        ))}
                      </Stack>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <EditBioModal isOpen={bioIsOpen} onClose={bioOnClose} />
            <EditProfileModal isOpen={profileIsOpen} onClose={profileOnClose} />
          </>
        )}
      </DashboardContainer>
    </>
  )
}

const AchievementCard: React.FC<{
  pitch: IPitch
  isOwner: boolean
  displayedUserProfile: any
}> = ({ pitch, isOwner, displayedUserProfile }) => {
  const { user } = useAuth()
  const { companyUrl } = useCompany()
  const achievement = pitch.pitchMemberAchievements?.find(
    (ach) => ach.userId === displayedUserProfile?.uid
  )
  const { onClose, onOpen, isOpen } = useDisclosure()
  return (
    <Box
      minHeight="101px"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05)"
      borderRadius="8px"
      backgroundColor="white"
      overflow="hidden"
      mb={8}
    >
      <Flex h="100%">
        <Image
          borderTopLeftRadius={"8px"}
          borderBottomLeftRadius={"8px"}
          minW="120px"
          maxW="120px"
          minHeight="101px"
          h="100%"
          background={gradient(pitch.id)}
          objectFit="cover"
          src={pitch.coverPicture?.url}
        />
        <Flex direction="column" p="16px" width="100%">
          <Flex justify="space-between">
            <Link
              //@ts-ignore
              as={RouterLink}
              to={`/${companyUrl}/pitch/${pitch.id}`}
            >
              <HHeading fontSize="md">{pitch.title}</HHeading>
            </Link>
            {isOwner && (
              <Icon
                as="button"
                name="editIcon"
                h="40px"
                w="40px"
                onClick={onOpen}
              />
            )}
          </Flex>

          {achievement ? (
            <Flex justify="space-between" width="70%">
              <HText fontSize="sm">
                {achievement.achievement || "No achievement added yet"}
              </HText>
            </Flex>
          ) : isOwner ? (
            <Link
              onClick={onOpen}
              textAlign="right"
              data-testid="add-achievement"
              color="textLink"
            >
              Add your achievement
            </Link>
          ) : (
            <Flex justify="space-between" width="70%">
              <HText fontSize="sm">No achievement added yet</HText>
            </Flex>
          )}
        </Flex>
      </Flex>
      <EditAchievementModal
        prefill={achievement?.achievement}
        onClose={onClose}
        isOpen={isOpen}
        pitch={pitch}
      />
    </Box>
  )
}

export const PitchSlider: React.FC<{
  displayedUser: string
  pitches: IPitch[]
}> = ({ displayedUser, pitches }) => {
  const [displayedPitches, setDisplayedPitches] = useState<IPitch[]>([])
  useEffect(() => {
    setDisplayedPitches(
      pitches?.filter((pitch) => {
        return (
          pitch.createdBy === displayedUser &&
          pitch.status !== PitchStatus.complete
        )
      }) || []
    )
  }, [displayedUser])

  //helper function for the case study slider buttons
  const moveElementInArray = (a: any[], from: number, to: number) => {
    a.splice(to, 0, a.splice(from, 1)[0])
    return a
  }
  const slideBackward = () => {
    const allPitches = [...displayedPitches]
    setDisplayedPitches(moveElementInArray(allPitches, 0, allPitches.length))
  }
  const slideForward = () => {
    const allPitches = [...displayedPitches]
    setDisplayedPitches(moveElementInArray(allPitches, -1, 0))
  }
  return displayedPitches.length > 0 ? (
    <Flex direction="column" mt={4}>
      <Flex justify="space-between">
        <HHeading fontSize="md" mb={4}>
          Pitches in progress
        </HHeading>
        <Flex>
          <IconButton
            onClick={slideBackward}
            variant="ghost"
            color="#424242"
            icon="chevron-left"
            aria-label="slide-back"
          />
          <IconButton
            onClick={slideForward}
            variant="ghost"
            color="#424242"
            icon="chevron-right"
            aria-label="slide-forward"
          />
        </Flex>
      </Flex>
      <Flex w="100%">
        {displayedPitches.slice(0, 3).map((pitch, i) => (
          <Box key={pitch.id} ml={i > 0 ? "40px" : 0}>
            <PitchCard pitch={pitch} />
          </Box>
        ))}
      </Flex>
    </Flex>
  ) : (
    <></>
  )
}

export default ProfileDisplay
