/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Link,
  Tag,
  Stack,
  useDisclosure,
} from '@chakra-ui/core'
import { getTime } from 'date-fns'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Autosuggest } from '../../Components/Autosuggest'
import { FillButton, OutlineButton } from '../../Components/Buttons'
import { DatePicker } from '../../Components/DatePicker'
import { Dropzone } from '../../Components/Dropzone'
import HuminateLoader from '../../Components/HuminateLoader'
import {
  HHeading,
  HInput,
  HText,
  HTextArea,
} from '../../Components/StyledTextComponents'
import { TagInput } from '../../Components/TagInput'
import { useAuth } from '../../Context/auth-context'
import { useCompany } from '../../Context/company-context'
import { usePitches } from '../../Context/pitches-context'
import { FriendlyModal } from '../../Components/FriendlyModal'
import { useWorkspaces } from '../../Context/workspace-context'
import { requireAtleastOne, required } from '../../formUtils'
import { IAttachment, IPitch, PitchStatus, TagTypes } from '../../types'
import { AddLinkModal } from '../CreateAPitch/modals/addLinkModal'
import { DashboardFooter } from '../Dashboard/DashboardWrapper'
import { SkillTagSuggestions } from '../../fixtures'
import firebase from 'firebase/compat/app'

const EditPitch = ({ onSuccess }: { onSuccess?: any }) => {
  const { pitches, updatePitch, updatePitchApprover } = usePitches()
  const navigate = useNavigate()
  const { users, fullUserName } = useWorkspaces()
  const { companyUrl: company } = useCompany()
  const [filesCover, setFilesCover] = useState<IAttachment[]>([])
  const [files, setFiles] = useState<IAttachment[]>([])
  const { pitchId } = useParams<{ pitchId?: string }>()
  const [currentPitch, setCurrentPitch] = useState<Partial<IPitch>>()
  const [links, setLinks] = useState<IAttachment[]>([])
  const [newPitchData, setNewPitchData] = useState<any>()

  const { onOpen, onClose, isOpen } = useDisclosure()
  const {
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
    isOpen: isOpenUpdate,
  } = useDisclosure()

  const { companyUrl } = useCompany()
  const { userProfile } = useAuth()
  const { currentWorkspace, tags } = useWorkspaces()
  //   match the pitch id from the URL to the pitches list
  useEffect(() => {
    if (pitchId === undefined) {
      setCurrentPitch({})
    } else {
      const p = pitches?.find((pitch) => pitch.id === pitchId)
      setCurrentPitch(p)
      if (p) {
        setFiles(
          p.attachments
            ? p.attachments.filter((attachment) => !attachment.isLink)
            : []
        )
        setLinks(
          p.attachments
            ? p.attachments.filter((attachment) => attachment.isLink)
            : []
        )
        setFilesCover(p.coverPicture ? [p.coverPicture] : [])
      }
    }
  }, [pitches])

  if (currentPitch === undefined || users === undefined) {
    return <HuminateLoader></HuminateLoader>
  }
  return (
    <>
      <FriendlyModal
        title="Send Notifications"
        isOpen={isOpenUpdate}
        noButton
        noTop
        onClose={() => {
          window.location.hash = ''
          onCloseUpdate()
        }}
      >
        <HText fontSize="xl">
          Would you like to notify people for the new skills added to the pitch?
        </HText>
        <Flex my="8px">
          {newPitchData?.newSkills?.map(({ text }: any) => (
            <Tag key={text}>{text}</Tag>
          ))}
        </Flex>
        <Flex justify="center">
          <OutlineButton
            my="16px"
            mx="4px"
            onClick={async () => {
              let newPitch: Partial<IPitch> = {
                title: newPitchData.title,
                description: newPitchData.description,
                skillTags: newPitchData.skillTags.map((tag: any) => {
                  return { tag: tag, usersNotified: true }
                }),

                dueDate: getTime(newPitchData.dueDate),
                attachments: [...links, ...files],
              }

              if (filesCover[0]) {
                newPitch = { ...newPitch, coverPicture: filesCover[0] }
              } else {
                newPitch = {
                  ...newPitch,
                  // @ts-ignore
                  coverPicture: firebase.firestore.FieldValue.delete(),
                }
              }
              console.log({ pitchApprover: newPitchData?.pitchApprover })
              if (
                newPitchData?.pitchApprover.uid !==
                currentPitch?.pitchApprover?.uid
              ) {
                await updatePitchApprover(
                  currentPitch,
                  //@ts-ignore
                  newPitchData?.pitchApprover || currentPitch.manager
                )
              }
              //@ts-ignore
              await updatePitch(currentPitch?.id, newPitch)
              onCloseUpdate()
              onSuccess()
            }}
          >
            No
          </OutlineButton>
          <FillButton
            my="16px"
            mx="4px"
            onClick={async () => {
              let newPitch: Partial<IPitch> = {
                title: newPitchData.title,
                description: newPitchData.description,
                skillTags: newPitchData.skillTags.map((tag: any) => {
                  return {
                    tag: tag,
                    usersNotified: newPitchData.newSkills.find(
                      (someTag: any) => someTag.id === tag.id
                    )
                      ? false
                      : true,
                  }
                }),

                dueDate: getTime(newPitchData.dueDate),
                attachments: [...links, ...files],
              }

              if (filesCover[0]) {
                newPitch = { ...newPitch, coverPicture: filesCover[0] }
              } else {
                newPitch = {
                  ...newPitch,
                  // @ts-ignore
                  coverPicture: firebase.firestore.FieldValue.delete(),
                }
              }
              console.log({ pitchApprover: newPitchData?.pitchApprover })
              if (
                newPitchData?.pitchApprover.uid !==
                currentPitch?.pitchApprover?.uid
              ) {
                await updatePitchApprover(
                  currentPitch,
                  //@ts-ignore
                  newPitchData?.pitchApprover || currentPitch.manager
                )
              }
              //@ts-ignore
              await updatePitch(currentPitch?.id, newPitch)
              onCloseUpdate()
              onSuccess()
            }}
          >
            Yes
          </FillButton>
        </Flex>
      </FriendlyModal>
      <Formik
        initialValues={{
          title: currentPitch.title ?? '',
          description: currentPitch.description ?? '',
          skillTags:
            currentPitch.skillTags?.map((pitchTag) => pitchTag.tag) ?? [],
          dueDate: currentPitch.submissionDate,
          submissionDate: currentPitch.submissionDate
            ? new Date(currentPitch.submissionDate)
            : new Date(),
          pitchApprover: currentPitch.manager || userProfile?.manager,
          attachments: currentPitch.attachments || [],
        }}
        onSubmit={async (values, _actions) => {
          console.log({ values })
          if (currentPitch.id) {
            console.log(currentPitch.skillTags)
            const newSkillsArray = values.skillTags.filter(
              ({ id }) =>
                currentPitch?.skillTags?.find(({ tag }) => tag.id === id) ===
                undefined
            )
            //@ts-ignore
            if (
              currentPitch &&
              currentPitch.skillTags &&
              newSkillsArray.length > 0
            ) {
              setNewPitchData({ newSkills: newSkillsArray, ...values })
              onOpenUpdate()
            } else {
              let newPitch: Partial<IPitch> = {
                title: values.title,
                description: values.description,
                skillTags: values.skillTags.map((tag) => {
                  return { tag: tag, usersNotified: true }
                }),
                submissionDate: getTime(values.submissionDate),
                attachments: [...links, ...files],
              }

              if (values.dueDate) {
                newPitch = { ...newPitch, dueDate: getTime(values.dueDate) }
              }

              if (filesCover[0]) {
                newPitch = { ...newPitch, coverPicture: filesCover[0] }
              } else {
                newPitch = {
                  ...newPitch,
                  // @ts-ignore
                  coverPicture: firebase.firestore.FieldValue.delete(),
                }
              }
              console.log({ pitchApprover: values.pitchApprover })
              if (
                newPitchData?.pitchApprover.uid !==
                currentPitch?.pitchApprover?.uid
              ) {
                await updatePitchApprover(
                  currentPitch,
                  //@ts-ignore
                  values.pitchApprover || currentPitch.manager
                )
              }
              await updatePitch(currentPitch.id, newPitch)
              onSuccess()
            }
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
            <Field name="cover">
              {({ form }: any) => (
                <FormControl
                  isInvalid={form.errors.cover && form.touched.cover}
                  marginBottom="32px"
                >
                  <FormLabel color="fontBlack" htmlFor="cover">
                    Cover image (optional)
                  </FormLabel>
                  <FormHelperText color="fontDarkGray" mb={2}>
                    Only .jpg and .png files, 4 MB max file size. <br />
                    Recommended size 1200px wide by 630 tall.
                  </FormHelperText>

                  <Dropzone
                    limit={1}
                    files={filesCover}
                    setFiles={setFilesCover}
                    path={`${company}/pitch/${currentPitch.id}/cover`}
                  />
                  <FormErrorMessage
                    style={{ height: '17px', marginBottom: '-25px' }}
                  >
                    {form.errors.cover}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="title" validate={required}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={form.errors.title && form.touched.title}
                  marginBottom="32px"
                >
                  <FormLabel color="fontBlack" htmlFor="title">
                    Title
                  </FormLabel>
                  <HInput mt={2} data-testid="title" {...field} id="title" />
                  <FormErrorMessage
                    style={{ height: '17px', marginBottom: '-25px' }}
                  >
                    {form.errors.title}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description" validate={required}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                  marginBottom="32px"
                >
                  <FormLabel color="fontBlack" htmlFor="description">
                    Description
                  </FormLabel>
                  <FormHelperText color="fontDarkGray">
                    E.g. competitor analysis, market size, customer examples and
                    successes
                  </FormHelperText>
                  <HTextArea
                    minH="200px"
                    mt={2}
                    data-testid="description"
                    {...field}
                    style={{
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                    }}
                    id="description"
                    value={props.values.description}
                    fontSize="16px"
                    resize="vertical"
                  />
                  <FormErrorMessage
                    style={{ height: '17px', marginBottom: '-25px' }}
                  >
                    {form.errors.description}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="skillTags" validate={requireAtleastOne}>
              {({ field, form }: any) => (
                <FormControl
                  marginBottom="32px"
                  isInvalid={form.errors.skillTags && form.touched.skillTags}
                >
                  <FormLabel color="fontBlack" htmlFor="skillTags">
                    Skill tags
                  </FormLabel>
                  <FormHelperText color="fontDarkGray" mb={2}>
                    Tags help others discover and participate your pitch. Add
                    some relevant skill hashtags that are related to the pitch.
                    E.g. People Management, CRM, Python, or Social Media.
                  </FormHelperText>
                  <TagInput
                    testId="skillTags"
                    tags={field.value}
                    suggestions={[...SkillTagSuggestions, ...tags]}
                    onTagUpdate={(tags) => {
                      props.setFieldValue('skillTags', tags)
                    }}
                  />
                  <FormErrorMessage
                    style={{ height: '17px', marginBottom: '-25px' }}
                  >
                    {form.errors.skillTags}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="attachments">
              {({ _field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.attachments && form.touched.attachments
                  }
                  marginBottom="32px"
                >
                  <FormLabel color="fontBlack" htmlFor="attachments">
                    Attachments (optional)
                  </FormLabel>
                  <FormHelperText color="fontDarkGray" mb={2}>
                    E.g. screenshots, wireframe, documents
                  </FormHelperText>
                  <Dropzone
                    files={files}
                    setFiles={setFiles}
                    path={`${company}/attachments`}
                  />
                  {links && (
                    <Stack mt="2">
                      {links.map((link, i) => {
                        return (
                          <Flex
                            key={i}
                            w="100%"
                            minH={12}
                            p={4}
                            backgroundColor="rgba(9, 19, 33, 0.0638304)"
                            justify="space-between"
                            align="center"
                          >
                            <HText fontSize="sm">{link.url}</HText>
                            <IconButton
                              aria-label="remove image"
                              icon="close"
                              borderRadius="100%"
                              variant="ghost"
                              size="xs"
                              color="fontBlack"
                              onClick={() => {
                                const currentLinks = [...links]
                                const index = currentLinks.findIndex(
                                  (searchLink) => searchLink.url === link.url
                                )

                                index > -1 && currentLinks.splice(index, 1)
                                setLinks(currentLinks)
                              }}
                            />
                          </Flex>
                        )
                      })}
                    </Stack>
                  )}
                  <Flex mt={4} justify="center">
                    <HText fontSize="sm">
                      You can also{' '}
                      <Link color="textLink" onClick={onOpen}>
                        add a link
                      </Link>
                    </HText>
                  </Flex>
                  <FormErrorMessage
                    style={{ height: '17px', marginBottom: '-25px' }}
                  >
                    {form.errors.attachments}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {!(
              currentPitch.status === PitchStatus.draft ||
              currentPitch.status === PitchStatus.overdueSubmission
            ) ? (
              <Field name="dueDate">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.dueDate && form.touched.dueDate}
                    marginBottom="32px"
                  >
                    <FormLabel color="fontBlack" htmlFor="dueDate">
                      Deadline
                    </FormLabel>
                    <FormHelperText color="fontDarkGray">
                      Expected completion date
                    </FormHelperText>
                    <Box maxW="180px">
                      <DatePicker
                        testid="edit-datepicker"
                        isDisabled={false}
                        startDate={field.value}
                        handleChange={(val: any) =>
                          props.setFieldValue('dueDate', val)
                        }
                      />
                    </Box>

                    <FormErrorMessage
                      style={{ height: '17px', marginBottom: '-25px' }}
                    >
                      {form.errors.dueDate}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            ) : (
              <Field name="submissionDate">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={
                      form.errors.submissionDate && form.touched.submissionDate
                    }
                    marginBottom="32px"
                  >
                    <FormLabel color="fontBlack" htmlFor="submissionDate">
                      Submission Deadline
                    </FormLabel>
                    <FormHelperText color="fontDarkGray">
                      Expected completion date of the the draft
                    </FormHelperText>
                    <Box maxW="180px">
                      <DatePicker
                        testid="edit-datepicker"
                        isDisabled={false}
                        startDate={field.value}
                        handleChange={(val: any) =>
                          props.setFieldValue('submissionDate', val)
                        }
                      />
                    </Box>

                    <FormErrorMessage
                      style={{ height: '17px', marginBottom: '-25px' }}
                    >
                      {form.errors.submissionDate}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            <Field name="pitchApprover" validate={required}>
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.pitchApprover && form.touched.pitchApprover
                  }
                  marginBottom="32px"
                >
                  <FormLabel color="fontBlack" htmlFor="pitchApprover">
                    Pitch approver
                  </FormLabel>
                  <FormHelperText color="fontDarkGray">
                    In most of the cases, your manager is the pitch approver,
                    but you can update it if required.{' '}
                  </FormHelperText>
                  <Autosuggest
                    testId="manager"
                    options={users || []}
                    initialValue={
                      props.values.pitchApprover?.firstName +
                      ' ' +
                      props.values.pitchApprover?.lastName
                    }
                    onSelect={(val) =>
                      props.setFieldValue('pitchApprover', val)
                    }
                  />
                  <FormErrorMessage
                    style={{ height: '17px', marginBottom: '-25px' }}
                  >
                    {form.errors.pitchApprover}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex justify="center">
              <FillButton my="16px" type="submit">
                Save Pitch Updates
              </FillButton>
            </Flex>
          </form>
        )}
      </Formik>

      <AddLinkModal
        onClose={onClose}
        isOpen={isOpen}
        links={links}
        setLinks={setLinks}
      />
    </>
  )
}

export default EditPitch
