import React, { useState, useEffect } from "react"
import { useAuth0 } from "../auth0-spa"

import { Auth0User, IUserProfile } from "../types"
import { createCtx } from "./createCtx"
import { firebaseApp } from "../firebase"
import * as R from "ramda"

interface AuthContextProps {
  user: Auth0User | undefined
  loading: boolean
  userProfile: IUserProfile | undefined
  userProfileLoading: boolean | undefined
  updateUserProfile: (data: Partial<IUserProfile>) => void
  logout: () => void
  noCompanyAccess: boolean
}

const [useAuth, AuthContextProvider] = createCtx<AuthContextProps>()

const AuthProvider: React.FC = ({ children }) => {
  console.log("AUTH")

  const { user, loading, logout, noCompanyAccess } = useAuth0()

  const [userProfile, setUserProfile] = useState<IUserProfile | undefined>(
    undefined
  )
  const [userProfileLoading, setUserProfileLoading] = useState<
    boolean | undefined
  >(true)

  // Need to set data from firestore here
  useEffect(() => {
    if (user?.sub && !loading) {
      setUserProfileLoading(true)
      const unsubscribe = firebaseApp
        .firestore()
        .collection("users")
        .doc(user?.sub)
        .onSnapshot(
          (doc) => {
            const d = doc.data()
            if (d !== undefined) {
              setUserProfile({ ...doc.data(), uid: doc.id })
              setUserProfileLoading(false)
              if (d.email !== user.email) {
                console.info("Updating the users email address")
                user.email &&
                  updateUserProfile({ email: user.email, uid: doc.id })
              }
            }
            setUserProfileLoading(false) // Handles new-user case
          },
          (err) => {
            console.warn(err)
          }
        )
      return unsubscribe
    }
  }, [user?.sub, loading])

  const updateUserProfile = (data: Partial<IUserProfile>) => {
    if (user?.sub) {
      //@ts-ignore
      const cleanProfile = R.omit(["company", "role", "private"], data)
      // const cleanProfile = { ...data, company: 'demo2' }
      console.log({ cleanProfile })
      firebaseApp
        .firestore()
        .collection("users")
        .doc(user?.sub)
        // merge avoids overwriting the entire document
        .update(cleanProfile)
    }
  }

  return (
    <AuthContextProvider
      value={{
        logout,
        user,
        loading,
        userProfile,
        updateUserProfile,
        userProfileLoading,
        noCompanyAccess,
      }}
    >
      {children}
    </AuthContextProvider>
  )
}

export { useAuth, AuthProvider }
