import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverBody,
  Tag,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Badge,
  Stack,
  Text,
  Link,
  PopoverArrow,
  PopoverCloseButton,
  Divider,
  useDisclosure,
} from "@chakra-ui/core"
import { getTime } from "date-fns"
import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import { usePitches } from "../../../Context/pitches-context"
import { useWorkspaces } from "../../../Context/workspace-context"
import theme from "../../../theme"

import { required, requireAtleastOne } from "../../../formUtils"
import {
  IAttachment,
  IPitch,
  ITask,
  ITaskList,
  IInvite,
  PitchMemberTypes,
  ISkillTag,
  IUserProfile,
  InviteStatuses,
} from "../../../types"
import { FillButton, OutlineButton } from "./../../../Components/Buttons"
import { DatePicker } from "./../../../Components/DatePicker"
import { Dropzone } from "./../../../Components/Dropzone"
import {
  HHeading,
  HInput,
  HText,
  HTextArea,
} from "./../../../Components/StyledTextComponents"
import { useAuth } from "../../../Context/auth-context"
import { MentionsInput, Mention, MentionItem } from "react-mentions"
import { TagInput } from "../../../Components/TagInput"
import { SkillTagSuggestions } from "../../../fixtures"
import { AddLinkModal } from "../../CreateAPitch/modals/addLinkModal"
import { PersonProfileModal } from "../../CreateAPitch/modals/personalProfileModal"
import { FriendlyModal } from "../../../Components/FriendlyModal"
import { PitchActionButton } from "../PitchDisplay"

//@ts-ignore
import gradient from "random-gradient"
interface TaskModalProps {
  taskList?: ITaskList
  task?: ITask
  company: string
  pitch: IPitch | undefined
  onClose: () => void
  isOpen: boolean
  readOnly?: boolean
}

export const CreateTaskListModal: React.FC<TaskModalProps> = ({
  isOpen,
  onClose,
  pitch,
}) => {
  const { user } = useAuth()
  const { createTaskList, currentPitch } = usePitches()

  console.log({ currentPitch })

  const [title, setTitle] = useState<string>("")
  const saveTaskList = () => {
    pitch?.id &&
      title &&
      user?.sub &&
      createTaskList(
        {
          name: title,
          tasks: [],
          pitch: pitch.id,
          createdBy: user?.sub,
        },
        currentPitch?.taskLists || []
      )
    setTitle("")
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent borderRadius="8px">
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10}>
          <HHeading mb={7}>Create task list</HHeading>
          <FormLabel>Title</FormLabel>
          <HInput
            value={title}
            data-testid="taskList-title"
            onChange={(e: any) => setTitle(e.target.value)}
          />
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          <Flex justify="center" w="100%">
            <Button rounded="full" variant="ghost" mr={2} onClick={onClose}>
              Cancel
            </Button>

            <FillButton
              data-testid="submit-tasklist"
              isDisabled={!pitch?.id && title !== undefined && title.length < 1}
              onClick={() => saveTaskList()}
            >
              Add
            </FillButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

// this modal can be used to edit by passing in a task of to create, if no task is provided
export const EditTaskModal: React.FC<TaskModalProps> = ({
  isOpen,
  onClose,
  task,
  taskList,
  company,
  readOnly,
  pitch,
}) => {
  const [showMemberSearch, setShowMemberSearch] = useState(false)

  const [files, setFiles] = useState<IAttachment[]>(
    task?.attachments ? task.attachments.filter(({ isLink }) => !isLink) : []
  )
  const { updateTaskList, createInvite, updatePitch, userCanSee, invites } =
    usePitches()
  const { user, userProfile, updateUserProfile } = useAuth()
  const [skillTags, setSkillTags] = useState(task?.skillTags ?? [])
  const { editTask } = userCanSee(pitch, user?.sub)
  const { users, setSearchFilter, filteredResults, searchFilter, tags } =
    useWorkspaces()
  const [difficulty, setDifficulty] = useState<number>(task?.difficulty || 1)
  const [isMemberPopOpen, setIsMemberPopOpen] = useState(false)
  const [inviteWithEmail, setInviteWithEmail] = useState(false)
  const [externalEmail, setExternalEmail] = useState("")
  const [taskMember, setTaskMember] = useState(task?.member || [])

  const {
    onClose: onCloseMini,
    onOpen: onOpenMini,
    isOpen: isOpenMini,
  } = useDisclosure()
  const [selectedPerson, setSelectedPerson] = useState<IUserProfile>()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const handleDeleteTask = () => {
    if (task?.id && taskList?.id) {
      const taskIndex = taskList.tasks.findIndex(
        (thisTask) => thisTask.id === task.id
      )
      taskList.tasks.splice(taskIndex, 1)
      user?.sub && updateTaskList(taskList.id, taskList, user.sub)
    }
  }
  const [links, setLinks] = useState<IAttachment[]>(
    task?.attachments ? task.attachments.filter(({ isLink }) => isLink) : []
  )
  const [openLink, setOpenLink] = useState(false)
  const [viewAttachments, setViewAttachments] = useState(false)

  const usersWithTags = users?.map((user) => {
    const matchedTags: ISkillTag[] = []

    //@ts-ignore
    user?.tags?.map((userTag) => {
      if (
        //@ts-ignore
        skillTags?.filter(
          (tag: any): any => tag.text.toLowerCase() === userTag.id.toLowerCase()
        ).length > 0
      ) {
        matchedTags.push(userTag)
      }
    })
    return { ...user, matchedTags }
  })

  const userSearchResults =
    usersWithTags
      ?.filter(
        (member) => !taskMember.find((taskMember) => taskMember === member.uid)
      )
      .filter((member) => {
        if (pitch !== undefined && pitch.manager !== undefined) {
          return (
            pitch?.members.indexOf(member.uid) !== -1 ||
            member.uid === pitch?.manager?.uid
          )
        } else {
          return true
        }
      }) || []

  const peopleSearchResults =
    filteredResults
      ?.filter(
        (member) => !taskMember.find((taskMember) => taskMember === member.uid)
      )
      ?.sort((userA, userB) => {
        const userTagsA =
          //@ts-ignore
          usersWithTags?.find(({ uid }) => uid === userA.uid).matchedTags
            .length || 0
        //@ts-ignore
        const userTagsB =
          //@ts-ignore
          usersWithTags?.find(({ uid }) => uid === userB.uid).matchedTags
            .length || 0
        return userTagsB - userTagsA
      }) || []

  const allUsers = [...userSearchResults, ...peopleSearchResults] || []

  console.log({ userSearchResults, peopleSearchResults })

  return (
    <>
      <PersonProfileModal
        userProfile={selectedPerson}
        onClose={() => {
          onCloseMini()
        }}
        isOpen={isOpenMini}
      />
      {/* <AddLinkModal
        onClose={() => setOpenLink(false)}
        isOpen={openLink}
        links={links}
        setLinks={setLinks}
      /> */}
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        {showDeleteModal ? (
          <ModalContent borderRadius="8px" p="16px">
            <HHeading>{"Delete Task"}</HHeading>
            <HText mt="16px">{`Are you sure you want to delete:`}</HText>
            <HText mt="16px" as="b">{`${task?.title}`}</HText>

            <Flex mt="16px">
              <OutlineButton
                type="button"
                onClick={() => setShowDeleteModal(false)}
                mr="8px"
              >
                <Flex>Cancel</Flex>
              </OutlineButton>
              <FillButton
                backgroundColor="deleteRed"
                onClick={() => {
                  handleDeleteTask()
                  onClose()
                }}
              >
                <Flex>
                  <Icon name="delete" mr="4px" />
                  Delete
                </Flex>
              </FillButton>
            </Flex>
          </ModalContent>
        ) : (
          <ModalContent
            borderRadius="8px"
            display={isOpenMini ? "none" : "auto"}
          >
            <ModalCloseButton />

            <Formik
              initialValues={{
                title: task?.title || "",
                member: task?.member || [],
                skillTags: task?.skillTags ?? [],
                dueDate: task?.dueDate || new Date(),
                description: task?.description || "",
              }}
              onSubmit={(values, _actions) => {
                if (
                  values.dueDate &&
                  values.title &&
                  values.description &&
                  taskList?.id
                ) {
                  const newTask: ITask = {
                    id: taskList.id + getTime(new Date()),
                    taskList: taskList.id,
                    dueDate: getTime(values.dueDate || new Date()),
                    title: values.title || "",
                    description: values.description || "",
                    member: values.member,
                    isCompleted: false,
                    attachments: [...links, ...files],
                    difficulty: difficulty,
                    skillTags: values.skillTags,
                  }

                  if (task?.id) {
                    const taskIndex = taskList.tasks.findIndex(
                      (thisTask) => thisTask.id === task.id
                    )
                    taskList.tasks[taskIndex] = newTask
                    user?.sub && updateTaskList(taskList.id, taskList, user.sub)
                  } else {
                    taskList.tasks.push(newTask)
                    user?.sub && updateTaskList(taskList.id, taskList, user.sub)
                    // pitch?.id &&
                    //   updatePitch(pitch?.id, {
                    //     taskCount: (pitch.taskCount ?? 0) + 1,
                    //   });
                  }

                  // create invites for any members not in the pitch already, this will trigger the background cloud function
                  const membersNotInPitchAlready = values.member
                    .filter(
                      (taskMember) =>
                        pitch?.members.find(
                          (member) => member === taskMember
                        ) === undefined
                    )
                    .filter((taskMember) => {
                      const alreadyHasInviteToPitch =
                        invites &&
                        invites
                          .filter(({ to }) => to === taskMember)
                          .filter(
                            ({ subject }) => subject?.reference === pitch?.id
                          ).length > 0
                      return !alreadyHasInviteToPitch
                    })

                  membersNotInPitchAlready.forEach((member) => {
                    const newInvite: IInvite = {
                      status: InviteStatuses.pending,
                      from: user?.sub,
                      to: member,
                      subject: {
                        task: newTask,
                        reference: pitch?.id,
                        type: PitchMemberTypes.collaborator,
                      },
                    }

                    pitch && createInvite(newInvite)
                  })

                  onClose()
                }
              }}
            >
              {(props) => (
                <Form
                  onSubmit={(e) => {
                    props.handleSubmit(e)
                  }}
                >
                  <ModalBody paddingTop={10} paddingX={6} borderRadius="8px">
                    <Flex w="100%" mb={7} justify="space-between" mt={4}>
                      {!readOnly && editTask && task?.id && !task?.isCompleted && (
                        <FillButton
                          type="button"
                          backgroundColor="deleteRed"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          <Flex>
                            <Icon name="delete" mr="4px" />
                            Delete
                          </Flex>
                        </FillButton>
                      )}
                    </Flex>
                    <Flex width="100%" justify={"space-between"}>
                      <Field name="title" validate={required} maxWidth="280px">
                        {({ field, form }: any) => (
                          <FormControl
                            width="100%"
                            mr="8px"
                            isInvalid={form.errors.title && form.touched.title}
                            marginBottom="32px"
                          >
                            <FormLabel color="fontBlack" htmlFor="title">
                              Title
                            </FormLabel>
                            <HInput
                              mt={2}
                              data-testid="title"
                              {...field}
                              isDisabled={readOnly}
                              id="title"
                            />
                            <FormErrorMessage
                              style={{ height: "17px", marginBottom: "-25px" }}
                            >
                              {form.errors.title}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="dueDate" validate={required} width="80px">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.dueDate && form.touched.dueDate
                            }
                            marginBottom="32px"
                          >
                            <FormLabel color="fontBlack" htmlFor="dueDate">
                              Due date
                            </FormLabel>

                            <Box maxW="180px">
                              <DatePicker
                                isDisabled={readOnly}
                                startDate={field.value}
                                data-testid="due-date"
                                handleChange={(val: any) =>
                                  props.setFieldValue("dueDate", val)
                                }
                              />
                            </Box>

                            <FormErrorMessage
                              style={{ height: "17px", marginBottom: "-25px" }}
                            >
                              {form.errors.dueDate}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Flex>

                    <Field name="description" validate={required}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                          marginBottom="32px"
                        >
                          <FormLabel color="fontBlack" htmlFor="description">
                            Description
                          </FormLabel>
                          <HTextArea
                            isDisabled={readOnly}
                            minH="48px"
                            mt={2}
                            data-testid="description"
                            {...field}
                            style={{
                              fontFamily: "inherit",
                              fontSize: "inherit",
                            }}
                            id="description"
                            value={props.values.description}
                            fontSize="16px"
                            resize="vertical"
                          />
                          <FormErrorMessage
                            style={{ height: "17px", marginBottom: "-25px" }}
                          >
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="skillTags">
                      {({ field, form }: any) => (
                        <FormControl
                          marginBottom="32px"
                          isInvalid={
                            form.errors.skillTags && form.touched.skillTags
                          }
                        >
                          <FormLabel color="fontBlack" htmlFor="skillTags">
                            Skill tags
                          </FormLabel>
                          <FormHelperText color="fontDarkGray" mb={2}>
                            Tags added here will be added to the pitch
                          </FormHelperText>
                          <TagInput
                            testId="skillTags"
                            tags={field.value}
                            suggestions={[...SkillTagSuggestions, ...tags]}
                            onTagUpdate={(tags) => {
                              props.setFieldValue("skillTags", tags)
                              //@ts-ignore
                              setSkillTags(tags)
                            }}
                          />
                          <FormErrorMessage
                            style={{ height: "17px", marginBottom: "-25px" }}
                          >
                            {form.errors.skillTags}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Flex justify="space-between">
                      <Field name="member">
                        {({ field, form }: any) => {
                          const usersWithTags = users?.map((user) => {
                            const matchedTags: ISkillTag[] = []

                            //@ts-ignore
                            user?.tags?.map((userTag) => {
                              if (
                                form.values.skillTags.filter(
                                  (tag: any): any =>
                                    tag.text.toLowerCase() ===
                                    userTag.id.toLowerCase()
                                ).length > 0
                              ) {
                                matchedTags.push(userTag)
                              }
                            })
                            return { ...user, matchedTags }
                          })

                          return (
                            <FormControl
                              isInvalid={
                                form.errors.member && form.touched.member
                              }
                              marginBottom="16px"
                              w="100%"
                            >
                              <FormLabel color="fontBlack" htmlFor="member">
                                Assigned Members
                              </FormLabel>
                              <Flex>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "grid",
                                    marginRight: "8px",
                                    gridAutoFlow: "column",
                                    overflowY: "auto",
                                    overscrollBehaviorX: "contain",
                                    scrollSnapType: "x mandatory",
                                    background: "#FBFBFB",
                                    gap: "8px",
                                    borderRadius: "8px",
                                    padding: "8px",
                                    placeContent: "flex-start",
                                  }}
                                >
                                  {props.values.member.map((member, i) => {
                                    const user = usersWithTags?.find(
                                      (user) => user?.uid === member
                                    )
                                    const isChampion = pitch?.champions?.find(
                                      (uid: any) => uid === user?.uid
                                    )
                                    return (
                                      <Flex
                                        shadow="md"
                                        direction="column"
                                        key={user?.uid}
                                        backgroundColor="backgroundWhite"
                                        borderWidth="1px"
                                        flex="1"
                                        rounded="lg"
                                        py="16px"
                                        minHeight="236px"
                                        width="148px"
                                      >
                                        <Flex justify="center">
                                          <Avatar
                                            key={`${user?.firstName}${user?.lastName}`}
                                            h={"48px"}
                                            w={"48px"}
                                            name={
                                              user?.firstName +
                                              " " +
                                              user?.lastName
                                            }
                                            background={gradient(
                                              user?.lastName
                                            )}
                                            src={user?.photo}
                                          />
                                        </Flex>
                                        <Flex direction="column" mx="16px">
                                          <Text color="fontBlack" fontSize="md">
                                            {user?.firstName +
                                              " " +
                                              user?.lastName}
                                          </Text>
                                          <Text
                                            color="fontDarkGray"
                                            fontSize="14px"
                                          >
                                            {user?.position}
                                          </Text>
                                          {isChampion ? (
                                            <Badge
                                              variant="solid"
                                              variantColor={
                                                theme.colors.championBadge
                                              }
                                              w="78px"
                                              my="4px"
                                            >
                                              champion
                                            </Badge>
                                          ) : (
                                            <Badge
                                              variant="solid"
                                              variantColor={
                                                theme.colors.collaboratorBadge
                                              }
                                              w="108px"
                                              my="4px"
                                            >
                                              collaborator
                                            </Badge>
                                          )}
                                          <Flex flexWrap="wrap">
                                            {user?.matchedTags.map(
                                              (skill, i) => (
                                                <Tag
                                                  mb={2}
                                                  mr={2}
                                                  p={2}
                                                  px={4}
                                                  fontSize="sm"
                                                  key={i}
                                                  textAlign="start"
                                                  color="fontDarkgray"
                                                  variant={"outline"}
                                                >
                                                  {skill.text}
                                                </Tag>
                                              )
                                            )}
                                          </Flex>
                                        </Flex>
                                      </Flex>
                                    )
                                  })}
                                </div>
                              </Flex>
                              {showMemberSearch ||
                              props.values.member.length === 0 ? (
                                <>
                                  <Flex width="100%">
                                    <InputGroup mb="22px" h="48px" width="100%">
                                      <HInput
                                        isDisabled={readOnly}
                                        placeholder="Search names or skills"
                                        value={searchFilter}
                                        onChange={(e: any) =>
                                          setSearchFilter(e.target.value)
                                        }
                                        data-testid="search-pitch-members"
                                      ></HInput>
                                      <InputRightElement>
                                        <Icon
                                          name="search-2"
                                          mt="4px"
                                          color="#BABABA"
                                        />
                                      </InputRightElement>
                                    </InputGroup>
                                    <Flex justify="center">
                                      <IconButton
                                        aria-label="Close search members"
                                        icon="small-close"
                                        height="48px"
                                        ml="4px"
                                        onClick={() =>
                                          setShowMemberSearch(false)
                                        }
                                      >
                                        X
                                      </IconButton>
                                    </Flex>
                                  </Flex>
                                  <Stack
                                    overflowY="scroll"
                                    spacing="12px"
                                    maxH="200px"
                                    w="100%"
                                  >
                                    {allUsers.map((user, i) => {
                                      if (!user) {
                                        return <></>
                                      }

                                      const isChampion = pitch?.champions?.find(
                                        (uid: any) => uid === user?.uid
                                      )

                                      const isMember = pitch?.members?.find(
                                        (uid: any) => uid === user?.uid
                                      )

                                      const userTags =
                                        //@ts-ignore
                                        usersWithTags?.find(
                                          ({ uid }) => uid === user?.uid
                                        ).matchedTags || []

                                      return (
                                        <Flex
                                          key={user?.uid || user?.lastName}
                                          justify="space-between"
                                          w="100%"
                                        >
                                          <Flex
                                            cursor="pointer"
                                            align="center"
                                            mb={4}
                                            data-testid={`member-${i}`}
                                            onClick={(e: any) => {
                                              //@ts-ignore
                                              setSelectedPerson(user)
                                              onOpenMini()
                                            }}
                                          >
                                            <Avatar
                                              name={`${user?.firstName} ${user?.lastName}`}
                                              h="40px"
                                              w="40px"
                                              mr={2}
                                              src={user?.photo}
                                              background={gradient(
                                                user?.lastName || user?.uid
                                              )}
                                            />
                                            <Flex
                                              direction="column"
                                              h="100%"
                                              justify="space-between"
                                              mr="36px"
                                            >
                                              <HText fontSize="sm">
                                                {user?.firstName +
                                                  " " +
                                                  user?.lastName}
                                              </HText>
                                              <HText
                                                fontSize="xs"
                                                color="fontSmall"
                                              >
                                                {user?.position}
                                              </HText>
                                              <Flex flexWrap="wrap">
                                                {
                                                  //@ts-ignore
                                                  usersWithTags
                                                    ?.find(
                                                      ({ uid }) =>
                                                        uid === user.uid
                                                    )
                                                    .matchedTags.map(
                                                      (skill, i) => (
                                                        <Tag
                                                          mb={2}
                                                          mr={2}
                                                          p={2}
                                                          px={4}
                                                          fontSize="sm"
                                                          key={i}
                                                          textAlign="start"
                                                          color="fontDarkgray"
                                                          variant={"outline"}
                                                        >
                                                          {skill.text}
                                                        </Tag>
                                                      )
                                                    )
                                                }
                                              </Flex>
                                            </Flex>
                                          </Flex>
                                          <Flex
                                            direction="column"
                                            align="flex-end"
                                          >
                                            {isChampion ? (
                                              <Badge
                                                variant="solid"
                                                backgroundColor={
                                                  theme.colors.championBadge
                                                }
                                                w="78px"
                                              >
                                                champion
                                              </Badge>
                                            ) : isMember ? (
                                              <Badge
                                                variant="solid"
                                                backgroundColor={
                                                  theme.colors.collaboratorBadge
                                                }
                                                w="108px"
                                              >
                                                collaborator
                                              </Badge>
                                            ) : (
                                              <HText fontSize="sm">
                                                Will be Invited to Pitch
                                              </HText>
                                            )}
                                            <Link
                                              color="textLink"
                                              fontSize="sm"
                                              mr="4px"
                                              onClick={() => {
                                                setIsMemberPopOpen(false)
                                                props.setFieldValue("member", [
                                                  ...props.values.member,
                                                  user?.uid,
                                                ])
                                              }}
                                            >
                                              Add to Task
                                            </Link>
                                          </Flex>
                                        </Flex>
                                      )
                                    })}
                                  </Stack>
                                </>
                              ) : (
                                <Button
                                  onClick={() => setShowMemberSearch(true)}
                                >
                                  Search Members
                                </Button>
                              )}

                              <FormErrorMessage
                                style={{
                                  height: "17px",
                                  marginBottom: "-25px",
                                }}
                              >
                                {form.errors.member}
                              </FormErrorMessage>
                            </FormControl>
                          )
                        }}
                      </Field>
                    </Flex>
                    <Flex direction="column" mb="8px">
                      {props.values.member.map((member) => {
                        const memberOfPitch = pitch?.members.find(
                          (pitchMember) => pitchMember === member
                        )
                        if (!memberOfPitch) {
                          const user = users?.find(
                            (user) => user.uid === member
                          )
                          return (
                            <HText fontSize="sm">
                              <Icon name="info-outline" />
                              {" " + user?.firstName + " "}is not a member of
                              the pitch yet. An invite will be sent.
                            </HText>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Flex>

                    <Flex direction="column">
                      <FormLabel color="fontBlack" htmlFor="dueDate">
                        Task points
                      </FormLabel>
                      <HText fontSize="sm" mb="8px">
                        An effort required to complete this task.
                      </HText>
                      <Flex
                        mb="32px"
                        marginRight="auto"
                        backgroundColor="rgba(9, 19, 33, 0.0638304)"
                        padding="6px"
                      >
                        <Button
                          type="button"
                          onClick={() => (readOnly ? {} : setDifficulty(1))}
                          backgroundColor={
                            difficulty === 1 ? "darkBlue" : "transparent"
                          }
                          color={difficulty === 1 ? "white" : "darkBlue"}
                          w="108px"
                        >
                          {`1 `}
                          <Text
                            ml="8px"
                            as="span"
                            fontSize="sm"
                            fontWeight="400"
                          >
                            Easy
                          </Text>
                        </Button>
                        <Button
                          type="button"
                          onClick={() => (readOnly ? {} : setDifficulty(2))}
                          backgroundColor={
                            difficulty === 2 ? "darkBlue" : "transparent"
                          }
                          color={difficulty === 2 ? "white" : "darkBlue"}
                          w="108px"
                        >
                          {`2 `}
                          <Text
                            ml="8px"
                            as="span"
                            fontSize="sm"
                            fontWeight="400"
                          >
                            Medium
                          </Text>
                        </Button>
                        <Button
                          type="button"
                          onClick={() => (readOnly ? {} : setDifficulty(3))}
                          backgroundColor={
                            difficulty === 3 ? "darkBlue" : "transparent"
                          }
                          color={difficulty === 3 ? "white" : "darkBlue"}
                          w="108px"
                        >
                          {`3 `}
                          <Text
                            ml="8px"
                            as="span"
                            fontSize="sm"
                            fontWeight="400"
                          >
                            Hard
                          </Text>
                        </Button>
                      </Flex>
                    </Flex>
                    {viewAttachments ? (
                      <Field name="attachments">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.attachments &&
                              form.touched.attachments
                            }
                            marginBottom="32px"
                          >
                            <FormLabel color="fontBlack" htmlFor="attachments">
                              Attachments (optional)
                            </FormLabel>

                            <Flex direction="column">
                              {!readOnly && (
                                <Dropzone
                                  files={files}
                                  setFiles={setFiles}
                                  path={`${company}/pitches/${pitch?.id}/task-attachments`}
                                />
                              )}
                              <Stack mt="2">
                                {links &&
                                  links.map((link, i) => {
                                    return (
                                      <Flex
                                        key={i}
                                        w="100%"
                                        minH={12}
                                        p={4}
                                        backgroundColor="rgba(9, 19, 33, 0.0638304)"
                                        justify="space-between"
                                        align="center"
                                      >
                                        <Link
                                          fontSize="sm"
                                          href={link.url}
                                          isExternal
                                        >
                                          {link.url}
                                        </Link>
                                        {!readOnly && (
                                          <IconButton
                                            aria-label="remove image"
                                            icon="close"
                                            borderRadius="100%"
                                            variant="ghost"
                                            size="xs"
                                            color="fontBlack"
                                            onClick={() => {
                                              const currentLinks = [...links]
                                              const index =
                                                currentLinks.findIndex(
                                                  (searchLink) =>
                                                    searchLink.url === link.url
                                                )

                                              index > -1 &&
                                                currentLinks.splice(index, 1)
                                              setLinks(currentLinks)
                                            }}
                                          />
                                        )}
                                      </Flex>
                                    )
                                  })}
                              </Stack>
                              {!readOnly && !openLink && (
                                <Flex mt={4} justify="center">
                                  <HText fontSize="sm">
                                    You can also{" "}
                                    <Link
                                      color="textLink"
                                      onClick={() => setOpenLink(true)}
                                      data-testid="add-link"
                                    >
                                      add a link
                                    </Link>
                                  </HText>
                                </Flex>
                              )}
                              {openLink && (
                                <Formik
                                  initialValues={{ url: "", title: "" }}
                                  onSubmit={(values) => {
                                    const newLinks = [...links]
                                    newLinks.push({
                                      url: values.url,
                                      name: values.title,
                                      isLink: true,
                                    })
                                    setLinks(newLinks)
                                    setOpenLink(false)
                                  }}
                                >
                                  {({ values, setFieldValue }) => (
                                    <Form>
                                      <HHeading fontSize="md" mb={2}>
                                        URL
                                      </HHeading>

                                      <HInput
                                        data-testid="link-url"
                                        value={values.url}
                                        onChange={(e: any) => {
                                          setFieldValue("url", e.target.value)
                                        }}
                                      />
                                      <HHeading fontSize="md" mt={8} mb={2}>
                                        Title (optional)
                                      </HHeading>
                                      <HInput
                                        mb="16px"
                                        data-testid="link-title"
                                        value={values.title}
                                        onChange={(e: any) => {
                                          setFieldValue("title", e.target.value)
                                        }}
                                      />
                                      <Flex w="100%" justify="center">
                                        <Button
                                          type="button"
                                          rounded="full"
                                          backgroundColor="white"
                                          mr={2}
                                          onClick={() => setOpenLink(false)}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          data-testid="link-modal-submit"
                                          type="submit"
                                          rounded="full"
                                          backgroundColor="darkBlue"
                                          color="white"
                                        >
                                          Add
                                        </Button>
                                      </Flex>
                                    </Form>
                                  )}
                                </Formik>
                              )}
                            </Flex>

                            <FormErrorMessage
                              style={{ height: "17px", marginBottom: "-25px" }}
                            >
                              {form.errors.attachments}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    ) : (
                      <PitchActionButton
                        onClick={() => setViewAttachments(true)}
                      >
                        Add Attachments
                      </PitchActionButton>
                    )}
                  </ModalBody>
                  <ModalFooter w="100%" paddingBottom={10} paddingX={10}>
                    <Flex w="100%" flexGrow={1} justify="center">
                      <Box></Box>
                      <Flex justify="center" w="100%">
                        <Button
                          type="button"
                          rounded="full"
                          variant="ghost"
                          mr={2}
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        {!readOnly && editTask && !task?.isCompleted && (
                          <FillButton type="submit" data-testid="add-task">
                            {task ? "Update" : "Add"}
                          </FillButton>
                        )}
                      </Flex>
                    </Flex>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        )}
      </Modal>
    </>
  )
}
