/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useDebugValue,
} from "react"
import {
  Box,
  Button,
  ButtonGroup,
  Link as ChakraLink,
  Flex,
  Icon,
  Stack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  SimpleGrid,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
  useDisclosure,
} from "@chakra-ui/core"
import { Field, Formik } from "formik"
import { TagInput } from "../../Components/TagInput"
import { useNavigate, useParams } from "react-router-dom"
import { PitchCard } from "../../Components/PitchCard"
import { StyledTab } from "../../Components/StyledTextComponents"
import { isMobile } from "../../Components/isMobile"
import { HHeading, HText } from "../../Components/StyledTextComponents"

import theme from "../../theme"

import { FriendlyModal } from "../../Components/FriendlyModal"
import { useWindowDimensions } from "../../useWindowDimensions"
import { required } from "../../formUtils"

import { useAuth } from "../../Context/auth-context"
import { useCompany } from "../../Context/company-context"
import { usePitches } from "../../Context/pitches-context"

import {
  usePitchOrder,
  PitchOrderProvider,
} from "../../Context/pitchOrder-context"

import { FillButton } from "../../Components/Buttons"
import { useWorkspaces } from "../../Context/workspace-context"
import { PitchStatus, InviteStatuses, IPitch } from "../../types"
import { DashboardContainer } from "./DashboardWrapper"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { urls } from "../../App"

import { PitchIntroModal } from "./modals/PitchIntroModal"
import { Requests } from "./Requests"
import { SearchResultPitches } from "../Search"

import * as R from "ramda"
import { CreatePitch } from "../../Components/CreatePitch"

// a little function to help us with reordering the result
const reorder = (list: IPitch[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // change background colour if dragging
  marginRight: "1.5em",
  // styles we need to apply on draggables
  ...draggableStyle,
})

const TourBox = ({
  title,
  buttonAction,
  bodyText,
  bodyText2,
  buttonText,
  isCompleted,
  ...rest
}: any) => (
  <Box
    d="flex"
    flexDirection="column"
    as="button"
    padding="24px"
    borderRadius="4px"
    bg="white"
    mt="8px"
    flex="auto"
    minWidth="170px"
    maxWidth="360px"
    width="33%"
    marginRight="16px"
  >
    <Text
      fontWeight="600"
      mb="8px"
      fontSize="md"
      color="#333333"
      w="100%"
      h="auto"
      textAlign="left"
      opacity={isCompleted ? 0.7 : 1}
    >
      {title}
    </Text>
    <Text
      fontSize="sm"
      color="fontDarkGray"
      w="100%"
      h="auto"
      textAlign="left"
      opacity={isCompleted ? 0.7 : 1}
    >
      {bodyText}
    </Text>
    {bodyText2 ? (
      <Text
        fontSize="sm"
        color="fontDarkGray"
        w="100%"
        h="auto"
        textAlign="left"
        opacity={isCompleted ? 0.7 : 1}
      >
        {bodyText2}
      </Text>
    ) : null}
    <Flex
      justify="flex-end"
      pt="8"
      direction="column"
      align="flex-end"
      height="100%"
      width="100%"
    >
      <Button variantColor="teal" size="sm" onClick={buttonAction} {...rest}>
        {buttonText}
      </Button>
    </Flex>
  </Box>
)

interface GetStartedProps {
  setTourStage: Dispatch<SetStateAction<number>>
  setPitchIntro: Dispatch<SetStateAction<number>>
  tourStage: number
}
const GetStartedBox: React.FC<GetStartedProps> = ({
  setTourStage,
  setPitchIntro,
  tourStage,
}) => {
  const { updateUserProfile } = useAuth()
  const isMobileDevice = isMobile()
  return isMobileDevice ? (
    <></>
  ) : (
    <Box
      bg="darkBlue"
      paddingY="24px"
      paddingX="32px"
      borderRadius={"8px"}
      data-testid="getStartedBox"
      width="100%"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.05);"
    >
      <Flex justify="space-between" mb="8px">
        <Text color="white" fontSize="22px" fontWeight="600">
          Get started
        </Text>
        <IconButton
          color="db"
          bg="#B3B3B3"
          borderRadius="100%"
          fontWeight="800"
          aria-label="Close get started"
          fontSize="10px"
          size="xs"
          data-testid="close-getStarted"
          onClick={() => {
            setTourStage(0)
            updateUserProfile({ finishedDashboardTutorial: true })
          }}
          icon="close"
        />
      </Flex>
      <Text color="white" fontSize="md" mb="8px">
        Great to have you on board! Feel free to explore, or get a head start
        below.
      </Text>
      <Flex marginRight="-16px" flexWrap="wrap">
        <TourBox
          title={"Review your Bio"}
          bodyText={
            "Tags used in your bio will send you a notification when matched with tags in a pitch or task."
          }
          buttonAction={() => {
            window.location.href = `${window.location.origin}/${
              window.location.pathname.split("/")[1]
            }/profile`
          }}
          buttonText={"Review"}
        />

        <TourBox
          data-testid="startTour"
          title={"Take the Mini Tour"}
          bodyText={`There's so much to see and do in your workspace.`}
          bodyText2={`Start exploring now by taking the mini tour.
            `}
          buttonAction={() => setTourStage(1)}
          buttonText={"Tour"}
        />
        <TourBox
          title={"Create"}
          bodyText={`
            Create your first pitch on Huminate.`}
          bodyText2={`Find people to help you.`}
          buttonAction={() => setPitchIntro(1)}
          buttonText={"Get Started"}
        />
      </Flex>
    </Box>
  )
}

const DashboardHome: React.FC = () => {
  const { userProfile, updateUserProfile } = useAuth()
  const { companyUrl: company } = useCompany()
  const { currentWorkspace, setCurrentWorkspaceBySlug } = useWorkspaces()
  const navigate = useNavigate()
  const [draftPitches, setDraftPitches] = useState<IPitch[]>([])
  const [inProgressPitches, setInProgressPitches] = useState<IPitch[]>([])
  const [completePitches, setCompletePitches] = useState<IPitch[]>([])
  const [approvalRequests, setApprovalRequests] = useState<IPitch[]>([])
  const [followedPitches, setFollowedPitches] = useState<IPitch[]>([])

  const [pitches, setPitches] = useState<IPitch[] | undefined>([])
  const {
    pitches: pitchesToBeFiltered,
    createPitch,
    allMyInvites,
    updateInvite,
  } = usePitches()
  console.log("render")
  //@ts-ignore
  const { workspace: workspaceSlug } = useParams()
  const [tourStage, setTourStage] = useState(0)
  const [pitchIntro, setPitchIntro] = useState(0)
  const [openCreatePitchModal, setOpenCreatePitchModal] = useState(0)
  const { onOpen, onClose, isOpen } = useDisclosure()

  const isMobileDevice = isMobile()

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#E2E8F0" : theme.colors.backgroundLight,
    display: "flex",
    padding: "0px",
    overflow: "auto",
    width: "auto",
    marginBottom: "8px",
    height: "300px",
  })

  const DragNDropGrid = ({
    dragPitches,
    pitchTab,
  }: {
    dragPitches?: IPitch[]
    pitchTab: string
  }) => {
    const { pitchOrder, updatePitchOrder } = usePitchOrder()
    const isMobileDevice = isMobile()
    const { width } = useWindowDimensions()

    if (pitchOrder && dragPitches && dragPitches.length) {
      //@ts-ignore
      if (pitchOrder[pitchTab]) {
        dragPitches.sort(
          //@ts-ignore
          (a, b) =>
            //@ts-ignore
            pitchOrder[pitchTab].indexOf(a.id) -
            //@ts-ignore
            pitchOrder[pitchTab].indexOf(b.id)
        )
      }
    }

    const NUMBER_PER_ROW = width > 1390 ? 4 : width > 1060 ? 3 : 2

    const [pitches, setPitches] = useState(dragPitches)
    const pitchRows = R.splitEvery(NUMBER_PER_ROW, pitches || [])

    const onDragEnd = (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return <></>
      }

      if (!pitches) {
        return <></>
      }
      const sourceRowIdx = parseInt(result.source.droppableId.split("-")[1], 10)
      const destinationRowIdx = parseInt(
        result.destination.droppableId.split("-")[1],
        10
      )
      const currentIndex = sourceRowIdx * NUMBER_PER_ROW + result.source.index
      let resultIndex = sourceRowIdx * NUMBER_PER_ROW + result.destination.index
      if (sourceRowIdx !== destinationRowIdx) {
        resultIndex =
          destinationRowIdx * (NUMBER_PER_ROW - 1) + result.destination.index
      }
      const items: IPitch[] = reorder(pitches, currentIndex, resultIndex)
      updatePitchOrder({ [pitchTab]: items.map(({ id }) => id) })
      setPitches(items)
    }

    if (isMobileDevice && pitches) {
      return (
        <Stack spacing={6} align="center">
          {pitches.map((pitch) => (
            <PitchCard
              data-testid={`pitch-${pitch.title}`}
              pitch={pitch}
              key={pitch.id}
            />
          ))}
        </Stack>
      )
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        {/* Draft Tab */}
        {pitchRows.map((pitchRow, i) => (
          <Flex mt={18} key={i}>
            <Droppable droppableId={`droppable-${i}`} direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {pitchRow &&
                    pitchRow.map((pitch, index) => {
                      return (
                        <Draggable
                          key={pitch.id}
                          draggableId={pitch.id || "1"}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <PitchCard
                                data-testid={`pitch-${pitch.title}`}
                                pitch={pitch}
                                key={pitch.id}
                              />
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Flex>
        ))}
      </DragDropContext>
    )
  }

  useEffect(() => {
    if (currentWorkspace && userProfile) {
      setPitches(
        pitchesToBeFiltered
          ?.filter((pitch) => pitch.workspaceUID === currentWorkspace.id)
          ?.filter(
            (pitch) =>
              pitch.members.indexOf(userProfile.uid) !== -1 ||
              pitch?.manager?.uid === userProfile.uid
          )
      )
    }
  }, [currentWorkspace, pitchesToBeFiltered])

  useEffect(() => {
    if (pitches && userProfile && pitchesToBeFiltered) {
      const draftPitches = pitches?.filter(
        (pitch) =>
          pitch.status === PitchStatus.draft ||
          pitch.status === PitchStatus.pendingApproval ||
          pitch.status === PitchStatus.declined ||
          pitch.status === PitchStatus.overdueSubmission
      )
      setDraftPitches(draftPitches)

      setCompletePitches(
        pitches?.filter((pitch) => pitch.status === PitchStatus.complete)
      )
      setInProgressPitches(
        pitches?.filter(
          (pitch) =>
            pitch.status === PitchStatus.inProgress ||
            pitch.status === PitchStatus.overdue
        )
      )
      setFollowedPitches(
        pitchesToBeFiltered?.filter((pitch) =>
          userProfile?.followedPitches?.find((id) => id === pitch.id)
        )
      )
      setApprovalRequests(
        pitchesToBeFiltered.filter(
          (pitch) =>
            pitch.status === PitchStatus.pendingApproval &&
            pitch.manager?.uid === userProfile?.uid
        )
      )
    }
  }, [pitches && pitches.length, userProfile, pitchesToBeFiltered])

  if (userProfile === undefined) {
    return <></>
  }

  let recommendedPitches: IPitch[] | undefined = []

  console.log({ followedPitches })

  if (userProfile && pitchesToBeFiltered && pitches && pitches.length === 0) {
    //@ts-ignore
    recommendedPitches = pitchesToBeFiltered
      .filter(({ workspaceUID }) => currentWorkspace?.id === workspaceUID)
      .filter(({ skillTags }) => {
        let match = false
        skillTags.filter(({ tag }) => {
          let match2 = false
          userProfile?.tags?.map(({ id }) => {
            if (tag.id === id) {
              match2 = true
            }
          })
          return match2
        })
        if (skillTags.length > 0) {
          match = true
        }
        return match
      })
  }

  if (!currentWorkspace) {
    return <></>
  }

  return (
    <>
      <CreatePitch
        isOpen={openCreatePitchModal}
        onClose={() => setOpenCreatePitchModal(0)}
      />
      <DashboardContainer>
        <Flex direction="column" maxW="1374px" marginX="auto">
          {/* this dims the screen when the tour is running */}
          {tourStage !== 0 && (
            <Box
              width="100vw"
              height="calc(100vh + 300px)"
              left="0"
              top="0"
              position="absolute"
              onClick={() => {
                setTourStage(0)
              }}
              backgroundColor="rgba(0, 0, 0, 0.3);"
              zIndex={9998}
            />
          )}
          <Popover
            returnFocusOnClose={false}
            isOpen={tourStage === 2}
            onClose={() => setTourStage(0)}
            placement="bottom"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  left: "350px",
                  top: "0%",
                  height: "0px",
                }}
              />
            </PopoverTrigger>
            <PopoverContent
              zIndex={9999}
              background="#203854"
              borderColor="#203854"
            >
              <PopoverArrow />
              <PopoverCloseButton
                color="db"
                bg="#B3B3B3"
                borderRadius="100%"
                mt="4px"
              />
              <PopoverBody>
                <Text
                  as="b"
                  color="rgba(255, 255, 255, 0.8)"
                  my="16px"
                  fontSize="lg"
                >
                  Company Search
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  Search for the abilities you need to build teams across the
                  company.
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  Each bio profiles unique expreience, passion and strenghts
                  that may be useful when planning your next initiative.
                </Text>
                <Flex justify="space-between" align="center">
                  <Text color="rgba(255, 255, 255, 0.8)">2/5</Text>
                  <ButtonGroup size="sm">
                    <Button
                      variantColor="teal"
                      borderRadius="16px"
                      margin="8px"
                      data-testid="next-tour-step"
                      onClick={() => {
                        setTourStage(3)
                      }}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover
            returnFocusOnClose={false}
            isOpen={tourStage === 1}
            onClose={() => setTourStage(0)}
            placement="top"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  right: "460px",
                  top: "0%",
                  height: "0px",
                }}
              />
            </PopoverTrigger>
            <PopoverContent
              zIndex={9999}
              background="#203854"
              borderColor="#203854"
            >
              <PopoverArrow />
              <PopoverCloseButton
                color="db"
                bg="#B3B3B3"
                borderRadius="100%"
                mt="4px"
              />
              <PopoverBody>
                <Text
                  as="b"
                  fontSize="lg"
                  color="rgba(255, 255, 255, 0.8)"
                  my="16px"
                >
                  See existing Initiatives
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  Get involved in company wide initiatives by seeking out new
                  teams to join and expand your experience.
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  Like, follow and comment to show your support.
                </Text>
                <Flex justify="space-between" align="center">
                  <Text color="rgba(255, 255, 255, 0.8)">1/5</Text>
                  <ButtonGroup size="sm">
                    <Button
                      variantColor="teal"
                      borderRadius="16px"
                      margin="8px"
                      data-testid="next-tour-step"
                      onClick={() => {
                        setTourStage(2)
                      }}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover
            returnFocusOnClose={false}
            isOpen={tourStage === 4}
            onClose={() => setTourStage(0)}
            placement="bottom-end"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  right: "160px",
                  top: "0%",
                  height: "0px",
                }}
              />
            </PopoverTrigger>
            <PopoverContent
              zIndex={9999}
              background="#203854"
              borderColor="#203854"
            >
              <PopoverArrow />
              <PopoverCloseButton
                color="db"
                bg="#B3B3B3"
                borderRadius="100%"
                mt="4px"
              />
              <PopoverBody>
                <Text
                  as="b"
                  fontSize="lg"
                  color="rgba(255, 255, 255, 0.8)"
                  my="16px"
                >
                  {`Actions & Notifications`}
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  Stay up to date with requests, actions and notifications here
                  as you who up here as you start getting involved.
                </Text>
                <Flex justify="space-between" align="center">
                  <Text color="rgba(255, 255, 255, 0.8)">4/5</Text>
                  <ButtonGroup size="sm">
                    <Button
                      variantColor="teal"
                      borderRadius="16px"
                      margin="8px"
                      data-testid="next-tour-step"
                      onClick={() => {
                        setTourStage(5)
                      }}
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Popover
            returnFocusOnClose={false}
            isOpen={tourStage === 5}
            onClose={() => setTourStage(0)}
            placement="bottom-end"
            closeOnBlur={false}
          >
            <PopoverTrigger>
              <div
                style={{
                  position: "absolute",
                  right: "64px",
                  top: "0%",
                  height: "0px",
                }}
              />
            </PopoverTrigger>
            <PopoverContent
              zIndex={9999}
              background="#203854"
              borderColor="#203854"
            >
              <PopoverArrow />
              <PopoverCloseButton
                color="db"
                bg="#B3B3B3"
                borderRadius="100%"
                mt="4px"
              />
              <PopoverBody>
                <Text
                  as="b"
                  color="rgba(255, 255, 255, 0.8)"
                  my="16px"
                  fontSize="lg"
                >
                  Multiple Workspaces
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  Swap between different workspaces within your company.
                </Text>
                <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                  View, search and get involved in company wide initiatives.
                </Text>
                <Flex justify="space-between" align="center">
                  <Text color="rgba(255, 255, 255, 0.8)">5/5</Text>
                  <ButtonGroup size="sm">
                    <Button
                      variantColor="teal"
                      borderRadius="16px"
                      margin="8px"
                      data-testid="next-tour-step"
                      onClick={() => {
                        setTourStage(0)
                      }}
                    >
                      close
                    </Button>
                  </ButtonGroup>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Box>
            {!userProfile?.finishedDashboardTutorial && (
              <GetStartedBox
                setTourStage={setTourStage}
                tourStage={tourStage}
                setPitchIntro={setPitchIntro}
              />
            )}
            <Flex align="center" mb="20px">
              <Text p="0px">
                Welcome to Huminate, {userProfile?.firstName}
                <span role="img" aria-label="wave">
                  &nbsp;👋
                </span>
              </Text>
              <Popover
                returnFocusOnClose={false}
                isOpen={tourStage === 3}
                onClose={() => setTourStage(0)}
                placement="right"
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  {isMobileDevice ? (
                    <></>
                  ) : (
                    <FillButton
                      ml="16px"
                      data-testid="dashboard-create-pitch"
                      onClick={() => {
                        window.sessionStorage.removeItem("pitchDraft")
                        setOpenCreatePitchModal(1)
                      }}
                    >
                      Create
                    </FillButton>
                  )}
                </PopoverTrigger>
                <PopoverContent
                  zIndex={9999}
                  background="#203854"
                  borderColor="#203854"
                >
                  <PopoverArrow />
                  <PopoverCloseButton
                    color="db"
                    bg="#B3B3B3"
                    borderRadius="100%"
                    mt="4px"
                  />
                  <PopoverBody>
                    <Text
                      as="b"
                      color="rgba(255, 255, 255, 0.8)"
                      my="16px"
                      fontSize="lg"
                    >
                      Create new initiatives
                    </Text>
                    <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                      Add initiatives using the guided wizard or skip the wizard
                      and go right to the editor.
                    </Text>
                    <Text color="rgba(255, 255, 255, 0.8)" my="16px">
                      Initiatives start as a public draft until submitted and
                      approved bu the designated manager.
                    </Text>
                    <Flex justify="space-between" align="center">
                      <Text color="rgba(255, 255, 255, 0.8)">3/5</Text>
                      <ButtonGroup size="sm">
                        <Button
                          variantColor="teal"
                          borderRadius="16px"
                          margin="8px"
                          data-testid="next-tour-step"
                          onClick={() => {
                            setTourStage(4)
                          }}
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
            <SearchResultPitches />
          </Box>
          <PitchIntroModal
            isOpen={pitchIntro > 0}
            pitchIntro={pitchIntro}
            onClickNext={() => setPitchIntro(pitchIntro + 1)}
            onClickCreate={() => {
              setOpenCreatePitchModal(1)
            }}
            onClose={() => setPitchIntro(0)}
          />
        </Flex>
      </DashboardContainer>
    </>
  )
}

export default DashboardHome
