/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Flex,
  Link,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  Divider,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  SimpleGrid,
  Text,
  MenuItem,
  Stack,
  Grid,
  FlexProps,
  SimpleGridProps,
  IconButton,
} from "@chakra-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import { ActivityMenuItem } from "../../Components/ActivityCard"
import { FriendlyModal } from "../../Components/FriendlyModal"
import { CreateAPitchOneView } from "../CreateAPitch/CreateAPitch1"
import { CreateAPitchTwoView } from "../CreateAPitch/CreateAPitch2"
import {
  CreateAPitchThreeOneView,
  CreateAPitchThreeTwoView,
} from "../CreateAPitch/CreateAPitch3"
import { CreateAPitchFourView } from "../CreateAPitch/CreateAPitch4"
import { useActivities } from "../../Context/activities-context"
import { useWorkspaces } from "../../Context/workspace-context"
import { usePitches } from "../../Context/pitches-context"
import { useAuth } from "../../Context/auth-context"

import { useCompany } from "../../Context/company-context"
import Brand from "../../Components/Images/huminate-logo_R_blue.png"
import { HText } from "../../Components/StyledTextComponents"
import { useWindowDimensions } from "../../useWindowDimensions"

import { isMobile } from "../../Components/isMobile"
import { ProfileButton } from "./ProfileButton"
import { urls } from "../../App"
import { IWorkspace, IActivity, IPitch } from "../../types"
import { firebaseApp } from "../../firebase"
import {
  FaLightbulb,
  FaPlusCircle,
  FaUserFriends,
  FaTh,
  FaBriefcase,
  FaGripHorizontal,
  FaBell,
} from "react-icons/fa"

import { BsFillPeopleFill } from "react-icons/bs"

//@ts-ignore
import gradient from "random-gradient"
// eslint-disable-next-line react/display-name
const MenuDropdownButton = React.forwardRef((props: ButtonProps, ref) => (
  <Button ref={ref} variant="ghost" rightIcon="chevron-down" {...props} />
))

const WorkspaceBar = () => {
  const { companyUrl: company } = useCompany()
  const { workspaces, currentWorkspace, setCurrentWorkspace } = useWorkspaces()
  const isMobileDevice = isMobile()
  console.log({ currentWorkspace })
  return (
    <Menu>
      <MenuButton
        width="100%"
        background="#f1f1f1"
        p="4px"
        justifyContent="flex-start"
        borderBottom="1px solid #dddddd"
        maxWidth="100vw"
      >
        <Flex
          justify="flex-start"
          align="center"
          ml={isMobileDevice ? "4px" : "18px"}
          px={isMobileDevice ? "0px" : "1rem"}
        >
          <HText whiteSpace="nowrap" as="b">
            {currentWorkspace?.isShared && (
              <BsFillPeopleFill style={{ marginRight: "4px" }} />
            )}
          </HText>
          <HText
            whiteSpace="nowrap"
            as="b"
          >{`${currentWorkspace?.name}`}</HText>
          <HText
            ml="6px"
            whiteSpace="nowrap"
            overflow="hidden"
            //@ts-ignore
            textOverflow="ellipsis"
          >{`| ${currentWorkspace?.description}`}</HText>
        </Flex>
      </MenuButton>
      <MenuList
        ml={isMobileDevice ? "2px" : "36px"}
        p="4px"
        placement="bottom-start"
      >
        {workspaces &&
          workspaces.map((workspace: IWorkspace) => (
            <ReactRouterLink
              key={workspace.id}
              to={`/${urls(company, workspace.slug).dashboard}`}
              onClick={() => setCurrentWorkspace(workspace)}
            >
              <MenuItem>
                <Flex direction="column" mt="8px">
                  <HText mb="2px">{workspace.name}</HText>
                  <HText mb="8px" fontSize="sm">
                    {workspace?.description}
                  </HText>
                </Flex>
              </MenuItem>
            </ReactRouterLink>
          ))}
        <ReactRouterLink to={`/${urls(company).workspacesView}`}>
          <MenuItem key={"view-all-workspaces"}>
            <Text color="textLink">View all spaces</Text>
          </MenuItem>
        </ReactRouterLink>
      </MenuList>
    </Menu>
  )
}
interface DashboardNavBarProps extends FlexProps {
  isOpen?: boolean
  onClickNext?: () => void
  onClickPrev?: () => void
  onClickClose?: () => void
  isMobile?: boolean
}
const DashboardNavBar: React.FC<DashboardNavBarProps> = (
  props,
  { ...rest }
) => {
  const { workspaces, setCurrentWorkspace } = useWorkspaces()
  const { companyUrl: company } = useCompany()
  const { pitchFilterByTitle } = usePitches()

  const { currentWorkspace, filteredResults, setSearchFilter, searchFilter } =
    useWorkspaces()
  const navigate = useNavigate()
  const myRef = useRef(null)
  const { activities } = useActivities()

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${urls(company).userSkillSearchView}`)
    }
  }

  const defaultWorkspace =
    workspaces && workspaces.length > 0 ? workspaces[0].slug : ""

  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [isSearchPop, setIsSearchPop] = useState<boolean>(false)
  const initRef = React.useRef()

  useEffect(() => {
    if (
      searchFilter &&
      searchFilter.length > 2 &&
      !firstLoad &&
      window.location.pathname.indexOf("pitches") === -1 &&
      window.location.pathname.indexOf("users_view") === -1
    ) {
      setIsSearchPop(true)
    } else if (searchFilter && searchFilter.length < 3) {
      setIsSearchPop(false)
    }
  }, [searchFilter])

  return (
    <Flex
      width="100%"
      justify="space-between"
      {...rest}
      borderBottom="1px solid #dddddd"
      backgroundColor="white"
      minH={["auto", "auto", "60px"]}
      maxH={"66px"}
      px={"36px"}
    >
      <Stack
        isInline
        background="white"
        align="center"
        my={["10px", "10px", 0]}
        spacing="8px"
      >
        <Button
          d="flex"
          flexDirection="column"
          borderRadius="4px"
          marginRight="16px"
          alignItems="center"
          mb="3px"
          color="#78E1D4"
          fontWeight="600"
          fontSize={"24px"}
          variant="ghost"
          textTransform="capitalize"
          data-testid="brandIcon"
          onClick={() => navigate(`/${urls(company).companyView}`)}
        >
          {company.indexOf("demo") > -1 ? (
            <Text
              d="flex"
              alignItems="center"
              mb="3px"
              color="#78E1D4"
              fontWeight="600"
              textTransform="capitalize"
            >
              Company Logo
            </Text>
          ) : (
            <Text
              d="flex"
              alignItems="center"
              mb="3px"
              color="#78E1D4"
              fontWeight="600"
              textTransform="capitalize"
            >
              {company}
            </Text>
          )}
        </Button>
        <Popover
          isOpen={isSearchPop}
          onClose={() => setIsSearchPop(false)}
          usePortal
          //@ts-ignore
          initialFocusRef={initRef}
        >
          <Flex>
            <PopoverTrigger>
              <Box
                w="332px"
                maxHeight="40px"
                flexShrink={0}
                mr="25px"
                onClick={() => setFirstLoad(false)}
              >
                <InputGroup>
                  <InputLeftElement>
                    <Icon name="search-2" color="#4F4F4F" />
                  </InputLeftElement>
                  <Input
                    minH="40px"
                    color="#4F4F4F"
                    //@ts-ignore
                    ref={initRef}
                    _placeholder={{ fontWeight: "bold", color: "#4F4F4F" }}
                    fontSize="sm"
                    border="0px"
                    backgroundColor="rgba(9, 19, 33, 0.0638304)"
                    data-testid="last-name"
                    value={searchFilter || ""}
                    onKeyDown={onKeyDownHandler}
                    onChange={(e: any) => setSearchFilter(e.target.value)}
                    placeholder="Search"
                  />
                </InputGroup>
              </Box>
            </PopoverTrigger>
            {searchFilter && (
              <IconButton
                aria-label="Close search members"
                icon="small-close"
                height="40px"
                ml="-65px"
                zIndex={3}
                onClick={() => setSearchFilter("")}
              >
                X
              </IconButton>
            )}
          </Flex>

          <PopoverContent zIndex={9999}>
            <PopoverBody>
              <Flex d="column">
                <Text as="b" py="16px">
                  People
                </Text>
                {filteredResults === undefined ||
                  (filteredResults.length === 0 && (
                    <HText>No Matching People</HText>
                  ))}
                {filteredResults?.slice(0, 3).map((userProfile) => (
                  <Flex align="center" key={userProfile.uid} mb="12px">
                    <Avatar
                      h="32px"
                      w="32px"
                      src={userProfile.photo}
                      name={userProfile.firstName + " " + userProfile.lastName}
                      background={gradient(userProfile.uid)}
                      mr="12px"
                    />
                    <ReactRouterLink
                      to={`/${urls(company).profile}/${userProfile.uid}`}
                      onClick={() => setSearchFilter("")}
                    >
                      <Link fontSize="sm" color="fontBlack">
                        {userProfile.firstName + " " + userProfile.lastName}
                      </Link>
                    </ReactRouterLink>
                  </Flex>
                ))}
                <ReactRouterLink
                  to={`/${urls(company).userSkillSearchView}`}
                  onClick={() => setSearchFilter("")}
                >
                  <Link fontWeight="500" color="textLink" fontSize="sm">
                    View all Profiles
                  </Link>
                </ReactRouterLink>
                <Divider />
                <Flex d="column">
                  <Text as="b" py="16px">
                    Pitches
                  </Text>
                  {pitchFilterByTitle === undefined ||
                    (pitchFilterByTitle.length === 0 && (
                      <HText>No Matching Pitches</HText>
                    ))}
                  {pitchFilterByTitle?.slice(0, 3).map((pitch) => {
                    return (
                      <ReactRouterLink
                        key={pitch.id}
                        to={`/${company}/pitch/${pitch.id}`}
                        onClick={() => setSearchFilter("")}
                      >
                        <Flex alignItems="center" mb="12px">
                          <Image
                            h="32px"
                            w="27px"
                            borderRadius="8px"
                            mr="12px"
                            background={gradient(pitch.id)}
                            src={pitch.coverPicture?.url}
                            objectFit="cover"
                          />
                          <Flex d="column">
                            <Link fontSize="sm" color="fontBlack">
                              {pitch.title}
                            </Link>
                          </Flex>
                        </Flex>
                      </ReactRouterLink>
                    )
                  })}
                  <ReactRouterLink
                    to={`/${urls(company).pitchSearchView}`}
                    onClick={() => setSearchFilter("")}
                  >
                    <Link fontWeight="500" color="textLink" fontSize="sm">
                      View all Pitches
                    </Link>
                  </ReactRouterLink>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Stack>

      <Flex justify="space-between">
        <Box mt="6px">
          <DashboardFooterMobile isMobile={false} />
        </Box>
        <ProfileButton isMobile={false} />
      </Flex>
    </Flex>
  )
}

export const DashboardFooter: React.FC<SimpleGridProps> = ({ ...rest }) => (
  <SimpleGrid
    background="white"
    columns={[1, 1, 3]}
    minH="60px"
    maxH="66px"
    py="16px"
    px="50px"
    {...rest}
  >
    <Flex
      justify={["left", "center", "flex-start"]}
      align="center"
      mb={["8px", "8px", 0]}
    >
      <Link isExternal href="https://huminate.com">
        <Image h="27px " src={Brand} />
      </Link>
    </Flex>

    <Flex justify={["left", "center", "center"]} align="center">
      <Text fontSize="sm">{`@ ${new Date().getFullYear()} Huminate`}</Text>
    </Flex>
    <Box />
  </SimpleGrid>
)

const DashboardNavBarMobile: React.FC<DashboardNavBarProps> = (
  props,
  { ...rest }
) => {
  const { workspaces, setCurrentWorkspace } = useWorkspaces()
  const { companyUrl: company } = useCompany()
  const { pitchFilterByTitle } = usePitches()

  const { currentWorkspace, filteredResults, setSearchFilter, searchFilter } =
    useWorkspaces()
  const navigate = useNavigate()
  const myRef = useRef(null)
  const { activities } = useActivities()

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${urls(company).userSkillSearchView}`)
    }
  }

  const [showSearchDropdown, setShowSearchDropdown] = useState<boolean>(false)
  useEffect(() => {
    setShowSearchDropdown(searchFilter !== undefined && searchFilter.length > 0)
  }, [searchFilter])

  const defaultWorkspace =
    workspaces && workspaces.length > 0 ? workspaces[0].slug : ""

  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  return (
    <Flex
      flexWrap="wrap"
      justify="space-between"
      alignItems="center"
      w="100vw"
      {...rest}
      py="4px"
      backgroundColor="white"
      borderBottom="1px solid #dddddd"
      minH={["auto", "auto", "60px"]}
      px={"8px"}
    >
      <Stack w="100%" isInline background="white" align="center" spacing="8px">
        <Button
          d="flex"
          flexDirection="column"
          borderRadius="4px"
          marginRight="16px"
          alignItems="center"
          fontWeight="600"
          fontSize={"24px"}
          backgroundColor="#78E1D4"
          data-testid="brandIcon"
          variant="ghost"
          onClick={() => navigate(`/${urls(company).companyView}`)}
        >
          <Text d="flex" alignItems="center" color="white" fontWeight="600">
            h
          </Text>
        </Button>
        <Popover
          isOpen={
            !!searchFilter &&
            !firstLoad &&
            window.location.pathname.indexOf("users_view") === -1
          }
          initialFocusRef={myRef}
        >
          <Flex w="100%">
            <PopoverTrigger>
              <Box
                w="100%"
                minWidth="96px"
                maxHeight="40px"
                flexShrink={0}
                onClick={() => setFirstLoad(false)}
              >
                <InputGroup>
                  <InputLeftElement>
                    <Icon name="search-2" color="#4F4F4F" />
                  </InputLeftElement>
                  <Input
                    ref={myRef}
                    minH="40px"
                    color="#4F4F4F"
                    _placeholder={{ fontWeight: "bold", color: "#4F4F4F" }}
                    fontSize="sm"
                    border="0px"
                    backgroundColor="rgba(9, 19, 33, 0.0638304)"
                    data-testid="last-name"
                    value={searchFilter || ""}
                    onKeyDown={onKeyDownHandler}
                    onChange={(e: any) => {
                      setSearchFilter(e.target.value)
                    }}
                    placeholder="Search"
                  />
                </InputGroup>
              </Box>
            </PopoverTrigger>
            {searchFilter && (
              <Button
                ml={"-43px"}
                zIndex={99}
                onClick={() => setSearchFilter("")}
              >
                X
              </Button>
            )}
          </Flex>

          <PopoverContent zIndex={9999}>
            <PopoverBody>
              <Flex d="column">
                <Text as="b" py="16px">
                  People
                </Text>
                {filteredResults?.slice(0, 3).map((userProfile) => {
                  if (userProfile.uid) {
                    return (
                      <Flex align="center" key={userProfile.uid} mb="12px">
                        <Avatar
                          h="32px"
                          w="32px"
                          src={userProfile.photo}
                          name={
                            userProfile.firstName + " " + userProfile.lastName
                          }
                          background={gradient(userProfile.uid)}
                          mr="12px"
                        />
                        <ReactRouterLink
                          to={`/${urls(company).profile}/${userProfile.uid}`}
                          onClick={() => setSearchFilter("")}
                        >
                          <Link fontSize="sm" color="fontBlack">
                            {userProfile.firstName + " " + userProfile.lastName}
                          </Link>
                        </ReactRouterLink>
                      </Flex>
                    )
                  } else {
                    console.log({ userProfile })
                  }
                })}
                <ReactRouterLink to={`/${urls(company).userSkillSearchView}`}>
                  <Link fontWeight="500" color="textLink" fontSize="sm">
                    View all Profiles
                  </Link>
                </ReactRouterLink>
                <Divider />
                <Flex d="column">
                  <Text as="b" py="16px">
                    Pitches
                  </Text>
                  {pitchFilterByTitle?.slice(0, 3).map((pitch) => {
                    return (
                      <Flex key={pitch.id} alignItems="center" mb="12px">
                        <Image
                          h="32px"
                          w="27px"
                          borderRadius="8px"
                          mr="12px"
                          background={gradient(pitch.id)}
                          src={pitch.coverPicture?.url}
                          objectFit="cover"
                        />
                        <Flex d="column">
                          <Link fontSize="sm" color="fontBlack">
                            {pitch.title}
                          </Link>
                        </Flex>
                      </Flex>
                    )
                  })}
                  <ReactRouterLink to={`/${urls(company).pitchSearchView}`}>
                    <Link fontWeight="500" color="textLink" fontSize="sm">
                      View all Pitches
                    </Link>
                  </ReactRouterLink>
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <ProfileButton {...props} data-testid="nav-profile" isMobile={true} />
      </Stack>
    </Flex>
  )
}

export const DashboardFooterMobile: React.FC<DashboardNavBarProps> = (
  props,
  { ...rest }
) => {
  const {
    workspaces,
    setCurrentWorkspace,
    currentWorkspace,
    filteredResults,
    setSearchFilter,
    searchFilter,
  } = useWorkspaces()
  const { companyUrl: company } = useCompany()

  const navigate = useNavigate()
  const { activities } = useActivities()
  const { userProfile } = useAuth()

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13) {
      navigate(`/${urls(company).userSkillSearchView}`)
    }
  }

  const [showSearchDropdown, setShowSearchDropdown] = useState<boolean>(false)
  useEffect(() => {
    setShowSearchDropdown(searchFilter !== undefined)
  }, [searchFilter])

  const defaultWorkspace =
    workspaces && workspaces.length > 0 ? workspaces[0].slug : ""
  const [openCreatePitchModal, setOpenCreatePitchModal] = useState(0)

  const fontSizeIcons = props?.isMobile ? "12px" : "16px"
  const { width } = useWindowDimensions()

  let unreadActivities = 0

  if (userProfile !== undefined && activities) {
    if (userProfile.readActivities !== undefined) {
      unreadActivities = activities.filter(
        //@ts-ignore
        ({ id }) => userProfile.readActivities.indexOf(id) === -1
      ).length
    } else {
      unreadActivities = activities.length
    }
  }

  console.log(unreadActivities)

  return (
    <>
      <FriendlyModal
        isOpen={openCreatePitchModal !== 0}
        noButton
        title={"Create a Pitch"}
        onClose={() => {
          //@ts-ignore
          window.sessionStorage.removeItem("pitchDraft")
          setOpenCreatePitchModal(0)
        }}
      >
        {openCreatePitchModal === 1 ? (
          <CreateAPitchOneView
            onBack={() => {
              window.sessionStorage.removeItem("pitchDraft")
              setOpenCreatePitchModal(0)
            }}
            onNext={() => {
              setOpenCreatePitchModal(openCreatePitchModal + 1)
            }}
          />
        ) : openCreatePitchModal === 2 ? (
          <CreateAPitchTwoView
            onBack={() => setOpenCreatePitchModal(1)}
            onNext={() => {
              setOpenCreatePitchModal(3)
            }}
          />
        ) : openCreatePitchModal === 3 ? (
          <CreateAPitchThreeOneView
            onBack={() => {
              setOpenCreatePitchModal(openCreatePitchModal - 1)
            }}
            onNext={() => {
              setOpenCreatePitchModal(openCreatePitchModal + 1)
            }}
          />
        ) : openCreatePitchModal === 4 ? (
          <CreateAPitchThreeTwoView
            onBack={() => setOpenCreatePitchModal(3)}
            onNext={() => {
              setOpenCreatePitchModal(openCreatePitchModal + 1)
            }}
          />
        ) : openCreatePitchModal === 5 ? (
          <CreateAPitchFourView />
        ) : (
          <></>
        )}
      </FriendlyModal>

      <Flex w={"100%"} justify="flex-end">
        {width < 1135 && !props?.isMobile && (
          <Menu>
            <MenuButton
              d="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pb="9px"
              px="16px"
              color="fontDarkGray"
              height="56px"
              fontWeight="600"
              borderRadius="0px"
              textTransform="capitalize"
            >
              <FaGripHorizontal size={24} style={{ padding: "2px" }} /> Menu
            </MenuButton>
            <MenuList>
              <Flex justify="space-around" width="300px">
                <Button
                  d="flex"
                  w="89px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mb="3px"
                  px="16px"
                  color="fontDarkGray"
                  height="56px"
                  fontWeight="600"
                  borderRadius="0px"
                  variant="ghost"
                  textTransform="capitalize"
                  onClick={() => {
                    window.sessionStorage.removeItem("pitchDraft")
                    setOpenCreatePitchModal(1)
                  }}
                >
                  <FaPlusCircle size={24} style={{ padding: "0px" }} />
                  <Text
                    d="flex"
                    alignItems="center"
                    mb="3px"
                    color="fontDarkGray"
                    fontWeight="600"
                    fontSize={fontSizeIcons}
                    textTransform="capitalize"
                  >
                    Create
                  </Text>
                </Button>

                <Button
                  d="flex"
                  w="89px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mb="3px"
                  p="2px"
                  color="fontDarkGray"
                  height="56px"
                  fontWeight="600"
                  borderRadius="0px"
                  backgroundColor="white"
                  data-testid="nav-workspace"
                  borderBottom={
                    window.location.pathname.indexOf("dashboard") !== -1 &&
                    window.location.pathname.indexOf("activity") === -1
                      ? "2px solid #4F4F4F"
                      : "2px solid white"
                  }
                  variant="ghost"
                  textTransform="capitalize"
                  onClick={() =>
                    navigate(`/${urls(company, defaultWorkspace).dashboard}`)
                  }
                >
                  <FaBriefcase size={24} style={{ padding: "2px" }} />
                  <Text
                    d="flex"
                    alignItems="center"
                    mb="3px"
                    color="fontDarkGray"
                    fontWeight="600"
                    fontSize={fontSizeIcons}
                    textTransform="capitalize"
                  >
                    Workspace
                  </Text>
                </Button>
              </Flex>
              <Flex justify="space-around" width="300px">
                <Button
                  d="flex"
                  w="89px"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  mb="3px"
                  p="2px"
                  color="fontDarkGray"
                  height="56px"
                  fontWeight="600"
                  borderRadius="0px"
                  borderBottom={
                    window.location.pathname.indexOf("users_view") !== -1
                      ? "2px solid #4F4F4F"
                      : "2px solid white"
                  }
                  variant="ghost"
                  textTransform="capitalize"
                  onClick={() =>
                    navigate(`/${urls(company).userSkillSearchView}`)
                  }
                >
                  <FaUserFriends size={24} style={{ padding: "2px" }} />
                  <Text
                    d="flex"
                    alignItems="center"
                    mb="3px"
                    color="fontDarkGray"
                    fontWeight="600"
                    fontSize={fontSizeIcons}
                    textTransform="capitalize"
                  >
                    People
                  </Text>
                </Button>
                <Button
                  d="flex"
                  w="89px"
                  flexDirection="column"
                  justifyContent="center"
                  borderRadius="0px"
                  borderBottom={
                    window.location.pathname.indexOf("activity") !== -1
                      ? "2px solid #4F4F4F"
                      : "2px solid white"
                  }
                  alignItems="center"
                  mb="3px"
                  p="2px"
                  color="fontDarkGray"
                  height="56px"
                  fontWeight="600"
                  variant="ghost"
                  textTransform="capitalize"
                  onClick={() => {
                    navigate(`/${urls(company).activity}`)
                  }}
                  aria-label="notifications"
                >
                  {unreadActivities > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "4px",
                        height: "16px",
                        width: "18px",
                        borderRadius: "100%",
                        backgroundColor: "HotPink",
                        paddingTop: "1px",
                        fontSize: "12px",
                        color: "fontDarkGray",
                        zIndex: 4,
                        textAlign: "center",
                      }}
                    >
                      {unreadActivities}
                    </div>
                  )}
                  <FaBell size={24} style={{ padding: "2px" }} />
                  <Flex>
                    <Text
                      d="flex"
                      alignItems="center"
                      mb="3px"
                      color="fontDarkGray"
                      fontWeight="600"
                      fontSize={fontSizeIcons}
                      p="0px"
                      textTransform="capitalize"
                    >
                      Activities
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            </MenuList>
          </Menu>
        )}
        {!props?.isMobile && width > 1135 && (
          <Flex justify="space-around" ml="-76px">
            <Button
              d="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="3px"
              px="16px"
              color="fontDarkGray"
              height="56px"
              fontWeight="600"
              borderRadius="0px"
              variant="ghost"
              textTransform="capitalize"
              onClick={() => {
                window.sessionStorage.removeItem("pitchDraft")
                setOpenCreatePitchModal(1)
              }}
            >
              <FaPlusCircle size={24} style={{ padding: "0px" }} />
              <Text
                d="flex"
                alignItems="center"
                mb="3px"
                color="fontDarkGray"
                fontWeight="600"
                fontSize={fontSizeIcons}
                textTransform="capitalize"
              >
                Create
              </Text>
            </Button>
          </Flex>
        )}
        {(width > 1135 || props?.isMobile) && (
          <Flex
            w={props?.isMobile ? "100%" : "324px"}
            justify="space-around"
            backgroundColor="white"
            {...rest}
            borderTop={props?.isMobile ? "1px solid #dddddd" : "none"}
          >
            <Button
              d="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="3px"
              p="2px"
              color="fontDarkGray"
              height="56px"
              fontWeight="600"
              borderRadius="0px"
              backgroundColor="white"
              data-testid="nav-workspace"
              borderBottom={
                window.location.pathname.indexOf("dashboard") !== -1 &&
                window.location.pathname.indexOf("activity") === -1
                  ? "2px solid #4F4F4F"
                  : "2px solid white"
              }
              variant="ghost"
              textTransform="capitalize"
              onClick={() =>
                navigate(`/${urls(company, defaultWorkspace).dashboard}`)
              }
            >
              <FaBriefcase size={24} style={{ padding: "2px" }} />
              <Text
                d="flex"
                alignItems="center"
                mb="3px"
                color="fontDarkGray"
                fontWeight="600"
                fontSize={fontSizeIcons}
                textTransform="capitalize"
              >
                Workspace
              </Text>
            </Button>
            <Button
              d="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="3px"
              p="2px"
              color="fontDarkGray"
              height="56px"
              fontWeight="600"
              borderRadius="0px"
              borderBottom={
                window.location.pathname.indexOf("users_view") !== -1
                  ? "2px solid #4F4F4F"
                  : "2px solid white"
              }
              variant="ghost"
              textTransform="capitalize"
              onClick={() => navigate(`/${urls(company).userSkillSearchView}`)}
            >
              <FaUserFriends size={24} style={{ padding: "2px" }} />
              <Text
                d="flex"
                alignItems="center"
                mb="3px"
                color="fontDarkGray"
                fontWeight="600"
                fontSize={fontSizeIcons}
                textTransform="capitalize"
              >
                People
              </Text>
            </Button>
            {props?.isMobile && (
              <Button
                d="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb="3px"
                px="8px"
                color="fontDarkGray"
                height="56px"
                fontWeight="600"
                borderRadius="0px"
                variant="ghost"
                textTransform="capitalize"
                data-testid="dashboard-create-pitch"
                onClick={() => {
                  window.sessionStorage.removeItem("pitchDraft")
                  setOpenCreatePitchModal(1)
                }}
              >
                <FaPlusCircle size={24} style={{ padding: "0px" }} />
                <Text
                  d="flex"
                  alignItems="center"
                  mb="3px"
                  color="fontDarkGray"
                  fontWeight="600"
                  fontSize={fontSizeIcons}
                  textTransform="capitalize"
                >
                  Create
                </Text>
              </Button>
            )}
            <Button
              d="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="3px"
              p="2px"
              color="fontDarkGray"
              height="56px"
              fontWeight="600"
              borderRadius="0px"
              borderBottom={
                window.location.pathname.indexOf("users_view") !== -1
                  ? "2px solid #4F4F4F"
                  : "2px solid white"
              }
              variant="ghost"
              textTransform="capitalize"
              onClick={() => navigate(`/${urls(company).posts}`)}
            >
              <FaLightbulb size={24} style={{ padding: "2px" }} />
              <Text
                d="flex"
                alignItems="center"
                mb="3px"
                color="fontDarkGray"
                fontWeight="600"
                fontSize={fontSizeIcons}
                textTransform="capitalize"
              >
                Skill seeker
              </Text>
            </Button>

            <Box position="relative">
              <Button
                d="flex"
                flexDirection="column"
                justifyContent="center"
                borderRadius="0px"
                borderBottom={
                  window.location.pathname.indexOf("activity") !== -1
                    ? "2px solid #4F4F4F"
                    : "2px solid white"
                }
                alignItems="center"
                mb="3px"
                p="2px"
                color="fontDarkGray"
                height="56px"
                fontWeight="600"
                variant="ghost"
                textTransform="capitalize"
                onClick={() => {
                  navigate(`/${urls(company).activity}`)
                }}
                aria-label="notifications"
              >
                {unreadActivities > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      right: "8px",
                      top: "4px",
                      height: "16px",
                      width: "18px",
                      borderRadius: "100%",
                      backgroundColor: "HotPink",
                      paddingTop: "1px",
                      fontSize: "12px",
                      color: "fontDarkGray",
                      zIndex: 4,
                      textAlign: "center",
                    }}
                  >
                    {unreadActivities}
                  </div>
                )}
                <FaBell size={24} style={{ padding: "2px" }} />
                <Flex>
                  <Text
                    d="flex"
                    alignItems="center"
                    mb="3px"
                    color="fontDarkGray"
                    fontWeight="600"
                    fontSize={fontSizeIcons}
                    p="0px"
                    textTransform="capitalize"
                  >
                    Activities
                  </Text>
                </Flex>
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
    </>
  )
}

interface DashboardContainerProps extends DashboardNavBarProps {
  background?: string
}
export const DashboardContainer: React.FC<DashboardContainerProps> = ({
  children,
  background,
  ...rest
}) => {
  const isMobileDevice = isMobile()

  if (isMobileDevice) {
    return (
      // <Box>
      <Grid
        gridTemplateRows="auto auto 1fr auto"
        h="100vh"
        w="100vw"
        overflowX="hidden"
        overflowY="hidden"
        background="#FBFBFB"
      >
        {/* navbar */}
        <DashboardNavBarMobile {...rest} />
        <WorkspaceBar />
        {/* body */}
        {/* wrap the main content in an auto scroll overflow grid to fix header and footer on the screen */}
        <Grid gridTemplateColumns="1fr" overflowY="auto" w="100vw">
          <Box
            padding={["4px", "4px"]}
            background={background || "backgroundLight"}
          >
            {children}{" "}
          </Box>
        </Grid>

        {/* footer */}
        <DashboardFooterMobile {...rest} isMobile={true} />
      </Grid>
    )
  } else {
    return (
      // <Box>
      <Grid
        gridTemplateRows="auto auto"
        h="100vh"
        w="100vw"
        overflowY="hidden"
        background="#FBFBFB"
      >
        {/* navbar */}
        <DashboardNavBar {...rest} />
        <WorkspaceBar />
        {/* body */}
        {/* wrap the main content in an auto scroll overflow grid to fix header and footer on the screen */}
        <Grid gridTemplateColumns="1fr" overflowY="auto" background="#FBFBFB">
          <Box
            padding={["16px", "36px"]}
            background={background || "backgroundLight"}
            minH="calc(100vh - 142px)"
          >
            {children}{" "}
          </Box>
        </Grid>

        {/* footer */}
        <DashboardFooter />
      </Grid>
    )
  }
}
