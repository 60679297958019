import {
  Flex,
  Box,
  Stack,
  Button,
  SimpleGrid,
  Text,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  MenuButton,
  Avatar,
  Link,
  Tag as ChakraTag,
} from "@chakra-ui/core"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { urls } from "../App"

import * as R from "ramda"
//@ts-ignore
import gradient from "random-gradient"

import { Fade } from "react-awesome-reveal"

import { PitchStatus, TagTypes, IUserProfile, IPitch } from "../types"

import { useCompany } from "../Context/company-context"
import { useAuth } from "../Context/auth-context"

import { FriendlyModal } from "../Components/FriendlyModal"
import { HHeading, HText } from "../Components/StyledTextComponents"
import { PitchCard } from "../Components/PitchCard"
import { SideScroll } from "../Components/SideScroll"

import { useWorkspaces } from "../Context/workspace-context"
import { usePitches } from "../Context/pitches-context"
import { isMobile } from "../Components/isMobile"
import { PitchInviteCard } from "./Profile/ProfileDisplay"

import { DashboardContainer } from "./Dashboard/DashboardWrapper"
import { Requests } from "./Dashboard/Requests"

enum SortFilterPitches {
  Recommended = "Recommended",
  Completed = "Completed",
  MyWork = "My Work",
  Status = "Status",
  RecentlyApproved = "Recently Approved",
  ViewAll = "View All",
  MostLiked = "Most Liked",
}

enum SortFilterPitchesStatus {
  Draft = "Draft",
  RecentlyApproved = "Recently Approved",
  Completed = "Completed",
}

enum SortFilterPeople {
  Recommended = "Recommended",
  Alphabetical = "Alphabetical",
}

export const SearchResultView: React.FC = () => {
  const { companyUrl } = useCompany()

  const [topLevel, setTopLevel] = useState(
    window.location.hash === "#Pitches" ? "Pitches" : "People"
  )

  const navigate = useNavigate()

  return (
    <DashboardContainer maxWidth="100vw">
      <div style={{ marginTop: "-24px" }}>
        <Divider />
        <Menu>
          <MenuButton
            as={Button}
            color="black"
            bg="#78E1D4"
            _hover={{ bg: "teal.400" }}
            //@ts-ignore
            rightIcon="chevron-down"
          >
            {topLevel}
          </MenuButton>
          <MenuList placement="bottom-start">
            <MenuItem
              onClick={() => {
                setTopLevel("People")
                navigate(`/${companyUrl}/users_view#People`)
              }}
            >
              People
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTopLevel("Pitches")
                navigate(`/${companyUrl}/users_view#Pitches`)
              }}
            >
              Pitches
            </MenuItem>
          </MenuList>
        </Menu>
        <Divider mt="8px" />
        {topLevel === "People" ? (
          <SearchResultUsers />
        ) : (
          <SearchResultPitches />
        )}
      </div>
    </DashboardContainer>
  )
}

const UserPitchRecs = ({ pitchMatchTags, userProfile }: any) => {
  const { setSearchFilter, searchFilter } = useWorkspaces()
  const [showRest, setShowRest] = useState(false)
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  console.log({ pitchMatchTags })
  const groupedPitchTags = R.groupBy((a) => {
    //@ts-ignore
    return a.pitch.id
    //@ts-ignore
  }, pitchMatchTags)
  console.log({ groupedPitchTags })

  const justValues = Object.values(groupedPitchTags)

  const sortAlphabetical = R.sort(
    //@ts-ignore
    (a, b) => b.length - a.length,
    justValues
  )
  const firstThree = sortAlphabetical.slice(0, 3)
  const rest = sortAlphabetical.slice(3, sortAlphabetical.length)

  return (
    <>
      <Divider />
      {R.map((a) => {
        return (
          <Flex
            key={
              //@ts-ignore
              a[0].pitch.title
            }
            direction="column"
            py="2px"
          >
            <Flex wrap="wrap" align="center">
              <HText mr="8px" mb="0.5rem">
                Matching Tags
              </HText>

              {
                //@ts-ignore
                a.map(({ tag }) => (
                  <ChakraTag
                    mr={2}
                    mb={2}
                    fontSize="sm"
                    key={"1"}
                    backgroundColor="turquoise"
                    color="fontBlack"
                    cursor="pointer"
                    onClick={() => {
                      //@ts-ignore
                      setSearchFilter(tag.text)
                    }}
                  >
                    {
                      //@ts-ignore
                      tag.text
                    }
                  </ChakraTag>
                ))
              }
            </Flex>
            <PitchInviteCard
              pitch={
                //@ts-ignore
                a[0].pitch
              }
              selectedUser={userProfile}
            />
          </Flex>
        )
      }, firstThree)}
      {!showRest && rest.length > 0 && (
        <Link fontSize="sm" color="textLink" onClick={() => setShowRest(true)}>
          Show More
        </Link>
      )}
      {showRest &&
        R.map((a) => {
          return (
            <Flex
              key={
                //@ts-ignore
                a[0].pitch.title
              }
              direction="column"
              py="2px"
            >
              <Flex wrap="wrap">
                {
                  //@ts-ignore
                  a.map(({ tag }) => (
                    <ChakraTag
                      mr={2}
                      mb={2}
                      fontSize="sm"
                      key={"1"}
                      backgroundColor="turquoise"
                      color="fontBlack"
                      cursor="pointer"
                      onClick={() => {
                        //@ts-ignore
                        setSearchFilter(tag.text)
                      }}
                    >
                      {
                        //@ts-ignore
                        tag.text
                      }
                    </ChakraTag>
                  ))
                }
              </Flex>
              <PitchInviteCard
                pitch={
                  //@ts-ignore
                  a[0].pitch
                }
                selectedUser={userProfile}
              />
            </Flex>
          )
        }, rest)}
    </>
  )
}

export const SearchResultUsers: React.FC = () => {
  const navigate = useNavigate()
  const isMobileDevice = isMobile()
  const { companyUrl } = useCompany()
  const { pitches } = usePitches()
  const [modalProfile, setModalProfile] = useState<any | undefined>()
  const { user } = useAuth()

  const { filteredResults, searchFilter, setSearchFilter } = useWorkspaces()
  const [peopleFilter, setPeopleFilter] = useState<SortFilterPeople>(
    (window.location.hash.split("#")[1] as SortFilterPeople) ||
      SortFilterPeople.Recommended
  )

  const [newfilteredResults, setNewfilteredResults] = useState<IUserProfile[]>(
    []
  )

  const [displayedCount, setDisplayedCount] = useState<{
    start: number
    end: number
  }>({ start: 0, end: 5 })

  useEffect(() => {
    setDisplayedCount({ start: 0, end: 5 })

    if (searchFilter === undefined) {
      setSearchFilter("")
    }
    if (
      peopleFilter === SortFilterPeople.Recommended &&
      filteredResults &&
      user &&
      user.sub &&
      pitches
    ) {
      const usersWithMatch = filteredResults.map(({ tags, ...rest }) => {
        const pitchMatchTags: any[] = []

        pitches
          .filter(({ champions }) => champions?.indexOf(user?.sub) !== -1)
          .map((pitch: IPitch) => {
            pitch.skillTags.map(({ tag }) => {
              tags?.map((userTag) => {
                if (userTag.id === tag.id) {
                  pitchMatchTags.push({ pitch, tag })
                }
              })
            })
          })
        return { tags, pitchMatchTags, ...rest }
      })
      setNewfilteredResults(
        R.sort(
          (a, b) => b.pitchMatchTags.length - a.pitchMatchTags.length,
          usersWithMatch
        )
      )
    } else if (
      peopleFilter === SortFilterPeople.Alphabetical &&
      filteredResults
    ) {
      //@ts-ignore
      const newOrderedPeople = R.sortBy((a, b) => a?.lastName - b?.lastName)(
        filteredResults
      )
      setNewfilteredResults(newOrderedPeople)
    }
  }, [peopleFilter, filteredResults, pitches])

  return (
    <DashboardContainer maxWidth="100vw">
      <FriendlyModal
        title="User Profile"
        isOpen={modalProfile}
        noTop
        noButton
        onClose={() => {
          setModalProfile(undefined)
        }}
      >
        {modalProfile && (
          <Flex d="column">
            <Flex align="center" padding="0" pt="16px">
              <Avatar
                style={
                  isMobileDevice
                    ? {
                        height: "56px",
                        width: "56px",
                      }
                    : {
                        height: "80px",
                        width: "80px",
                      }
                }
                src={modalProfile.photo}
                name={modalProfile.firstName + " " + modalProfile.lastName}
                background={gradient(modalProfile.lastName)}
                mr="24px"
              />
              <Flex direction="column">
                <HHeading fontSize="md" fontWeight="normal">
                  {modalProfile.firstName + " " + modalProfile.lastName}
                </HHeading>
                <HText color="fontSmall" fontSize="xs">
                  {modalProfile.position}
                </HText>
              </Flex>
            </Flex>

            <HHeading mt={8} mb={2} fontSize="sm">
              Bio
            </HHeading>
            <HText fontSize="sm" whiteSpace="pre-line">
              {modalProfile.bio}
            </HText>
            <Flex alignItems="baseline">
              <span aria-label="bag" role="img">
                💼
              </span>
              <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                Professional skills
              </HHeading>
            </Flex>
            <Flex wrap="wrap">
              {modalProfile?.tags
                //@ts-ignore
                ?.filter((tag) => tag.type === TagTypes.skill)
                //@ts-ignore
                .map((skill, i) => (
                  <ChakraTag
                    mr={2}
                    mb={2}
                    fontSize="sm"
                    key={i}
                    backgroundColor="tagGreenBackground"
                    color="tagGreenText"
                    cursor="pointer"
                    onClick={() => {
                      setSearchFilter(skill.text)
                    }}
                  >
                    {skill.text}
                  </ChakraTag>
                ))}
            </Flex>
            <Flex alignItems="baseline">
              <span aria-label="heart" role="img">
                ❤️
              </span>
              <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                Passion
              </HHeading>
            </Flex>
            <Flex wrap="wrap">
              {
                //@ts-ignore

                modalProfile?.tags
                  //@ts-ignore
                  ?.filter((tag) => tag.type === TagTypes.interest)
                  //@ts-ignore
                  .map((skill, i) => (
                    <ChakraTag
                      mr={2}
                      mb={2}
                      fontSize="sm"
                      key={i}
                      backgroundColor="tagRedBackground"
                      color="tagRedText"
                      cursor="pointer"
                      onClick={() => {
                        setSearchFilter(skill.text)
                      }}
                    >
                      {skill.text}
                    </ChakraTag>
                  ))
              }
            </Flex>
            <Flex alignItems="baseline">
              <span aria-label="muscles" role="img">
                💪
              </span>
              <HHeading mt={8} mb={2} ml={2} fontSize="sm">
                Strengths
              </HHeading>
            </Flex>
            <Flex wrap="wrap">
              {modalProfile?.tags
                //@ts-ignore
                ?.filter((tag) => tag.type === TagTypes.strength)
                //@ts-ignore

                .map((skill, i) => (
                  <ChakraTag
                    mb={2}
                    mr={2}
                    fontSize="sm"
                    key={i}
                    backgroundColor="tagBlueBackground"
                    color="tagBlueText"
                    cursor="pointer"
                    onClick={() => {
                      setSearchFilter(skill.text)
                    }}
                  >
                    {skill.text}
                  </ChakraTag>
                ))}
            </Flex>
            <Link
              fontSize="sm"
              color="textLink"
              onClick={() =>
                navigate(`/${urls(companyUrl).profile}/${modalProfile.uid}`)
              }
            >
              View full profile
            </Link>
          </Flex>
        )}
      </FriendlyModal>
      <Flex>
        <Text fontSize="16px" marginRight="4px" marginTop="8px">
          Sort
        </Text>
        <Menu>
          <Box minWidth="124px">
            <MenuButton
              as={Button}
              color="black"
              _hover={{ bg: "gray.400" }}
              //@ts-ignore
              rightIcon="chevron-down"
            >
              {peopleFilter}
            </MenuButton>
          </Box>
          <MenuList placement="bottom-start">
            <MenuItem
              onClick={() => {
                //@ts-ignore
                window.location.hash = SortFilterPeople.Recommended
                setPeopleFilter(SortFilterPeople.Recommended)
              }}
            >
              Recommended
            </MenuItem>
            <MenuItem
              onClick={() => {
                //@ts-ignore
                window.location.hash = SortFilterPeople.Alphabetical
                setPeopleFilter(SortFilterPeople.Alphabetical)
              }}
            >
              Alphabetical
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Flex justifyContent="center" w="100%">
        <Stack
          spacing={6}
          mt="20px"
          w="100%"
          maxWidth={isMobileDevice ? "100vw" : "720px"}
        >
          {newfilteredResults?.map((userProfile) => {
            return (
              <>
                <Box
                  key={userProfile.uid}
                  borderRadius="8px"
                  my="8px"
                  w="100%"
                  maxWidth={isMobileDevice ? "100vw" : "720px"}
                  shadow={isMobileDevice ? "none" : "lg"}
                  padding={isMobileDevice ? "0px" : "32px"}
                >
                  <Flex align="center">
                    <Avatar
                      style={
                        isMobileDevice
                          ? {
                              height: "56px",
                              width: "56px",
                            }
                          : {
                              height: "80px",
                              width: "80px",
                            }
                      }
                      src={userProfile.photo}
                      name={userProfile.firstName + " " + userProfile.lastName}
                      background={
                        userProfile?.lastName
                          ? gradient(userProfile?.lastName)
                          : "none"
                      }
                      mr="24px"
                    />
                    <Flex direction="column">
                      <HHeading fontSize="md" fontWeight="normal">
                        {userProfile.firstName + " " + userProfile.lastName}
                      </HHeading>
                      <HText color="fontSmall" fontSize="xs">
                        {userProfile.position}
                      </HText>
                      <Link
                        fontSize="sm"
                        color="textLink"
                        onClick={() => setModalProfile(userProfile)}
                      >
                        View profile
                      </Link>
                    </Flex>
                  </Flex>

                  {peopleFilter === SortFilterPeople.Recommended &&
                    //@ts-ignore
                    userProfile.pitchMatchTags && (
                      <UserPitchRecs
                        userProfile={userProfile}
                        pitchMatchTags={
                          //@ts-ignore
                          userProfile?.pitchMatchTags
                        }
                      />
                    )}
                </Box>
                <Divider />
              </>
            )
          })}
        </Stack>
      </Flex>
    </DashboardContainer>
  )
}

export const SearchResultPitches: React.FC = () => {
  const { searchFilter, setSearchFilter } = useWorkspaces()
  const { userProfile, userProfileLoading } = useAuth()
  const [pitchFilterByTitleAndSort, setPitchFilterByTitleAndSort] = useState<
    IPitch[]
  >([])
  const isMobileDevice = isMobile()

  const { pitches, pitchFilterByTitle } = usePitches()
  const [pitchFilter, setPitchFilter] = useState<SortFilterPitches>(
    SortFilterPitches.Recommended
  )
  const [pitchFilterStatus, setPitchFilterStatus] =
    useState<SortFilterPitchesStatus>(SortFilterPitchesStatus.Draft)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const splitSearch = searchFilter?.trim()?.split(",")

  const pitchTagList: any[] = []
  pitches?.map(({ skillTags, id }) => {
    const pitchTags = skillTags.filter(({ tag }) => !tag?.toggled)
    if (pitchTags.length > 0) {
      pitchTagList.push({ id, tags: pitchTags })
    }
    return id
  })

  const tagsOnly = R.flatten(pitchTagList.map(({ tags }) => tags)).map(
    ({ tag }) => tag
  )

  const pitchTagList2 = R.uniqBy(({ text }) => text, tagsOnly).filter(
    ({ text }) => {
      let isNotInSearch = false
      splitSearch?.map((searchFilter) => {
        if (
          text.toLowerCase().indexOf(searchFilter.toLowerCase().trim()) !== -1
        ) {
          isNotInSearch = true
        }
      })
      return isNotInSearch
    }
  )

  const filteredPitchList =
    pitches &&
    pitches
      .filter(
        ({ skillTags }) =>
          skillTags.filter(
            ({ tag }: any) =>
              selectedTags.indexOf(tag.text) > -1 && !tag.toggled
          ).length > 0
      )
      .filter(({ status }) => status !== PitchStatus.complete)

  useEffect(() => {
    console.log({ pitchFilter, pitchFilterStatus, pitchFilterByTitle })
    if (searchFilter === undefined) {
      setSearchFilter("")
    }
    if (
      pitchFilter === SortFilterPitches.Recommended &&
      userProfile &&
      userProfile.tags
    ) {
      setSelectedTags(userProfile.tags.map(({ text }) => text))
      console.log("TEST")
      const newOrderedPitches = R.sort(
        //@ts-ignore
        (a, b) => b?.createdAt.seconds - a?.createdAt.seconds,
        pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
      )
      console.log({ newOrderedPitches })
      setPitchFilterByTitleAndSort(newOrderedPitches)
    } else if (pitchFilter === SortFilterPitches.MyWork) {
      const newOrderedPitches = R.sort(
        //@ts-ignore
        (a, b) => b?.createdAt.seconds - a?.createdAt.seconds,
        R.filter(
          //@ts-ignore
          (a) => a.members.indexOf(userProfile.uid) > -1,
          pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
        ) as IPitch[]
      )
      setPitchFilterByTitleAndSort(newOrderedPitches)
    } else if (
      pitchFilter === SortFilterPitches.Status &&
      pitchFilterStatus === SortFilterPitchesStatus.RecentlyApproved
    ) {
      const newOrderedPitches: IPitch[] = R.sort(
        //@ts-ignore
        (a, b) => b?.createdAt.seconds - a?.createdAt.seconds,
        R.filter(
          //@ts-ignore
          (a) => a.status === PitchStatus.inProgress,
          pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
        ) as IPitch[]
      )

      setPitchFilterByTitleAndSort(newOrderedPitches)
    } else if (
      pitchFilter === SortFilterPitches.Status &&
      pitchFilterStatus === SortFilterPitchesStatus.Completed
    ) {
      const newOrderedPitches: IPitch[] = R.sort(
        //@ts-ignore
        (a, b) => b?.createdAt.seconds - a?.createdAt.seconds,
        R.filter(
          //@ts-ignore
          (a) => a.status === PitchStatus.complete,
          pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
        ) as IPitch[]
      )

      setPitchFilterByTitleAndSort(newOrderedPitches)
    } else if (
      pitchFilter === SortFilterPitches.Status &&
      pitchFilterStatus === SortFilterPitchesStatus.Draft
    ) {
      const newOrderedPitches: IPitch[] = R.sort(
        //@ts-ignore
        (a, b) => b?.createdAt.seconds - a?.createdAt.seconds,
        R.filter(
          //@ts-ignore
          (a) =>
            a.status === PitchStatus.draft ||
            a.status === PitchStatus.overdueSubmission ||
            a.status === PitchStatus.pendingApproval,
          pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
        ) as IPitch[]
      )
      console.log({ newOrderedPitches })
      setPitchFilterByTitleAndSort(newOrderedPitches)
    } else if (pitchFilter === SortFilterPitches.ViewAll) {
      setPitchFilterByTitleAndSort(
        R.sort(
          () => 0,
          pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
        )
      )
    } else if (pitchFilter === SortFilterPitches.MostLiked) {
      const newOrderedPitches: IPitch[] = R.sort(
        //@ts-ignore
        (a, b) => (b?.likesCount || 0) - (a?.likesCount || 0),
        pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
      )

      setPitchFilterByTitleAndSort(newOrderedPitches)
    } else if (pitchFilter === SortFilterPitches.Completed) {
      console.log("COMPLETED")
      const newOrderedPitches: IPitch[] = R.filter(
        //@ts-ignore
        ({ status }) => status === PitchStatus.complete,
        pitchFilterByTitle !== undefined ? pitchFilterByTitle : pitches || []
      )
      setPitchFilterByTitleAndSort(newOrderedPitches)
    }
  }, [pitchFilter, pitchFilterStatus, userProfileLoading, pitches])
  console.log({ searchFilter })
  return (
    <Box>
      <Flex flexWrap="wrap" width="100%">
        <Flex height="54px" mr="16px">
          <Menu>
            <Box>
              <MenuButton
                as={Button}
                color="black"
                _hover={{ bg: "gray.400" }}
                //@ts-ignore
                rightIcon="chevron-down"
              >
                {pitchFilter}
              </MenuButton>
            </Box>
            <MenuList placement="bottom-start">
              <MenuItem
                onClick={() => {
                  setSelectedTags([])
                  setPitchFilter(SortFilterPitches.Recommended)
                }}
              >
                Recommended
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedTags([])
                  setPitchFilter(SortFilterPitches.MyWork)
                }}
              >
                {SortFilterPitches.MyWork}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedTags([])
                  setPitchFilter(SortFilterPitches.Status)
                }}
              >
                {SortFilterPitches.Status}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedTags([])

                  setPitchFilter(SortFilterPitches.ViewAll)
                }}
              >
                {SortFilterPitches.ViewAll}
              </MenuItem>
            </MenuList>
          </Menu>
          {pitchFilter === SortFilterPitches.Status && (
            <Menu>
              <Box pl="18px">
                <MenuButton
                  as={Button}
                  color="black"
                  _hover={{ bg: "gray.400" }}
                  //@ts-ignore
                  rightIcon="chevron-down"
                >
                  {pitchFilterStatus}
                </MenuButton>
              </Box>
              <MenuList placement="bottom-start">
                <MenuItem
                  onClick={() => {
                    setSelectedTags([])
                    setPitchFilterStatus(SortFilterPitchesStatus.Draft)
                  }}
                >
                  {SortFilterPitchesStatus.Draft}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedTags([])
                    setPitchFilterStatus(
                      SortFilterPitchesStatus.RecentlyApproved
                    )
                  }}
                >
                  {SortFilterPitchesStatus.RecentlyApproved}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedTags([])
                    setPitchFilterStatus(SortFilterPitchesStatus.Completed)
                  }}
                >
                  {SortFilterPitchesStatus.Completed}
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
        {(pitchFilter === SortFilterPitches.ViewAll ||
          pitchFilter === SortFilterPitches.Recommended) && (
          <SideScroll>
            {selectedTags.map((text) => (
              <Button
                flexShrink={0}
                marginX={2}
                backgroundColor="#78E1D4"
                _hover={{ bg: "teal.600" }}
                color="fontDarkgray"
                key={text}
                onClick={() =>
                  setSelectedTags(
                    selectedTags.filter((t: string) => t !== text)
                  )
                }
              >
                {text}
              </Button>
            ))}
            {pitchTagList2 &&
              pitchTagList2.map(
                (tag: any) =>
                  selectedTags.filter((text: string) => tag.text === text)
                    .length === 0 && (
                    <Button
                      flexShrink={0}
                      key={tag.text}
                      marginX={2}
                      variant={"outline"}
                      color="fontDarkgray"
                      onClick={() =>
                        setSelectedTags([...selectedTags, tag.text])
                      }
                    >
                      {tag.text}
                    </Button>
                  )
              )}
          </SideScroll>
        )}
      </Flex>
      {console.log({ pitchFilterByTitleAndSort })}
      <Flex justify="center">
        {pitchFilter === SortFilterPitches.MyWork && <Requests mb="32px" />}
      </Flex>
      <Flex justify="center">
        <Box>
          {isMobileDevice ? (
            <Stack spacing={6}>
              <Fade triggerOnce duration={500}>
                {selectedTags && selectedTags.length > 0
                  ? filteredPitchList && filteredPitchList.length
                    ? filteredPitchList?.map((pitch) => {
                        return <PitchCard key={`${pitch.id}1`} pitch={pitch} />
                      })
                    : ["", "No Pitches Match", " "].map((e) => (
                        <HText key={e}>{e}</HText>
                      ))
                  : pitchFilterByTitleAndSort &&
                    pitchFilterByTitleAndSort.length
                  ? pitchFilterByTitleAndSort?.map((pitch: IPitch) => {
                      return <PitchCard key={`${pitch.id}2`} pitch={pitch} />
                    })
                  : ["", "No Pitches Match", " "].map((e) => (
                      <HText key={e}>{e}</HText>
                    ))}
              </Fade>
            </Stack>
          ) : (
            <SimpleGrid columns={[1, 1, 3]} spacingX={10} spacingY={10}>
              <Fade triggerOnce duration={500}>
                {selectedTags && selectedTags.length > 0
                  ? filteredPitchList && filteredPitchList.length
                    ? filteredPitchList?.map((pitch) => {
                        return <PitchCard key={`${pitch.id}1`} pitch={pitch} />
                      })
                    : ["", "No Pitches Match", " "].map((e) => (
                        <HText key={e}>{e}</HText>
                      ))
                  : pitchFilterByTitleAndSort &&
                    pitchFilterByTitleAndSort.length
                  ? pitchFilterByTitleAndSort?.map((pitch: IPitch) => {
                      return <PitchCard key={`${pitch.id}2`} pitch={pitch} />
                    })
                  : ["", "No Pitches Match", " "].map((e) => (
                      <HText key={e}>{e}</HText>
                    ))}
              </Fade>
            </SimpleGrid>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
