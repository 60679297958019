import React from "react"
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Text,
  Link,
} from "@chakra-ui/core"

//@ts-ignore
import gradient from "random-gradient"
import { Link as ReactRouterLink } from "react-router-dom"
import { HHeading, HText } from "../../Components/StyledTextComponents"
import { useAuth } from "../../Context/auth-context"
import { useCompany } from "../../Context/company-context"
import { usePitches } from "../../Context/pitches-context"
import { urls } from "../../App"
import { useWorkspaces } from "../../Context/workspace-context"
import { IWorkspace, IActivity } from "../../types"
import { FaCaretDown } from "react-icons/fa"
interface ProfileButtonProps {
  isOpen?: boolean
  onClickNext?: () => void
  onClickClose?: () => void
  isMobile: boolean
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({
  isOpen,
  isMobile,
  onClickNext,
  onClickClose,
}) => {
  const { userProfile, logout } = useAuth()

  const { companyUrl: company } = useCompany()
  const { workspaces, setCurrentWorkspace } = useWorkspaces()

  const { pitches } = usePitches()
  return (
    <>
      <Menu>
        <MenuButton outline="none">
          <Button
            outline="none"
            d="flex"
            variant="ghost"
            flexDirection="column"
            justifyContent="center"
            borderRadius="0px"
            borderBottom={
              window.location.pathname.indexOf("profile") !== -1
                ? "2px solid #4F4F4F"
                : "none"
            }
            alignItems="center"
            h="100%"
            w="94px"
            ml="0px"
            // ml={isMobile ? '0px' : '-8px'}
            color="fontDarkGray"
            fontWeight="600"
            mt="-4px"
            textTransform="capitalize"
            data-testid="nav-profile"
          >
            <Avatar
              h={"28px"}
              w={"28px"}
              p="0px"
              m="0px"
              background={gradient(userProfile?.lastName)}
              src={userProfile?.photo}
              name={userProfile?.firstName + " " + userProfile?.lastName}
            />
            {!isMobile && (
              <Flex>
                <Text
                  d="flex"
                  alignItems="center"
                  mb="3px"
                  color="fontDarkGray"
                  fontWeight="600"
                  fontSize="16px"
                  textTransform="capitalize"
                >
                  Account
                </Text>
                <FaCaretDown />
              </Flex>
            )}
          </Button>
        </MenuButton>

        <MenuList
          placement="bottom-end"
          zIndex={9999}
          p="16px"
          justifyContent="left"
        >
          <Flex justify="space-between" flexWrap="wrap" p={[0, "8px", "16px"]}>
            <Stack spacing="16px">
              <HHeading fontSize="16px" color="#333333">
                Your account
              </HHeading>
              {userProfile?.isAdmin && (
                <Box>
                  <ReactRouterLink to={`/${urls(company).adminDashboard}`}>
                    <HText data-testid="admin" fontSize="16px">
                      Admin Dashboard
                    </HText>
                  </ReactRouterLink>
                </Box>
              )}
              {userProfile?.isAdmin && (
                <Box>
                  <ReactRouterLink to={`/${urls(company).myTeam}`}>
                    <HText data-testid="manager" fontSize="16px">
                      My Team
                    </HText>
                  </ReactRouterLink>
                </Box>
              )}
              <Box>
                <ReactRouterLink to={`/${urls(company).profile}`}>
                  <HText data-testid="profile" fontSize="16px">
                    Profile
                  </HText>
                </ReactRouterLink>
              </Box>
              <Box>
                <ReactRouterLink to={`/${urls(company).settings}`}>
                  <HText fontSize="16px">Settings</HText>
                </ReactRouterLink>
              </Box>
              <Box>
                <Link onClick={logout} data-testid="logout">
                  <HText fontSize="16px">Log out</HText>
                </Link>
              </Box>
            </Stack>
          </Flex>
        </MenuList>
      </Menu>
      <Popover>
        <PopoverContent
          zIndex={9999}
          w="300px"
          background="#203854"
          borderColor="#203854"
        >
          <PopoverArrow />
          <PopoverCloseButton
            color="db"
            bg="#B3B3B3"
            borderRadius="100%"
            mt="4px"
          />
          <PopoverBody>
            <Text as="b" color="rgba(255, 255, 255, 0.8)" my="32px">
              Navigation
            </Text>
            <Text color="rgba(255, 255, 255, 0.8)" my="32px">
              {`Access to your\n
            - bio\n
            - settings\n
            - quick links and\n
            - log-out\n
            of huminate here`}
            </Text>
            <Flex justify="space-between">
              <Text color="rgba(255, 255, 255, 0.8)">Step 6/6</Text>
              <ButtonGroup size="sm">
                <Button
                  variantColor="teal"
                  borderRadius="16px"
                  onClick={onClickNext}
                  data-testid="next-tour-step"
                >
                  Next
                </Button>
              </ButtonGroup>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}
