import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/database'
import 'firebase/compat/functions'
import 'firebase/compat/auth'
import "firebase/compat/performance";


export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyD18E2D2Vmdhvy5UtaxcXjR2Dou4qG6K7Q',
  authDomain: 'huminate-e8f9b.firebaseapp.com',
  databaseURL: 'https://huminate-e8f9b.firebaseio.com',
  projectId: 'huminate-e8f9b',
  storageBucket: 'huminate-e8f9b.appspot.com',
  messagingSenderId: '92045274219',
  appId: '1:92045274219:web:a578bc17ead8a090',
}
export const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG)

firebaseApp.performance()

//@ts-ignore
window.firebaseApp = firebaseApp
