/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Box,
  Text,
} from '@chakra-ui/core'
import { Field, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropzone } from '../../Components/Dropzone'
import { OutlineButton, FillButton } from '../../Components/Buttons'
import { HInput } from '../../Components/StyledTextComponents'
import { useAuth } from '../../Context/auth-context'
import { useCompany } from '../../Context/company-context'
import { usePitches } from '../../Context/pitches-context'
import { required } from '../../formUtils'
import { IAttachment, IPitch, IManager } from '../../types'
import { Autosuggest } from '../../Components/Autosuggest'
import { useWorkspaces } from '../../Context/workspace-context'
import { DatePicker } from '../../Components/DatePicker'
import { getTime } from 'date-fns'

interface MyFormValues31 {
  title: string
}

export const CreateAPitchThreeOneView = ({ onNext, onBack }: any) => {
  const { companyUrl: company } = useCompany()
  const [files, setFiles] = useState<IAttachment[]>([])

  const [draftPitch, setDraftPitch] = useState<IPitch | null | undefined>()

  useEffect(() => {
    const d = window.sessionStorage.getItem('pitchDraft')
    if (d) {
      const draftP = JSON.parse(d)
      setDraftPitch(draftP)
      setFiles(draftP?.attachments)
    } else {
      setDraftPitch(null)
    }
  }, [])

  if (draftPitch === undefined || draftPitch === null) {
    return <Box width="100%" minHeight="400px" />
  }

  const initialValues: MyFormValues31 = {
    title: draftPitch?.title || '',
  }

  return (
    <Box width="100%" minHeight="400px" pb="16px">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          let newPitch: Partial<IPitch> = {
            title: values.title,
          }
          const coverPicture = files && files[0]
          if (coverPicture) {
            newPitch = { ...newPitch, coverPicture: coverPicture }
          }

          window.sessionStorage.setItem(
            'pitchDraft',
            JSON.stringify({ ...draftPitch, ...newPitch })
          )

          onNext()
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            style={{ width: '100%', height: 'calc(100% - 16px)' }}
          >
            <Flex
              direction="column"
              justify="space-between"
              height="100%"
              width="100%"
            >
              <>
                <Field name="title" validate={required}>
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.title && form.touched.title}
                      marginBottom="32px"
                    >
                      <FormLabel color="fontBlack" htmlFor="title">
                        Title
                      </FormLabel>
                      <HInput
                        mt={2}
                        data-testid="title"
                        {...field}
                        id="title"
                      />
                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.title}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="cover">
                  {({ form }: any) => (
                    <FormControl
                      isInvalid={form.errors.cover && form.touched.cover}
                      marginBottom="32px"
                    >
                      <FormLabel color="fontBlack" htmlFor="cover">
                        Cover image (optional)
                      </FormLabel>
                      <FormHelperText color="fontDarkGray" mb={2}>
                        Recommended size 1200px wide by 630 tall.
                      </FormHelperText>

                      <Dropzone
                        limit={1}
                        files={files}
                        setFiles={setFiles}
                        path={`${company}/pitch/${draftPitch.id}/cover`}
                      />
                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.cover}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </>
              <Flex justify="center" mt="0px">
                <OutlineButton
                  onClick={onBack}
                  data-testid="back"
                  style={{ marginRight: '8px' }}
                >
                  Back
                </OutlineButton>

                <FillButton
                  type="submit"
                  data-testid="next"
                  style={{ marginLeft: '8px' }}
                >
                  Next
                </FillButton>
              </Flex>
              <Flex width="100%" justify="center">
                <Flex
                  alignItems="center"
                  width="120px"
                  mt="16px"
                  justify="space-between"
                >
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkGray"
                    borderRadius={'100%'}
                  />
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Box>
  )
}

interface MyFormValues32 {
  pitchApprover: IManager | undefined
  submissionDate: Date
}

export const CreateAPitchThreeTwoView = ({ onNext, onBack }: any) => {
  const { createPitch } = usePitches()
  const { userProfile } = useAuth()
  const { users } = useWorkspaces()
  const [files, setFiles] = useState<IAttachment[]>([])
  const [pitchApproverChanged, setPitchApproverChanged] = useState<boolean>(
    false
  )

  const [draftPitch, setDraftPitch] = useState<IPitch | null | undefined>()

  useEffect(() => {
    const d = window.sessionStorage.getItem('pitchDraft')
    if (d) {
      const draftP = JSON.parse(d)
      setDraftPitch(draftP)
      setFiles(draftP?.attachments)
    } else {
      setDraftPitch(null)
    }
  }, [])

  if (draftPitch === undefined) {
    return <Box width="100%" minHeight="400px" />
  }

  const initialValues: MyFormValues32 = {
    pitchApprover: draftPitch?.manager || userProfile?.manager,
    submissionDate: draftPitch?.submissionDate
      ? new Date(draftPitch?.submissionDate)
      : new Date(),
  }

  return (
    <Box width="100%" minHeight="400px">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          let newPitch: Partial<IPitch> = {
            manager: values.pitchApprover,
            submissionDate: getTime(values.submissionDate),
          }
          const coverPicture = files && files[0]
          if (coverPicture) {
            newPitch = { ...newPitch, coverPicture: coverPicture }
          }
          //@ts-ignore
          createPitch({ ...draftPitch, ...newPitch }).then(() => onNext())
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            style={{ width: '100%', height: 'calc(100% - 16px)' }}
          >
            <Flex direction="column" justify="space-between" height="100%">
              <Box>
                <Field name="submissionDate" validate={required}>
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.submissionDate &&
                        form.touched.submissionDate
                      }
                      marginBottom="16px"
                    >
                      <FormLabel color="fontBlack" htmlFor="submissionDate">
                        Submission Date
                      </FormLabel>
                      <FormHelperText color="fontDarkGray">
                        Submit draft for approval by:
                      </FormHelperText>
                      <Box>
                        <DatePicker
                          testid="datepicker"
                          startDate={field.value}
                          handleChange={(val: any) =>
                            props.setFieldValue('submissionDate', val)
                          }
                        />
                      </Box>

                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.submissionDate}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="pitchApprover" validate={required}>
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.pitchApprover && form.touched.pitchApprover
                      }
                      marginBottom="32px"
                    >
                      <FormLabel color="fontBlack" htmlFor="pitchApprover">
                        Pitch approver
                      </FormLabel>
                      <FormHelperText color="fontDarkGray">
                        By default this is your manager, but you can change it
                        if required.{' '}
                      </FormHelperText>
                      <Autosuggest
                        options={users || []}
                        initialValue={
                          props.values.pitchApprover?.firstName +
                          ' ' +
                          props.values.pitchApprover?.lastName
                        }
                        onSelect={(val) => {
                          setPitchApproverChanged(true)
                          props.setFieldValue('pitchApprover', val)
                        }}
                      />
                      {/* <HInput
                      mt={2}
                      data-testid="first-name"
                      {...field}
                      id="firstName"
                    /> */}
                      <FormErrorMessage
                        style={{ height: '17px', marginBottom: '-25px' }}
                      >
                        {form.errors.pitchApprover}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Flex justify="center" mt="48px">
                <OutlineButton
                  onClick={onBack}
                  data-testid="back"
                  style={{ marginRight: '8px' }}
                >
                  Back
                </OutlineButton>
                <FillButton
                  type="submit"
                  data-testid="next"
                  style={{ marginLeft: '8px' }}
                >
                  Create Draft
                </FillButton>
              </Flex>
              <Flex justify="center" width="100%" mt="16px">
                <Flex
                  alignItems="center"
                  maxWidth="120px"
                  width="100%"
                  justify="space-between"
                >
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                  <Box
                    width="16px"
                    height="16px"
                    backgroundColor="darkBlue"
                    borderRadius={'100%'}
                  />
                </Flex>
              </Flex>
            </Flex>
          </form>
        )}
      </Formik>
    </Box>
  )
}
