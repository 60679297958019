import React from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  ModalFooter,
} from "@chakra-ui/core"
import {
  HHeading,
  HText,
  StyledTab,
} from "../../../Components/StyledTextComponents"
import {
  InvitePeople,
  EmailInvitePeople,
} from "../../../Components/InvitePeople"
import { FriendlyModal } from "../../../Components/FriendlyModal"

import { OutlineButton } from "../../../Components/Buttons"

interface InvitePeopleModalProps {
  onClose: () => void
  isOpen: boolean
  noTop?: boolean
}
export const InvitePeopleModal: React.FC<InvitePeopleModalProps> = ({
  isOpen,
  onClose,
  ...rest
}) => {
  return (
    <FriendlyModal isOpen={isOpen} onClose={onClose} {...rest} noButton>
      <HHeading mb={2}>Invite people</HHeading>
      <HText mb={8}>Search and add the skills you need for the pitch.</HText>
      <Tabs color="fontDarkGray">
        <TabList mb="16px">
          <StyledTab mx={2}>Search People</StyledTab>
          <StyledTab>Invite by Email</StyledTab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <InvitePeople />
          </TabPanel>

          <TabPanel>
            <EmailInvitePeople />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FriendlyModal>
  )
}
