import React from 'react'
import {
  Box,
  SimpleGrid,
  Flex,
  Image,
  Link,
  Stack,
  Icon,
} from '@chakra-ui/core'
import {
  HText,
  HHeading,
  HTextArea,
} from '../../Components/StyledTextComponents'
import { IPitch, PitchStatus } from '../../types'
interface PitchDetailProps {
  currentPitch?: IPitch
}
export const PitchDetail: React.FC<PitchDetailProps> = ({ currentPitch }) => {
  return (
    <Box flexGrow={1}>
      <HHeading mb={4} fontSize="lg">
        Description
      </HHeading>
      <HText whiteSpace="pre-line" mb={10}>
        {currentPitch?.description}
      </HText>
      {currentPitch?.attachments && currentPitch?.attachments.length > 0 && (
        <HHeading mb={4} fontSize="lg">
          Attachments
        </HHeading>
      )}
      <Flex>
        {currentPitch?.attachments
          ?.filter(({ type }) => type?.split('/')[0] === 'image')
          .map((attachment, i) => {
            return (
              <Flex
                background="white"
                key={i}
                h="150px"
                w="200px"
                mr="24px"
                mb="24px"
                align="center"
                justify="center"
              >
                <Link
                  h="100%"
                  w="100%"
                  href={attachment.url}
                  download={true}
                  isExternal
                >
                  <Image
                    h="100%"
                    w="100%"
                    objectFit="contain"
                    src={attachment.url}
                  />
                </Link>
              </Flex>
            )
          })}
      </Flex>

      {currentPitch?.attachments
        ?.filter(({ isLink }) => isLink)
        .map((attachment, i) => {
          return (
            <Stack key={i}>
              <Link
                isExternal
                fontSize="sm"
                color="textLink"
                href={
                  attachment.url.startsWith('http') ||
                  attachment.url.startsWith('mailto:')
                    ? attachment.url
                    : '//' + attachment.url
                }
              >
                {attachment.url}
              </Link>
            </Stack>
          )
        })}
      {currentPitch?.attachments
        ?.filter(({ isLink }) => !isLink)
        .filter(({ type }) => type?.split('/')[0] !== 'image')
        .map((attachment, i) => {
          return (
            <Stack key={i}>
              <Link
                isExternal
                fontSize="sm"
                color="textLink"
                href={attachment.url}
                download={true}
              >
                {attachment.name}
              </Link>
            </Stack>
          )
        })}
    </Box>
  )
}
