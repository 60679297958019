export interface MentionItem {
  display: string
  id: string
  type: null
}
export interface IPerson {
  id: string
  photo: string
  name: string
  position: string
  skills: string[]
  bio?: string
}

export enum TagTypes {
  skill = 'skill',
  interest = 'interest',
  strength = 'strength',
}
export interface ISkillTag {
  id: string
  text: string
  type: TagTypes
  company?: string
  toggled?: boolean
}

export interface IPitchSkillTag {
  tag: ISkillTag
  usersNotified: boolean
}
export interface IManager {
  uid: string
  firstName: string
  lastName: string
  photo: string
}
export interface IUserProfile {
  isAdmin?: boolean
  uid: string
  company?: string
  firstName?: string
  lastName?: string
  isExternal?: boolean
  email?: string
  position?: string
  photo?: string
  bio?: string
  manager?: IManager
  tags?: ISkillTag[]
  isInactive?: boolean
  pitchOrder?: any
  pitchStatusNotification?: boolean
  skillMatchPitchNotification?: boolean
  taggedCommentNotification?: boolean
  taskStatusNotification?: boolean
  pitchInvitesNotification?: boolean
  overduePitchNotification?: boolean
  // professionalExperience?: ISkillTag[];
  // interests?: ISkillTag[];
  // strengths?: ISkillTag[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updated?: any
  finishedSignUp?: boolean
  readActivities?: string[]
  finishedDashboardTutorial?: boolean
  pitches?: string[]
  hidePitchWorkflowPrompt?: boolean
  followedPitches?: string[]
  workspaces?: string[]
  hideInviteToolTip?: boolean
  hideAddTaskListToolTip?: boolean
  hideAddTaskToolTip?: boolean
  hideAssignTaskMembersToolTip?: boolean
}

export interface ITask {
  difficulty: number
  taskList: string
  id?: string
  isCompleted: boolean
  title: string
  isHighPriority?: boolean
  dueDate: number
  attachments?: IAttachment[]
  description: string
  member?: string[]
  notifyNewSkills?: boolean
  skillTags?: ISkillTag[]
}

export interface ITaskList {
  pitch: string
  id?: string
  name: string
  createdBy: string
  tasks: ITask[]
}

export enum PitchStatus {
  draft = 'Draft',
  overdueSubmission = 'Overdue Submission',
  pendingApproval = 'Pending Approval',
  inProgress = 'In Progress',
  overdue = 'Overdue',
  almostDue = 'Almost Due',
  complete = 'Complete',
  declined = 'Declined',
}

export enum PitchMemberTypes {
  collaborator = 'collaborator',
  champion = 'champion',
}
export interface IAttachment {
  name: string
  //use this to attach links to the pitch
  isLink?: boolean
  //this is the firebase download url or the link url
  url: string
  type?: string
}
export interface IPitchMemberAchievement {
  userId: string
  achievement: string
}

export interface IPitch {
  id?: string
  title: string
  description: string
  createdBy: string
  updatedAt?: {
    seconds: number
  }
  createdAt?: {
    seconds: number
  }
  approvedBy?: string
  approvedOn?: {
    seconds: number
  }
  submittedBy?: string
  submittedOn?: Date
  company?: string
  createdByFirstName: string
  createdByLastName: string
  skillTags: IPitchSkillTag[]
  members: string[]
  dueDate?: number
  submissionDate?: number
  taskLists?: string[]
  manager?: IManager
  coverPicture?: IAttachment
  attachments?: IAttachment[]
  status?: PitchStatus
  completion?: number
  completedBy?: string
  completedAt?: any
  comments?: IComment[]
  requests?: IRequest[]
  champions?: string[]
  pitchAchievement?: string
  pitchApprover?: any
  pitchMemberAchievements?: IPitchMemberAchievement[]
  workspaceUID?: string
  createdByProfilePicture?: string
  likesCount?: number
  taskCount?: number
  commentCount?: number
  archived?: boolean
}

export enum RequestStatuses {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined',
}
export interface IRequest {
  id?: string
  name: string
  userId: string
  updatedBy?: string
  status: RequestStatuses
  pitchReference: string
  type: PitchMemberTypes
}

export enum ActivityTypes {
  welcome = 'Welcome',
  createdPitch = 'Created pitch',
  invitedToPitch = 'Invited to Pitch',
  invitedToTask = 'Invited to Task',
  requestedToJoin = 'Requested to Join',
  acceptedInviteToPitch = 'Accepted Invited to Pitch',
  acceptedInviteToTask = 'Accepted Invited to Task',
  acceptedRequestToJoin = 'Accepted Request to Join',
  rejectedInviteToPitch = 'Rejected Invited to Pitch',
  rejectedInviteToTask = 'Rejected Invited to Task',
  rejectedRequestToJoin = 'Rejected Request to Join',
  updatedPitchTags = 'Updated Pitch Tags',
  taggedInComment = 'Tagged in Comment',
  commentedOnPitch = 'Comment on Pitch',
  pitchOverdueSubmission = 'Pitch Overdue for Submission',
  pitchSubmitForApproval = 'Pitch Submit for Approval',
  pitchDeclined = 'Pitch Declined',
  pitchApproved = 'Pitch Approved',
  pitchOverdueCompletion = 'Pitch Overdue for Completion',
  taskOverdueCompletion = 'Task Overdue for Completion',
  pitchCompleted = 'Pitch Completed',
  postCreated = 'Post Created',
}
export interface IActivity {
  id?: string
  type: ActivityTypes
  userId: string
  firstName?: string
  lastName?: string
  userPictureUrl?: string
  company: string
  time: number
  commentText?: string
  pitchApproverId?: string
  pitchTitle?: string
  pitchCoverPicture?: string
  taskTitle?: string
  taskId?: string
  invitedUserId?: string
  invitedFirstName?: string
  invitedLastName?: string
  invitedPictureUrl?: string
  comment?: string
  taggedUserId?: string
  taggedUserFirstName?: string
  taggedUserLastName?: string
  taggedUserPhotoUrl?: string
  skillTags?: string[]
  submissionDueDate?: string
  approverFirstName?: string
  approverLastName?: string
  competionDueDate?: string
  achievementText?: string
  userReference?: string
  pitchReference?: string
  read?: boolean
}

export interface IComment {
  id?: string
  userId: string
  time: number
  comment: string
  taggedUsersNotified: boolean
  taggedUsers: MentionItem[]
  replyTo?: string
}

export interface Auth0User {
  //   email: "dylan@exponentlabs.io"
  // email_verified: false
  // name: "dylan@exponentlabs.io"
  // nickname: "dylan"
  // picture: "https://s.gravatar.com/avatar/c78cb48d4d23a6108bb7ec386abf9ab0?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fdy.png"
  // sub: "auth0|5e2fec10cfb1a00cc86dabe5"
  // updated_at: "2020-07-03T03:14:21.639Z"

  email: string
  email_verified: boolean
  name: string
  nickname: string
  picture: string
  sub: string
  updated_at: string
}

export enum InviteStatuses {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
}

// invite a member outside the platform using the toOutsideEmail property
export interface IInvite {
  id?: string
  status?: InviteStatuses
  from?: string
  inviteSent?: boolean
  to?: string
  toOutsideEmail?: string
  toOutsideName?: string
  subject?: {
    task?: ITask
    reference?: string
    type?: PitchMemberTypes
  }
}

export interface ICompany {
  name: string
  workspaces: IWorkspace[]
}

export interface IWorkspace {
  company: string
  admin: IUserProfile | undefined
  members: IUserProfile[]
  isShared?: boolean
  isArchived?: boolean
  name: string
  slug: string
  createdAt?: {
    seconds: number
  }
  id?: string
  description?: string
  coverImage?: string
}
