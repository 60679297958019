/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useState } from 'react'
import { SignUpWrapper, SignUpButton } from '../SignupFlow/Layouts'
import {
  Flex,
  Box,
  FormControl,
  Input,
  FormErrorMessage,
  Text,
  Link,
} from '@chakra-ui/core'
import { Formik, Field } from 'formik'
import { Link as ReactRouterLink } from 'react-router-dom'
import { firebaseApp } from '../../firebase'

const required = (value: any) => {
  if (!value) {
    return 'This is required'
  }
}

const SignInToYourWorkspace: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  return (
    <SignUpWrapper>
      <Flex justify="center">
        <Box padding={['8px', '16px']}>
          <Text fontWeight="700" fontSize="3xl" color="fontBlack" my="40px">
            Sign in to your workspace
          </Text>
          <Formik
            initialValues={{
              workspaceUrl: '',
            }}
            onSubmit={(values, _actions) => {
              setIsLoading(true)
              setErrorMessage('')
              firebaseApp
                .firestore()
                .collection('companyEmails')
                .doc(values.workspaceUrl.toLowerCase())
                .get()
                .then((doc) => {
                  const d = doc.data()
                  if (d) {
                    window.location.href =
                      process.env.NODE_ENV === 'production'
                        ? `https://app.huminate.com/${values.workspaceUrl.toLowerCase()}/welcome/1`
                        : `http://localhost:3002/${values.workspaceUrl.toLowerCase()}/welcome/1`
                  } else {
                    console.log(errorMessage)
                    setIsLoading(false)
                    _actions.setFieldError(
                      'workspaceUrl',
                      `Oops, ${values.workspaceUrl} is not a Huminate workspace.`
                    )
                  }
                })
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Field name="workspaceUrl" validate={required}>
                  {({ field, form }: any) => (
                    <>
                      <FormControl
                        marginBottom="48px"
                        isInvalid={
                          form.errors.workspaceUrl && form.touched.workspaceUrl
                        }
                      >
                        <Flex>
                          <Input
                            variant="filled"
                            data-testid="workspace-url"
                            placeholder="your-workspace-url"
                            style={{ textAlign: 'right' }}
                            {...field}
                          />
                          <Flex direction="column" justify="center">
                            <Text
                              fontSize="lg"
                              ml="8px"
                              color="fontDarkGray"
                              height="24px"
                            >
                              .huminate.com
                            </Text>
                          </Flex>
                        </Flex>
                        <FormErrorMessage
                          style={{ height: '17px', marginBottom: '-25px' }}
                        >
                          {form.errors.workspaceUrl}
                        </FormErrorMessage>
                      </FormControl>
                      <Text fontSize="md" ml="4px" color="fontDarkGray">
                        Don’t know the URL of your workspace?
                      </Text>
                      <Link
                        // @ts-ignore
                        as={ReactRouterLink}
                        to="/find-workspace"
                        fontSize="md"
                        ml="4px"
                        color="textLink"
                      >
                        Find your workspace
                      </Link>
                      <Flex justify="flex-end" my="16px">
                        <SignUpButton
                          style={{ margin: '0px' }}
                          data-testid="continue-workspace"
                          disabled={isLoading}
                        >
                          Continue
                        </SignUpButton>
                      </Flex>
                    </>
                  )}
                </Field>
              </form>
            )}
          </Formik>
        </Box>
      </Flex>
    </SignUpWrapper>
  )
}

export default SignInToYourWorkspace
