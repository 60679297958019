/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Flex, IconButton, Link, Stack } from '@chakra-ui/core'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import { firebaseApp } from '../firebase'
import { IAttachment } from '../types'
import { HText } from './StyledTextComponents'

const storageRef = firebaseApp.storage().ref()

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '38px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

// path shoudl be the folder path for the upload, the filename is appended to this.
// no trailing slash needed

interface DropzoneProps {
  limit?: number
  path: string
  files: IAttachment[]
  setFiles: Dispatch<SetStateAction<IAttachment[]>>
}
export const Dropzone: React.FC<DropzoneProps> = ({
  path = 'images',
  files,
  setFiles,
  limit,
}) => {
  const [isUploading, setIsUploading] = useState(false)

  function onFilesDrop(newFiles: File[]) {
    setIsUploading(true)
    const newPath = path + `/${newFiles[0].name}`
    storageRef
      .child(newPath)
      .put(newFiles[0])
      .then((snapshot: any) => {
        snapshot.ref.getDownloadURL().then((res: string) => {
          setIsUploading(false)
          setFiles([
            ...files,
            { url: res, name: newFiles[0].name, type: newFiles[0].type },
          ])
        })
      })
  }
  function onFileDelete(name: string) {
    console.info('Deleting File')
    storageRef
      .child(path + '/' + name)
      .delete()
      .then((snapshot: any) => {
        console.info('Finished Deleting File')
        // this wont support multiple files with same name, we should use UUID or something
        setFiles([])
      })
      .catch((error) => {
        console.log(error)
        setFiles([]) // If the file delete fails (404s), at least empty the reference so you can upload something else
      })
  }

  const thumbs = files.map((file) => (
    <Flex
      key={file.url}
      w="100%"
      minH={12}
      p={4}
      backgroundColor="rgba(9, 19, 33, 0.0638304)"
      justify="space-between"
      align="center"
    >
      <Link href={file.url} isExternal={true}>
        <HText fontSize="sm">{file.name}</HText>
      </Link>
      <IconButton
        aria-label="remove image"
        icon="close"
        borderRadius="100%"
        variant="ghost"
        size="xs"
        color="fontBlack"
        onClick={() => onFileDelete(file.name)}
      />
    </Flex>
  ))

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled: (limit && limit <= files.length) || false,
    onDrop: (acceptedFiles) => {
      onFilesDrop(acceptedFiles)
    },
  })

  const style: DropzoneRootProps = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isUploading ? (
          <p>Uploading...</p>
        ) : (
          <p>Drop files here or click to upload</p>
        )}
      </div>
      {thumbs.length > 0 && <Stack mt={4}>{thumbs}</Stack>}
    </div>
  )
}
