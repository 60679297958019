import React, { useEffect, useState } from "react"

import { createCtx } from "./createCtx"
import { ActivityTypes, IActivity } from "../types"

import { firebaseApp } from "../firebase"
import { fromUnixTime } from "date-fns"
import { useAuth } from "./auth-context"

interface ActivitiesContextProps {
  activities: IActivity[] | undefined
  getMoreActivities: () => void
  getMoreAllActivities: () => void
  allActivities: IActivity[] | undefined
  followingActivities: IActivity[] | undefined
  achievementActivities: IActivity[] | undefined
}

const [useActivities, ActivitiesContextProvider] =
  createCtx<ActivitiesContextProps>()

const ActivitiesProvider: React.FC = ({ children }) => {
  const [activities, setActivities] = useState<IActivity[] | undefined>(
    undefined
  )
  const [allActivities, setAllActivities] = useState<IActivity[] | undefined>(
    undefined
  )
  const [followingActivities, setFollowingActivities] = useState<
    IActivity[] | undefined
  >(undefined)
  const [achievementActivities, setAchievementActivities] = useState<
    IActivity[] | undefined
  >(undefined)
  const { user, userProfile } = useAuth()

  const getMoreActivities = () => {
    return
    // if (activities && activities.length > 0) {
    //   const last = activities[activities.length - 1]

    //   firebaseApp
    //     .firestore()
    //     .collection('activities')
    //     .where('company', '==', window.location.pathname.split('/')[1])
    //     .orderBy('time', 'desc')
    //     .startAfter(last.time)
    //     .limit(15)
    //     .onSnapshot(
    //       (snapshot) => {
    //         const acts = snapshot.docs.map((x) => {
    //           const data: IActivity = x.data() as IActivity

    //           return { ...data, id: x.id }
    //         })

    //         setActivities([...activities, ...acts])
    //       },
    //       (err) => {
    //         console.warn(err)
    //       }
    //     )
    // }
  }

  const getMoreAllActivities = () => {
    if (allActivities && allActivities.length > 0) {
      const last = allActivities[allActivities.length - 1]

      firebaseApp
        .firestore()
        .collection("activities")
        .where("company", "==", window.location.pathname.split("/")[1])
        .orderBy("time", "desc")
        .startAfter(last.time)
        .limit(15)
        .onSnapshot(
          (snapshot) => {
            const acts = snapshot.docs.map((x) => {
              const data: IActivity = x.data() as IActivity

              return { ...data, id: x.id }
            })

            setAllActivities([...allActivities, ...acts])
          },
          (err) => {
            console.warn(err)
          }
        )
    }
  }

  // Need to set data from firestore here
  useEffect(() => {
    if (user?.sub && userProfile) {
      console.log({ test: userProfile.pitches })
      const unsubscribe = firebaseApp
        .firestore()
        .collection("activities")
        .where("company", "==", window.location.pathname.split("/")[1])
        .orderBy("time", "desc")
        .limit(50)
        .onSnapshot(
          (snapshot) => {
            const acts = snapshot.docs.map((x) => {
              const data: IActivity = x.data() as IActivity

              return { ...data, id: x.id }
            })

            const welcomeAct = acts.filter(
              ({ type, userId }) =>
                type === ActivityTypes.welcome && userId === user?.sub
            )

            const myPitchActs = acts.filter(
              ({ pitchReference }) =>
                userProfile.pitches?.indexOf(pitchReference || "") !==
                  undefined &&
                userProfile.pitches?.indexOf(pitchReference || "") > -1
            )

            const ApproverPitches = acts.filter(
              ({ pitchApproverId }) => pitchApproverId === user?.sub
            )

            const followedPitches = acts.filter(
              ({ pitchReference }) =>
                userProfile.followedPitches?.indexOf(pitchReference || "") !==
                  undefined &&
                userProfile.followedPitches?.indexOf(pitchReference || "") > -1
            )

            console.log({ welcomeAct })
            console.log({ myPitchActs })
            console.log({ followedPitches })
            console.log({ ApproverPitches })

            setActivities(
              [...welcomeAct, ...myPitchActs, ...ApproverPitches].sort(
                (a, b) => b.time - a.time
              )
            )
            setAllActivities(acts.sort((a, b) => b.time - a.time))
            setFollowingActivities(
              followedPitches.sort((a, b) => b.time - a.time)
            )
            setAchievementActivities(
              acts
                .filter(({ type }) => type === ActivityTypes.pitchCompleted)
                .sort((a, b) => b.time - a.time)
            )
          },
          (err) => {
            console.warn(err)
          }
        )
      return unsubscribe
    }
  }, [userProfile])

  return (
    <ActivitiesContextProvider
      value={{
        activities,
        allActivities,
        getMoreActivities,
        getMoreAllActivities,
        followingActivities,
        achievementActivities,
      }}
    >
      {children}
    </ActivitiesContextProvider>
  )
}

export { useActivities, ActivitiesProvider }
