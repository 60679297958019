import { useEffect, useState } from 'react'
import { firebaseApp } from './firebase'
import { IUserProfile, ITaskList } from './types'
import { useAuth } from './Context/auth-context'
import * as R from 'ramda'

export const useUserProfile = (id: string | undefined) => {
  const [userProfile, setUserProfile] = useState<IUserProfile | undefined>(
    undefined
  )
  const { loading } = useAuth()

  // Need to set data from firestore here
  useEffect(() => {
    if (id && !loading) {
      const unsubscribe = firebaseApp
        .firestore()
        .collection('users')
        .doc(id)
        .onSnapshot(
          (doc) => {
            const d = doc.data()
            if (d !== undefined) {
              setUserProfile({ ...doc.data(), uid: doc.id })
            }
          },
          (err) => {
            console.error(err)
          }
        )
      return unsubscribe
    }
  }, [id, loading])

  const updateUserProfile = (data: IUserProfile) => {
    if (id) {
      firebaseApp
        .firestore()
        .collection('users')
        .doc(id)
        // merge avoids overwriting the entire document
        .update(R.omit(['isAdmin', 'company'], data))
    }
  }

  return { userProfile, updateUserProfile }
}
