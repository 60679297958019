import React from "react";
import { ButtonProps, Button } from "@chakra-ui/core";

export const OutlineButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      variant="outline"
      color="brandSecondary"
      rounded="full"
      border="1px"
      borderColor="brandSecondary"
      {...rest}
    >
      {children}
    </Button>
  );
};

export const FillButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return (
    <Button
      color="white"
      rounded="full"
      backgroundColor="darkBlue"
      fontSize="md"
      fontWeight="500"
      {...rest}
    >
      {children}
    </Button>
  );
};
