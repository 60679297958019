import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/core'
import { HHeading, HText } from './../../../Components/StyledTextComponents'
import { usePitches } from '../../../Context/pitches-context'
import { useNavigate } from 'react-router-dom'
import { useCompany } from '../../../Context/company-context'
import { useWorkspaces } from '../../../Context/workspace-context'
import { urls } from '../../../App'

interface DeletePitchModalProps {
  onClose: () => void
  isOpen: boolean
  pitchId: string
}
export const DeletePitchModal: React.FC<DeletePitchModalProps> = ({
  isOpen,
  onClose,
  pitchId,
}) => {
  const { updatePitch } = usePitches()
  const navigate = useNavigate()
  const { companyUrl } = useCompany()
  const { currentWorkspace } = useWorkspaces()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="8px">
        <ModalCloseButton />
        <ModalBody paddingTop={10} paddingX={10}>
          <HHeading mb="29px" fontSize="xl">
            Archive pitch?
          </HHeading>
          <HText fontSize="sm">
            {`Are you sure you want to archive the pitch 'Pitch title'. You can restore an archived pitch by asking your site admin`}
          </HText>
        </ModalBody>
        <ModalFooter paddingBottom={10} paddingX={10}>
          <Button rounded="full" variant="ghost" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              updatePitch(pitchId, { archived: true })
              navigate(
                `/${urls(companyUrl, currentWorkspace?.slug).dashboard}`
              )
            }}
            color="white"
            backgroundColor="deleteRed"
            rounded="full"
          >
            Archive
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
