import * as React from 'react'
import {
  Flex,
  Box,
  Stack,
  Button,
  SimpleGrid,
  Text,
  Menu,
  MenuList,
  MenuItem,
  Divider,
  MenuButton,
} from '@chakra-ui/core'
import { isMobile } from './isMobile'

const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number
) => {
  let scrollAmount = 0
  const slideTimer = setInterval(() => {
    element.scrollLeft += step
    scrollAmount += Math.abs(step)
    if (scrollAmount >= distance) {
      clearInterval(slideTimer)
    }
  }, speed)
}

export const SideScroll = ({ children }: any) => {
  const contentWrapper = React.useRef(null)
  const isMobileDevice = isMobile()
  return (
    <Flex>
      <Button
        onClick={() => {
          //@ts-ignore
          sideScroll(contentWrapper.current, 25, 100, -10)
        }}
      >
        {'<'}
      </Button>
      <Flex
        width={isMobileDevice ? '240px' : '720px'}
        height="74px"
        overflowX="hidden"
        ref={contentWrapper}
      >
        {children}
      </Flex>

      <Button
        onClick={() => {
          //@ts-ignore
          sideScroll(contentWrapper.current, 25, 100, 10)
        }}
      >
        {'>'}
      </Button>
    </Flex>
  )
}
